import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUpldFiles } from "../../../redux/upldFilesSlice";
import { RootState } from "../../../redux/store";
import { FormControl, FormGroup, FormLabel, Select,
  SelectChangeEvent, MenuItem, ListItemText, TextField } from "@mui/material";
import { col1, col2, col3, col4, col5, colsTot } from "../DocumentList";
import { SingleFile } from "../../../Data/DocumentData";  //, UpldFile

const stats = ["Y", "N", "N/A"];
const remarks: {[key: string]: string} = {
  "Y": "Already received with thanks",
  "N": "Doc missing. Please give ASAP to commence our audit",
  "N/A": "Not applicable for the entity"
};

export default function DocumentSelect(props: { sl: string, doc: number, document: string,
  value:string|undefined, disabled:boolean, numDocs:string, select:{ id:string, name:string },
  onSelect: (fileNum: number, setFiles: React.Dispatch<React.SetStateAction<SingleFile[]>>,
    event: SelectChangeEvent<string>, child: React.ReactNode) => void,
  onFile: (fileNum: number, setFiles: React.Dispatch<React.SetStateAction<SingleFile[]>>,
    e: React.ChangeEvent<HTMLInputElement>) => void}) { //, upldFiles: Array<UpldFile>
  const dispatch = useDispatch(), maxLines = 10; //4
  const upldFiles = useSelector((state: RootState) => state.upldFiles.value);
  // const upldFiles = props.upldFiles;
  // console.log("DocumentSelect:: upldFiles:", upldFiles.length>0?upldFiles[0]:upldFiles);
  let inputRef: Array<React.MutableRefObject<HTMLInputElement | null>> = [];
  //for(let i=0; i<maxLines; i++) inputRef[i] = useRef<HTMLInputElement | null>(null);
  inputRef[0] = useRef<HTMLInputElement | null>(null);
  inputRef[1] = useRef<HTMLInputElement | null>(null);
  inputRef[2] = useRef<HTMLInputElement | null>(null);
  inputRef[3] = useRef<HTMLInputElement | null>(null);
  inputRef[4] = useRef<HTMLInputElement | null>(null);
  inputRef[5] = useRef<HTMLInputElement | null>(null);
  inputRef[6] = useRef<HTMLInputElement | null>(null);
  inputRef[7] = useRef<HTMLInputElement | null>(null);
  inputRef[8] = useRef<HTMLInputElement | null>(null);
  inputRef[9] = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = (i: number, e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); //console.log("handleUploadClick:: sl:", props.sl);
    inputRef[i].current?.click();
  }, doc = props.doc, [files, setFiles] = useState<Array<SingleFile>>( //parseInt(props.sl) - 1
    upldFiles && upldFiles[doc] && upldFiles[doc].files? upldFiles[doc].files: []
  ), addToFiles = () => { if(files.length < maxLines) {
    const file: SingleFile = {name:"", path:"", mime:"", gcsUrl:"", name1:"",
      path1:"", mime1:"", gcsUrl1:"", desc:"", stat:"N", user:"", time:"", usr: null,
    }; setFiles(files => { const newFiles = [...files, file], newUpldFiles = [...upldFiles];
      newUpldFiles[doc] = {...newUpldFiles[doc], files: newFiles};
      dispatch(setUpldFiles(newUpldFiles)); return newFiles;
    });
  } }; let uploaded:Array<boolean> = [], urls:Array<string> = [], emails:Array<string> = [],
    names:Array<string> = [], times:Array<string> = [];
  //setFiles(upldFiles && upldFiles[doc] && upldFiles[doc].files? upldFiles[doc].files: []);

  if(props.numDocs==="S" && files.length < 1) addToFiles();

  useEffect(() => {
    setFiles(files => {
      //console.log("doc:", doc, ", files:", (files && files.length>0? files[0].usr: files));
      let newFiles = (upldFiles && upldFiles[doc] && upldFiles[doc].files ? [...upldFiles[doc].files]: []);
      // for(let i=0; i<newFiles.length; i++) {
      //   newFiles[i] = {...newFiles[i], first_name: (newFiles[i].usr? newFiles[i].usr.first_name: "")}
      // }
      //if(doc<3)console.log("doc:", doc, ", newFiles:", (newFiles && newFiles.length>0? newFiles[0].usr: newFiles));
      const file: SingleFile = {name:"", path:"", mime:"", gcsUrl:"", name1:"",
        path1:"", mime1:"", gcsUrl1:"", desc:"", stat:"N", user:"", time:"", usr: null,
      }; if(props.numDocs==="S" && newFiles.length<1) newFiles.push(file);
      //console.log("newFiles:",(newFiles.length>0?newFiles[0]:newFiles));
      return newFiles;
    });
  }, [ upldFiles ]);

  const handlePlusButton = (e:React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); addToFiles();
  };

  const handleTextChange = (i:number, e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { name, value } = e.target; //console.log("handleTextChange: name:",name, ",value:",value, ", i:",i)
    // const newUpldFiles = [...upldFiles]; newUpldFiles[doc].files[i].name = value;
    // dispatch(setUpldFiles(newUpldFiles));
    setFiles(files => { const newFiles = [...files], newUpldFiles = [...upldFiles];
      newFiles[i] = {...newFiles[i], [name]: value}; //desc
      newUpldFiles[doc] = {...newUpldFiles[doc], files: newFiles};
      dispatch(setUpldFiles(newUpldFiles)); return newFiles;
    });
  };

  // const handleSelectChange = (i:number, e:SelectChangeEvent<string>) => {
  //   const { target: {name, value} } = e; console.log("name:",name, ",value:", value, ", i:",i);
  //   setFiles(files => { const newFiles = [...files], newUpldFiles = [...upldFiles];
  //     newFiles[i] = {...newFiles[i], stat: value}; //[name]
  //     newUpldFiles[doc] = {...newUpldFiles[doc], files: newFiles};
  //     dispatch(setUpldFiles(newUpldFiles)); return newFiles;
  //   });
  //   //(props.onSelect.bind(null,i))(e);
  //   props.onSelect(i, setFiles, e, null); //Add setFiles
  // };

  files.forEach((file, i) => { if(file) {
    uploaded[i] = Boolean(file.path); //upldFiles[doc].path;
    urls[i] = (file.gcsUrl? file.gcsUrl: file.path);
    emails[i] = (file.user? file.user: file.name); //console.log("file:", file);
    names[i] = (file.usr? file.usr.first_name: "");
    const time = new Date(file.time);
    times[i] = `${time.getDate()}/${time.getMonth()+1}/${time.getFullYear().toString().substring(2)}`;
  }});
  //if(doc<3)console.log("doc:", doc, ", upldFiles[doc]:", upldFiles[doc], ", files:", files, ", emails:", emails, names);

  return (<>
    <FormControl sx={{paddingTop:0, position:"relative"}} size="small" disabled={props.disabled}>{
      !props.sl?(
        <FormGroup>
          <FormLabel sx={{paddingTop: 1, width: colsTot, border: 1,
            paddingRight: 1}}> {props.document}
          </FormLabel>
        </FormGroup>
      ):
      (<FormGroup row sx={{justifyContent: "space-between", borderBottom: 1}}>
        <FormLabel sx={{paddingTop: 1, textAlign: "right", width: col1, border: 1,
          paddingRight: 1}}> {props.sl}
        </FormLabel>
        <FormGroup sx={{ border: 1, borderTop:(props.disabled?0:1) }}>
          <FormLabel sx={{paddingTop: 1, paddingLeft: 1, width: col2}}>{props.document}</FormLabel>
          {props.numDocs==="M"?(files.map((file, i) => (
            <TextField name="desc" variant="outlined" size="small" sx={{width: col2}} key={i}
              value={files[i].desc} disabled={props.disabled} onChange={handleTextChange.bind(null,i)}
            />
          ))):null}
        </FormGroup>
        {props.numDocs==="S"? (
          <Select renderValue={(selected: string) => selected} value={files[0]?files[0].stat:"N"}
            {...props.select} sx={{width: col3, border: 1}} onChange={props.onSelect.bind(null,0,setFiles)}
            >{stats.map(stat=><MenuItem value={stat} key={stat}><ListItemText primary={stat} /></MenuItem>)}
          </Select>
        ): (<FormGroup>
          <FormLabel sx={{paddingTop: 1, width: col3}}>.</FormLabel>
          {files.map((file, i) => (
            <Select renderValue={(selected:string)=>selected} value={files[i].stat} key={i}
              {...props.select} sx={{width: col3, border: 0}} onChange={props.onSelect.bind(null,i,setFiles)}
              >{stats.map(stat=><MenuItem value={stat} key={stat}><ListItemText primary={stat} /></MenuItem>)}
            </Select>
          ))}
        </FormGroup>)}
        {props.numDocs==="S"? (
          <FormLabel sx={{paddingTop: 1, paddingLeft: 1, width: col4, border: 1}}>
            {files && files[0] && files[0].stat? remarks[files[0].stat]: ""}
          </FormLabel>
        ): (<FormGroup>
          <FormLabel sx={{paddingTop: 1, width: col4, borderLeft:1, borderRight:1}}>.</FormLabel>
          {files.map((file, i) => (
            <FormLabel sx={{padding: 1, paddingRight: 0, width: col4, border: 1}}>
              {/* {files[i].stat? remarks[files[i].stat]: "."} */}
              {file.stat? remarks[file.stat]: "."}
            </FormLabel>
          ))}
        </FormGroup>)}
        <FormGroup>
          {props.numDocs==="S"?
            <FormLabel sx={{paddingTop: 1, paddingLeft: 2, width: col5}}>
              {uploaded[0]? (props.disabled? <a href={urls[0]} target="_blank">
                {/* {files[0].name.substring(0,35)}</a>: files[0].name.substring(0,35) */}
                {times[0]}, {names[0]? names[0]: emails[0]}</a>: times[0]+", "+(names[0]? names[0]: emails[0])
              ):<button style={{borderRadius:"5px", borderWidth:"1px", cursor:(props.disabled?
                "default":"pointer"), color:(props.disabled?"#999":"#000"), padding:"3px 15px"}}
              onClick={handleUploadClick.bind(null,0)} disabled={props.disabled}>Upload</button>}</FormLabel>

            : (<>
              <FormLabel sx={{paddingTop: 0, paddingLeft: 2, width: col5}}>
                <button style={{borderRadius:"5px", borderWidth:"1px", marginLeft:"1vw", cursor:(props.disabled?
                "default":"pointer"), color:(props.disabled?"#999":"#000"), padding:"3px 15px"}}
                onClick={handlePlusButton} disabled={props.disabled}>+</button></FormLabel>
              {files.map((file, i) => (<>
                <FormLabel sx={{paddingTop: 1, paddingLeft: 2, width: col5}} key={i}>
                  {uploaded[i]? (props.disabled? <a href={urls[i]} target="_blank">
                    {/* {upldFiles[doc].files[i].name.substring(0,35)}</a>: upldFiles[doc].files[i].name.substring(0,35)) */}
                    {times[i]}, {names[i]? names[i]: emails[i]}</a>: times[i]+", "+(names[i]? names[i]: emails[i])
                  ):<button style={{borderRadius:"5px", borderWidth:"1px", padding:"3px 15px",
                    cursor:(props.disabled?"default":"pointer"), color:(props.disabled?"#999":"#000")}}
                  onClick={handleUploadClick.bind(null,i)} disabled={props.disabled}>Upload</button>}
                </FormLabel>{ i > 0 ?
                  <input type="file" key={i} ref={inputRef[i]} onChange={props.onFile.bind(null,i,setFiles)} style={{display:"none"}} />
                : null }
              </>))}
            </>)
          }
        </FormGroup>
        <input type="file" ref={inputRef[0]} onChange={props.onFile.bind(null,0,setFiles)} style={{display:"none"}} />
      </FormGroup>)
    }</FormControl>
  </>);
};

// export default function DocumentSelect(props: { sl: number,
//   document: string, value: string | undefined, select: {id: string, name: string,
//   onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void}}) {
//   return (<>
//     <FormControl sx={{paddingTop: 0}} size="small">
//       <FormGroup row sx={{justifyContent: "space-between"}}>
//         <FormLabel sx={{paddingTop: 1, textAlign: "right", width: col1, border: 1,
//           paddingRight: 1}}> {props.sl}
//         </FormLabel>
//         <FormLabel sx={{paddingTop: 1, paddingLeft: 1, width: col2, border: 1}}>
//           {props.document}
//         </FormLabel>
//         <Select renderValue={(selected: string) => selected} value={props.value}
//           sx={{width: col3, border: 1}} {...props.select}>
//           {stats.map(stat => (
//             <MenuItem value={stat} key={stat}><ListItemText primary={stat} /></MenuItem>
//           ))}
//         </Select>
//         <FormLabel sx={{paddingTop: 1, paddingLeft: 1, width: col4, border: 1}}>
//           {props.value? remarks[props.value]: ""}
//         </FormLabel>
//       </FormGroup>
//     </FormControl>
//   </>);
// };
