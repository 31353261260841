import { Autocomplete, TextField } from "@mui/material"; //, AutocompleteProps
import { minWidth } from "../ContactForm";

const roles = ["Software Dev", "Architect", "Designer", "Business Analyst"];

export default function BeautifulAutocomplete(props: {value: string,
onInputChange: (event: React.SyntheticEvent<Element, Event>, value: string) => void}) {
  return (
    <Autocomplete options={roles} sx={{minWidth: minWidth}}
      renderInput={(params) => (<TextField name="role" {...params} />)}
      getOptionLabel={(roleOption) => `${roleOption}`}
      renderOption={(props,option) => (<li {...props}>{`${option}`}</li>)}
      {...props} // value={formValues.role || ""}
      isOptionEqualToValue={(option, value) => option === value || value === ""}
      // onInputChange={handleAutoCompleteChange}
    />
  );
};
