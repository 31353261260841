import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UpldFile as DrtyFile } from "../Data/DocumentData";
//import type { RootState } from "./store";

//interface UpldFilesState { value: Array<{name:string, path:string}> };
interface DrtyFilesState { value: Array<DrtyFile> };
const initialState: DrtyFilesState = { value: [] };

export const drtyFilesSlice = createSlice({
  name: "drtyFiles", initialState, reducers: { setDrtyFiles: (state, action:
    PayloadAction< Array<DrtyFile> >) => {
      //console.log("setDrtyFiles:: state.value:", state.value, ", action.payload:", action.payload);
      state.value = action.payload;
      //console.log("setDrtyFiles:: state.value:", state.value);
    }
  }
});

export const { setDrtyFiles } = drtyFilesSlice.actions;
export default drtyFilesSlice.reducer;
