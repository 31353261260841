import { useState, useEffect, useRef } from "react";
//import { createReparentableSpace } from "react-reparenting";
import { Autocomplete, FormControl, FormGroup, Paper, Button } from "@mui/material";
import { TextField, SelectChangeEvent, Dialog, Alert, AlertTitle } from "@mui/material";
import { FormLabel, Select, MenuItem, ListItemText, InputAdornment } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Stack } from "@mui/system";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Company, FormValues, FinNote, findValues, getBal, balIndex, getNoteBal, getNoteMax, NoteBal,
  getBalances, getNotes, getAnnexes, getSces, SfpData, PnlData, KeyNote, getKeyNote } from "../../Data/FinancialData";
import FinExcel, { getFinExData } from "../../Data/FinExcel";
import { commaFormat, negBracket, removeComma } from "../../Data/DataUtility";
import { updateNoteBals } from "./AnnexureA";
import { months, refreshFinData } from "./FinancialPosition";

let btn1Handler = () => {}, btn2Handler = () => {};
export const dfNotes = getNotes("", []);

export const updNoteTotals = ( setFormValues: React.Dispatch<React.SetStateAction<FormValues>>,
  setPass: React.Dispatch<React.SetStateAction<number>>,
  pass=2, func=null) => { console.log("Notes:: updNoteTotals - pass:", pass); //, processing=true
  dfNotes.forEach((nt, i) => { if(nt.link) { //console.log("i:", i, ", nt:", nt); //values.notes
    const rules = nt.link.split(","); //console.log("i:", i, ", note:", nt.note, ", rules:", rules);
    rules.forEach(rule => { const splt = rule.split(":"), ln = splt[0];
      let rl = ""; if(splt.length > 1) rl = splt[1]; //console.log("rule:",rule,", splt:",splt,", ln:",ln);
      if(pass===1 && (ln==="T"||ln==="M"||ln==="O"||ln[0]==="U")) {
        processRule(nt, i, ln, rl, setFormValues); setTimeout(() => setPass(2), 20);
      } else if(pass===2 && (ln[0]==="2"||ln==="T2")) {
        processRule(nt, i, ln, rl, setFormValues); setTimeout(() => setPass(3), 20);
      } else if(pass===3 && (ln[0]==="3"||ln==="T3")) {
        processRule(nt, i, ln, rl, setFormValues); setTimeout(() => setPass(4), 20);
      } else if(pass===4 && (ln[0]==="4"||ln==="T4")) {
        processRule(nt, i, ln, rl, setFormValues); setTimeout(() => setPass(5), 20);
      } else if(pass===5 && (ln[0]==="5"||ln==="T5")) {
        processRule(nt, i, ln, rl, setFormValues); setTimeout(() => setPass(6), 20);
      } else if(pass===6 && (ln[0]==="6"||ln==="T6")) {
        processRule(nt, i, ln, rl, setFormValues); setTimeout(() => setPass(7), 20);
      } else if(pass===7 && (ln[0]==="7"||ln==="T7")) {
        processRule(nt, i, ln, rl, setFormValues); setTimeout(() => setPass(8), 20);
      } else if(pass===8 && (ln[0]==="8"||ln==="T8")) {
        processRule(nt, i, ln, rl, setFormValues); setTimeout(() => setPass(9), 20);
      } else if(pass===9 && (ln[0]==="9"||ln==="T9")) {
        processRule(nt, i, ln, rl, setFormValues); setTimeout(() => setPass(10), 20);
      } else if(pass===10 && ((ln[0]==="1"&&ln[1]==="0")||ln==="T10")) {
        processRule(nt, i, ln, rl, setFormValues); setTimeout(() => setPass(0), 20);
        //console.log("pass:", pass, ", func:", (typeof func), func);
      }
    });
  }});
}

const processRule = (nt:FinNote, i:number, line:string, rule:string, // values:FormValues
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>) => { let x;
  //if((!isNaN(x=parseFloat(line))&&x>8)||line==="10T") 
  //console.log("processRule:: i:", i, ", note:", nt.note, ", line:", line, ", rule:", rule, ", x:", x);
  setFormValues(oldValues => { const values = {...oldValues};
  const bals = values.balances, bal1 = values.balance1;
  const mdl = rule.substring(0,1), tgt = rule.substring(1); //console.log("mdl:", mdl, ", tgt:", tgt);
  if(line[0]==="T"||line[1]==="T"||line[2]==="T"||line[0]==="M"||line[1]==="M"||line==="2"||
    line==="3"||line==="4"||line==="5"||line==="6"||line==="7"||line==="8"||line==="9"||line==="10") {
    const nbl = (line[0]==="M"||line[1]==="M"? getNoteMax: getNoteBal)(nt.note, bals); //Maximum
    const nb1 = (line[0]==="M"||line[1]==="M"? getNoteMax: getNoteBal)(nt.note, bal1);
    //console.log("nbl:", nbl, ", nb1:", nb1);
    if(mdl==="A") { const splt = tgt.split("|"), ai = parseInt(splt[0]); // Annexure
      let ali = -1; if(splt.length>1) ali = parseInt(splt[1].substring(1)); //console.log("ai:",ai,", ali:",ali);
      if(!isNaN(ai) && !isNaN(ali) && ali>=0) { const aln = values.annexes[ai].lines[ali];
        const al1 = values.annex1[ai].lines[ali]; //console.log("aln:", aln, ", al1:", al1);
        const chWdv = nbl.bal - (aln.bal-aln.depBal), chWd1 = nb1.bal - (al1.bal-al1.depBal);
        aln.depAdj -= chWdv; aln.depBal -= chWdv; al1.depAdj -= chWd1; al1.depBal -= chWd1;
    } } else
    if(mdl==="O") { const splt = tgt.split("|"), note = splt[0]; // Opening Balance
      const nbO = bals[balIndex(note, 0, bals)];  // bals = formValues.balances,
      if(Math.abs(nb1.bal)>0.001) {
        nbO.bal = nb1.bal; //console.log("line:", line, ", mdl:", mdl, ", note:", note, ", nb1:", nb1);
    } } else
    if(mdl==="N") { const splt = tgt.split("|"), note = splt[0]; // Note Line Balance
      let nli = -1, add = false, sign = 1, pct = 1, pc1 = 1;
      //if((!isNaN(x=parseFloat(line))&&x>8)||line==="10T") console.log("line:", line, ", note:", nt.note, ", mdl:", mdl);
      if(splt.length>1) { if(splt[1][0]==="A") add = true;
        if(splt[1]==="M") sign = -1;
        if(splt[1].length>1) nli = parseInt(splt[1].substring(1));
        //console.log("splt[1]:", splt[1], ", splt[1][0]:", splt[1][0], ", add:", add, ", sign:", sign);
      }
      if(splt.length>2) { if(splt[2][0]==="A") add = true;
        if(splt[2]==="M") sign = -1;
        if(splt[2].length>1) {
          if(splt[2][0]==="P") { const pNote = splt[2].substring(1); let pli = 0; // Percent
            if(splt.length>3) pli = parseInt(splt[3].substring(1));
            pct = bals[balIndex(pNote, (isNaN(pli)||pli<0? 0: pli), bals)].bal/100;
            pc1 = bal1[balIndex(pNote, (isNaN(pli)||pli<0? 0: pli), bal1)].bal/100;
          } else nli = parseInt(splt[2].substring(1));
        } //console.log("splt[2]:", splt[2], ", splt[2][0]:", splt[2][0], ", add:", add, ", sign:", sign, ", pct:", pct);
      }
      if(splt.length>3) { add = (splt[3][0]==="A"); if(splt[3]==="M") sign = -1; }
      const nbN = bals[balIndex(note, (isNaN(nli)||nli<0? 0: nli), bals)];
      const n1N = bal1[balIndex(note, (isNaN(nli)||nli<0? 0: nli), bal1)];
      //if(add) console.log("note:", nt.note, note, ", nbN:", nbN, ", nbl:", nbl);
      nbN.bal = (add?nbN.bal:0) + nbl.bal * sign*pct; n1N.bal = (add?n1N.bal:0) + nb1.bal * sign*pc1;
      //if((!isNaN(x=parseFloat(line))&&x>8)||line==="10T") console.log("mdl:",mdl,", note:",note,", nbl:",nbl,", nb1:",nb1,", nbN:",nbN,", n1N:",n1N);
    }
  } else if(line[0]==="U"||line[1]==="U") {
    //const li = parseInt(line.substring(1)); if(!isNaN(li)&&li>=0) {
    const li = parseInt(line.substring((line[0]==="U"? 1: 2))); if(!isNaN(li)&&li>=0) {
      const blS = bals[balIndex(nt.note, li, bals)], bl1 = bal1[balIndex(nt.note, li, bal1)];
      if(mdl==="N") { const splt = tgt.split("|"), note = splt[0]; // Note Line Balance
        let nli = -1, add = false, sign = 1;
        if(splt.length>2) { if(splt[2][0]==="A") add = true;
          if(splt[2]==="M") sign = -1;
          if(splt[2].length>1) nli = parseInt(splt[2].substring(1));
        }
        if(splt.length>1) { if(splt[1][0]==="A") add = true;
          if(splt[1]==="M") sign = -1;
          if(splt[1].length>1) nli = parseInt(splt[1].substring(1));
          //console.log("splt[1]:", splt[1], ", splt[1][0]:", splt[1][0], ", add:", add);
        }
        const nbN = bals[balIndex(note, (isNaN(nli)||nli<0? 0: nli), bals)];
        const n1N = bal1[balIndex(note, (isNaN(nli)||nli<0? 0: nli), bal1)];
        nbN.bal = (add?nbN.bal:0) + blS.bal * sign; n1N.bal = (add?n1N.bal:0) + bl1.bal * sign;
        //console.log("mdl:",mdl,", note:",note,", li:",li,", blS:",blS,", bl1:",bl1,", nbN:",nbN,", n1N:",n1N);
      } else
      if(mdl==="O") { const splt = tgt.split("|"), note = splt[0]; // Opening Balance
        const nbO = bals[balIndex(note, 0, bals)];  // bals = formValues.balances,
        let sign = 1; if(splt.length>1 && splt[1]==="M") sign = -1;
        if(Math.abs(bl1.bal)>0.001) {
          nbO.bal = bl1.bal * sign; //console.log("line:", line, ", mdl:", mdl, ", note:", note, ", bl1:", bl1);
      } }
  } } else if(line[0]==="J"||line[1]==="J") { //console.log("line:", line, ", tgt:", tgt);
    const li = parseInt(line.substring((line[0]==="J"? 1: 2))); if(!isNaN(li)&&li>=0) {
      const blS = bals[balIndex(nt.note, li, bals)]; //console.log("li:", li, ", mdl:", mdl, ", bals:", bals);
      if(mdl==="N") { const splt = tgt.split("|"), note = splt[0]; // Note Line Adjustment
        let nli = -1, add = false, sign = 1;
        if(splt.length>2) { if(splt[2][0]==="A") add = true;
          if(splt[2]==="M") sign = -1;
          if(splt[2].length>1) nli = parseInt(splt[2].substring(1));
        }
        if(splt.length>1) { if(splt[1][0]==="A") add = true;
          if(splt[1]==="M") sign = -1;
          if(splt[1].length>1) nli = parseInt(splt[1].substring(1));
        } //console.log("splt:", splt, ", add:", add, ", nli:", nli);
        const nbN = bals[balIndex(note, (isNaN(nli)||nli<0? 0: nli), bals)];
        //console.log("nbN.bal:", nbN.bal, ", nbN:", nbN);
        nbN.bal = (add?nbN.bal:0) + blS.num * sign;
        //console.log("mdl:",mdl,", note:",note,", li:",li,", blS:",blS,", bl1:",bl1,", nbN:",nbN,", n1N:",n1N);
      }
  } }
  return values;
})};

interface IChild {
  onKeyUp: (ref: React.RefObject<HTMLDivElement>, event: React.KeyboardEvent<HTMLDivElement>) => void,
  onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  name: string, value: string, width:string, inputRef: React.RefObject<HTMLDivElement>
};

const Child = ({ name, value, width, onKeyUp, onBlur, inputRef}: IChild) => { //console.log("props:", props);
  return ( <TextField name={name} variant="outlined" size="small" sx={{width}} autoFocus
  onFocus={(e:React.FocusEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    console.log("Child:: onFocus:: name:", name, ", value:", value); //, ", e:", e);
    e.target.value = value; //console.log("e.target.style:", e.target.style);
    //e.target.style.width = "100%"; //"20px";
    // const styles = getComputedStyle(e.target); console.log("Child:: styles:", styles);
    // console.log("e.target.parentNode:", e.target.parentNode);
    // if(e.target.parentElement) { //console.log("e.target.parentElement.style:", e.target.parentElement.style);
    //   e.target.parentElement.style.width = "14.8vw"; //"15vw"; //"20.75vw"; //"50px";
    // }
  }} ref={inputRef} onBlur={onBlur} onKeyUp={(onKeyUp.bind(null, inputRef))} /> );
};

//const svBals: { bals: Array<NoteBal> } = { bals: [] };

interface IProps { //
  financialData: FormValues[],
  setFinancialData: React.Dispatch<React.SetStateAction<FormValues[]>>,
  companies: Company[], sfp: SfpData[], pnl: PnlData[], keyNotes: KeyNote[],
  setSvBals: React.Dispatch<React.SetStateAction<NoteBal[]>>,
  currentId: string, setCurrentId: React.Dispatch<React.SetStateAction<string>>,
  getDefaultFormValues: (data: Array<FormValues>|null, id: string) => FormValues,
  formValues: FormValues, setFormValues: React.Dispatch<React.SetStateAction<FormValues>>,
  inputMode: number, setInputMode: React.Dispatch<React.SetStateAction<number>>,
  setPass: React.Dispatch<React.SetStateAction<number>>, pass: number,
  //, userEmail: string, userType: string,
};

export default function Notes({ financialData, setFinancialData, companies,
  currentId, setCurrentId, getDefaultFormValues, formValues, setFormValues,
  sfp, pnl, keyNotes, inputMode, setInputMode, setPass, pass}: IProps) { //, userEmail, userType

  const [alertOpen, setAlertOpen] = useState(false); //let svBals:Array<NoteBal> = [];
  const [alertMesg, setAlertMesg] = useState("");
  const [btn1Text, setBtn1Text] = useState("");  // Ok
  const [btn2Text, setBtn2Text] = useState("");  // Cancel
  const textRef = useRef<HTMLInputElement>();
  const autoRef = useRef<HTMLInputElement>();
  const dateRef = useRef<HTMLInputElement>();
  const [selName, setSelName] = useState("");
  const [selYear, setSelYear] = useState("");
  const [ntChanged, setNtChanged] = useState(false);
  const [anxChanged, setAnxChanged] = useState(false);
  const [processing, setProcessing] = useState(false);
  //const [pass, setPass] = useState(1); //console.log("keyNotes:", keyNotes);
  const [tfChanged, setTfChanged] = useState({ name:"", value:"" });
  const [tfFocused, setTfFocused] = useState({ name:"", value:"" });
  const [editing, setEditing] = useState("");
  //const {Reparentable, sendReparentableChild} = createReparentableSpace();
  const arStr: Array<string> = [];
  const getParents = (parent = "tfHome") => { //console.log("getParents:", parent);
    const parnts: {[key:string]: Array<string>} = {};
    parnts[parent] = ["tfInput"]; //console.log("parnts:", parnts);
    return parnts;
  };
  const [parents, setParents] = useState(getParents); //{ tfHome: ["tfInput"], parentA: arStr }
  const inputRef = useRef<HTMLDivElement>(null); //console.log("inputRef:", inputRef);

  const updateTotals = () => { //console.log("Notes:: updateTotals");
    updNoteTotals(setFormValues, setPass, pass); setNtChanged(false);
    setAnxChanged(false); setTimeout(() => setAnxChanged(true), 20); //30
    //setTimeout(() => setAnxChanged(false), 5000); setTimeout(() => {setAnxChanged(true); console.log("Second Pass");}, 6000);
    //setTimeout(() => {updateNoteBals(formValues, setFormValues); console.log("Second Pass");}, 6000);
  };

  // useEffect(() => { let counter = 0; console.log("Enter Notes:: counter:", ++counter);
  //   const timer = setInterval(() => { console.log("counter:", ++counter);
  //   }, 2000);
  //   return () => { clearInterval(timer); console.log("Exit Notes:: counter:", ++counter);
  //   };
  // }, []);

  useEffect(() => { if(tfChanged.name) processTextFieldChanged(tfChanged.name, tfChanged.value); }, [tfChanged]);
  useEffect(() => { if(pass<1) setProcessing(false); }, [pass]);
  useEffect(() => { if(processing&& pass===1) updateTotals(); }, [pass, processing]); //ntChanged //ntChanged&& 
  useEffect(() => { if(anxChanged&&processing&&pass===2) updateNoteBals(formValues,setFormValues,setPass);}, [anxChanged,processing]);
  useEffect(() => { if(processing&& pass>2) updNoteTotals(setFormValues, setPass, pass); }, [pass, processing]);
  //const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const handleKeyup = (ref: React.RefObject<HTMLDivElement>,
    event: React.KeyboardEvent<HTMLDivElement>) => { //console.log("handleKeyup::", ", event:", event);
    const { key } = event; //console.log("key:", key);
    if(key==="Enter") { console.log("Enter pressed, key:", key, ", ref:", ref, (typeof ref));
      if(ref && ref.current) { // && ("current" in ref)
        ref.current.dispatchEvent(new KeyboardEvent('keypress', { key: "Tab" })); //A
        console.log("Dispatched keypress Tab");
        //ref.current.dispatchEvent(new KeyboardEvent('keyup', { key: "A" })); //Tab
    } }
  };

  const handleTextFieldBlur = (event: React.FocusEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { name, value } = event.target; console.log("Blur:: name:", name, ", value:", value, ", tfFocused:", tfFocused);
    if(name===tfFocused.name && value!==tfFocused.value) setTfChanged({name, value});
  };

  const handleTextFieldFocus = (event: React.FocusEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { name, value } = event.target; console.log("Focus:: name:", name, ", value:", value); //, ", event:", event);
    // const styles = getComputedStyle(event.target); console.log("styles:", styles);
    // if(event.target.parentElement) {
    //   const PStyles = getComputedStyle(event.target.parentElement); console.log("PStyles:", PStyles);
    //   if(event.target.parentElement.parentElement) {
    //     const PpStyles = getComputedStyle(event.target.parentElement.parentElement); console.log("PpStyles:", PpStyles);
    // } }
    setTfFocused({name, value});
    if(name) { // Move tf-input from tfHome to lineName
      // if(parents.tfHome.length>0) { //console.log("Moving tfInput from tfHome to parentA");
      //   sendReparentableChild("parentA", "tfHome", 0, "tfInput");
      //   //setParents({ tfHome: [], parentA: ["tfInput"] });
      // }
      setParents(getParents(name));
      setEditing(oldName => oldName==name? oldName: name);
    }
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { name, value } = event.target; console.log("name:", name, ", value:", value, ", event:", event);
    // processTextFieldChanged(name, value);
  }

  const getChild = (name: string, width: string) => {
    return <Child name={name} width={width}
      value={tfFocused.value} onBlur={handleTextFieldBlur} onKeyUp={handleKeyup} inputRef={inputRef}
    />;
  };
  // const getChildren = () => { const chldrn: {[key:string]: Array<JSX.Element>} = {};
  //   for(const par of Object.keys(parents)) chldrn[par] = parents[par].map(key => <Child key={key} width="31.75vw"
  //     value={tfFocused.value} onBlur={handleTextFieldBlur} onKeyUp={handleKeyup} inputRef={inputRef} />);
  //   //console.log("chldrn:", chldrn);
  //   return chldrn;
  // };
  // const children = getChildren();
  // const children: {[key:string]: Array<JSX.Element>} = {
  //   tfHome: parents.tfHome.map(key => <Child onBlur={handleTextFieldBlur} onKeyUp={handleKeyup} inputRef={inputRef} key={key} value={tfFocused.value} />),
  // };
  // if("parentA" in parents) {
  //   children.parentA = parents.parentA.map(key => <Child onBlur={handleTextFieldBlur} onKeyUp={handleKeyup} inputRef={inputRef} key={key} value={tfFocused.value} />);
  // }

  const processTextFieldChanged = (fld: string, value: string) => {
    let num = (value==="-"||value===""?0:parseFloat(removeComma(value))); // fld = name,
    console.log("processTextFieldChanged:: fld:", fld, ", value:", value, ", num:", num);
    let fldSet = false; const neg = ((value.substring(0,1)==="-" && value.length>1) || value==="0-");
    if(value.indexOf(".")>=0) {
      const intg = Math.floor(Math.abs(num));
      let dec = Math.abs(num) - intg; if(dec<0.001) dec = 0.1;
      num = (intg + dec) * (neg? -1: 1);
    }
    if(fld==="name") { //console.log("processTextFieldChanged:: selName:", selName); //name
      if(value && selYear) { const found = findValues(value, selYear, financialData);
        if(found) { fldSet = true; setCurrentId(found.id);
          setFormValues(formValues => ({...found})); setInputMode(2); //Edit
        } else {
          const company = companies.find(comp => comp.name===value), sDt = new Date(selYear);
          if(company) { refreshFinData(company, sDt, getDefaultFormValues,
            setFinancialData, setFormValues, setCurrentId); setInputMode(2); //Edit
        } }
      } setSelName(value);
    } else { let flds = fld.split("_"); fld = flds[0]; //console.log("flds:", flds); //name
      if(flds.length>1) { let i = parseInt(flds[1]); //console.log(`formValues.notes[${i}]:`, formValues.notes[i]);
        if(flds.length>2) { let li = parseInt(flds[2]);
          if(fld==="line") { //console.log("fld:", fld, ", value:", value); //, ", svBals:", svBals
            setFormValues(formValues => { let newValues = {...formValues}; //console.log("newValues:", newValues);
              // newValues.notes[i].lines[li] = value; //console.log(`newValues:.notes[${i}].lines[${li}]`, newValues.notes[i].lines[li]);
              const bi = balIndex(dfNotes[i].note, li, newValues.balances); //console.log("bi:", bi);
              // if(value!==dfNotes[i].lines[li]) { console.log("dfNotes:", value, dfNotes[i].lines[li]); //svBals[bi].desc
              //   newValues.balances[bi].desc = (value?value:" ");
              // } else { newValues.balances[bi].desc = ""; }
              newValues.balances[bi].desc = (value!==dfNotes[i].lines[li]? (value?value:" "): "");
              //console.log("newValues:", newValues);
              return newValues;
            }); fldSet = true;
          } else if(!isNaN(num)) {
            if(fld==="bal" || fld==="num") {
              setFormValues(formValues => { let newValues = {...formValues};
                newValues.balances[balIndex(dfNotes[i].note,li,newValues.balances)][fld] = num; //newValues.notes
                //console.log("newValues.balances:", newValues.balances);
                return newValues;
              }); fldSet = true;
            } else
            if(fld==="bal1") {
              setFormValues(formValues => { let newValues = {...formValues};
                newValues.balance1[balIndex(dfNotes[i].note,li,newValues.balance1)].bal = num; //newValues.notes
                //console.log("newValues.balance1:", newValues.balance1);
                return newValues;
              }); fldSet = true;
            } //else

        } }
    } }
    if(!fldSet) setFormValues((formValues) => ({...formValues, [fld]: value}));
    setNtChanged(false); setPass(1); setTimeout(() => setNtChanged(true), 20); //30 //updateTotals(name, value);
    //setTimeout(() => setNtChanged(true), 600); setTimeout(() => {setNtChanged(true); console.log("Second Pass");}, 650);
  };

  const handleAutoCompleteChange = (fld: string, event: React.SyntheticEvent<Element, Event>,
    value: string | null) => { //console.log("fld:", fld, "value:", value, ", event:", event);
    if(value){ let index = -1, refrsh = false, sDt = new Date();
      if(fld==="name") { //console.log("selYear:", selYear, "compName:", value);
        if(selYear) { sDt = (new Date(selYear)); //console.log("sDt:", sDt);
          index = financialData.findIndex(values => { let retVal = false;
            const dt = new Date(values.yrendDate); //console.log("dt:", dt);
            retVal = (values.name===value && dt.getDate()===sDt.getDate() && dt.getMonth()===sDt.getMonth() && dt.getFullYear()===sDt.getFullYear());
            return retVal;
          }); refrsh = (index < 0);
        } else setTimeout(() => dateRef.current?.focus(), 100);
      }
      else if(fld==="yrendDate") { //console.log("selName:", selName, "finYear:", value);
        sDt = new Date(value); //console.log("sDt:", sDt);
        if(selName && sDt) { //console.log("selName:", selName, ", sDt:", sDt);
          index = financialData.findIndex(values => { let retVal = false;
            const dt = new Date(values.yrendDate); //console.log("dt:", dt);
            retVal = (values.name===selName && dt.getDate()===sDt.getDate() && dt.getMonth()===sDt.getMonth() && dt.getFullYear()===sDt.getFullYear());
            return retVal;
          }); refrsh = (index < 0); //console.log("index:", index);
      } } else {
        //if(fld==="fsType") updateSbTests(fld, value);
        ///setFormValues((formValues) => ({...formValues, [fld]: value}));
      }
      if(index >= 0) {
        let compInfo = { ...financialData[index] }, id = compInfo.id;
        // compInfo.notes = getNotes(id, financialData);
        compInfo.balances = getBalances(id, financialData);
        compInfo.balance1 = getBalances(id, financialData, 1);
        compInfo.balance2 = getBalances(id, financialData, 2);
        compInfo.annexes = getAnnexes(id, financialData);
        compInfo.annex1 = getAnnexes(id, financialData, 1);
        compInfo.annex2 = getAnnexes(id, financialData, 2);
        compInfo.sces = getSces(id, financialData);
        compInfo.sce1 = getSces(id, financialData, 1);
        compInfo.sce2 = getSces(id, financialData, 2); //setSvBals(compInfo.balances); //console.log("svBals:", svBals);
        setFormValues(compInfo); setCurrentId(compInfo.id); console.log("compInfo:", compInfo);
        if(compInfo.ntChanged || compInfo.processing) {
          setProcessing(false); setPass(1); setTimeout(() => {
            setProcessing(true); console.log("processing:", processing, ", pass:", pass);
          }, 20);
        }
        //---------------------------------------------------------------------------------------//
        //---------------------------------------------------------------------------------------//
      } else
      if(refrsh) { //const company = companies.find(comp => comp.name===selName);
        const company = companies.find(comp => comp.name===(fld==="name"?value:selName));
        //const yr = `${sDt.getDate()}-${months[sDt.getMonth()].substring(0,3)}-${sDt.getFullYear()}`;
        //console.log("selName:", selName, ", company:", company, ", yr:", yr);
        if(company) {
          const func = (values: FormValues) => { if(values.ntChanged || values.processing) {
            setProcessing(false); setPass(1); setTimeout(() => setProcessing(true), 20);
          } }; refreshFinData(company, sDt,
          getDefaultFormValues, setFinancialData, setFormValues, setCurrentId, func);

          // const getFinValues = async () => {
          //   const url = '/api/fin-values/'+company.id+'?year='+yr; console.log("url:", url);
          //   const res = await fetch(url).then(response => { //console.log("response:", response);
          //     return response; //.json();
          //   });
          //   const resData = await res.json(); console.log("Notes:: /api/fin-values/:compId?year=yr - resData:", resData);
          //   return resData; //.finData;
          // };
          // getFinValues().then(resData => { //console.log("resData.finData:", resData.finData);
          //   let data = resData.finData as Array<FormValues>, idSet = false;
          //   if(resData.id) { setCurrentId(resData.id); idSet = true; }
          //   setFinancialData(oldData => { const newData = [...oldData];
          //     data.forEach(values => { console.log("Loaded values:", values);
          //       if(financialData.findIndex(f => f.id===values.id)<0) newData.push(values);
          //       if(!idSet) { const fDt = new Date(values.yrendDate);
          //         if(fDt.getDate()===sDt.getDate() && fDt.getMonth()===sDt.getMonth() &&
          //         fDt.getFullYear()===sDt.getFullYear()) { resData.id = values.id;
          //           setCurrentId(values.id); idSet = true;
          //       } }
          //     }); //console.log("resData:", resData); //console.log("newData:", newData);
          //     const values = getDefaultFormValues(newData, resData.id); //setSvBals(values.balances);
          //     setFormValues(values); //console.log("svBals:", svBals); //cheqSvBals();
          //     return newData;
          //   });
          // });
      } }
      setFormValues((formValues) => ({...formValues, [fld]: value}));
      //updateTotals(fld, value);
    } else {
      setFormValues(getDefaultFormValues(null,"")); setCurrentId("");
    }
    if(fld==="yrendDate") { if(value) setSelYear(value); else {setSelName(""); setSelYear("")}; }
    else if(fld==="name") { if(value) setSelName(value); else {setSelName(""); setSelYear("")}; }
  };

  const getCompanyNames = (yrendDate="") => { //console.log("getCompanyNames:: yrendDate:", yrendDate);
    const names: Array<string> = [""]; const yDt = yrendDate? (new Date(yrendDate)): null;
    financialData.forEach(values => {
      if(yDt) { const dt = new Date(values.yrendDate); //console.log("yDt:", yDt, ", dt:", dt);
        if(yDt.getFullYear()===dt.getFullYear() && yDt.getMonth()===dt.getMonth()
        && yDt.getDate()===dt.getDate() && !names.includes(values.name)) names.push(values.name)
      } else if(!names.includes(values.name)) names.push(values.name);
    }); //console.log("names:", names);
    return names;
  };
  const getCompNames = () => {
    const names: Array<string> = [""]; //const yDt = yrendDate? (new Date(yrendDate)): null;
    companies.forEach(comp => {
      // if(yDt) { const dt = new Date(values.yrendDate); //console.log("yDt:", yDt, ", dt:", dt);
      //   if(yDt.getFullYear()===dt.getFullYear() && yDt.getMonth()===dt.getMonth()
      //   && yDt.getDate()===dt.getDate() && !names.includes(values.name)) names.push(values.name)
      // } else
      if(!names.includes(comp.name)) names.push(comp.name);
    }); //console.log("names:", names);
    return names;
  };

  const getFinYears = (name="") => { //console.log("getFinYears:: name:", name);
    const finYears: Array<string> = [""];
    financialData.forEach(values => { const fDt = new Date(values.yrendDate);
      const finYear = `${fDt.getDate()} ${months[fDt.getMonth()]} ${fDt.getFullYear()}`;
      if((name === values.name) && !finYears.includes(finYear)) finYears.push(finYear);
    }); finYears.sort(); finYears.reverse(); //console.log("finYears:", finYears);
    if(name && finYears.length<2) { //console.log("getFinYears:: companies:", companies);
      const company = companies.find(comp => comp.name===name); //console.log("getFinYears:: company:", company);
      if(company) {
        const getYears = async () => {
          const res = await fetch('/api/fin-years/'+company.id).then(response => { //console.log("response:", response);
            return response; //.json();
          });
          const resData = await res.json(); //console.log("Notes:: /api/fin-years/:compId - resData:", resData);
          return resData.years;
        };
        getYears().then(years => { //console.log("years:", years);
          let yrs = years as Array<string>;
          yrs.forEach(yr => { //console.log("yr:", yr, (typeof yr));
            const yDt = new Date(yr); //console.log("yDt:", yDt, (typeof yDt));
            const finYear = `${yDt.getDate()} ${months[yDt.getMonth()]} ${yDt.getFullYear()}`;
            if(!finYears.includes(finYear)) finYears.push(finYear);
          }); finYears.sort(); finYears.reverse(); //console.log("finYears:", finYears);
        });
    } } //console.log("getFinYears:: finYears:", finYears);
    return finYears;
  };

  const handleDatePickerChange = (value: Dayjs | null) => { //console.log(value);
    if(value) {
      const dt = value; //as unknown as {month: ()=>string, date: ()=>string, year: ()=>string};
      const yrendDate = `${dt.month() + 1}/${dt.date()}/${dt.year()}`;
      // setFormValues({...formValues, yrendDate: yrendDate}); //value?.toString()
      setFormValues((formValues) => ({...formValues, yrendDate: yrendDate}));
      if(selName && dt) {
        console.log("Trying to find selName:", selName, ", dt:", dt, " in financialData:", financialData);
        const found = findValues(selName, dt.toString(), financialData); console.log("found:", found);
        if(found) { setCurrentId(found.id);
          setFormValues(formValues => ({...found})); setInputMode(2); //Edit
        } else {
          const company = companies.find(comp => comp.name===selName);
          if(company) { refreshFinData(company, (new Date(dt.toString())), getDefaultFormValues,
            setFinancialData, setFormValues, setCurrentId); setInputMode(2); //Edit
          }
      } } setSelYear(dt.toString());
    } else setSelYear("");
  };

  const handleSubmit = async () => { console.log("ntChanged:", ntChanged, ", processing:", processing)
    const dataPack = {...formValues, ntChanged, processing }; console.log("dataPack:", dataPack);
    if(processing) setProcessing(false);
    try {
      let hasError = false;
      const res = await fetch('/api/financial-data', { // /api/company-data
        method: 'POST', body: JSON.stringify(dataPack),
        headers: {'Content-Type': 'application/json'}
      });
      if (!res.ok) hasError = true;
      const resData = await res.json();
      if (hasError) throw new Error(resData.message);
      const financialInfo = resData.financialInfo;  console.log("resData:", resData);
      setFinancialData((prevData: Array<FormValues>) => {
        const newData = prevData.map((data: FormValues) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === financialInfo.id) { index = i; return true; } return false;
        });
        newData[index] = financialInfo; //console.log("newData:", newData); // Update existing record
        setFormValues(financialInfo); setCurrentId(financialInfo.id); console.log("financialInfo:", financialInfo);
        return newData;
      });

      //console.log("autoRef.current:", autoRef.current);
      //console.log("autoRef.current?.focus:", autoRef.current?.focus);
      setInputMode(0); autoRef.current?.focus();
      setAlertMesg("Form Saved"); setBtn1Text("OK");
      btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      //setTimeout(() => autoRef.current?.focus(), 100); //clearValues();
      //setTimeout(() => autoRef?.focus(), 100);
      setPass(1); setProcessing(true);
    } catch (error: any) {
      alert(error.message || 'Something went wrong!');
    }
  };

  const handleClearClick = () => { clearValues(); if(inputMode>0) setInputMode(0); };
  const clearValues = () => {
    const id = currentId, values = getDefaultFormValues(null, id);
    //console.log("currentId:", currentId, ", documentData:", documentData);
    //setFormValues(getDefaultFormValues(null, (inputMode===1? "": currentId)));
    setFormValues(values); setSelName(""); setSelYear("");
  };
  const handleAddNew = () => { setInputMode(1);
    const id = "", values = getDefaultFormValues(null, id); //console.log("AddNew:: values:", values);
    setFormValues(values); setSelName(""); setSelYear("");
    setTimeout(() => textRef.current?.focus(), 100); //console.log("currentId:", currentId);
  };
  const handleEdit = () => { setInputMode(2); setTimeout(() => textRef.current?.focus(), 100); };
  const handleAlertClick = () => { setAlertOpen(false); };

  const deleteId = async () => {
    return fetch('/api/financial-data/'+formValues.id, { // /api/company-data/
        method: 'DELETE', body: JSON.stringify(formValues),
        headers: {'Content-Type': 'application/json'}
    }).then(response => { //console.log("response:", response);
      return response; //.json();
    });
  };
  const handleDelete = () => {
    setAlertMesg("Do you want to delete the company " + formValues.name);
    btn1Handler = handleConfirmDelete; //console.log("handleDelete:: btn1Handler is set.");
    btn2Handler = () => { handleClearClick(); handleAlertClick(); };
    setBtn1Text("Yes"); setBtn2Text("No"); setAlertOpen(true);
  }
  const handleConfirmDelete = () => {
    deleteId().then((json) => { console.log("handleDelete:: json:", json);
      // handle success
      let index = -1, id = ""; const deleteId = formValues.id;
      const filteredData = financialData.filter((values, i) => {
       if (values.id === deleteId) { index = i; return false; } return true;
      }); setFinancialData(filteredData);
      if(index >= filteredData.length) index -= 1;
      if(index >= 0) id = filteredData[index].id; setCurrentId(id);
      setFormValues(getDefaultFormValues(filteredData, id));
//
      setInputMode(0); setTimeout(() => { autoRef.current?.focus();
        setAlertMesg("Deleted company info"); setBtn1Text("OK");
        btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      }, 100);
    })
    .catch(error => console.log("handleDelete:: error:", error));
  };

  const periods = [
    "for the year ended"  , "for the year ending",
    "for the period ended", "for the period ending",
  ];

  let x:any, y:any, z:any, desc:string; //console.log("inputMode:", inputMode, ", currentId:", currentId, currentId.length);
  const d = formValues.yrendDate? (new Date(formValues.yrendDate)): null; //console.log("formValues:", formValues);

  return (<>
    <Paper>
      <form>
        <div style={{ width:"75vw", height:"10vh", position:"fixed", zIndex:"99", backgroundColor:"#fff" }}>{/*68vw*/}
          {/* <h3 style={{ width:"12vw", textAlign:"center", display:"inline-block", verticalAlign:"middle" }}>Financial Position</h3> */}
          <div style={{ width:"12vw", textAlign:"center", display:"inline-block", verticalAlign:"middle" }}>{ currentId.length>4?
            <FinExcel excelSheets={getFinExData(formValues, sfp, pnl, keyNotes)}
            fileName="Excel Export" disabled={inputMode>0}>Download</FinExcel>: null }
          </div>
          <div style={{ width:"45vw", display:"inline-block", verticalAlign:"middle" }}>{/*43vw | 10vw*/}
            {/* ------------------------------------------------------------------------------------ */}
            <FormControl sx={{paddingTop: 1}}>
              <FormGroup row sx={{justifyContent: "space-between"}}>
                <FormGroup sx={{width:"6vw", alignItems:"start"}}>
                  <Stack>
                    <Button onClick={handleDelete} sx={{paddingLeft:3}}
                      disabled={inputMode<2}>Delete</Button>
                  </Stack>
                </FormGroup>
                <FormLabel sx={{paddingLeft: 1, width:"14vw"}}></FormLabel>
                <FormGroup sx={{width:"6vw", paddingLeft: 1, alignItems: "start"}}>
                  <Stack>
                    <Button onClick={handleAddNew} disabled={inputMode>0}>Add</Button>
                    <Button onClick={handleEdit} sx={{paddingTop:0}}
                      disabled={inputMode>0||currentId.length<4}>Edit</Button>
                  </Stack>
                </FormGroup>
                <FormGroup sx={{width:"19vw", paddingRight:2, alignItems:"end"}}>{/*17vw*/}
                  <Stack>
                    <Button onClick={handleSubmit} disabled={inputMode<1}>Save</Button>
                    <Button onClick={handleClearClick} sx={{paddingTop:0}}
                      disabled={inputMode<1}>{inputMode>0? "Cancel": "Clear"}</Button>
                  </Stack>
                </FormGroup>
              </FormGroup>
            </FormControl>
            {/* ------------------------------------------------------------------------------------ */}
          </div>
          <div style={{ width:"18vw", display:"inline-block", verticalAlign:"middle" }}>{/* width:"15vw" */}
            <FormControl sx={{paddingLeft: 2}}>
              <FormLabel sx={{width:"14.3vw", padding:1, paddingRight:0, //, border:1, fontStyle:"italic", fontWeight:"bold" //13.3vw
                 textAlign:"center", color:"#000"}}>{/*&nbsp;*/}<h3>Notes</h3>
              </FormLabel>
            </FormControl>
          </div>
        </div>
        <FormControl sx={{paddingTop: 1, marginTop: "10vh"}}>
          <FormGroup row sx={{paddingTop: (inputMode<1? 1: 0), justifyContent: "space-between"}}>
            <Autocomplete options={getCompNames()} sx={{width: "57.5vw", display: (inputMode<1?
              "block": "none")}} renderInput={(params) => (<TextField  // 52.5vw
                inputRef={autoRef} name="name" {...params} autoFocus label={selName?"":"Company Name"}
              />)} getOptionLabel={(option) => `${option}`}
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)}
              value={formValues.name || ""} disablePortal size="small"
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange.bind(null, "name")}  // onInputChange
            />{/*14.75vw*/}
            {/* <FormLabel sx={{width:"16.75vw", textAlign:"center", display: (inputMode<1? "block": "none")}}><h3>{/*Financial Position* /}</h3></FormLabel> */}
            <Autocomplete options={getFinYears(selName)} sx={{width: "16.75vw", display: (inputMode<1? "block": "none")}}
              renderInput={(params) => (<TextField inputRef={dateRef} name="yrendDate" {...params} label={selYear?"":"As At Date"} />)}
              getOptionLabel={(option) => `${option}`} disablePortal disabled={inputMode>0} //14.75vw
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)} size="small"
              value={(formValues.yrendDate?(x=new Date(formValues.yrendDate), `${x.getDate()} ${months[x.getMonth()]} ${x.getFullYear()}`):"")} // || ""
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange.bind(null, "yrendDate")} />
          </FormGroup>
          <FormGroup row sx={{paddingTop: (inputMode<1? 0: 1), justifyContent: "space-between", height: (inputMode<1? 0: 1)}}>
            <TextField id="name" name="name" variant="outlined" size="small"  // label="Name of Company"
              sx={{width: "57.5vw", visibility: (inputMode<1? "hidden": "visible")}} //"52.5vw"
              value={formValues.name} autoFocus disabled={inputMode<1}
              onChange={handleTextFieldChange} inputRef={textRef} //onBlur={handleTextFieldBlur}
            />{/*14.75vw*/}
            {/* <FormLabel sx={{width:"16.75vw", textAlign:"center", visibility:(inputMode<1?"hidden":"visible")}}><h3>{/*Financial Position* /}</h3></FormLabel> */}
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker format="DD/MM/YYYY" sx={{width: "16.75vw", visibility:(inputMode<1?"hidden":"visible")}}  // label="Date"
                value={dayjs(formValues.yrendDate)} onChange={handleDatePickerChange} //14.75
                slotProps={{ textField: {size: "small"} }} disabled={inputMode<1} />
            </LocalizationProvider>
          </FormGroup>
        </FormControl>

        <div style={{display: (currentId.length>4 ||
          (inputMode>0 && formValues.name && formValues.yrendDate)? "block": "none")}}>
          <FormControl sx={{paddingTop: 0}}>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              {inputMode>0? <><FormLabel sx={{width:"5.35vw", paddingTop: 1, paddingLeft: 2, textAlign: "left"}}>
                Address</FormLabel>
              <TextField id="address" name="address" variant="outlined" size="small"
                sx={{width: "68.85vw"}} value={formValues.address} disabled={inputMode<1}
                onChange={handleTextFieldChange} // onBlur={handleTextFieldBlur} //59.7vw
              /></>:
              <FormLabel sx={{width:"74.2vw", paddingTop:1, paddingBottom:1, paddingLeft:2}}>{formValues.address}</FormLabel>}
              <FormLabel sx={{width:"0.05vw"}}></FormLabel>{/*2.7vw*/}
            </FormGroup>
          </FormControl>

          <FormControl sx={{paddingTop: 0}}>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"18.9vw"}}></FormLabel>{/*18.15vw*/}
              <FormLabel sx={{width:"14.35vw"}}></FormLabel>
              <FormLabel sx={{width:"14.5vw"}}></FormLabel>{/*14.75vw*/}
              <FormLabel sx={{width:"13vw", borderBottom:1}}></FormLabel>
              <FormLabel sx={{width:"1vw", borderBottom:1}}></FormLabel>
              <FormLabel sx={{width:"13vw", borderBottom:1, paddingTop: 1, paddingRight: 1,
                textAlign: "right"}}>{formValues.curr}</FormLabel>
            </FormGroup>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"18.9vw"}}></FormLabel>{/*18.15vw*/}
              <FormLabel sx={{width:"14.5vw"}}></FormLabel>{/*14.75vw*/}
              <FormLabel sx={{width:"14.35vw"}}></FormLabel>
              <FormLabel sx={{width:"13vw", borderBottom:1, paddingTop:1, textAlign:"center"}}>
                {(d? `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`: "")}</FormLabel>
              <FormLabel sx={{width:"1vw"}}></FormLabel>
              <FormLabel sx={{width:"13vw", borderBottom:1, paddingTop:1, textAlign:"center"}}>
                {(d? `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()-1}`: "")}</FormLabel>
            </FormGroup>
          </FormControl>

          {/* <div>Input TextBox to be moved
            {/* {("tfHome" in children)&& <Reparentable id="tfHome">{children.tfHome}</Reparentable>} * /}
            {("tfHome" in children)&& children.tfHome}
          </div>
          <div>Parent A
            {/* {("parentA" in children)&& <Reparentable id="parentA">{children["parentA"]}</Reparentable>} * /}
            {("parentA" in children)&& children["parentA"]}
          </div> */}

          {/*----------------------------------------------------------------------------------*/}
          {keyNotes.map((kNote, ki) => {
            return !kNote.hidden&& (<div key={ki}>
              {dfNotes && dfNotes.map((nt, i) => { const rules = nt.link.split(","); //formValues.notes
                let ls:number[]=[]; rules.forEach((rule:string) => { const splt=rule.split(":"), rLine=splt[0];
                  //if(kNote.note>33) console.log("note:", kNote.note, ", rule:", rule, ", rLine:", rLine);
                  if(rLine[0]==="L") {const ln=parseInt(rLine.substring(1)); if(!isNaN(ln)&&ln>0) ls.push(ln);}
                });
                
                let tMax = false, formula = "";
                let ntName = nt.name; if(ntName.substring(0,2)==="&=") { ntName = ntName.substring(2);
                  let result = ""; if(ntName[0]==="(") { const fi = ntName.indexOf(")");
                    if(fi>=0) { formula = ntName.substring(0, fi+1); ntName = ntName.substring(fi+1);
                      let frmNote = ""; const ni = formula.indexOf("{"); if(ni>=0) {
                        const ne = formula.indexOf("}"); if(ne>=0) {
                          frmNote = formula.substring(ni+1, ne); //console.log("frmNote:", frmNote);
                          if(frmNote) { const fB=getNoteBal(frmNote,formValues.balances); //console.log("fB:", fB);
                            formula = formula.replace(`{${frmNote}}`,fB.bal.toString()); result = eval(formula);
                    } } } } ntName = result + ntName;
                  } //console.log("formula:", formula, ", result:", result);
                } let totLine = nt.totLine, totBold = (totLine!=="YN-"), totYN = (totLine=="YN");
                if(totLine==="YN"||totLine==="YN-"||totLine.substring(0,2)==="&=") totLine = "";
                let ti = totLine.indexOf(")"); if(ti >= 0 && totLine.substring(0,5)==="&(YN)") {
                  totYN = true; totBold = false; totLine = totLine.substring(ti+1);
                } else { ti = totLine.indexOf("&="); //console.log("ntName:", totLine, ", ti:", ti)
                  if(ti >= 0) { formula = totLine.substring(ti); tMax = (formula.substring(2)==="Max");
                  if(tMax) totLine = totLine.replace(formula,""); //console.log("formula:", formula, ", tMax:", tMax);
                } }
                const t=getNoteBal(nt.note,formValues.balances), t1=getNoteBal(nt.note,formValues.balance1);
                const m=getNoteMax(nt.note,formValues.balances), m1=getNoteMax(nt.note,formValues.balance1);
                const arr=nt.note.split("."), key="N"+arr[0], kn=getKeyNote(keyNotes,key,formValues.sfpHeads);
                let rest = ""; if(arr.length>1) rest = "."+arr[1];
                return kn&& !kn.hidden&& kn.note===kNote.note&& (<>
                  {nt.note[nt.note.length-1]==="-" && nt.name==="N"? null: <FormControl sx={{paddingTop: 0}}>
                    <FormGroup row sx={{justifyContent: "space-between"}}>
                      <FormLabel sx={{width:"4vw", paddingLeft: 1, fontWeight:"bold", visibility:(nt.note
                      .substring(nt.note.length-1)==="-"? "hidden": "visible")}}>{kn? kn.note+rest: nt.note}</FormLabel>
                      <FormLabel sx={{width:"1vw", paddingRight:1, textAlign:"right"}}>.</FormLabel>
                      <FormLabel sx={{width:(nt.midHd1?"20.75vw":"31.75vw"), fontSize:"103%",
                        fontWeight:"bold"}}>{ntName}</FormLabel>
                      {nt.midHd1? <FormLabel sx={{width:"11vw", textAlign:"center"}}>
                        {nt.midHd1}</FormLabel>: null}
                      <FormLabel sx={{width:"11vw", textAlign:"center"}}>{nt.midHd2}</FormLabel>
                      <FormLabel sx={{width:"27vw"}}></FormLabel>
                    </FormGroup>
                  </FormControl>}

                  {nt.lines && nt.lines.map((line, li) => { let dispPct = false, formula = "", fe = -1;
                    const fi = line.indexOf("&("); if(fi>=0) { formula = line.substring(fi);
                      fe = formula.indexOf(")"); if (fe>=0) formula = formula.substring(0, fe+1); //console.log("Line formula:", formula);
                    }
                    if(fe>=0 && formula) { dispPct = (formula[2] === "P"); //console.log("dispPct:", dispPct);
                      if(dispPct) line = line.replace(formula, "(%)"); //console.log("line:", line, ", formula:", formula);
                    }
                    return <FormControl sx={{paddingTop: 0}} key={li}>
                      <FormGroup row sx={{justifyContent: "space-between"}}>
                        <FormLabel sx={{width:"5vw"}}></FormLabel>
                        {inputMode<1 || !nt.inp? <FormLabel sx={{width:(nt.midCol?"20.75vw":"31.75vw")}}>
                          {(desc=getBal(nt.note,li,formValues.balances).desc,desc?desc:line)}</FormLabel>:<>
                        <TextField key={`tf_line_${i}_${li}`} fullWidth={true} //id="line"
                          name={`line_${i}_${li}`} variant="outlined" size="small"
                          value={(desc=getBal(nt.note,li,formValues.balances).desc,desc?desc:line)}
                          sx={{width:(nt.midCol?"20.75vw":"31.75vw"),display:(editing==`line_${i}_${li}`?"none":"block")}}
                          onFocus={handleTextFieldFocus} onChange={handleTextFieldChange} //onBlur={handleTextFieldBlur}
                          />
                        {/* {(`line_${i}_${li}` in children)&& children[`line_${i}_${li}`]} */}
                        {(`line_${i}_${li}` in parents)&& getChild(`line_${i}_${li}`, nt.midCol?"20.75vw":"31.75vw")}
                        {/* <TextField id="line" name={`line_${i}_${li}`} variant="outlined" size="small"
                          sx={{width:(nt.midCol?"20.75vw":"31.75vw"),display:(editing==`line_${i}_${li}`?"block":"none")}}
                          onChange={handleTextFieldChange} /> */}
                        </>}

                        {nt.midCol? (inputMode<1 || !nt.inp? <FormLabel sx={{width:"11vw", paddingRight:1,
                          textAlign:"right", borderBottom:(li===(nt.lines.length-1)&&nt.midHd1?1:0)}}>{negBracket(
                          getBal(nt.note,li,formValues.balances).num)}</FormLabel>: <>
                        <TextField name={`num_${i}_${li}`} variant="outlined" size="small" //id="num"
                          sx={{width:"11vw", borderBottom:(li===(nt.lines.length-1)&&nt.midHd1?1:0),
                            display:(editing==`num_${i}_${li}`?"none":"block")}}
                          value={commaFormat(getBal(nt.note,li,formValues.balances).num)}
                          onFocus={handleTextFieldFocus} onChange={handleTextFieldChange} //onBlur={handleTextFieldBlur}
                        />{(`num_${i}_${li}` in parents)&& getChild(`num_${i}_${li}`, "11vw")}
                        </>): null}
                        <FormLabel sx={{width:"11vw", borderBottom:(li===(nt.lines.length-1)&&nt.midHd1?1:0),
                          paddingRight:2, textAlign:"right"}}>{nt.midCol==="R"?(negBracket(
                            getBal(nt.note,li,formValues.balances).num*100/(Math.abs(t.num)>0.001?t.num:1)
                          )+"%"):(nt.midCol==="B"?negBracket(getBal(nt.note,li,formValues.balances).bal-
                            getBal(nt.note,li,formValues.balances).num):"")}</FormLabel>

                        {inputMode<1 || !nt.inp?<FormLabel sx={{width:"13vw", paddingRight:1,
                          textAlign:"right", borderBottom:(li===(nt.lines.length-1)&&nt.totLine!=="N--"?1:0)}}>
                          {dispPct? commaFormat(getBal(nt.note,li,formValues.balance1).bal)+"%":
                          negBracket(getBal(nt.note,li,formValues.balances).bal)}</FormLabel>:<>
                        <TextField name={`bal_${i}_${li}`} variant="outlined" size="small" //id="bal"
                          sx={{width: "13vw", borderBottom:(li===(nt.lines.length-1)&&nt.totLine!=="N--"?1:0),
                            display:(editing==`bal_${i}_${li}`?"none":"block")}}
                          value={commaFormat(getBal(nt.note,li,formValues.balances).bal)}
                          disabled={(nt.link? (
                            ((x=nt.link.split(","),y=x[0].split("|"),y[0]==="L0:PY"||y[0]==="L0:-PY"
                              ||y[0]==="L0:Disabled")&&li===0)|| //kn.note>33?console.log("y[0]:",y[0]):null,
                            ((x.find((v:string)=>v.split(":")[0]==="LL"))? li===(nt.lines.length-1): false) ||
                            ls.includes(li) //(li===ln)
                            //((x.find((v:string)=>((y=v.split(":")[0])[0]==="L"? (ln=parseInt(y.substring(1)), //console.log("y:",y,nt.note,li),
                            //!isNaN(ln) && ln>0): false)))? (li===ln): false) //console.log("ln:",ln,nt.note,li),
                          ): false)} onFocus={handleTextFieldFocus} onChange={handleTextFieldChange} //onBlur={handleTextFieldBlur}
                        />{(`bal_${i}_${li}` in parents)&& getChild(`bal_${i}_${li}`, "13vw")}
                        </>}
                        <FormLabel sx={{width:"1vw"}}></FormLabel>

                        {inputMode<1 || !nt.inp?<FormLabel sx={{width:"13vw", paddingRight:1, textAlign:"right",
                          borderBottom:(li===(nt.lines.length-1)&&nt.totLine!=="N--"?1:0)}}>
                          {dispPct? commaFormat(getBal(nt.note,li,formValues.balance1).bal)+"%":
                          negBracket(getBal(nt.note,li,formValues.balance1).bal)}</FormLabel>:<>
                        <TextField name={`bal1_${i}_${li}`} variant="outlined" size="small" //id="bal1"
                          sx={{width: "13vw", borderBottom:(li===(nt.lines.length-1)&&nt.totLine!=="N--"?1:0),
                            display:(editing==`bal1_${i}_${li}`?"none":"block")}}
                          value={commaFormat(getBal(nt.note,li,formValues.balance1).bal)}
                          disabled={nt.link? ( //x=nt.link.split(","),
                            ((y[0]==="L0:Disabled")&&li===0)||
                            (x.find((v:string)=>v.split(":")[0]==="LL")? li===(nt.lines.length-1): false) ||
                            (ls.includes(li) && !x.find((v:string)=>(y=v.split("|"),y.find((w:string)=>(
                              (z=w.split(":"))[1]==="AI1" && li===parseInt(z[0].substring(1)))))
                            )) //(console.log("ln:",ln,nt.note,li), li===ln)
                          ): false} onFocus={handleTextFieldFocus} onChange={handleTextFieldChange} //onBlur={handleTextFieldBlur}
                        />{(`bal1_${i}_${li}` in parents)&& getChild(`bal1_${i}_${li}`, "13vw")}
                        </>}
                      </FormGroup>
                    </FormControl>
                  })}

                  {nt.totLine=="N"||nt.totLine=="NN"||nt.totLine=="N-"||nt.totLine=="N--"? null:
                  <FormControl sx={{paddingTop: 0}}>
                    <FormGroup row sx={{justifyContent: "space-between"}}>
                      <FormLabel sx={{width:"5vw"}}></FormLabel>
                      <FormLabel sx={{width:(nt.midHd1?"20.75vw":"31.75vw"), fontWeight:(totBold?"bold":"")
                        }}>{totLine}</FormLabel>
                      {nt.midHd1? (<FormLabel sx={{width:"11vw", borderBottom:1, paddingRight:1,
                        textAlign:"right", fontWeight:"bold"}}>{negBracket(t.num)}</FormLabel>): null}
                      <FormLabel sx={{width:"11vw", borderBottom:(nt.midHd1?1:0), paddingRight:2,
                        textAlign:"right", fontWeight:"bold"}}>{nt.midCol==="R"?negBracket(t.num>0.001?100:0)
                        +"%":(nt.midCol==="B"?negBracket(t.bal-t.num):"")}</FormLabel>
                      <FormLabel sx={{width:"13vw", borderBottom:(nt.totLine==="YN-"?0:1), paddingRight:1,
                        textAlign:"right", fontWeight:(totBold?"bold":"")}}>{negBracket((tMax?m:t).bal)}</FormLabel>
                      <FormLabel sx={{width:"1vw"}}></FormLabel>
                      <FormLabel sx={{width:"13vw", borderBottom:(nt.totLine==="YN-"?0:1), paddingRight:1,
                        textAlign:"right", fontWeight:(totBold?"bold":"")}}>{negBracket((tMax?m1:t1).bal)}</FormLabel>
                    </FormGroup>
                  </FormControl>}

                  {nt.totLine=="NN"||totYN||nt.totLine=="YN-"? null:  // nt.totLine=="YN"
                  <FormControl sx={{paddingTop: 0}}>
                    <FormGroup row sx={{justifyContent: "space-between"}}>
                      <FormLabel sx={{width:"25.75vw"}}>&nbsp;</FormLabel>
                      <FormLabel sx={{width:"22vw", borderTop:(nt.midHd1?1:0)}}></FormLabel>
                      <FormLabel sx={{width:"13vw", borderTop:(nt.lines.length===0||nt.totLine=="N-"||nt.totLine=="N--"?0:1)}}></FormLabel>
                      <FormLabel sx={{width:"1vw"}}>&nbsp;</FormLabel>
                      <FormLabel sx={{width:"13vw", borderTop:(nt.lines.length===0||nt.totLine=="N-"||nt.totLine=="N--"?0:1)}}></FormLabel>
                    </FormGroup>
                  </FormControl>}
                </>);
              })}
            </div>);
          })}
          {/*----------------------------------------------------------------------------------*/}

        </div>
      </form>
    </Paper>
    <Dialog open={alertOpen} onClose={handleAlertClick}>
      <Alert onClose={handleAlertClick}>
        <AlertTitle>Success!</AlertTitle>
        {alertMesg}
        <div style={{marginTop: 20, display: "flex", justifyContent: "space-between"}}>
          {btn2Text? <Button style={{textTransform: "none", paddingLeft: 0,
           justifyContent: "left"}} onClick={btn2Handler}>{btn2Text}</Button>: null}
          {btn1Text? <Button style={{textTransform: "none", paddingRight: 0, marginLeft: 10,
           justifyContent: "right"}} onClick={btn1Handler}>{btn1Text}</Button>: null}
        </div>
      </Alert>
    </Dialog>
  </>)
};
