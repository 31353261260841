import { AppBar, Drawer, List, ListItem, Toolbar, Typography } from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles"; //"@emotion/react";
import { Link, useParams } from "react-router-dom";
import InputForm from "../../components/Form/InputForm";
import ContactForm from "../../components/Form/ContactForm";
import ContactCardGrid from "../../components/Grid/ContactCardGrid";
import ContactTable from "../../components/Table/ContactTable";
import ContactDataGrid from "../../components/DataGrid/ContactDataGrid";

const drawerWidth = 240;

const themedStyles = (theme: Theme) => {
  return {
    appBar: { zIndex: theme.zIndex.drawer + 1 }
  };
};

const simpleStyles = {
  drawer: {
    width: drawerWidth, "& .MuiBackdrop-root": { display: "none" }
  },
  drawerPaper: {
    width: drawerWidth, backgroundColor: "rgba(120,120,120,0.2)"
  },
  content: { marginLeft: drawerWidth, padding: 3 }
};

const LettersPage = () => {
  const { content } = useParams(); //console.log("content:", content);
  const theme = useTheme();
  const sideMenu = [
    { text: "Input Form", route: "/letters-mui/input-form" },
    { text: "Contact Form", route: "/letters-mui/contact-form" },
    { text: "Contact Card Grid", route: "/letters-mui/grid" },
    { text: "Contact Table", route: "/letters-mui/table" },
    { text: "Contact Data Grid", route: "/letters-mui/datagrid" },
  ];
  return (
    <div>
      <AppBar position="fixed" sx={themedStyles(theme).appBar}>{/* {zIndex: 9999} */}
        <Toolbar>
          <Typography variant="h6" noWrap>
            Advanced Material UI Styling
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="temporary" open={true} sx={simpleStyles.drawer} disableEnforceFocus
        PaperProps={{ sx: simpleStyles.drawerPaper, elevation: 9 }}>
        <Toolbar />
        <List>{sideMenu.map((nav, index) => (
          <ListItem key={nav.text}><Link to={nav.route}>{nav.text}</Link></ListItem>
        ))}</List>
      </Drawer>
      <main style={simpleStyles.content}>
        <Toolbar />
        {(content==="input-form"?<InputForm />:null)}
        {(content==="contact-form"?<ContactForm />:null)}
        {(content==="grid"?<ContactCardGrid />:null)}
        {(content==="table"?<ContactTable />:null)}
        {(content==="datagrid"?<ContactDataGrid />:null)}
      </main>
    </div>
  );
}

export default LettersPage;
