import { commaFormat, getResourcePay, removeComma } from "./DocumentData";

const letterTemplates = [null,
// ------------------------------------------------------------------------------------------- //
{ date: "%today%", // Quotation - March 6, 2023
  addrLines: [
    {text:"Head of Finance/Chief Financial Officer"},
    {text: "%company_name%", style: "bold"}, // Justified Company Limited
    {text: "Dhaka, Bangladesh"},
  ],
  salutation: "Dear Sir(s)/Madam,",
  titleLines: [
    {text: "Quotation/ Expression of interest for appointment as auditor of", style: "underline"},
    {text: "%company_name% %period% %yearend_date%", style: "underline"}, //for the year ended 30 June 2022
  ],
  paragraphs: [
    {lines: [
      {
        text: "As salamu alaikum. We have gone through your relevant documents for the purpose of audit of the\n"+
              "financial statements of %company_name% %period% %yearend_date%.\n"+ //for the year ended
              "\n"+
              "We hearby expressing our interest for the appointment as statutory auditor of %company_name%\n"+
              "for the year ended. For this purpose, considering all matters, we propose our fees as follows:"
      },
    ]},
    {lines: [
      {text: " Last year audit fee: as per FS [for BDT %revenue_asst% million]", textRight: "BDT %fee_last_year% \u00A0 \u00A0"},
      {text: " Fees as per ICAB fees schedule [for BDT %revenue_asst% million]", textRight: "BDT %fee_icab_schd% \u00A0 \u00A0"},
      {text: " %txt_work_based%", textRight: "%fee_work_based% \u00A0 \u00A0"},
    ], style: ["indented","bordered"]},

    {lines: [
      {text: " Our audit based upon covering our cost structure", textRight: "%cost_structure%", style: "bold"},
      // {text: "\n", style: "borderTop"},
    ], style: ["indented","borderBottom"]},

    {lines: [
      {text: " Additional cost for correction of error(s) in last year, if any", textRight: "%correction_err%"},
      {text: " Additional cost for FS_draft_bank stat", textRight: "%fs_draft_bank%"},
      {text: " Visit hour(s) costs", textRight: "%visit_hour_cost%"},
      {text: " Regulatory support costs", textRight: "%regu_supp_cost%"},
      {text: " RJSC filing return", textRight: "%rjsc_return%"},
      {text: " Annual Tax return", textRight: "%tax_return%"},
      {text: " %others_1_txt%", textRight: "%others_1_amt%"}, //VAT return - monthly
      {text: " %others_2_txt%", textRight: "%others_2_amt%"}, //Others
      {text: " Professional costs - designing and formatting latest IFRS", textRight: "%prof_cost%"},
      {text: " Discount", textRight: "%discount%"},
    ], style: "indented", hidden: "%quot_hide_dtl%"},
    {lines: [ //Show/hide quotation details
      {text: " Professional fees", textRight: "%prof_fees%", style: "borderTop"},
      {text: " TOTAL FEES & SERVICE COSTS", textRight: "%total_fees%", style: ["bold","borderTop"]},
      // {pageBreak: true},
    ], style: "indented"},
    {lines: [
      {text: " AIT (10%)", textRight: "%ait%"},
      {text: " VAT 15%", textRight: "%vat%"},
      {text: " TOTAL FEES plus Govt. taxes", textRight: "%fees_plus_tax%", style: ["bold","borderTop"]},
    ], style: ["indented","borderBottomThick"]},
    {lines: [
      {text: " Expected day of confirmation", textMiddle: "%meeting_end%", borderMiddle: true},
      {text: " Date of commencement, if agreed", textMiddle: "%meeting_end%", borderMiddle: true, style: "borderTop"},
      {text: {content: " Tentative date of delivery   [%required_time% days]", style: "borderRight"}, //red
       textMiddle: {content: "%delivery_date%", style: "borderLeft"},
       borderMiddle: true, style: ["bold","borderTop"]},
    ], style: ["indented","bordered","relative"]},
    {lines: [
      {text: "All bank statements and other relevant documents must be produced to us for our independent",
       textLeft: "N.B."},
      {text: "audit opinion. Please read the facts and figures of the financial statements carefully as no"},
      {text: "modification is allowed in Document Verification System (DVS) once DVC is generated."},
    ], style: ["indentLeft","relative"]},
    {lines: [
      {text: "In addition to above, our travelling and other staff support expenses, if so required, will be added with"},
      {text: "these fees which is not expected to be more than BDT 5000.\n\n"},
    ]},
    {lines: [
      {text: "Thank you.\nYours truly,\n\n\n\n"},
      {text: "Md. Abdur Rouf, FCA (Enrolment no 918)", style: "bold"},
      {text: "Partner"},
      {text: "Mak & Co.", style: "bold"},
      {text: "Chartered Accountants"},
      {text: "rouf10rrh@gmail.com", textLeft: "Email:", style: "indented"},
      {text: "rouf@maknco.net", style: "indented"},
      {text: "BSEC Bhaban (Level 11), 100 Kazi Nazrul Islam Avenue", style: "indented"},
      {text: "Kawran Bazar, Dhaka-1205", style: "indented"},
      {text: "Bangladesh", style: "indented"},
      // {text: "Mobile: (+88) 01713 480 580"},
    ]}
  ], style: "%quot_size_dtl%" //size6_97
},
// ------------------------------------------------------------------------------------------- //
// null,
// ------------------------------------------------------------------------------------------- //
/*{ date: "%today%",  // Modified Quotation from Engagement Letter - February 13, 2023
  // --------------------------------------------------------------------------------------- //
  addrLines: [ //Quotation
    {text:"Head of Finance/Chief Financial Officer"},
    {text: "%company_name%", style: "bold"}, // Justified Company Limited
    {text: "Dhaka, Bangladesh"},
  ],
  salutation: "Dear Sir(s)/Madam,",
  titleLines: [ //Quotation
    {text: "Quotation/ Expression of interest for appointment as auditor of", style: "underline"},
    {text: "%company_name% for the year ended %yearend_date%", style: "underline"}, //30 June 2022
  ],
  // --------------------------------------------------------------------------------------- //
  paragraphs: [
    /*{lines: [ //Engagement Letter
      { text: "Dear Sir" },
      {
        text: "\nWe are pleased to set out below our responsibilities and relevant terms and conditions in compliance with the International Standards\n"+
              "on Auditing (ISAs) and other applicable laws and regulations as the auditor of %company_name% for the year ended\n"+
              "%yearend_date% and our understanding for the purpose of any audit and related services as you require to us are:"
      },
    ]},* /
    {lines: [ //Quotation
      {
        text: "As salamu alaikum. We have gone through your relevant documents for the purpose of audit of the\n"+
              "financial statements of %company_name% for the year ended %yearend_date%.\n"+
              "\n"+
              "We hearby expressing our interest for the appointment as statutory auditor of %company_name%\n"+
              "for the year ended. For this purpose, considering all matters, we propose our fees as follows:"
      },
    ]},
    // ------------------------------------------------------------------------------------- //
    /*{lines: [ //Engagement Letter
      { text: ". The objective and scope of the audit [ ISA 210 para(10a)]", style: "bold",
        textLeft: {content: "1", style: "halfLeft"} },
      { text: "You have requested that we audit the financial satatements of the company as of and for the year ended 30 June 2022:" },
      { text: "\nThe said financial statements comprise:" },
      { text: "\u00A0    a. Statement of financial position;" },
      { text: "\u00A0    b. Statement of profit or loss and other comprehensive income;" },
      { text: "\u00A0    c. Statement of changes in equity;" },
      { text: "\u00A0    d. Statement of cash flows; and" },
      { text: "\u00A0    e. Notes, comprising a summary of significant accounting policies and disclosures;" },
      { text: "\nWe are pleased to confirm our acceptance and our understanding of this audit engagement by means of this letter. Our audit" },
      { text: "will be conducted with the objective of our expressing an opinion on the financial statements." },
    ], style: "halfIndentLeft"},* /
    {lines: [ //Quotation
      {text: " Last year audit fee: as per FS [for BDT %revenue_asst% million]", textRight: "BDT %fee_last_year% \u00A0 \u00A0"}, //, textRight: "BDT"
      {text: " Fees as per ICAB fees schedule [for BDT %revenue_asst% million]", textRight: "BDT %fee_icab_schd% \u00A0 \u00A0"}, //
      {text: " %txt_work_based%", textRight: "%fee_work_based% \u00A0 \u00A0"}, //
    ], style: ["indented","bordered"]}, //
    {lines: [
      {text: " Our audit based upon covering our cost structure", textRight: "%cost_structure%", style: "bold"},
      // {text: "\n", style: "borderTop"},
    ], style: ["indented","borderBottom"]},
    // ------------------------------------------------------------------------------------- //
    {lines: [
      { text: ". The responsibilities of auditor", style: "bold",
        textLeft: {content: "2", style: "halfLeft"} },
      { text: "We will conduct our audit in accordance with International Standards on Auditing (ISAs). Those standards require that we" },
      { text: "comply with ethical requirements and plan and perform the audit to obtain reasonable assurance whether the financial" },
      { text: "statements are free from material misstatement. An audit involves performing procedures to obtain audit evidence about the" },
      { text: "amounts and disclosures in the financial statements. The procedures selected depend on the auditors’ judgment, including the" },
      { text: "assessment of the risks of material misstatement of the financial statements, whether due to fraud or error. An audit also includes evaluating the appropriateness of accounting policies used and the reasonableness of accounting estimates made by management, as well as evaluating the overall presentation of the financial statements." },

      { text: "\nBecause of the inherent limitation of an audit, together with the inherent limitation of internal control, there is an unavoidable"},
      { text: "risk that some material misstatement may not be detected, even though the audit is properly planned and performed in accordance with ISAs." },

      { text: "\nIn making our risk assessments, we consider internal control relevant to the entity’s preparation of the financial statements in order to design audit procedures that are appropriate in the circumstances, but not for expressing an opinion on the"},
      { text: "effectiveness of the entity’s internal control. However, we will communicate to you in writing concerning any significant"},
      { text: "deficiencies in internal control relevant to the audit of the financial statements that we have identified during the audit." },

      { text: "\nAs part of our audit, we will read the other information in your annual report, if any, and consider whether such information, or the manner of its presentation, is materially consistent with information, or the manner of its presentation, appearing in the financial statements.  However, our audit does not include the performance of procedures to corroborate such other information (including forward-looking statements)."},
    ], style: "halfIndentLeft"},
    {lines: [
      { text: ". The responsibilities of Company's Management [ISA 210 para 6(b), A11- A20, ISQC 1 para A19]", style: "bold",
        textLeft: {content: "3", style: "halfLeft"} },
      { text: "Our audit will be conducted on the basis that management and, where appropriate, those charged with governance acknowledge and understand that they have responsibility:"},
      { text: "\n\u00A0    (a)    For the preparation of financial statements including all other adequate disclosure is that of the management of the"+
              "\n\u00A0             Company;" },
      { text: "\n\u00A0    (b)    For identifying and ensuring that the Company complies with laws and regulations applicable to its activities;" },
      { text: "\n\u00A0    (c)    For such internal control as management determines is necessary to enable the preparation of financial statements"+
              "\n\u00A0             that are free from material misstatement, whether due to fraud or error;" },
      { text: "\n\u00A0    (d)    For maintaining proper accounting records and, selecting and applying proper accounting policies consistently; and" },
      { text: "\n\u00A0    (e)    To provide us with:" },
      { text: "\n\u00A0        (i) Access to all information of which management is aware that is relevant to the preparation of the financial statements"+
              "\n\u00A0             such as records, documentation and other matters;"},
      { text: "\n\u00A0       (ii) Additional information that we may request from management for the purpose of the audit; and" },
      { text: "\n\u00A0      (iii) Unrestricted access of to persons within the entity from whom we determine it necessary to obtain audit evidence." },
      { text: "\nDirecotors and management must, under section 213 of the Companies Act 1994, make available to us all the books and records of the company and related information including any register, minutes of meetings and general documents and give us any information, explanations and assistnace we require for the purpose of our audit. We are also entitled under section 217 to receive notice of any general meetings and resolutions and communications relating to those meetings."},
      { text: "\nAs part of our audit process, we will request from management and, where appropriate, those charged with governance, written confirmation concerning representations made to us in connection with the audit."},
      { text: "\nUpon giving us the appointment as a statutory auditor of your company you agree to pay our service fees along with applicable government taxes/VAT and charges, if any, as mentioned in para 7.1 and consider the matters of 'Integrity of Client as metioned in ISQC 1."},
    ], style: "halfIndentLeft"},
    {lines: [
      { text: ". Reporting [ISA 210: para 10(e), Companies Act 1994: u/s 213(3)]", style: "bold",
        textLeft: {content: "4", style: "halfLeft"} },
      { text: "We are required to form our opinion on whether the financial statements prepared in accordance with International Financial Reporting Standards (IFRSs), give a true and fair view of the states of the company’s affairs as of reporting date and of the results of its operations and its cash flows for the year then ended and comply with the Companies Act and other applicable laws and regulations. We are also required to report whether:" },
      { text: "\n\u00A0    (a)    we have obtained all the information and explanations which to the best of our knowledge and belief were necessary"+
              "\n\u00A0             for the purpose of our audit and made due verification thereof;" },
      { text: "\n\u00A0    (b)    proper books of account as required by law have been kept by the Company so far as it appeared from our examination"+
              "\n\u00A0             of those books;" },
      { text: "\n\u00A0    (c)    the company’s financial statements dealt with by the report are in agreement with the books of account." },
      { text: "\nOur report will be addressed to the shareholders of the company. We have a professional responsibility to report as an independent auditor, if the financial statements do not comply, in any material respect, with IFRSs and other applicable laws and regulation. Hence we cannot provide assurance that an unqualified opinion will be rendered and circumstances may arise in which it would be necessary for us to modify our report or withdraw from the engagement. In such circumstances, our findings or reasons for withdrawal will be communicated to the Board of Directors."},
      { text: "\nGenerally, we send 3 (three) sets of audited financial statements incompliance with IFRS duly signed by us unless the Company requires any additional copies upon payment of our prescribed cost(s)."},
    ], style: "halfIndentLeft"},
    {lines: [
      { text: ". Timetable", style: "bold",
        textLeft: {content: "5", style: "halfLeft"} },
      { text: "We will provide our service to your entity upon discussion with you." },
      { text: "\nIn order to enable us to perform the services, you shall supply promptly all information and assistance and all access to documentation in your possession, custody or under your control and to personnel under your control where required by us. You shall use your best endeavors to procure these supplies were not in your possession or custody or under your control. You shall inform us of any information or developments which may come to your notice and which might have a bearing on the services." },
    ], style: "halfIndentLeft"},{lines: [
      { text: ". Other Services", style: "bold",
        textLeft: {content: "6", style: "halfLeft"} },
      { text: "Should we be approached to provide other services to the entity we shall assess each approach independently, and no other services shall be construed to be performed as part of our audit. Further, in the circumstances of our position as auditors, any appointment of either firm for other services will also have to be dealt with as an appointment entirely separately from the terms of our audit appointment." },
    ], style: "halfIndentLeft"},{lines: [
      { text: ". General Terms", style: "bold",
        textLeft: {content: "7", style: "halfLeft"} },
      { text: ". Fees and Invoicing Arrangements [ ISA 210 para A23]", style: "bold",
        textLeft: {content: "\u00A0 7.1", style: "halfLeft"} },
      { text: "#N/A" },
      { text: ". Our Responsibilities", style: "bold",
        textLeft: {content: "\u00A0 7.2", style: "halfLeft"} },
      { text: "We will provide the services described in this engagement letter (or such variations as may subsequently be agreed between us) with reasonable skill and care in accordance with the professional standards expected of us, and in a timely manner." },
      { text: "\nThe nature of any advice we provide will necessarily depend on the amount and accuracy of information provided to us and the timescale within which the advice is required.  If general advice is provided, the applicability of this will depend on the particular circumstances in which it is to be used by the entity (of which we might not be aware) and should be viewed accordingly.  In relation to any particular transaction, specific advice should always be sought and all material information provided to us.  If, at the entity’s request, we provide our advice in an abbreviated format (i.e. other than a full written report), you acknowledge that you will not receive all the information you would otherwise have done." },
      { text: "\nWhere we correspond by means of the Internet or other electronic media at your request, whilst we will take reasonable steps to safeguard the security and confidentiality of the information transmitted, you acknowledge that we cannot guarantee its security and confidentiality."},
      { text: "\nWhilst our reports and advice may be a factor to be taken into account when deciding whether or not to proceed with a particular course of action, the entity remains responsible for any operating decisions that it makes, and regard must be had to the restrictions on the scope of our work and to the large number of other factors, operating and otherwise, of which you and your other advisers are, or should be, aware by means other than our work." },
      { text: "\nIndependence", style: "bold" },
      { text: "We have policies and procedures designed to ensure our independence, including policies on holding financial interests in the company and other related parties, business relationships and employment relationships." },

    ], style: "halfIndentLeft"},
  ], style: "size10" //size10
},*/
// ------------------------------------------------------------------------------------------- //
// ------------------------------------------------------------------------------------------- //
{ date: "%today%",  // Appointment Letter - February 3, 2023
  addrLines: [
    {text: "Md. Abdur Rouf, FCA (Enrollment no 918)", style: "bold"},
    {text: "Partner"},
    {text: "Mak & Co.", style: "bold"},
    {text: "Chartered Accountants"},
    {text: "BSEC Bhaban (Level 11), 100 Kazi Nazrul Islam Avenue"},
    {text: "Kawran Bazar, Dhaka-1205"},
    {text: "Bangladesh"},
    {text: "rouf10rrh@gmail.com"},
    {text: "rouf@maknco.net\n\n"},
  ],
  salutation: "Dear Sir(s),",
  titleLines: [
    {text: "APPOINTMENT AS AUDITOR OF"},
    {text: "%company_name%"},  // Justified Company Limited
    {text: "%period% %yearend_date%", style: "underline"}, //for the year ended 30 June 2022
  ],
  paragraphs: [
    {lines: [
      {
        text: "\nWe are pleased to inform you that, Mak and Co., Chartered Accountants, represented by you (Mr.\n"+
              "Md. Abdur Rouf,  FCA  (Enrollment no 918)),  has  been  appointed  as  the  statutory  auditor  of\n"+
              "%company_name% %period% %yearend_date% and quoting our audit fee of BDT\n"+ //for the year ended
              "37,500 (fee + Vat/tax) based upon the decision of the EXTRA-ORDINARY GENERAL\n"+
              "MEETING/ANNUAL GENERAL MEETING of the company.\n"+
              "\n"+
              "We request you to accept the appointment and share us the relevant RJSC Form 23B.\n"+
              "\n"+
              "Thank you."+
              "\n"+
              "Yours faithfully,\n"+
              "\n\n\n\n"+
              "....................."
      },
    ], style: "justify"},{lines: [

    ]}
  ], style: "size12"
},
// ------------------------------------------------------------------------------------------- //
{ date: "%today%",  // Engagement Letter - February 13, 2023
  addrLines: [
    {text: "Managing Director"},
    {text: "%company_name%"},  // Justified Company Limited
    {text: "23/D/1, Box Culvert Road, Free School Street, Panthapath"},
    {text: "Dhaka-1205, Bangladesh"},
  ],
  titleLines: [
    {text: "Audit engagement on the Statutory financial statements [ ISA 210 para(6a)] of"},
    {text: "%company_name% %period% %yearend_date%."},  //for the year ended 30 June 2022
  ],
  paragraphs: [
    {lines: [
      { text: "Dear Sir" },
      {
        text: "\nWe are pleased to set out below our responsibilities and relevant terms and conditions in compliance with the International Standards\n"+
              "on Auditing (ISAs) and other applicable laws and regulations as the auditor of %company_name% %period%\n"+
              "%yearend_date% and our understanding for the purpose of any audit and related services as you require to us are:"
      },
    ]},{lines: [
      { text: ". The objective and scope of the audit [ ISA 210 para(10a)]", style: "bold",
        textLeft: {content: "1", style: "halfLeft"} },
      { text: "You have requested that we audit the financial satatements of the company as of and %period% %yearend_date%:" },



      { text: "\nThe said financial statements comprise:" },
      { text: "\u00A0    a. Statement of financial position;" },
      { text: "\u00A0    b. Statement of profit or loss and other comprehensive income;" },
      { text: "\u00A0    c. Statement of changes in equity;" },
      { text: "\u00A0    d. Statement of cash flows; and" },
      { text: "\u00A0    e. Notes, comprising a summary of significant accounting policies and disclosures;" },
      { text: "\nWe are pleased to confirm our acceptance and our understanding of this audit engagement by means of this letter. Our audit" },
      { text: "will be conducted with the objective of our expressing an opinion on the financial statements." },
    ], style: "halfIndentLeft"},{lines: [
      { text: ". The responsibilities of auditor", style: "bold",
        textLeft: {content: "2", style: "halfLeft"} },
      { text: "We will conduct our audit in accordance with International Standards on Auditing (ISAs). Those standards require that we" },
      { text: "comply with ethical requirements and plan and perform the audit to obtain reasonable assurance whether the financial" },
      { text: "statements are free from material misstatement. An audit involves performing procedures to obtain audit evidence about the" },
      { text: "amounts and disclosures in the financial statements. The procedures selected depend on the auditors’ judgment, including the" },
      { text: "assessment of the risks of material misstatement of the financial statements, whether due to fraud or error. An audit also includes evaluating the appropriateness of accounting policies used and the reasonableness of accounting estimates made by management, as well as evaluating the overall presentation of the financial statements." },

      { text: "\nBecause of the inherent limitation of an audit, together with the inherent limitation of internal control, there is an unavoidable"},
      { text: "risk that some material misstatement may not be detected, even though the audit is properly planned and performed in accordance with ISAs." },

      { text: "\nIn making our risk assessments, we consider internal control relevant to the entity’s preparation of the financial statements in order to design audit procedures that are appropriate in the circumstances, but not for expressing an opinion on the"},
      { text: "effectiveness of the entity’s internal control. However, we will communicate to you in writing concerning any significant"},
      { text: "deficiencies in internal control relevant to the audit of the financial statements that we have identified during the audit." },

      { text: "\nAs part of our audit, we will read the other information in your annual report, if any, and consider whether such information, or the manner of its presentation, is materially consistent with information, or the manner of its presentation, appearing in the financial statements.  However, our audit does not include the performance of procedures to corroborate such other information (including forward-looking statements)."},
    ], style: "halfIndentLeft"},{lines: [
      { text: ". The responsibilities of Company's Management [ISA 210 para 6(b), A11- A20, ISQC 1 para A19]", style: "bold",
        textLeft: {content: "3", style: "halfLeft"} },
      { text: "Our audit will be conducted on the basis that management and, where appropriate, those charged with governance acknowledge and understand that they have responsibility:"},
      { text: "\n\u00A0    (a)    For the preparation of financial statements including all other adequate disclosure is that of the management of the"+
              "\n\u00A0             Company;" },
      { text: "\n\u00A0    (b)    For identifying and ensuring that the Company complies with laws and regulations applicable to its activities;" },
      { text: "\n\u00A0    (c)    For such internal control as management determines is necessary to enable the preparation of financial statements"+
              "\n\u00A0             that are free from material misstatement, whether due to fraud or error;" },
      { text: "\n\u00A0    (d)    For maintaining proper accounting records and, selecting and applying proper accounting policies consistently; and" },
      { text: "\n\u00A0    (e)    To provide us with:" },
      { text: "\n\u00A0        (i) Access to all information of which management is aware that is relevant to the preparation of the financial statements"+
              "\n\u00A0             such as records, documentation and other matters;"},
      { text: "\n\u00A0       (ii) Additional information that we may request from management for the purpose of the audit; and" },
      { text: "\n\u00A0      (iii) Unrestricted access of to persons within the entity from whom we determine it necessary to obtain audit evidence." },
      { text: "\nDirecotors and management must, under section 213 of the Companies Act 1994, make available to us all the books and records of the company and related information including any register, minutes of meetings and general documents and give us any information, explanations and assistnace we require for the purpose of our audit. We are also entitled under section 217 to receive notice of any general meetings and resolutions and communications relating to those meetings."},
      { text: "\nAs part of our audit process, we will request from management and, where appropriate, those charged with governance, written confirmation concerning representations made to us in connection with the audit."},
      { text: "\nUpon giving us the appointment as a statutory auditor of your company you agree to pay our service fees along with applicable government taxes/VAT and charges, if any, as mentioned in para 7.1 and consider the matters of 'Integrity of Client as metioned in ISQC 1."},
    ], style: "halfIndentLeft"},{lines: [
      { text: ". Reporting [ISA 210: para 10(e), Companies Act 1994: u/s 213(3)]", style: "bold",
        textLeft: {content: "4", style: "halfLeft"} },
      { text: "We are required to form our opinion on whether the financial statements prepared in accordance with International Financial Reporting Standards (IFRSs), give a true and fair view of the states of the company’s affairs as of reporting date and of the results of its operations and its cash flows for the year then ended and comply with the Companies Act and other applicable laws and regulations. We are also required to report whether:" },
      { text: "\n\u00A0    (a)    we have obtained all the information and explanations which to the best of our knowledge and belief were necessary"+
              "\n\u00A0             for the purpose of our audit and made due verification thereof;" },
      { text: "\n\u00A0    (b)    proper books of account as required by law have been kept by the Company so far as it appeared from our examination"+
              "\n\u00A0             of those books;" },
      { text: "\n\u00A0    (c)    the company’s financial statements dealt with by the report are in agreement with the books of account." },
      { text: "\nOur report will be addressed to the shareholders of the company. We have a professional responsibility to report as an independent auditor, if the financial statements do not comply, in any material respect, with IFRSs and other applicable laws and regulation. Hence we cannot provide assurance that an unqualified opinion will be rendered and circumstances may arise in which it would be necessary for us to modify our report or withdraw from the engagement. In such circumstances, our findings or reasons for withdrawal will be communicated to the Board of Directors."},
      { text: "\nGenerally, we send 3 (three) sets of audited financial statements incompliance with IFRS duly signed by us unless the Company requires any additional copies upon payment of our prescribed cost(s)."},
    ], style: "halfIndentLeft"},{lines: [
      { text: ". Timetable", style: "bold",
        textLeft: {content: "5", style: "halfLeft"} },
      { text: "We will provide our service to your entity upon discussion with you." },
      { text: "\nIn order to enable us to perform the services, you shall supply promptly all information and assistance and all access to documentation in your possession, custody or under your control and to personnel under your control where required by us. You shall use your best endeavors to procure these supplies were not in your possession or custody or under your control. You shall inform us of any information or developments which may come to your notice and which might have a bearing on the services." },
    ], style: "halfIndentLeft"},{lines: [
      { text: ". Other Services", style: "bold",
        textLeft: {content: "6", style: "halfLeft"} },
      { text: "Should we be approached to provide other services to the entity we shall assess each approach independently, and no other services shall be construed to be performed as part of our audit. Further, in the circumstances of our position as auditors, any appointment of either firm for other services will also have to be dealt with as an appointment entirely separately from the terms of our audit appointment." },
    ], style: "halfIndentLeft"},{lines: [
      { text: ". General Terms", style: "bold",
        textLeft: {content: "7", style: "halfLeft"} },
      { text: ". Fees and Invoicing Arrangements [ ISA 210 para A23]",
        textLeft: {content: "\u00A0 7.1", style: "halfLeft"}, style: ["marginTop","bold"] },
      { text: "#N/A" },
      { text: ". Our Responsibilities",
        textLeft: {content: "\u00A0 7.2", style: "halfLeft"}, style: ["marginTop","bold"] },
      { text: "We will provide the services described in this engagement letter (or such variations as may subsequently be agreed between us) with reasonable skill and care in accordance with the professional standards expected of us, and in a timely manner." },
      { text: "\nThe nature of any advice we provide will necessarily depend on the amount and accuracy of information provided to us and the timescale within which the advice is required.  If general advice is provided, the applicability of this will depend on the particular circumstances in which it is to be used by the entity (of which we might not be aware) and should be viewed accordingly.  In relation to any particular transaction, specific advice should always be sought and all material information provided to us.  If, at the entity’s request, we provide our advice in an abbreviated format (i.e. other than a full written report), you acknowledge that you will not receive all the information you would otherwise have done." },
      { text: "\nWhere we correspond by means of the Internet or other electronic media at your request, whilst we will take reasonable steps to safeguard the security and confidentiality of the information transmitted, you acknowledge that we cannot guarantee its security and confidentiality."},
      { text: "\nWhilst our reports and advice may be a factor to be taken into account when deciding whether or not to proceed with a particular course of action, the entity remains responsible for any operating decisions that it makes, and regard must be had to the restrictions on the scope of our work and to the large number of other factors, operating and otherwise, of which you and your other advisers are, or should be, aware by means other than our work." },
      { text: "\nIndependence", style: "bold" },
      { text: "We have policies and procedures designed to ensure our independence, including policies on holding financial interests in the company and other related parties, business relationships and employment relationships." },
      { text: ". Information and Confidentiality",
        textLeft: {content: "\u00A0 7.3", style: "halfLeft"}, style: ["marginTop","bold"] },
      { text: "Our Partners and staff are under an obligation not to disclose to third parties confidential information relating to our clients." },
      { text: "\n" },

    ], style: "halfIndentLeft"},
  ], style: "size10" //size10
}
// ------------------------------------------------------------------------------------------- //
];

const replaceTemplate = (template, data=[]) => {
  let values = template; //console.log("template::", (typeof template), template, ", data:", data);
  const replaceText = (values) => {
    if((typeof values)==="string") {
      values = values.replace(/%(\w*)%/g, (m, key) => (data.hasOwnProperty(key)? data[key]: `%${key}%`));
    } else if((typeof values)==="object" && values !== null) {
      if(Array.isArray(values)) values = values.map((line) => replaceText(line, data));
      else Object.keys(values).forEach((key) => { values[key] = replaceText(values[key], data) });
    } //console.log("replaceTemplate:: values:", values);
    return values;
  };
  if((typeof template)==="string") values = (" "+template).slice(1);
  else if((typeof template)==="object" && template !== null)
    values = JSON.parse(JSON.stringify(template));
  return replaceText(values);
};

export const getLetter = (index, formValues, allocations=[]) => {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const today = new Date(), yrndt = new Date(formValues.yrendDate), mtEnd = new Date(formValues.mtEnd);
  const delDt = new Date(formValues.mtEnd); let x;
  delDt.setDate(mtEnd.getDate() + parseFloat(formValues.rqDays)); const delHr = delDt.getHours();
  const rvAst = Math.round((Math.max(parseFloat(removeComma(formValues.revenue)),
    parseFloat(removeComma(formValues.tAssets)), parseFloat(removeComma(formValues.borrows)),
    Math.abs(parseFloat(removeComma(formValues.profLos))) ))/1000000);
  //console.log("allocations[11].time:", (typeof allocations[11].time), allocations[11].time);
  const cost = parseFloat(removeComma(formValues.feeMar))-parseFloat(removeComma(formValues.profCost));
  const crErr = (allocations.length>11 && allocations[11].time>0?
    (allocations[11].time/60)*getResourcePay(formValues.rs1Name) :0);
  const fsNet = (formValues.fsNet==="0"?"- \u00A0 \u00A0 ":formValues.fsNet+"\u00A0");
  const vsNet = (formValues.visitNet==="0"?"- \u00A0 \u00A0 ":formValues.visitNet+"\u00A0");
  const rSupp = (formValues.reguSup==="0"?"- \u00A0 \u00A0 ":formValues.reguSup+"\u00A0");
  const txRet = (formValues.taxReturn==="0"?"- \u00A0 \u00A0 ":formValues.taxReturn+"\u00A0");
  const rjRet = (formValues.rjscRet==="0"?"- \u00A0 \u00A0 ":formValues.rjscRet+"\u00A0");
  const o1Amt = (formValues.oth1Amt==="0"?"- \u00A0 \u00A0 ":formValues.oth1Amt+"\u00A0");
  const o2Amt = (formValues.oth2Amt==="0"?"- \u00A0 \u00A0 ":formValues.oth2Amt+"\u00A0");
  const pCost = (formValues.profCost==="0"?"- \u00A0 \u00A0 ":formValues.profCost+"\u00A0");
  const extra = crErr + parseFloat(removeComma(formValues.fsNet)) +
    parseFloat(removeComma(formValues.visitNet)) + parseFloat(removeComma(formValues.reguSup)) +
    parseFloat(removeComma(formValues.rjscRet)) + parseFloat(removeComma(formValues.taxReturn)) +
    parseFloat(removeComma(formValues.oth1Amt)) + parseFloat(removeComma(formValues.oth2Amt));
  const fIcab = parseFloat(removeComma(formValues.feeIcab)) + extra;
  //const wBase = parseFloat(removeComma( (Math.ceil(parseFloat(formValues.rsNet)/1000)*1000).toString() ));
  const wBase = Math.ceil(parseFloat(removeComma(formValues.rsNet))/1000)*1000 + extra;
  const disct = (x=parseFloat(removeComma(formValues.discount)),formValues.discount==="0"?
    "- \u00A0 \u00A0 ":(x<0?"("+commaFormat(-x)+")":commaFormat(x)+"\u00A0"));
  const pFees = extra + parseFloat(removeComma(formValues.profCost)) + x;
  const tFees = cost + pFees, ait = cost * 0.1, vat = cost * 0.15;
  //console.log("formValues.ynendDate:", formValues.yrendDate, ", yrndt:", yrndt);
  //console.log("LetterTemplates:: extra:", extra, ", profCost:", formValues.profCost, ", x:", x);
  //console.log("LetterTemplates:: tFees:", tFees, ", cost:", cost, ", pFees:", pFees);
  //console.log("LetterTemplates:: formValues.hideDtl:", formValues.hideDtl);
  const data = {
    today: `${months[today.getMonth()]} ${today.getDate()}, ${today.getFullYear()}`,
    company_name: formValues.name, period: formValues.period, //"for the year ended"
    yearend_date: `${yrndt.getDate()} ${months[yrndt.getMonth()]} ${yrndt.getFullYear()}`,
    revenue_asst: `${rvAst}`, quot_hide_dtl: formValues.hideDtl, //Show/hide quotation details
    quot_size_dtl: (formValues.hideDtl?"size9_485":"size6_965"), //size9_49 size9_485  //size10
    fee_last_year: `${commaFormat(parseFloat(removeComma(formValues.feeLyear)))}`,
    fee_icab_schd: `${fIcab<1?"- \u00A0 \u00A0 ":commaFormat(fIcab)}`,
    // txt_work_based: `${tFees>wBase?"":"Fee as per our cost structure [for BDT "+rvAst+" million]"}`,
    // fee_work_based: `${tFees>wBase?"":"BDT "+commaFormat(wBase)}`, cost_structure:`${commaFormat(cost)}`,
    txt_work_based: `${tFees>wBase||wBase>fIcab?"":"Fee as per our cost structure [for BDT "+rvAst+" million]"}`,
    fee_work_based: `${tFees>wBase||wBase>fIcab?"":"BDT "+commaFormat(wBase)}`, cost_structure:`${commaFormat(cost)}`,
    correction_err: `${crErr<1?"- \u00A0 \u00A0 ":commaFormat(crErr)+"\u00A0"}`, fs_draft_bank: `${fsNet}`,
    visit_hour_cost:`${vsNet}`, regu_supp_cost: `${rSupp}`, rjsc_return: `${rjRet}`, tax_return: `${txRet}`,
    others_1_txt: `${formValues.oth1Txt}`, others_1_amt: `${o1Amt}`,
    others_2_txt: `${formValues.oth2Txt}`, others_2_amt: `${o2Amt}`,
    prof_cost: `${pCost}`, discount: `${disct}`, prof_fees: `${commaFormat(pFees)}`,
    total_fees:`${commaFormat(tFees)}`, ait:`${commaFormat(ait)}`, vat:`${commaFormat(vat)}`,
    fees_plus_tax: `${commaFormat(tFees+ait+vat)}`, required_time: `${formValues.rqDays}`,
    meeting_end: `${mtEnd.getDate()}-${months[mtEnd.getMonth()].substring(0,3)}-${mtEnd.getFullYear()}`,
    delivery_date: `${delDt.getDate()}-${months[delDt.getMonth()].substring(0,3)}-${delDt.getFullYear()
      } at ${delHr>12?(delHr-12):delHr}:${delDt.getMinutes()} ${delHr<12?"AM":"PM"}`,
  }; //12-Mar-2023 at 2:19 PM
  const letter = replaceTemplate(letterTemplates[index], data);
  // console.log("getLetterTemplate:: data:", data, ", letter:", letter);
  // console.log("getLetterTemplate:: Original Template:", letters[index]);
  return letter;
};

export default letterTemplates;
