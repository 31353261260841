import { useState, useEffect, useRef } from "react";
import md5 from "md5";
import { useDispatch, useSelector } from "react-redux";
import { setUpldFiles } from "../../redux/upldFilesSlice";
import { setDrtyFiles } from "../../redux/drtyFilesSlice";
import { setOvflFiles } from "../../redux/ovflFilesSlice";
import { setUpldFilesData } from "../../redux/upldFilesDataSlice";
import { RootState } from "../../redux/store";
import { Autocomplete, FormControl, FormGroup, ListItemText, MenuItem, Paper } from "@mui/material";
import { Select, TextField, Radio, SelectChangeEvent, Dialog, Alert, AlertTitle } from "@mui/material";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
// import fs from "fs"; // fs is a node js (serverside) module
import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
// import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"; // DesktopDatePicker,
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormValues, Allocation, Allocations, UpldFiles, getUpldFiles, SingleFile,
  getAllocations, getMgLtrAllocs, getFsDftAllocs } from "../../Data/DocumentData";
import DocumentSelect from "./FormSubComponents/DocumentSelect";
import { sendMail } from "../../pages/letterspage/LettersPage";

dayjs.extend(advancedFormat);

// const useStyles = makeStyles({
//   input: {
//     width: 400,
//     height: 150,
//     '& input + fieldset': {
//       borderColor: 'hotpink',
//     },
//   },
// });

const stats = ["Y", "N", "N/A"];
//const remarks: {"Y": string, "N": string, "N/A": string} = {
const remarks: {[key: string]: string} = {
  "Y": "Already received with thanks",
  "N": "Doc missing. Please give ASAP to commence our audit",
  "N/A": "Not applicable for the entity"
};

const minWidth = 300, width = "25.6vw", oneThird = "8.533vw", twoThird = "17.067vw";
// export const col1 = "2vw", col2 = "21.6vw", col3 = "5.25vw", col4 = "24.25vw"; //col2 = "19.6vw"
export const col1 = "2vw", col2 = "22.6vw", col3 = "5.25vw", col4 = "21.25vw", col5 = "14.6vw"; //"14vw"
export const colsTot = "65.7vw";
const col2Less = "19vw", col13 = "5.05vw", col4Less = "24vw";
const defaultAddress = "23/D/1, Box Culvert Road, Free School Street, Panthapath, Dhaka-1205, Bangladesh.";
const today = new Date();  //, lessWidth = "20vw"
let btn1Handler = () => {}, btn2Handler = () => {};
let oldValues;

//const col2 = "19.6vw", col4 = "22.25vw", col5 = "12vw"; // width = "21.6vw",

interface IProps { //
  documentData: FormValues[],
  setDocumentData: React.Dispatch<React.SetStateAction<FormValues[]>>,
  currentId: string, setCurrentId: React.Dispatch<React.SetStateAction<string>>,
  getDefaultFormValues: (data: Array<FormValues>|null, id: string) => FormValues,
  formValues: FormValues, setFormValues: React.Dispatch<React.SetStateAction<FormValues>>
  allocations: Array<Allocation>, allocationData: Array<Allocations>,
  setAllocations: React.Dispatch<React.SetStateAction<Allocation[]>>,
  setAllocationData: React.Dispatch<React.SetStateAction<Allocations[]>>,
  mgLtrAllocs: Array<Allocation>, mgLtrAllocData: Array<Allocations>,
  setMgLtrAllocs: React.Dispatch<React.SetStateAction<Allocation[]>>,
  setMgLtrAllocData: React.Dispatch<React.SetStateAction<Allocations[]>>,
  fsDftAllocs: Array<Allocation>, fsDftAllocData: Array<Allocations>,
  setFsDftAllocs: React.Dispatch<React.SetStateAction<Allocation[]>>,
  setFsDftAllocData: React.Dispatch<React.SetStateAction<Allocations[]>>,
  inputMode: number, setInputMode: React.Dispatch<React.SetStateAction<number>>,
  userEmail: string,
};

export default function DocumentList({ documentData, setDocumentData,
  currentId, setCurrentId, getDefaultFormValues, formValues, setFormValues,
  allocations, setAllocations, allocationData, setAllocationData,
  mgLtrAllocs, setMgLtrAllocs, mgLtrAllocData, setMgLtrAllocData,
  fsDftAllocs, setFsDftAllocs, fsDftAllocData, setFsDftAllocData,
  inputMode, setInputMode, userEmail}: IProps) {
  const dispatch = useDispatch();
  const upldFiles = useSelector((state: RootState) => state.upldFiles.value);
  // const [upldFiles, setLocalUpldFiles] = useState(useSelector((state: RootState) => state.upldFiles.value));
  const drtyFiles = useSelector((state: RootState) => state.drtyFiles.value);
  const ovflFiles = useSelector((state: RootState) => state.ovflFiles.value);
  const upldFilesData = useSelector((state: RootState) => state.upldFilesData.value);
  //console.log("DocumentList:: upldFilesData:", upldFilesData);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMesg, setAlertMesg] = useState("");
  const [btn1Text, setBtn1Text] = useState("");  // Ok
  const [btn2Text, setBtn2Text] = useState("");  // Cancel
  //const [inputMode, setInputMode] = useState(0); // 0 - Read Only, 1 - Add New, 2 - Edit
  const textRef = useRef<HTMLInputElement>(); //let autoRef: HTMLInputElement | undefined;
  const autoRef = useRef<HTMLInputElement>();
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    // setFormValues({...formValues, [name]: value});
    setFormValues((formValues) => ({...formValues, [name]: value}));
  }; // console.log("formValues:", formValues);
  //console.log("currentId:", currentId, "documentData:", documentData);
  //--------------------------------------------------------------------------------------------//
  useEffect(() => { let index = -1; //updateTotals("", "");
    // upldFiles = useSelector((state: RootState) => state.upldFiles.value);
    if(inputMode === 1) { //console.log("inputMode:", inputMode);
      dispatch(setUpldFiles([]));  //setLocalUpldFiles([]);
    } else
    if((index = upldFilesData.findIndex(uploads=>uploads.id===currentId)) >= 0) {
      dispatch(setUpldFiles(upldFilesData[index].upldFiles));
    }
  }, [ currentId, inputMode ]);
  //--------------------------------------------------------------------------------------------//
  const handleAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: string
    | null) => { //console.log("value:", value, ", event:", event);
    if(value){ let index = -1;
      if((index = documentData.findIndex(values=>values.name===value)) >= 0) {
        let companyInfo = documentData[index], id = companyInfo.id;
        setFormValues(companyInfo); setCurrentId(companyInfo.id);
        if((index = allocationData.findIndex(allocs=>allocs.id===id)) >= 0) {
          setAllocations(allocationData[index].allocations);
        }
        if((index = mgLtrAllocData.findIndex(allocs=>allocs.id===id)) >= 0) {
          setMgLtrAllocs(mgLtrAllocData[index].allocations);
        }
        if((index = fsDftAllocData.findIndex(allocs=>allocs.id===id)) >= 0) {
          setFsDftAllocs(fsDftAllocData[index].allocations);
        }
        //console.log("handleAutoCompleteChange:: upldFilesData:", upldFilesData);
        if((index = upldFilesData.findIndex(uploads=>uploads.id===id)) >= 0) {
          //console.log("index:", index, ", upldFilesData[index]:", upldFilesData[index]);
          let newUpldFiles = upldFilesData[index].upldFiles; //setLocalUpldFiles(newUpldFiles);
          dispatch(setUpldFiles(newUpldFiles)); //upldFilesData[index].
        } //drtyFiles = []; ovflFiles = [];
        dispatch(setDrtyFiles([])); dispatch(setOvflFiles([]));
    } }
  };
  const getCompanyNames = () => {
    const names = documentData.map((values) => values.name); //console.log("names:", names);
    return names;
  };

  const handleDatePickerChange = (value: Dayjs | null) => { //console.log(value);
    if(value) {
      const dt = value; //as unknown as {month: ()=>string, date: ()=>string, year: ()=>string};
      const yrendDate = `${dt.month() + 1}/${dt.date()}/${dt.year()}`;
      // setFormValues({...formValues, yrendDate: yrendDate}); //value?.toString()
      setFormValues((formValues) => ({...formValues, yrendDate: yrendDate}));
    }
  };

  // const handleSelectChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
  //   const { target: {name, value} } = event; // console.log("name:", name);
  //   setFormValues((formValues) => ({...formValues, [name]: value}));
  // };
  const handleSelectChange = async (doc:number, numDocs:string, i:number, setFiles:
    React.Dispatch<React.SetStateAction<SingleFile[]>>, e:SelectChangeEvent<string>) => {
    const { target: {name, value} } = e; //console.log("name:", name, ", value:", value);
    //console.log("doc:",doc, ", i:",i, ", upldFiles[doc]:", upldFiles[doc]);
    const newUpldFiles=[...upldFiles], uplFiles = upldFiles[doc]; // upldFile
    const newDrtyFiles=[...drtyFiles], drtFiles = drtyFiles[doc];
    const newOvflFiles=[...ovflFiles], ovfFiles = ovflFiles[doc];
    let name1, path1, mime1, gcsUrl1, desc, stat, user, time, usr, files:SingleFile[] = [];
    //console.log("handleSelectChange:: upldFile:", upldFile);

    if(newUpldFiles[doc] && newUpldFiles[doc].files && newUpldFiles[doc].files.length>0) {
      files = [...newUpldFiles[doc].files];
      if(files.length>i) files[i] = { ...files[i], stat: value };
    }
    if(files.length<=i) {
      files[i] = { name:"", path:"", mime:"", gcsUrl:"", name1:"", path1:"", mime1:"",
        gcsUrl1:"", desc:"", stat: value, user:"", time:"", usr: null };
    }
    newUpldFiles[doc] = newUpldFiles[doc]? {...newUpldFiles[doc], files}: {desc:"", numDocs:numDocs, files};
    //console.log("newUpldFiles["+doc+"]:", newUpldFiles[doc]);

    let upldFile, drtyFile, ovflFile;
    let upFiles = (uplFiles && uplFiles.files ? uplFiles.files : []);
    let drFiles = (drtFiles && drtFiles.files ? drtFiles.files : []);
    let ovFiles = (ovfFiles && ovfFiles.files ? ovfFiles.files : []);

    // upldFile = uplFiles && uplFiles.files && uplFiles.files.length>0 ? uplFiles.files[0] :
    //   {name:"", path:"", mime:"", gcsUrl:"", name1:"", path1:"", mime1:"", gcsUrl1:""};
    // drtyFile = drtFiles && drtFiles.files && drtFiles.files.length>0 ? drtFiles.files[0] :
    //   {name:"", path:"", mime:"", gcsUrl:"", name1:"", path1:"", mime1:"", gcsUrl1:""};
    // ovflFile = ovfFiles && ovfFiles.files && ovfFiles.files.length>0 ? ovfFiles.files[0] :
    //   {name:"", path:"", mime:"", gcsUrl:"", name1:"", path1:"", mime1:"", gcsUrl1:""};

    //upFiles.forEach(async (upldFile, i) => {
    upldFile = upFiles && upFiles.length>i? {...upFiles[i], stat:value}: {name:"", path:"", mime:"",
      gcsUrl:"", name1:"",path1:"",mime1:"", gcsUrl1:"",desc:"",stat:value,user:"",time:"",usr:null};
    if(upldFile) {
      drtyFile = drFiles && drFiles.length>i ? drFiles[i] : {name:"", path:"", mime:"", gcsUrl:"",
        name1:"", path1:"", mime1:"", gcsUrl1:"", desc:"", stat:"N", user:"", time:"", usr: null};
      ovflFile = ovFiles && ovFiles.length>i ? ovFiles[i] : {name:"", path:"", mime:"", gcsUrl:"",
        name1:"", path1:"", mime1:"", gcsUrl1:"", desc:"", stat:"N", user:"", time:"", usr: null};

      if(value!=="Y" && upldFile.path) { // "N" || "N/A"  // && upldFile
        if(drtyFile && drtyFile.path1 && drtyFile.path1!==drtyFile.path) {//Remove from server
          const dataPack = { filepath: drtyFile.path1 }; //console.log("dataPack:", dataPack);
          try { let hasError = false;
            const res = await fetch("/api/remove-file", {
              method: 'POST', body: JSON.stringify(dataPack),
              headers: {'Content-Type': 'application/json'}
            }); if (!res.ok) hasError = true;
            const resData = await res.json(); console.log("handleSelectChange:: resData:", resData);
            if (hasError) throw new Error(resData.message);
          } catch (error: any) {
            console.log(error.message||'Problem deleting the previously uploaded file from the server!');
          }
        } name1 = ""; path1 = ""; mime1=""; gcsUrl1=""; desc=""; stat="N"; user=""; time=""; usr = null;
        //console.log("drtyFile:", drtyFile, ", upldFile:", upldFile);
        if(upldFile.path1 && upldFile.path1!==upldFile.path) {//Remove Later, Push to Overflown
          //console.log("Push to ovflFiles:: upldFiles.path1:", upldFile.path1);
          //console.log("ovflFile:", ovflFile, ", ovflFiles:", ovflFiles);
          if((ovflFile && !ovflFile.path1) || !ovflFile) {// Push to Overflown if there is space
            if(ovflFile) { name1 = ovflFile.name; path1 = ovflFile.path; mime1 = ovflFile.mime;
              gcsUrl1 = ovflFile.gcsUrl; }
            // newOvflFiles[doc].files[0] = { name:upldFile.name1, path:upldFile.path1, mime:upldFile.mime1,
            //   gcsUrl:upldFile.gcsUrl1, name1, path1, mime1, gcsUrl1 };
            files = (newOvflFiles[doc] && newOvflFiles[doc].files? [...newOvflFiles[doc].files]: []);
            files[i] = { name:upldFile.name1, path:upldFile.path1, mime:upldFile.mime1,
              gcsUrl:upldFile.gcsUrl1, name1, path1, mime1, gcsUrl1, desc:upldFile.desc,
              stat:upldFile.stat, user:upldFile.user, time:upldFile.time, usr: upldFile.usr };
            newOvflFiles[doc] = { ...newOvflFiles[doc], files };
            //console.log("doc:",doc,", newOvflFiles[doc]:",newOvflFiles[doc]);
        } } name1 = ""; path1=""; mime1=""; gcsUrl1=""; desc=""; stat="N"; user=""; time=""; usr = null;
        // newUpldFiles[doc].files[0] = { name: "", path: "", mime: "", gcsUrl: "",
        //   name1:upldFile.name, path1:upldFile.path, mime1:upldFile.mime, gcsUrl1:upldFile.gcsUrl };
        files = (newUpldFiles[doc] && newUpldFiles[doc].files? [...newUpldFiles[doc].files]: []);
        files[i] = { name: "", path: "", mime: "", gcsUrl: "", name1: upldFile.name,
          path1: upldFile.path, mime1: upldFile.mime, gcsUrl1: upldFile.gcsUrl, desc: upldFile.desc,
          stat: value, user: "", time: "", usr : null };
        newUpldFiles[doc] = { ...newUpldFiles[doc], files };
        //console.log("newUpldFiles["+doc+"]:", newUpldFiles[doc]);

        if(drtyFile) { name1 = drtyFile.name; path1 = drtyFile.path; mime1 = drtyFile.mime;
          gcsUrl1 = drtyFile.gcsUrl; desc = drtyFile.desc; stat = drtyFile.stat;
          user = drtyFile.user; time = drtyFile.time; usr = drtyFile.usr; }

        // newDrtyFiles[doc].files[0] = { name:"", path:"", mime:"", gcsUrl:"", name1, path1, mime1, gcsUrl1 };
        files = (newDrtyFiles[doc] && newDrtyFiles[doc].files? [...newDrtyFiles[doc].files]: []);
        files[i] = { name:"", path:"", mime:"", gcsUrl:"", name1, path1, mime1, gcsUrl1,
          desc, stat, user, time, usr };
        newDrtyFiles[doc] = { ...newDrtyFiles[doc], files };
        //console.log("value!=='Y':: newUpldFiles[",doc,"]:", newUpldFiles[doc]);
      } else if(value==="Y" && upldFile.path1) {  // && upldFile
        //------------------------------------------------------------------------------------------//
        name1 = ""; path1 = ""; mime1=""; gcsUrl1=""; desc=""; stat="N"; user=""; time=""; usr = null;
        if(upldFile.path1) { name1=upldFile.name1; path1=upldFile.path1;
          mime1=upldFile.mime1; gcsUrl1=upldFile.gcsUrl1; desc=upldFile.desc;
          stat=upldFile.stat; user=upldFile.user; time=upldFile.time; usr=upldFile.usr;
        }
        // newUpldFiles[doc].files[0] = { name: name1, path: path1, mime: mime1, gcsUrl: gcsUrl1,
        //   name1, path1, mime1, gcsUrl1 };
        files = (newUpldFiles[doc] && newUpldFiles[doc].files? [...newUpldFiles[doc].files]: []);
        files[i] = { name: name1, path: path1, mime: mime1, gcsUrl: gcsUrl1, name1, path1,
          mime1, gcsUrl1, desc, stat: value, user:userEmail, time:(new Date()).toString(), usr };
        newUpldFiles[doc] = { ...newUpldFiles[doc], files };
        console.log("newUpldFiles["+doc+"]:", newUpldFiles[doc]);

        name1 = ""; path1 = ""; mime1=""; gcsUrl1=""; desc=""; stat="N"; user=""; time=""; usr = null;
        if(drtyFile.path1) { name1=drtyFile.name1; path1=drtyFile.path1;
          mime1=drtyFile.mime1; gcsUrl1=drtyFile.gcsUrl1; desc=drtyFile.desc;
          stat=drtyFile.stat; user=drtyFile.user; time=drtyFile.time; usr=drtyFile.usr;
        }
        // newDrtyFiles[doc].files[0] = { name: name1, path: path1, mime: mime1, gcsUrl: gcsUrl1,
        //   name1, path1, mime1, gcsUrl1 };
        files = (newDrtyFiles[doc] && newDrtyFiles[doc].files? [...newDrtyFiles[doc].files]: []);
        files[i] = { name: name1, path: path1, mime: mime1, gcsUrl: gcsUrl1,
          name1, path1, mime1, gcsUrl1, desc, stat, user, time, usr };
        newDrtyFiles[doc] = { ...newDrtyFiles[doc], files };
        //console.log("value==='Y':: newUpldFiles[",doc,"]:", newUpldFiles[doc]);
        //------------------------------------------------------------------------------------------//
      }
    } //});
    //setLocalUpldFiles(newUpldFiles);
    dispatch(setUpldFiles(newUpldFiles)); console.log("newUpldFiles["+doc+"]:", newUpldFiles[doc]);
    dispatch(setDrtyFiles(newDrtyFiles)); dispatch(setOvflFiles(newOvflFiles));
    setFormValues((formValues) => ({...formValues, [name]: value}));
    setFiles(files => { const newFiles = [...files]; console.log("files:", files);
      newFiles[i] = {...newUpldFiles[doc].files[i]};
      // newFiles[i] = (newUpldFiles[doc] && newUpldFiles[doc].files && newUpldFiles[doc].files[i]?
      //   {...newUpldFiles[doc].files[i]}: {name:"", path:"", mime:"", gcsUrl:"",
      //   name1:"", path1:"", mime1:"", gcsUrl1:"", desc:"", stat:"N", user:"", time:"",
      // });
      console.log("newFiles:", newFiles);
      return newFiles; //, stat: value
    });
    //console.log("Modified upldFile:", upldFile);
  };

  // const uploadFile = (file: string) => new Promise((resolve, reject) => {
  //   const stream = fs.createReadStream(file);
  //   stream.on('data', async chunk => { stream.pause();
  //     try {
  //       let resp = await fetch('https://some/endpoint', {
  //         method: 'post', body: JSON.stringify({
  //           file: file, chunk: chunk.toString('base64')
  //         })
  //       }); console.log("uploadFile:: resp:", resp);
  //       stream.resume();
  //     } catch(e) { console.log( "Error uploading chunk of %s", file)
  //       reject(e) // handle errors
  //     }
  //   });
  //   stream.on('end', resolve);
  //   stream.on('error', e=> { console.log(" Error reading %s", file)
  //     reject (e)
  //   });
  // });

  const handleFileChange = async (doc:number, numDocs:string, i:number, setFiles:
    React.Dispatch<React.SetStateAction<SingleFile[]>>, e:React.ChangeEvent<HTMLInputElement>) => {
    //console.log("handleFileChange:: doc:",doc, ", i:",i, ", e.target.files:",e.target.files);
    //console.log("formValues:", formValues, ", currentId:", currentId);
    if (!e.target.files) return; //console.log("upldFiles:", upldFiles, ", drtyFiles:", drtyFiles);
    const upldFile = e.target.files[0]; //console.log("upldFile:", upldFile);
    if(!upldFile) return;
    const mimeType = upldFile.type; //console.log("mimeType:", mimeType);
    const folder = upldFile.name.split(".")[0]; //console.log("folder:", folder);

    let resData, chunkSize = 1024 * 1024 * 0.75; //0.75MB //1MB //10MB
    if(upldFile.size > chunkSize) { // Large file
      //--------------------------------------------------------------------------------------//
      const readFileAsArrayBuffer = async (file: Blob) => {
        const result: string|ArrayBuffer|null = await new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.onload = (e) => resolve(fileReader.result);
          fileReader.readAsArrayBuffer(file);
        }); //console.log("result:", (typeof result), result);
        return result;
      }
      //--------------------------------------------------------------------------------------//
      const numChunks = Math.ceil(upldFile.size / chunkSize); console.log("numChunks", numChunks);
      let start = 0, end = chunkSize, currChunk = 0, uploaded;
      for(currChunk=0; currChunk<numChunks; currChunk++) { uploaded = false;
        start = currChunk * chunkSize; end = (currChunk + 1) * chunkSize;
        if(end > upldFile.size) end = upldFile.size;
        const chunk = upldFile.slice(start, end); console.log("chunk:", chunk);
        const buffer = await readFileAsArrayBuffer(chunk); console.log("buffer:", (typeof buffer), buffer);
        if(buffer instanceof ArrayBuffer) {
          const hash = md5(new Uint8Array(buffer)); console.log("hash:", hash);
          //---------------------------------------------------------------------------//
          const formData = new FormData();
          formData.append("id", formValues.id); formData.append("name", formValues.name);
          formData.append("doc", doc.toString()); formData.append("i", i.toString());
          formData.append("file", chunk); formData.append("folder", folder); //upldFile
          formData.append("filename", upldFile.name); formData.append("seq", currChunk.toString());
          formData.append("hash", hash); let count = 0;
          while(count < 10 && !uploaded) { count++;
            try { let hasError=false;
              const res = await fetch("/api/upload", { method: 'POST', body: formData });
              if (!res.ok) hasError = true;
              resData = await res.json(); console.log("handleFileChange:: resData:", resData);
              if (hasError) throw new Error(resData.message); //console.log("upldFiles["+doc+"]:", upldFiles[doc]);
              else uploaded = (resData.message === "success");
            } catch (error: any) { console.log(error.message || 'Upload failed!'); }
          }
          if(!uploaded) return; //break;
          //---------------------------------------------------------------------------//
      } }
      if(uploaded) { const data =
        { id: formValues.id, doc: doc.toString(), i: i.toString(), folder, filename: upldFile.name };
        try { let hasError=false;
          const res = await fetch("/api/merge-files", { method: 'POST', body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" } //x-www-form-urlencoded
          });
          if (!res.ok) hasError = true;
          resData = await res.json(); console.log("merge-files:: resData:", resData);
          if (hasError) throw new Error(resData.message);
        } catch (error: any) { console.log(error.message || 'Upload failed!'); }
      }
      //--------------------------------------------------------------------------------------//
    } else { // Small file
      const formData = new FormData();
      formData.append("id", formValues.id); formData.append("name", formValues.name);
      formData.append("doc", doc.toString()); formData.append("i", i.toString());
      formData.append("file", upldFile); //formData.append("folder", folder);
      try { let hasError=false;
        const res = await fetch("/api/upload", { method: 'POST', body: formData });
        if (!res.ok) hasError = true;
        resData = await res.json(); console.log("handleFileChange:: resData:", resData);
        if (hasError) throw new Error(resData.message); //console.log("upldFiles["+doc+"]:", upldFiles[doc]);
      } catch (error: any) {
        console.log(error.message || 'Upload failed!');
        //alert(error.message || 'Upload failed!');
      }
    } //return;

    let name1="", path1="", mime1="", gcsUrl1="", desc="", stat="N",
      user="", time="", usr = null, files:Array<SingleFile>=[]; //, newFiles

    const newUpldFiles = [...upldFiles], newDrtyFiles = [...drtyFiles];
    if(!newUpldFiles[doc]) {
      files[i] = { name:"", path:"", mime:"", gcsUrl:"", name1:"", path1:"", mime1:"",
        gcsUrl1:"", desc:"", stat:"N", user:"", time:"", usr : null };
      newUpldFiles[doc] = { desc:"", numDocs:numDocs, files };
    }

    // if(upldFiles[doc]){ name1=upldFiles[doc].name1; path1=upldFiles[doc].path1;
    //   mime1=upldFiles[doc].mime1; gcsUrl1=upldFiles[doc].gcsUrl1; }
    // newUpldFiles[doc] = { name: upldFile.name, path: resData.path, mime: resData.mimetype,
    //   gcsUrl:"", name1, path1, mime1, gcsUrl1 }; name1=""; path1=""; mime1=""; gcsUrl1="";
    // if(drtyFiles[doc]){ name1=drtyFiles[doc].name1; path1=drtyFiles[doc].path1;
    //   mime1=drtyFiles[doc].mime1; gcsUrl1=drtyFiles[doc].gcsUrl1; }
    // newDrtyFiles[doc] = { name: upldFile.name, path: resData.path, mime: resData.mimetype,
    //   gcsUrl:"", name1, path1, mime1, gcsUrl1 };

    if(upldFiles[doc] && upldFiles[doc].files && upldFiles[doc].files.length>i){
      name1=upldFiles[doc].files[i].name1; path1=upldFiles[doc].files[i].path1;
      mime1=upldFiles[doc].files[i].mime1; gcsUrl1=upldFiles[doc].files[i].gcsUrl1;
      desc =upldFiles[doc].files[i].desc;  stat=upldFiles[doc].files[i].stat;
      user =upldFiles[doc].files[i].user;  time=upldFiles[doc].files[i].time;
      usr = upldFiles[doc].files[i].usr;
    } //console.log("newUpldFiles["+doc+"]:", newUpldFiles[doc]);

    // newUpldFiles[doc].files[0] = {
    //   name: upldFile.name, path: resData.path, mime: resData.mimetype,
    //   gcsUrl:"", name1, path1, mime1, gcsUrl1
    // };
    files = (newUpldFiles[doc] && newUpldFiles[doc].files? [...newUpldFiles[doc].files]: []);
    files[i] = { name:upldFile.name, path:resData.path, mime:mimeType, gcsUrl:"", //resData.mimetype
      name1, path1, mime1, gcsUrl1, desc, stat:"Y", user:userEmail, time:(new Date()).toString(), usr };
    newUpldFiles[doc] = { ...newUpldFiles[doc], files };
    //console.log("newDrtyFiles:", newDrtyFiles); console.log("drtyFiles:", drtyFiles);
    name1=""; path1=""; mime1=""; gcsUrl1=""; desc=""; stat="N"; user=""; time=""; usr = null;
    if(drtyFiles[doc] && drtyFiles[doc].files && drtyFiles[doc].files.length>i){
      name1=drtyFiles[doc].files[i].name1; path1=drtyFiles[doc].files[i].path1;
      mime1=drtyFiles[doc].files[i].mime1; gcsUrl1=drtyFiles[doc].files[i].gcsUrl1;
      desc =drtyFiles[doc].files[i].desc;  stat =drtyFiles[doc].files[i].stat;
      user =drtyFiles[doc].files[i].user;  time =drtyFiles[doc].files[i].time;
      usr = drtyFiles[doc].files[i].usr;
    }

    // newDrtyFiles[doc].files[0] = {
    //   name: upldFile.name, path: resData.path, mime: resData.mimetype,
    //   gcsUrl:"", name1, path1, mime1, gcsUrl1
    // };
    files = (newDrtyFiles[doc] && newDrtyFiles[doc].files? [...newDrtyFiles[doc].files]: []);
    files[i] = { name:upldFile.name, path:resData.path, mime:mimeType, gcsUrl:"", //resData.mimetype
      name1, path1, mime1, gcsUrl1, desc, stat:"Y", user:userEmail, time:(new Date()).toString(), usr };
    newDrtyFiles[doc] = { ...newDrtyFiles[doc], files };
    //console.log("newDrtyFiles:", newDrtyFiles);
    //setLocalUpldFiles(newUpldFiles);
    dispatch(setUpldFiles(newUpldFiles)); console.log("newUpldFiles["+doc+"]:", newUpldFiles[doc]);
    dispatch(setDrtyFiles(newDrtyFiles));
    setFormValues((formValues) => ({...formValues, [docList[doc].name]: "Y"}));
    setFiles(files => { const newFiles = [...files]; console.log("files:", files);
      newFiles[i] = {...newUpldFiles[doc].files[i]}; console.log("newFiles:", newFiles);
      // newFiles[i] = (newUpldFiles[doc] && newUpldFiles[doc].files && newUpldFiles[doc].files[i]?
      //   {...newUpldFiles[doc].files[i]}: {name:"", path:"", mime:"", gcsUrl:"",
      //   name1:"", path1:"", mime1:"", gcsUrl1:"", desc:"", stat:"N", user:"", time:"",
      // });
      return newFiles;
    });
  };

  const handleSubmit = async () => {
    const dataPack = {...formValues,
      allocations, mgLtrAllocs, fsDftAllocs, upldFiles, drtyFiles, ovflFiles
    };
    try { let hasError = false;
      const res = await fetch('/api/company-data', { // http://localhost:3100
        method: 'POST', body: JSON.stringify(dataPack),   // formValues
        headers: {'Content-Type': 'application/json'}
      });
      if (!res.ok) hasError = true;
      const resData = await res.json(); console.log("handleSubmit:: resData:", resData);
      if (hasError) throw new Error(resData.message);
      const companyInfo = resData.companyInfo;
      const allocationInfo = resData.allocationInfo;
      const mgLtrAllocInfo = resData.mgLtrAllocInfo;
      const fsDftAllocInfo = resData.fsDftAllocInfo;
      const upldFilesInfo = resData.upldFilesInfo; //console.log("upldFilesInfo:", upldFilesInfo);
      setDocumentData((prevData: Array<FormValues>) => {
        const newData = prevData.map((data: FormValues) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === companyInfo.id) { index = i; return true; } return false;
        });
        newData[index] = companyInfo; // Update existing record
        setFormValues(companyInfo); setCurrentId(companyInfo.id);
        return newData;
      });
      setAllocationData((prevData: Array<Allocations>) => {
        const newData = prevData.map((data: Allocations) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === allocationInfo.id) { index = i; return true; } return false;
        });
        newData[index] = allocationInfo; setAllocations(allocationInfo.allocations);
        return newData;
      });
      setMgLtrAllocData((prevData: Array<Allocations>) => {
        const newData = prevData.map((data: Allocations) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === mgLtrAllocInfo.id) { index = i; return true; } return false;
        });
        newData[index] = mgLtrAllocInfo; setMgLtrAllocs(mgLtrAllocInfo.allocations);
        return newData;
      });
      setFsDftAllocData((prevData: Array<Allocations>) => {
        const newData = prevData.map((data: Allocations) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === fsDftAllocInfo.id) { index = i; return true; } return false;
        });
        newData[index] = fsDftAllocInfo; setFsDftAllocs(fsDftAllocInfo.allocations);
        return newData;
      });

      const newData = upldFilesData.map((data: UpldFiles) => data );
      let index = newData.length; newData.some((values, i) => {
        if (values.id === upldFilesInfo.id) { index = i; return true; } return false;
      }); //console.log("upldFiles:", upldFiles, ", drtyFiles:", drtyFiles);
      newData[index] = upldFilesInfo;
      dispatch(setUpldFiles(upldFilesInfo.upldFiles));
      //console.log("upldFilesInfo.upldFiles[0].files[0].usr:", upldFilesInfo.upldFiles[0].files[0].usr);
      dispatch(setUpldFilesData(newData)); dispatch(setDrtyFiles([]));
      dispatch(setOvflFiles([])); //setLocalUpldFiles(upldFilesInfo.upldFiles);

      //console.log("autoRef.current:", autoRef.current);
      //console.log("autoRef.current?.focus:", autoRef.current?.focus);
      setInputMode(0); autoRef.current?.focus();
      setAlertMesg("Form Saved"); setBtn1Text("OK");
      btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      //setTimeout(() => autoRef.current?.focus(), 100); //clearValues();
      //setTimeout(() => autoRef?.focus(), 100);
    } catch (error: any) {
      alert(error.message || 'Something went wrong!');
    }
  };

  const handleClearClick = async () => {
    clearValues(); if(inputMode>0) setInputMode(0);
    console.log("upldFiles:", upldFiles, ", drtyFiles:", drtyFiles);
    // Remove the drtyFiles from the server
    const dataPack = { drtyFiles }; //console.log("dataPack:", dataPack);
    try { let hasError = false;
      const res = await fetch("/api/remove-files", {
        method: 'POST', body: JSON.stringify(dataPack),
        headers: {'Content-Type': 'application/json'}
      }); if (!res.ok) hasError = true;
      const resData = await res.json(); console.log("resData:", resData);
      if (hasError) throw new Error(resData.message);
    } catch (error: any) {
      alert(error.message || 'Problem deleting the uploaded files from the server!');
    }
  };
  const clearValues = () => {
    const id = currentId, values = getDefaultFormValues(null, id);
    //console.log("currentId:", currentId, ", documentData:", documentData);
    //setFormValues(getDefaultFormValues(null, (inputMode===1? "": currentId)));
    setFormValues(values); setAllocations(getAllocations(id, allocationData));
    setMgLtrAllocs(getMgLtrAllocs(id, mgLtrAllocData));
    setFsDftAllocs(getFsDftAllocs(id, fsDftAllocData));
    let newUpldFiles = getUpldFiles(id, upldFilesData); //drtyFiles = [];
    //setLocalUpldFiles(newUpldFiles);
    dispatch(setUpldFiles(newUpldFiles)); //getUpldFiles(id, upldFilesData)
    dispatch(setDrtyFiles([])); dispatch(setOvflFiles([])); //ovflFiles = [];
  };
  const handleAddNew = () => { setInputMode(1);
    const id = "", values = getDefaultFormValues(null, id); //setCurrentId(id);
    setFormValues(values); setAllocations(getAllocations(id, []));
    setMgLtrAllocs(getMgLtrAllocs(id, [])); setFsDftAllocs(getFsDftAllocs(id, []));
    //setLocalUpldFiles([]); drtyFiles = []; ovflFiles = [];
    dispatch(setUpldFiles([])); dispatch(setDrtyFiles([])); dispatch(setOvflFiles([]));
    setTimeout(() => textRef.current?.focus(), 100); //console.log("currentId:", currentId);
  };
  const handleEdit = () => { setInputMode(2); setTimeout(() => textRef.current?.focus(), 100); };
  const handleAlertClick = () => { setAlertOpen(false); };
  //let btn1Handler = handleAlertClick, btn2Handler = handleAlertClick;
  //console.log("DocumentList:: btn1Handler is set.");
  const deleteId = async () => {
    return fetch('/api/company-data/'+formValues.id, {
        method: 'DELETE', body: JSON.stringify(formValues),
        headers: {'Content-Type': 'application/json'}
    }).then(response => {
      //console.log("response:", response);
      return response; //.json();
    });
  };
  const handleDelete = () => {
    setAlertMesg("Do you want to delete the company " + formValues.name);
    btn1Handler = handleConfirmDelete; //console.log("handleDelete:: btn1Handler is set.");
    btn2Handler = () => { handleClearClick(); handleAlertClick(); };
    setBtn1Text("Yes"); setBtn2Text("No"); setAlertOpen(true);
  }
  const handleConfirmDelete = () => {
    deleteId().then((json) => { console.log("handleDelete:: json:", json);
      // handle success
      let index = -1, id = ""; const deleteId = formValues.id;
      const filteredData = documentData.filter((values, i) => {
       if (values.id === deleteId) { index = i; return false; } return true;
      }); setDocumentData(filteredData);
      if(index >= filteredData.length) index -= 1;
      if(index >= 0) id = filteredData[index].id; setCurrentId(id);
      setFormValues(getDefaultFormValues(filteredData, id));
      const filteredAllocData = allocationData.filter((allocs) => (allocs.id !== deleteId));
      setAllocationData(filteredAllocData); setAllocations(getAllocations(id, filteredAllocData));
      const fltMgLtrAllocData = mgLtrAllocData.filter((allocs, i) => (allocs.id !== deleteId));
      setMgLtrAllocData(fltMgLtrAllocData); setMgLtrAllocs(getMgLtrAllocs(id, fltMgLtrAllocData));
      const fltFsDftAllocData = fsDftAllocData.filter((allocs, i) => (allocs.id !== deleteId));
      setFsDftAllocData(fltFsDftAllocData); setFsDftAllocs(getFsDftAllocs(id, fltFsDftAllocData));

      const fltUpldFilesData = upldFilesData.filter((uploads, i) => (uploads.id !== deleteId));
      dispatch(setUpldFilesData(fltUpldFilesData)); dispatch(setDrtyFiles([]));
      dispatch(setUpldFiles(getUpldFiles(id, fltUpldFilesData))); dispatch(setOvflFiles([]));

      setInputMode(0); setTimeout(() => { autoRef.current?.focus();
        setAlertMesg("Deleted company info"); setBtn1Text("OK");
        btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      }, 100);
    })
    .catch(error => console.log("handleDelete:: error:", error));
  };

  // const sendMail = async (objMail: {email:string, subject:string, html:string}) => {
  //   return fetch('/api/send-mail', {
  //       method: 'POST', body: JSON.stringify(objMail),
  //       headers: {'Content-Type': 'application/json'}
  //   }).then(response => { //console.log("response:", response);
  //     return response; //.json();
  //   });
  // };
  const handleSendMail = () => {
    setAlertMesg("Do you want to send email to address: " + formValues.email);
    btn1Handler = handleConfirmSendMail;
    btn2Handler = () => { handleClearClick(); handleAlertClick(); };
    setBtn1Text("Yes"); setBtn2Text("No"); setAlertOpen(true);
  }
  // const docList: Array<{[key: string]: string}> = [
  //   { id:"stat-coi" , name:"statCoi" , numDocs:"S", document:"COI (Certificate of incorporation)" },
  //   { id:"stat-dfin", name:"statDfin", numDocs:"S", document:"Draft financials" },
  //   { id:"stat-lfin", name:"statLfin", numDocs:"S", document:"Last year audited financials" },
  //   { id:"stat-moa" , name:"statMoa" , numDocs:"S", document:"MoA (Memorandum of association), AoA" },
  //   { id:"stat-etin", name:"statEtin", numDocs:"S", document:"eTIN" },
  //   { id:"stat-tlic", name:"statTlic", numDocs:"S", document:"Trade license" },
  //   { id:"stat-bin" , name:"statBin" , numDocs:"S", document:"BIN, if any" },
  //   { id:"stat-fxii", name:"statFxii", numDocs:"S", document:"Form xii, if any" },
  //   { id:"stat-schx", name:"statSchx", numDocs:"S", document:"Schedule X, if any" },
  //   { id:"stat-vcrd", name:"statVcrd", numDocs:"S", document:"MD/Director/CFO visiting card(s)" },
  //   { id:"stat-f23b", name:"statF23b", numDocs:"S", document:"Form 23B (rejected by old audit firm)" },
  //   { id:"stat-abst", name:"statAbst", numDocs:"M", document:"All bank statements signed and sealed copy" },
  //   { id:"stat-abln", name:"statAbln", numDocs:"M", document:"All bank loan (borrowing) statements" },
  //   { id:"stat-lthd", name:"statLthd", numDocs:"S", document:"Letterhead pad 30 pages" },
  //   { id:"stat-mbct", name:"statMbct", numDocs:"S", document:"Membership certificate, if any" },
  //   { id:"stat-mvmt", name:"statMvmt", numDocs:"S", document:"Movement of inventory, receivables, payables, advance, sales & COGS" },
  //   { id:"stat-gl",   name:"statGL",   numDocs:"S", document:"GENERAL LEDGER" },
  //   { id:"stat-cash", name:"statCash", numDocs:"S", document:"CASH BOOK/BANK BOOK" },
  //   { id:"stat-sdoc", name:"statSdoc", numDocs:"M", document:"Supporting doc for big figures (i.e. land/PPE and others)" },
  // ];
  const docList: Array<{[key: string]: string}> = [
    { sl: "1", name:"statCoi" , numDocs:"S", document:"COI (Certificate of incorporation)" },
    { sl: "2", name:"statDfin", numDocs:"S", document:"Draft financials" },
    { sl: "3", name:"statLfin", numDocs:"M", document:"Last year audited financials" },
    { sl: "4", name:"statMoa" , numDocs:"S", document:"MoA (Memorandum of association), AoA" },
    { sl: "5", name:"statEtin", numDocs:"S", document:"eTIN" },
    { sl: "6", name:"statTlic", numDocs:"S", document:"Trade license" },
    { sl: "7", name:"statBin" , numDocs:"S", document:"BIN, if any" },
    { sl: "8", name:"statFxii", numDocs:"S", document:"Form xii, if any" },
    { sl: "9", name:"statSchx", numDocs:"S", document:"Schedule X, if any" },
    { sl:"10", name:"statVcrd", numDocs:"S", document:"MD/Director/CFO visiting card(s)" },
    { sl:"11", name:"statF23b", numDocs:"S", document:"Form 23B (rejected by old audit firm)" },
    { sl:"12", name:"statAbst", numDocs:"M", document:"All bank statements signed and sealed copy" },
    { sl:"13", name:"statAbln", numDocs:"M", document:"All bank loan (borrowing) statements" },
    { sl:"14", name:"statLthd", numDocs:"S", document:"Letterhead pad 30 pages" },
    { sl:"15", name:"statMbct", numDocs:"S", document:"Membership certificate, if any" },
    { sl:"16", name:"statMvmt", numDocs:"S", document:"Movement of inventory, receivables, payables, advance, sales & COGS" },
    { sl:"17", name:"statGL",   numDocs:"S", document:"GENERAL LEDGER" },
    { sl:"18", name:"statCash", numDocs:"S", document:"CASH BOOK/BANK BOOK" },
    { sl:"19", name:"statSdoc", numDocs:"M", document:"Supporting doc for big figures (i.e. land/PPE and others)" },
    {},{},{},{},{},{},{},{},{},{},{}, // 30
    //------------------------------------------------------------------------------------------------------------//
    { sl: "",  name:"",         numDocs:"",  document:"REPROTING" }, // 31
    { sl: "1", name:"statFnst", numDocs:"S", document:"Signed Financial statements" },
    { sl:"1a", name:"statFnrp", numDocs:"M", document:"Initialed Fin statements (Report & 4 pages)" },
    { sl:"1b", name:"statFnfs", numDocs:"S", document:"Final FS (in excel) email to Manager" },
    { sl:'2',  name:'stat', numDocs:'S', document:'DVC'},
    { sl:'3',  name:'stat', numDocs:'S', document:'Invoice + Mushak 6.3'},
    { sl:'4',  name:'stat', numDocs:'S', document:'VAT challan'},
    { sl:'4a', name:'stat', numDocs:'S', document:'Tax challan'},
    { sl:'5',  name:'stat', numDocs:'S', document:'Office copy of Form 23B [if applicable u/s 210(2)]'},
    { sl: '',  name:'',     numDocs:'', document:'AUDIT CONCLUSION'},
    { sl:'6',  name:'stat', numDocs:'S', document:'IFRS FS_formatting & email to client (1st daft)'},
    { sl:'7',  name:'stat', numDocs:'S', document:'Correction of errors'},
    { sl:'8',  name:'stat', numDocs:'S', document:'Mangement representation'},
    { sl:'9',  name:'stat', numDocs:'S', document:'Going concern representation'},
    { sl:'10', name:'stat', numDocs:'S', document:'Cash_Analysis (major transactions)'},
    { sl:'11', name:'stat', numDocs:'S', document:'Loan_Analysis'},
    { sl:'12', name:'stat', numDocs:'S', document:'ToD/ToC - CASH'},
    { sl:'13', name:'stat', numDocs:'S', document:'ToD/ToC - CAPITAL'},
    { sl:'14', name:'stat', numDocs:'S', document:'ToD/ToC - PPE'},
    { sl:'15', name:'stat', numDocs:'S', document:'ToD/ToC - BORROWINGS(loans, interest)'},
    { sl:'16', name:'stat', numDocs:'S', document:'ToD/ToC - INVESTMENTS(Inv, interest)'},
    { sl:'17', name:'stat', numDocs:'S', document:'ToD/ToC - REVENUE(+TR, Adv fm customers) '},
    { sl:'18', name:'stat', numDocs:'S', document:'ToD/ToC - INVENTORIES(+CoS, Prod, TP) '},
    { sl:'19', name:'stat', numDocs:'S', document:'ToD/ToC - EXP (Adv., prov fr exp, admin, dist)'},
    { sl:'20', name:'stat', numDocs:'S', document:'ToD/ToC - TAX (AIT, D/Tx, CTx, Tx exp)'},
    { sl:'21', name:'stat', numDocs:'S', document:'Movement analysis'},
    { sl:'22', name:'stat', numDocs:'S', document:'Substative Tests - CASH'},
    { sl:'23', name:'stat', numDocs:'S', document:'Substative Tests - REVENUE'},
    { sl:'24', name:'stat', numDocs:'S', document:'Substative Tests - PPE'},
    { sl:'25', name:'stat', numDocs:'S', document:'Substative Tests - INVENTORIES (purchase)'},
    { sl:'26', name:'stat', numDocs:'S', document:'Substative Tests - BORROWINGS'},
    { sl:'27', name:'stat', numDocs:'S', document:"Manager's review FS_final_email_for confirmation"},
    { sl:'28', name:'stat', numDocs:'S', document:"Partner's review FS (agreed qualifying points)"},
    { sl:'29', name:'stat', numDocs:'S', document:'Print/setting book binding [ _____ sets ]   '},
    { sl:'30', name:'stat', numDocs:'S', document:'F/check page setup'},
    { sl:'31', name:'stat', numDocs:'S', document:'Initial'},
    { sl:'32', name:'stat', numDocs:'S', document:'Delivery (by hand, courier etc)'},
    { sl:'33', name:'stat', numDocs:'S', document:'Full signature'},
    { sl: '',  name:'',     numDocs:'',  document:'RISK ASSESSMENT & PLANNING'},
    { sl:'34', name:'stat', numDocs:'S', document:'Allocation of assignments'},
    { sl:'35', name:'stat', numDocs:'S', document:'Client entry in group'},
    { sl:'36', name:'stat', numDocs:'S', document:'List of doc collect, forward, filing (Document list)'},
    { sl:'37', name:'stat', numDocs:'S', document:'Professional clearance'},
    { sl:'38', name:'stat', numDocs:'S', document:'Appointment letter [u/s 210(1)]'},
    { sl:'39', name:'stat', numDocs:'S', document:'Quotation/EOI [u/s 210(1)'},
    { sl:'40', name:'stat', numDocs:'S', document:'Audit engagement letter signed by client [ISA 210]'},
    { sl:'41', name:'stat', numDocs:'S', document:'Team deputation, if any'},
    { sl:'42', name:'stat', numDocs:'S', document:'Independence declaration'},
    { sl:'43', name:'stat', numDocs:'S', document:'Materiality - initial'},
    {sl:'43a', name:'stat', numDocs:'S', document:'Materiality - final'},
    { sl:'44', name:'stat', numDocs:'S', document:'Risk Anal-initial [ISA 520]'},
    {sl:'44a', name:'stat', numDocs:'S', document:'Risk Anal - final [ISA 520]'},
    { sl:'45', name:'stat', numDocs:'S', document:'Risk FS'},
    { sl:'46', name:'stat', numDocs:'S', document:'Planning  [ISA 300]'},
    { sl:'47', name:'stat', numDocs:'S', document:'Form 23B [2 sets 2 years]'},
    { sl:'48', name:'stat', numDocs:'S', document:'Bank confirmation/ External confirmation '},
  ];
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const yrndt = new Date(formValues.yrendDate);
  const remarks: {[key: string]: string} = {
    "Y": "Already received with thanks",
    "N": "Doc missing. Please give ASAP to commence our audit",
    "N/A": "Not applicable for the entity"
  };
  let docStat = formValues[docList[0].name];
  let html = `<html>
    <head><style>
      p span {display:inline-block;vertical-align:middle;}
    </style></head>
    <body><div style="box-sizing:border-box;">
      <p>Dear Sir(s)/Madam,</p>
      <p>As salamu alaikum. We need the following missing documents for the purpose of commencement of our audit ASAP.</p>
      <p>
        <span style="width:26vw;text-align:right">Name of the client entity [as per COI]:</span>
        <span style="margin-left:3vw;font-weight:bold">${formValues.name}</span>
      </p>
      <p>
        <span style="width:26vw;text-align:right">${formValues.period}:</span>
        <span style="margin-left:4vw;font-weight:bold">${yrndt.getDate()} ${months[yrndt.getMonth()]} ${yrndt.getFullYear()}</span>
      </p>
      <p>
        <span style="width:26vw;text-align:right">Address:</span>
        <span style="margin-left:1vw;">${formValues.address}</span>
      </p>
      <p>Preliminary list of documents to commence the audit</p>
      <p style="height:42px;margin-bottom:0;"><span
        style="width:2vw;height:100%;border:1px solid black;border-bottom:0;text-align:center;padding-right:5px"><br>Sl#</span><span
        style="width:23vw;height:100%;border:1px solid black;border-bottom:0;border-left:0;padding-left:5px"><br>List of Documents</span><span
        style="width:5vw;height:100%;border:1px solid black;border-bottom:0;border-left:0;text-align:center">Receiving Status</span><span
        style="width:23vw;height:100%;border:1px solid black;border-bottom:0;border-left:0;padding-left:5px"><br>Remarks</span>
      </p>`;
      html+=`<p style="margin-top:0;"><span
        style="width:2vw;height:100%;padding:10px 0;border:1px solid black;text-align:right;padding-right:5px">1</span><span
        style="width:23vw;height:100%;padding:10px 0;border:1px solid black;border-left:0;padding-left:5px">${docList[0].document}</span><span
        style="width:5vw;height:100%;padding:10px 0;border:1px solid black;border-left:0;text-align:center">${docStat}</span><span
        style="width:23vw;height:100%;padding:10px 0;border:1px solid black;border-left:0;padding-left:5px">${docStat?remarks[docStat]:""}</span>
      </p>`;
      html+=`
    </div></body>
  </html>`;
  const handleConfirmSendMail = () => { //console.log("handleConfirmSendMail:");
    const content = {
      email: formValues.email, subject: "Document List", html,
      cc: "rouf106rrh@gmail.com, ayasa.afruza81@gmail.com", //
      bcc: "mahbubur.rahman.64@gmail.com"
    };
    //content.bcc = "mahbubur.rahman.64@gmail.com"; //+(userEmail?", "+userEmail:""); //if(userEmail)
    sendMail(content).then((json) => { console.log("sendMail:: json:", json);
      setInputMode(0); setTimeout(() => { autoRef.current?.focus();
        setAlertMesg("Email sent to address: "+formValues.email); setBtn1Text("OK");
        btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      }, 100);
    })
    .catch(error => console.log("sendMail:: error:", error));
  };

  // const minWidth = 300, width = "25.6vw", oneThird = "8.533vw", twoThird = "17.067vw";
  // const col1 = "2vw", col2 = "21.6vw", col3 = "5.25vw", col4 = "24.25vw";
  // const col2Less = "19vw", col13 = "5.05vw", col4Less = "24vw";

  // const col2 = "19.6vw", col4 = "22.25vw", col5 = "12vw"; // width = "21.6vw",

  return (<>
    <Paper>
      <form>
        <p>Dear Sir(s)/Madam,</p>
        <p>As salamu alaikum. We need the following missing documents for the purpose of commencement of our audit ASAP.</p>
        <FormControl sx={{paddingTop: 1}}>
          <FormGroup row sx={{padding: (inputMode<1? 1: 0), justifyContent: "space-between"}}>
            <FormLabel component="legend" sx={{paddingTop: 1, textAlign: "right", width: width,
              display: (inputMode<1? "block": "none")}}> Name of the client entity [as per COI]
            </FormLabel>
            <Autocomplete options={getCompanyNames()} sx={{width: width, display: (inputMode<1?
              "block": "none")}} renderInput={(params) => (<TextField inputRef={autoRef}
                label="Name" name="name" {...params} autoFocus
              />)} getOptionLabel={(option) => `${option}`}
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)}
              value={formValues.name || ""} disablePortal size="small"
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange}  // onInputChange
            />
          </FormGroup>

          <FormGroup row sx={{padding: (inputMode<1? 0: 1), justifyContent: "space-between", height: (inputMode<1? 0: 1)}}>
            <FormLabel component="legend" sx={{paddingTop: 1, textAlign: "right", width: width
              // , display: (inputMode<1? "none": "block")
              , visibility: (inputMode<1? "hidden": "visible")}}> Name of the client entity [as per COI]
            </FormLabel>
            <TextField id="name" name="name" label="Name" variant="outlined" size="small"
              sx={{width: width, marginLeft: 2, visibility: (inputMode<1? "hidden": "visible")}}
              // style={{display: (inputMode<1? "none": "block")}}
              value={formValues.name} autoFocus disabled={inputMode<1}
              onChange={handleTextFieldChange} inputRef={textRef}
            />
          </FormGroup>
          <FormGroup row sx={{padding: 1, justifyContent: "space-between"}}>
            <FormLabel component="legend" sx={{paddingTop: 1, textAlign: "right", width: width}}>
              {formValues.period} {/* for the year ended */}
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                label="Date" format="DD/MM/YYYY" sx={{minWidth: minWidth}}
                value={dayjs(formValues.yrendDate)} onChange={handleDatePickerChange}
                slotProps={{ textField: {size: "small"} }} disabled={inputMode<1}
              />
            </LocalizationProvider>
          </FormGroup>

          <FormGroup row sx={{padding: 1, justifyContent: "space-between"}}>
            <div style={{paddingTop: 1, display: "flex", width: width}}>
              <p style={{textAlign: "left", width: twoThird}}>
                {/* {(inputMode==1? "Adding New Company": (currentId? "id = " + currentId: ""))} */}
                {(inputMode==1? "Adding New Company": (inputMode==2? "Editing "+formValues.name: ""))}
              </p>
              <FormLabel sx={{textAlign: "right", width: oneThird}}>
                Address:
              </FormLabel>
            </div>
            <TextField id="address" name="address" label="Address" variant="outlined" size="small"
              sx={{width: width, marginLeft: 2}} value={formValues.address} multiline
              onChange={handleTextFieldChange} minRows={2} maxRows={4} disabled={inputMode<1}
            />
          </FormGroup>
        </FormControl>

        {/* ------------------------------------------------------------------------------------ */}
        <FormControl sx={{paddingTop: 2}}>
          <FormGroup row sx={{justifyContent: "space-between"}}> {/* padding: 1, paddingLeft: 0, */}
            <FormGroup sx={{width: col13, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleDelete} disabled={inputMode<2} style={{paddingLeft: 0}}>Delete</Button>
                <Button onClick={handleSendMail} disabled={inputMode>0||currentId.length<4||formValues.email.length<4}>Send Mail</Button>
              </Stack>
            </FormGroup>
            <FormLabel sx={{paddingTop: 1, paddingLeft: 1, width: col2Less}}></FormLabel>
            <FormGroup sx={{width: col13, paddingLeft: 1, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleAddNew} disabled={inputMode>0}>Add</Button>
                <Button onClick={handleEdit} disabled={inputMode>0||currentId.length<4}>Edit</Button>
              </Stack>
            </FormGroup>
            <FormGroup sx={{width: col4Less, paddingLeft: 1, alignItems: "end"}}>
              <Stack>
                <Button onClick={handleSubmit} disabled={inputMode<1}>Save</Button>
                <Button onClick={handleClearClick} disabled={inputMode<1}>{inputMode>0? "Cancel": "Clear"}</Button>
              </Stack>
            </FormGroup>
          </FormGroup>
        </FormControl>
        {/* ------------------------------------------------------------------------------------ */}

        <div> {/* style={{display:(inputMode===1? "none": "block")}} */}
          <p>Preliminary list of documents to commence the audit</p>
          <FormControl sx={{paddingTop: 0, position:"relative"}} size="small" disabled={inputMode<1}>
            <FormGroup row sx={{padding: 0, justifyContent: "space-between"}}>
              <FormLabel sx={{paddingTop: 2, textAlign: "center", width: col1, border: 1}}>
                Sl#
              </FormLabel>
              <FormLabel sx={{paddingTop: 2, paddingLeft: 1, width: col2, border: 1}}>
                List of Documents
              </FormLabel>
              <FormLabel sx={{paddingTop: 0, textAlign: "center", width: col3, border: 1}}>
                Receiving Status
              </FormLabel>
              <FormLabel sx={{paddingTop: 2, paddingLeft: 1, width: col4, border: 1}}>
                Remarks
              </FormLabel>
              <FormLabel sx={{paddingTop: 2, paddingLeft: 2, width: col5, border: 0}}>
                Document
              </FormLabel>
            </FormGroup>
            {/* <FormLabel sx={{position:"absolute", top:"7px", left:"101%", padding:"3px 15px"}}>
              Document
            </FormLabel> */}
          </FormControl>
          {docList.map((doc, i) => ( i < 19 ? //(i+1).toString()
            <DocumentSelect document={doc.document} select={{id:doc.id, name:doc.name}}
              sl={doc.sl} onSelect={handleSelectChange.bind(null, i, doc.numDocs)}
              numDocs={doc.numDocs} onFile={handleFileChange.bind(null, i, doc.numDocs)}
              value={formValues[doc.name]} doc={i} key={i} disabled={inputMode<1}
              //upldFiles={upldFiles}
            /> : null
          ))}
        </div>

        {/* ------------------------------------------------------------------------------------ */}
        <FormControl sx={{paddingTop: 2}}>
          <FormGroup row sx={{justifyContent: "space-between"}}> {/* padding: 1, paddingLeft: 0, */}
            <FormGroup sx={{width: col13, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleDelete} disabled={inputMode<2} style={{paddingLeft: 0}}>Delete</Button>
              </Stack>
            </FormGroup>
            <FormLabel sx={{paddingTop: 1, paddingLeft: 1, width: col2Less}}></FormLabel>
            <FormGroup sx={{width: col13, paddingLeft: 1, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleAddNew} disabled={inputMode>0}>Add</Button>
                <Button onClick={handleEdit} disabled={inputMode>0||currentId.length<4}>Edit</Button>
              </Stack>
            </FormGroup>
            <FormGroup sx={{width: col4Less, paddingLeft: 1, alignItems: "end"}}>
              <Stack>
                <Button onClick={handleSubmit} disabled={inputMode<1}>Save</Button>
                <Button onClick={handleClearClick} disabled={inputMode<1}>{inputMode>0? "Cancel": "Clear"}</Button>
              </Stack>
            </FormGroup>
          </FormGroup>
        </FormControl>
        {/* ------------------------------------------------------------------------------------ */}

        <div>
          <p>AUDIT COMPLETION MEMORANDUM</p>
          <FormControl sx={{paddingTop: 0, position:"relative"}} size="small" disabled={inputMode<1}>
            <FormGroup row sx={{padding: 0, justifyContent: "space-between"}}>
              <FormLabel sx={{paddingTop: 2, textAlign: "center", width: col1, border: 1}}>
                Sl#
              </FormLabel>
              <FormLabel sx={{paddingTop: 2, paddingLeft: 1, width: col2, border: 1}}>
                List of Documents
              </FormLabel>
              <FormLabel sx={{paddingTop: 0, textAlign: "center", width: col3, border: 1}}>
                Receiving Status
              </FormLabel>
              <FormLabel sx={{paddingTop: 2, paddingLeft: 1, width: col4, border: 1}}>
                Remarks
              </FormLabel>
              <FormLabel sx={{paddingTop: 2, paddingLeft: 2, width: col5, border: 0}}>
                Document
              </FormLabel>
            </FormGroup>
          </FormControl>
          {docList.map((doc, i) => ( i >= 30 ?  // 19
            <DocumentSelect document={doc.document} select={{id:doc.name, name:doc.name}}
              sl={doc.sl} onSelect={handleSelectChange.bind(null, i, doc.numDocs)}
              numDocs={doc.numDocs} onFile={handleFileChange.bind(null, i, doc.numDocs)}
              value={formValues[doc.name]} doc={i} key={i} disabled={inputMode<1}
              //upldFiles={upldFiles}
            /> : null
          ))}
        </div>
        {/* ------------------------------------------------------------------------------------ */}
        <FormControl sx={{paddingTop: 2}}>
          <FormGroup row sx={{justifyContent: "space-between"}}> {/* padding: 1, paddingLeft: 0, */}
            <FormGroup sx={{width: col13, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleDelete} disabled={inputMode<2} style={{paddingLeft: 0}}>Delete</Button>
              </Stack>
            </FormGroup>
            <FormLabel sx={{paddingTop: 1, paddingLeft: 1, width: col2Less}}></FormLabel>
            <FormGroup sx={{width: col13, paddingLeft: 1, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleAddNew} disabled={inputMode>0}>Add</Button>
                <Button onClick={handleEdit} disabled={inputMode>0||currentId.length<4}>Edit</Button>
              </Stack>
            </FormGroup>
            <FormGroup sx={{width: col4Less, paddingLeft: 1, alignItems: "end"}}>
              <Stack>
                <Button onClick={handleSubmit} disabled={inputMode<1}>Save</Button>
                <Button onClick={handleClearClick} disabled={inputMode<1}>{inputMode>0? "Cancel": "Clear"}</Button>
              </Stack>
            </FormGroup>
          </FormGroup>
        </FormControl>
        {/* ------------------------------------------------------------------------------------ */}
      </form>
    </Paper>
    <Dialog open={alertOpen} onClose={handleAlertClick}>
      <Alert onClose={handleAlertClick}>
        <AlertTitle>Success!</AlertTitle>
        {alertMesg}
        <div style={{marginTop: 20, display: "flex", justifyContent: "space-between"}}>
          {btn2Text? <Button style={{textTransform: "none", paddingLeft: 0,
           justifyContent: "left"}} onClick={btn2Handler}>{btn2Text}</Button>: null}
          {btn1Text? <Button style={{textTransform: "none", paddingRight: 0, marginLeft: 10,
           justifyContent: "right"}} onClick={btn1Handler}>{btn1Text}</Button>: null}
        </div>
      </Alert>
    </Dialog>
  </>);
};
