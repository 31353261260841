import { pdf, Page, Text, View, Document, Font } from '@react-pdf/renderer';
import styles from './LettersPage.styles';

export const toHtml = (letter) => { console.log(letter);
  if(!letter) return null; let str;
  const camel2Kebab = camel => { let kebab = "";
    for(let i=0; i<camel.length; i++){
      kebab += (camel[i]===camel[i].toUpperCase()? "-"+camel[i].toLowerCase(): camel[i]);
    } return kebab;
  }
  const cssStr = obj => { let str = "";
    if(Array.isArray(obj)){ obj.forEach((ob) => {str += cssStr(ob); }); }
    else if((typeof obj)==="object" && obj) { //console.log("obj:", obj);
      const ex = ["flexGrow","fontFamily","fontSize"]; //
      for(let k in obj){
        if (obj.hasOwnProperty(k) && !ex.includes(k)) str += camel2Kebab(k)+":"+obj[k]+";";
    } } return str;
  }
  const strStyle = stl => { let str = ""; //console.log("stl:", stl);
    if(Array.isArray(stl)){ stl.forEach((st) => {str += strStyle(st); }); }
    else if((typeof stl)==="string" && stl) { str += stl + " "; }
    return str;
  };
  const replNLwBr = text => { let txt = "";
    txt = text.replace(/\n/g, "<br>");
    return txt;
  };
  let letrStyle = (letter.style?(Array.isArray(letter.style)
    ?(letter.style.map(style=>styles[style])):[styles[letter.style]]
  ):[]); //console.log("letrStyle:", letrStyle);
  letrStyle.splice(0, 0, styles.section); console.log("letrStyle:", letrStyle);
  let html = `
    <div class="page">
      <div style="${cssStr(letrStyle)}">
        ${letter.date?letter.date+"<br><br>":""}`; letter.addrLines.forEach((line) => {html += `
        <div${line.style?' class="'+strStyle(line.style)+'"':''}>${line.text}</div>`;}); html += `<br>
        ${letter.salutation}`; letter.titleLines.forEach((line) => {html += `
        <div${line.style?' class="title '+strStyle(line.style)+'"':''}>${line.text}</div>`;});

        letter.paragraphs.forEach((para) => {html += `
        <div${para.style?' class="'+strStyle(para.style)+'"':''}>
          ${para.hidden?"":(str="",para.lines.forEach((line) => { //console.log("line:", line);
            let rowStyle = (line.style?(Array.isArray(line.style)
              ?(line.style.map(style=>style)):[line.style]
            ):[]), textStyle = ((typeof line.text)==="object" && line.text.style
              ?[line.text.style]:[]
            ); //console.log("rowStyle:", rowStyle, strStyle(rowStyle));
            if(line.textLeft||line.textMiddle||line.textRight) {
              let leftStyle = ((typeof line.textLeft)==="object" && line.textLeft.style
                ?[line.textLeft.style]:[]
              ), midStyle = ((typeof line.textMiddle)==="object" && line.textMiddle.style
                ?[line.textMiddle.style]:[]
              ); textStyle.push("text"); leftStyle.splice(0, 0, "left"); midStyle.push("middle");
              if(!line.hidden){ str += (`<div${rowStyle.length>0?' class="'+strStyle(rowStyle)+'"':''}>
            `);
                if(line.textLeft) str += `<div class="${strStyle(leftStyle)}">${
                replNLwBr((typeof line.textLeft)==="object"?line.textLeft.content:line.textLeft)}</div>`;
                if(line.text) str += `<div class="${strStyle(textStyle)}">${
                replNLwBr((typeof line.text)==="object"?line.text.content:line.text)}</div>`;
                if(line.textMiddle) str += `<div class="${strStyle(midStyle)}">${
                replNLwBr((typeof line.textMiddle)==="object"?line.textMiddle.content:line.textMiddle)}</div>`;
                if(line.textRight) str += `<div class="right">${
                replNLwBr((typeof line.textRight)==="object"?line.textRight.content:line.textRight)}</div>`;
                str += (`
          </div>`);
              }
            } else { //console.log("text:", line.text, "textStyle:", textStyle, ", rowStyle:", rowStyle);
              str += (line.text?`<div${textStyle.length>0||rowStyle.length>0?' class="'+strStyle([textStyle,rowStyle])+'"':''}>${
              replNLwBr((typeof line.text)==="object"?line.text.content:line.text)}</div>
          `:"");
            }
          }),str)}
        </div>`;});

        html += `
      </div>
    </div>
  `;
  return html;
};

// Create Document Component
const LettersDoc = ({ letter, option }) => {
  if(!letter) return null;
  let letrStyle = (letter.style?(Array.isArray(letter.style)
    ?(letter.style.map(style=>styles[style])):[styles[letter.style]]
  ):[]); //console.log("letrStyle:", letrStyle);
  letrStyle.splice(0, 0, styles.section); //console.log("letrStyle:", letrStyle);
  return (
    <Document>
      <Page size="B4" style={styles.page}>
        <View style={letrStyle}>
          {letter.date?(<Text>{letter.date}{'\n\n'}</Text>):null}
          {letter.addrLines.map((line,i) => (
            <Text style={line.style?styles[line.style]:""} key={i}>{line.text}</Text>
          ))}
          <Text>{"\n"}</Text>
          <Text>{letter.salutation}</Text>
          {letter.titleLines.map((line,i)=> (
            <Text style={[styles.title,(line.style?styles[line.style]:"")]} key={i}>{line.text}</Text>
          ))}
          {letter.paragraphs.map((para,i)=><><div style={para.style?(Array.isArray(para.style)
            ?(para.style.map(style=>styles[style])):styles[para.style]):""} key={i}>{
            para.hidden?null:para.lines.map((line,i)=>{ //Testing hidden
              let rowStyle = (line.style?(Array.isArray(line.style)
                ?(line.style.map(style=>styles[style])):[styles[line.style]]
              ):[]), textStyle = ((typeof line.text)==="object" && line.text.style
                ?[styles[line.text.style]]:[]
              );
              if(line.textLeft||line.textMiddle||line.textRight) {
                let leftStyle = ((typeof line.textLeft)==="object" && line.textLeft.style
                  ?[styles[line.textLeft.style]]:[]
                ), midStyle = ((typeof line.textMiddle)==="object" && line.textMiddle.style
                  ?[styles[line.textMiddle.style]]:[]
                );
                //if(line.style)console.log("leftStyle:",leftStyle,",line.text:",line.text,(typeof line.text));
                //rowStyle.push(styles.row); // console.log("rowStyle:", rowStyle);
                textStyle.push(styles.text);
                //leftStyle.push(styles.left); // console.log("leftStyle:", leftStyle);
                leftStyle.splice(0, 0, styles.left);
                midStyle.push(styles.middle);
                return line.hidden?null:(<>
                  <div style={rowStyle} key={i}>
                    {line.text?(
                      <Text style={textStyle}>{(typeof line.text)==="object" //[...textStyle,...rowStyle]
                        ?line.text.content:line.text}
                      </Text>
                    ):null}
                    {line.textLeft?(
                      <Text style={leftStyle}>{(typeof line.textLeft)==="object" //[...leftStyle,...rowStyle]
                        ?line.textLeft.content:line.textLeft}
                      </Text>
                    ):null}
                    {line.textMiddle?(<>
                      {line.borderMiddle?(
                        <Text style={[{position: "absolute", left: "55%"},styles.borderLeft]}> </Text>
                      ):null}
                      <Text style={midStyle}>{(typeof line.textMiddle)==="object" //[...midStyle,...rowStyle]
                        ?line.textMiddle.content:line.textMiddle}
                      </Text></>
                    ):null}
                    {line.textRight?(
                      <Text style={styles.right}>{line.textRight}{" \u00A0"}</Text> //[styles.right,...rowStyle]
                    ):null}
                  </div>
                  {line.pageBreak?(<Text break> </Text>):<Text>{"\n"}</Text>}
                </>);
              } else {
                return <>
                  {line.text?(
                    <Text style={[textStyle,rowStyle]} key={i}>{(typeof line.text)==="object"
                      ?line.text.content:line.text}
                    </Text>
                  ):null}
                  {line.pageBreak?(<Text break> </Text>):null}
                </>
              }
            })
          }</div>
          <Text>{"\n"}</Text></>)}
        </View>
      </Page>
    </Document>
  );
}

export default LettersDoc;
