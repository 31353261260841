// interface FinAcc {[key: string]: string | number;
//   name: string; note: string; bal1: number; bal2: number; bal3: number;
// };

export interface FinNote {[key: string]: string | boolean | Array<string>;
  note: string; name: string; lines: Array<string>; totLine: string;
  link: string; midCol: string; midHd1: string; midHd2: string; inp: boolean;
};

export interface NoteBal {[key: string]: string | number;
  note: string; line: number; desc: string; bal: number; num: number;
};

export interface AnxLine {[key: string]: string | number;
  sl: number; desc: string; head: string; link: string; addition: number; disposal: number;
  bal: number; rate: number; depAmt: number; depAdj: number; depBal: number;
};

// interface AnxHead {[key: string]: string;
//   desc: string;
// };

export interface AnxData {[key: string]: string | number | Array<AnxLine> | Array<string>;
  note: string; type: string; cos: number; link: string; lines: Array<AnxLine>;
  heads: Array<string>;
};

export interface SceLine {[key: string]: string | number;
  desc: string; capital: number; arrear: number; deposit: number; earning: number;
  link: string;
};

export interface SceData {[key: string]: string | number | Array<SceLine>;
  type: string; capital: number; arrear: number; deposit: number; earning: number;
  link: string; lines: Array<SceLine>;
};

interface SfpLine {[key: string]: string | boolean;
  desc: string; key: string; note: string; link: string; ed1: boolean; ed2: boolean; fld: string;
};

interface SfpHead {[key: string]: string;
  desc: string; key: string; link: string; fld: string;
};

export interface SfpLineHead {[key: string]: number | SfpHead | null;
  sec: number; grp: number; line: number; head: SfpHead|null;
};

export interface FormValues {[key: string]: string | boolean | Array<FinNote> 
  | Array<NoteBal> | Array<AnxData> | Array<SceData> | Array<SfpLineHead>;
  id: string; yrendDate: string; name: string; address: string; notes: Array<FinNote>;
  curr: string; balances: Array<NoteBal>; balance1: Array<NoteBal>; balance2: Array<NoteBal>;
  annexes: Array<AnxData>; annex1: Array<AnxData>; annex2: Array<AnxData>;
  sces: Array<SceData>; sce1: Array<SceData>; sce2: Array<SceData>;
  sfpHeads: Array<SfpLineHead>; ntChanged: boolean; processing: boolean; user: string;
};

export interface Company {[key: string]: string //| Array<FinNote>
  id: string; name: string; address: string; //notes: Array<FinNote>;
  user: string;
};

//----------------------------------------------------------------//

export const getSfpLineHeads = (id: string, data: FormValues[]) => {
  const found = data.find((values, i) => values.id === id);
  if(found) { let foundHeads = found.sfpHeads;
    if(foundHeads && foundHeads.length>0) { //console.log("getSfpLineHeads:: foundHeads:", foundHeads);
      const lineHeads: Array<SfpLineHead> = foundHeads.map(lnHd => {
        const head: SfpHead|null = (lnHd.head? {...lnHd.head}: null);
        return ({...lnHd, head});
      }); return lineHeads;
  } }
  const defaultHeads: Array<SfpLineHead> = [];
  return defaultHeads;
}

//----------------------------------------------------------------//

export const headIndex = (sec: number, grp: number, line: number, sfpHeads: Array<SfpLineHead>) => {
  const blank: SfpLineHead = {sec, grp, line, head: null}; let index = -1;
  if(sfpHeads) { index = sfpHeads.findIndex(h => h?(h.sec===sec&& h.grp===grp&& h.line===line):false);
    if(index < 0) { index = sfpHeads.length; sfpHeads[index] = blank; }
  } return index;
}

//----------------------------------------------------------------//

export const balIndex = (note: string, line: number, bals: Array<NoteBal>) => {
  const blank: NoteBal = {note, line, desc:"", bal:0, num:0}; let index = -1;
  if(bals) { index = bals.findIndex(b => (b.note===note && b.line===line));
    if(index < 0) { index = bals.length; bals[index] = blank; }
  } return index;
}

//----------------------------------------------------------------//

export const getBal = (note: string, line: number, bals: Array<NoteBal>) => {
  const blank: NoteBal = {note:"", line:0, desc:"", bal:0, num:0};
  const found = bals && bals.find((b, i) => b.note===note && b.line===line);
  if(found) return found; else return blank;
}

//----------------------------------------------------------------//

export const getAddress = (name: string, data: FormValues[]) => {
  if(!name || !data || data.length<1) return "";
  const found = data.find(values => values.name === name); //console.log("found:", found);
  if(found) return found.address;
}

//----------------------------------------------------------------//

export const getNote = (note: string, values: FormValues) => { //console.log("getNote:: note:", note, ", values:", values);
  if(!note || !values || !values.notes || values.notes.length<1) return null;
  const found = values.notes.find((n, i) => n.note === note); //console.log("found:", found);
  if(found) return found;
}

//----------------------------------------------------------------//

export const getNotes = (id: string, data: FormValues[]) => { //console.log("getNotes:: id:", id, ", data:", data);
  const found = data.find((values, i) => values.id === id); //console.log("found:", found);
  if(found && found.notes && found.notes.length>0) {
    const notes = found.notes.map(nt => {
      const lines = nt.lines.map(line => (' '+line).slice(1)); //console.log("getNotes:: lines:", lines);
      return ({...nt, lines});
    });
    return notes; // [...found.notes];
  }
  const defaultNotes: Array<FinNote> = [
    {note:"4", name:"Property, plant and equipment", lines:["At cost (Annexure A)","Accumulated depreciation  (Annexure A)"], totLine:"", link:"L0:A,L1:A", midCol:"", midHd1:"", midHd2:"", inp:false},
    {note:"5", name:"Intangible assets",             lines:["At cost (Annexure A)","Accumulated amortization  (Annexure A)"], totLine:"", link:"L0:A,L1:A", midCol:"", midHd1:"", midHd2:"", inp:false},
    {note:"5.01",name:"Preliminary expense",         lines:["Preliminary expense","","","","",""], totLine:"Total transferred to intangible assets (Annexure A)", link:"T:A1|L2", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"5.02",name:"Un-allocated Revenue Expenditure",lines:["License Registration & Renewals","Professional fees","Bank Charges","","","","","","",""], totLine:"Total transferred to intangible assets (Annexure A)", link:"T:A1|L3", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"IFA", name:"Investments in financial assets",lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"RUA", name:"Right-of-use asset",          lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"GW",  name:"Goodwill",                    lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"IPR", name:"Investment property",         lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"IJV", name:"Interests in joint ventures", lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"IAS", name:"Investment in associates",    lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"IFRP",name:"Investments in financial assets with related parties",lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"IFCA",name:"Investments in financial assets under current assets",lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"DFI", name:"Derivative financial instruments",lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"FLR", name:"Finance lease receivables",   lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"CAS", name: "Contract assets",            lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"CC",  name: "Contract costs",             lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"DTA", name: "Deferred tax asset",         lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},

    {note:"6", name:"Inventories",                   lines:["Finished goods","Work-in-progress","Raw and packing materials","Production supplies and spare parts"], totLine:"", link:"U0:O23,2U0:N23.CI-|L1|M,U1:O23.03,U1:N23.03CI-|L0|M,U2:O23.02,U2:N23.02|L5|M,U3:O23.04,U3:N23.04|L2|M", midCol:"", midHd1:"", midHd2:"", inp:true}, //,U2:O23.02,U2:N23.02|L2|M,
    {note:"7", name:"Trade and other receivables",   lines:["Trade receivable","Other receivables","Secured receivables (cheque on hand)","","","","","","Provision for bad and doubtful debts"], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"8", name:"Advances, deposits and prepayments",lines:["Advance against purchases","Advance office rent","Advance for expenses","Advance for VAT","Security deposit(s)","Bank guarantee (funded)","","","","","","","Prepaid expense"], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"9", name:"Receivables from related parties",lines:["Receivable for goods","Receivable for services","","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"10", name:"Advance income tax",           lines:["Opening balance","TDS on export","TDS on cash incentive","TDS on bill received/invoice","TDS on imported goods","TDS on trade license","TDS on vehicle fitness (tax token)","Tax on bank interest income","","","","","","","","Adjustment during the year (Note: 20)"], totLine:"", link:"L0:PY,LL:N20-LL,2T:O10", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"CFA", name: "Current financial assets",   lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"RRG",name:"Right to returned goods assets",lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    // {note:"TOR",name:"Trade and other receivables",  lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    // {note:"RRP",name:"Receivables from related parties",lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"HFS",name:"Assets classified as held for sale",lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"11",name:"Cash and cash equivalents",     lines:["Cash on hand","Cash at bank(s) (Note: 11.01)"], totLine:"", link:"LL:N11.01", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"11.01",name:"Cash at bank(s)",            lines:["AB Bank Limited A/C No.4015799703-000","Shah Jalal Islamic Bank Limited AC No.401613100001120","Shah Jalal Islamic Bank Limited AC No.400711100017043","Mercantile Bank Limited AC No.111411132975557","","","","","","","","","","","","","","","","","",""], totLine:"", link:"T:N11|L1", midCol:"", midHd1:"", midHd2:"", inp:true},

    {note:"12",name:"Share capital",                 lines:[], totLine:"N", link:"", midCol:"", midHd1:"", midHd2:"", inp:false},
    {note:"12.01",name:"Authorized capital",         lines:["1000000 ordinary shares of Tk 100 each"], totLine:"N", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"12.02",name:"Issued, subscribed, called up and paid up capital", lines:["50000 ordinary Shares of Tk 100 each"], totLine:"NN", link:"L0:12.02S-", midCol:"", midHd1:"", midHd2:"", inp:false},
    {note:"12.02S-",name:"",                         lines:["Muhammad A Rouf","Syed Mahbub","Shams Iftakhar","A Afruza Mahbub","","","","","","","","","","","","","","","",""], totLine:"", link:"S,T:N12.02", midCol:"R", midHd1:"No of shares", midHd2:"Ratio", inp:true},
    {note:"13",name:"Calls-in-arrear",               lines:["Muhammad A Rouf","Syed Mahbub","","","","","","","","","",""], totLine:"", link:"", midCol:"N", midHd1:"", midHd2:"", inp:true},
    {note:"14",name:"Share money deposit",           lines:["A Afruza Mahbub","","","","","","","","",""], totLine:"", link:"", midCol:"N", midHd1:"", midHd2:"", inp:true},
    {note:"15",name:"Retained earnings",             lines:["Opening balance","Changes in accounting policy"], totLine:"NN", link:"L0:PY,T:N15.OR-,8T:N15.OR-,9T:N15.OR-,10T:N15.OR-", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"15.OR-",name:"N",                          lines:["Opening balance re-stated","Total comprehensive income for the year"], totLine:"", link:"L0:15,LL:PNL,T:O15,8T:O15,9T:O15", midCol:"", midHd1:"", midHd2:"", inp:false},
    {note:"CPR", name: "Capital reserve",            lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},

    {note:"16",name:"Borrowings from bank",          lines:["Social Islami Bank Ltd-HPSM","Social Islami Bank Ltd -overdraft","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""], totLine:"", link:"", midCol:"B", midHd1:"Non-current portion", midHd2:"Current portion", inp:true},
    {note:"17",name:"Financial liabilities with related parties", lines:["Loan from directors","","","","","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"RBO",name:"Retirement benefit obligations",lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"DTL",name: "Deferred tax liabilities",    lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"LL", name: "Lease liabilities",           lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"CL", name: "Contract liabilities",        lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"LSBP",name:"Liability for share-based payments",lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"DFIN",name:"Deferred income",             lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"PRV",name: "Provisions",                  lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},

    {note:"18",name:"Advance received from customers",lines:["Shafiq n Brothers","Sajib Traders","Advance received from customers - others","","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"19",name:"Trade and other payables",      lines:["Payable for raw materials","Payable for packaging materials","Payable for transport contractors","Payable for other services","","","","","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"STFL",name:"Short-term financial liabilities",lines:["","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"20",name:"Current tax payable",           lines:["Opening Balance","Current tax for the year","Adjustment during the year"], totLine:"", link:"L0:PY,L1:28.01,T:O20,U2:N10|L15:", midCol:"", midHd1:"", midHd2:"", inp:true}, //,U2:N10|L8:
    {note:"21",name:"Provision for expense",         lines:["Outstanding wages, salary and allowances","Rent and utilities","Professional fees","Audit fees","","","","","","","","","","",""], totLine:"", link:"", midCol:"", midHd1:"", midHd2:"", inp:true}, //L2:24|,L3:24|
    //22. Revenue (T:N28.01|L0)
    {note:"22",name:"Revenue",                       lines:["Sales/bill received","VAT on sales","","","","","","","","",""], totLine:"", link:"T:N28.01TE-|L1|P28.01TR-|L1,T:N28.01|L0", midCol:"", midHd1:"", midHd2:"", inp:true}, //T:N28.01|L0
    {note:"23",name:"Cost of sales",                 lines:["Opening inventory - Finished goods","Cost of production (Note: 23.01)"], totLine:"NN", link:"L0:-PY|6|L0,L1:23.01,6T:N23.CI-|L0", midCol:"", midHd1:"", midHd2:"", inp:true}, //,4T:N23.CI-|L0
    //23. Minus - Cost of Sales (7T:N28.01|L0|M|A)
    {note:"23.CI-",name:"N",                          lines:["","Closing inventory - Finished goods (Note: 6)"], totLine:"", link:"L0:23,L1:-6|L0,7T:N28.01|L0|M|A", midCol:"", midHd1:"", midHd2:"", inp:false}, //,7T:N28.01|L0|M|A
    {note:"23.01",name:"Cost of production",         lines:["Materials used in production (Note: 23.02)","Production overhead (Note: 23.03)"], totLine:"", link:"L0:23.02,L1:23.03,5T:N23|L1", midCol:"", midHd1:"", midHd2:"", inp:false}, //,3T:N23|L1
    {note:"23.02",name:"Materials used in production",lines:["Opening Raw and packing materials","Purchased during the year","","","","Closing Raw and packing materials"], totLine:"", link:"L0:-PY|L5,L5:-6|L2,2:N23.01|L0", midCol:"B", midHd1:"Raw materials", midHd2:"Packing materials", inp:true}, //L0:-PY|L2,L2:-6|L2,
    {note:"23.03",name:"Production overhead",        lines:["Opening inventory Work-in-progress"], totLine:"N--", link:"L0:PY|6|L1,T:N23.03CI-|L8", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"23.03MO-",name:"Manufacturing overhead",  lines:["Wages","Factory rent","Electricity","Gas bill","Water","","","","","","","","","","","","","","Repairs and maintenance (Note: 23.04)","Depreciation expense (Annexure A)","Amortization expense (Annexure A)"], totLine:"YN", link:"L5:23.04,L6:AI1,L7:AI1,3T:N23.03CI-|L9", midCol:"", midHd1:"", midHd2:"", inp:true}, //,2T:N23.03CI-|L9
    {note:"23.03CI-",name:"",                        lines:["Closing inventory Work-in-progress (Note 6)"], totLine:"&=23.03;23.03MO-;23.03CI-", link:"L0:-6|L1,4T:N23.01|L1", midCol:"", midHd1:"", midHd2:"", inp:false}, //,2:N23.01|L1
    {note:"23.04",name:"Repairs and maintenance",    lines:["Opening Production supplies and spare parts","Spare parts purchased during the year","Closing Production supplies and spare parts (Note 6)"], totLine:"", link:"L0:PY|6|L3,L2:-6|L3,T:N23.03MO-|L18", midCol:"", midHd1:"", midHd2:"", inp:true}, //,T:N23.03MO-|L5
    //24. Minus - Administrative expense (3T:N28.01|L0|M|A) - problem
    {note:"24",name:"Administrative expense",        lines:["Salary and allowances","Rent and utilities","Telephone, internet and mobile expenses","Printing and stationery","Travelling and conveyance","Entertainment expense","Repairs and maintenance","Fees and renewals","Professional fees","Audit fees","","","","","","","","","","","","","","","","","","Loss on sale of assets (24.01)","Depreciation expense (Annexure A)","Amortization expense (Annexure A)"], totLine:"", link:"L27:-24.01B-,L28:AI1,L29:AI1,3T:N28.01|L0|M|A", midCol:"", midHd1:"", midHd2:"", inp:true}, //,3T:N28.01|L0|M|A
    {note:"24.01",name:"&=({24.01BT-}<0?'Loss':'Gain') on sale of assets",lines:[], totLine:"NN", link:"", midCol:"", midHd1:"", midHd2:"", inp:false},
    {note:"24.01A-",name:"a. Property, plant and equipment",lines:["Sales proceeds of assets","Written down value [-35000-(-5000)]"], totLine:"NN", link:"L1:A,2:N24.01AT-,3:N24.01BT-|L9", midCol:"", midHd1:"", midHd2:"", inp:true}, //2:N24.01BT-|L9
    {note:"24.01AT-",name:"N",                       lines:["(Loss)/gain"], totLine:"N-", link:"", midCol:"", midHd1:"", midHd2:"", inp:false},
    {note:"24.01B-",name:"b. Intangible assets",     lines:["Sales proceeds of assets","Written down value [-20000-(-5000)]"], totLine:"NN", link:"L1:A,2:N24.01BT-", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"24.01BT-",name:"N",                       lines:["(Loss)/gain"], totLine:"&=24.01A;24.01B", link:"2T:N24|L27|M", midCol:"", midHd1:"", midHd2:"", inp:false}, //2T:N24|L10|M
    //25. Minus - Distribution costs (2T:N28.01|L0|M|A)
    {note:"25",name:"Distribution costs",            lines:["Delivery expenses","Promotional expenses","","","","","","","","","","",""], totLine:"", link:"2T:N28.01|L0|M|A", midCol:"", midHd1:"", midHd2:"", inp:true}, //2T:N28.01|L0|M|A
    //26. Plus - Other income (2T:N28.01|L0|A) - problem, nbl.bal - 0
    {note:"26",name:"Other income",                  lines:["Sales of newspaper","","","","","","","",""], totLine:"", link:"2T:N28.01|L0|A", midCol:"", midHd1:"", midHd2:"", inp:true}, //2T:N28.01|L0|A
    //27. Minus - Finance costs (2T:N28.01|L0|M|A) - problem
    {note:"27",name:"Finance costs",                 lines:["Interest on borrowings from bank-HPSM","Interest on borrowings from bank-overdraft","Bank charges","","","","","","","","","","","","","","","",""], totLine:"", link:"2T:N28.01|L0|M|A", midCol:"", midHd1:"", midHd2:"", inp:true}, //2T:N28.01|L0|M|A
    {note:"28",name:"Income tax expense",            lines:["Current tax expense"], totLine:"", link:"L0:28.01", midCol:"", midHd1:"", midHd2:"", inp:false},
    //Profit before tax - Problem is here
    {note:"28.01",name:"Current tax for the year",   lines:["Profit before tax","Accounting depreciation"], totLine:"YN-", link:"L0:22;23;24;25;26;27,L1:23.03MO-|L6;24|L11,2T:N28.01TI-|L9,7U0:N15.OR-|L1,7T:N28.01TI-|L9", midCol:"", midHd1:"", midHd2:"", inp:false}, //,7U0:N15.OR-|L1
    {note:"28.01TI-",name:"N",                       lines:["Tax depreciation"], totLine:"&(YN)Total Income", link:"3T:N28.01TE-|L0|P28.01TR-|L0,7T:N28.01TE-|L0|P28.01TR-|L0", midCol:"", midHd1:"", midHd2:"", inp:true},
    {note:"28.01TR-",name:"",                        lines:["Tax rate - on total income&(P)","Tax rate - on turnover&(P)"], totLine:"NN", link:"", midCol:"", midHd1:"", midHd2:"", inp:true},

    //Income tax expense (8M:N15.OR-|L1|M|A)
    {note:"28.01TE-",name:"",                        lines:["Tax based on total income","Tax based on turnover"], totLine:"Tax expense (maximum of above 2)&=Max", link:"L0:Disabled,L0:28.01TI-*28.01TR-|L0/100,L1:22*28.01TR-|L1/100,4M:N28,4M:N20|L1,8J0:N28.01TE-|L0|A,8J1:N28.01TE-|L1|A,8M:N28,8M:N15.OR-|L1|M|A,8M:N20|L1", midCol:"N", midHd1:"", midHd2:"", inp:true}, //,8M:N15.OR-|L1|M|A
  ];
  return defaultNotes;
};

//----------------------------------------------------------------//

interface SfpGroup {[key: string]: string | Array<SfpLine> | Array<SfpHead>;
  name: string; totLine: string; lines: Array<SfpLine>; heads: Array<SfpHead>;
};

export interface SfpData {[key: string]: string | Array<SfpGroup>;
  name: string; totLine: string; subTot: string; link: string; groups: Array<SfpGroup>;
};

export const getSfp = () => {
  const sfp: Array<SfpData> = [
    { name: "ASSETS",                 totLine: "Total assets", subTot: "",     link: "", groups: [
      {name:"Non-current assets",     totLine: "Total non-current assets",     lines: [
        {desc: "Property, plant and equipment", key: "N4", note: "4", link: "N4", ed1: false, ed2: false, fld: ""},
        {desc: "Intangible assets",             key: "N5", note: "",  link: "N5", ed1: false, ed2: false, fld: ""},
        {desc: "Investments in financial assets",key: "NIFA", note:"", link:"NIFA", ed1: false, ed2: false, fld: ""}, //
        {desc: "Right-of-use asset",            key: "NRUA", note: "", link:"NRUA", ed1: false, ed2: false, fld: ""},
      ], heads: [
        {desc: "Goodwill",                      key: "NGW",  link:"NGW",  fld: ""},
        {desc: "Investment property",           key: "NIPR", link:"NIPR", fld: ""},
        {desc: "Interests in joint ventures",   key: "NIJV", link:"NIJV", fld: ""},
        {desc: "Investment in associates",      key: "NIAS", link:"NIAS", fld: ""},
        {desc:"Investments in financial assets with related parties", key: "NIFRP", link:"NIFRP", fld: ""},
        {desc:"Derivative financial instruments", key: "NDFI", link:"NDFI", fld: ""},
        {desc: "Finance lease receivables",     key: "NFLR", link:"NFLR", fld: ""},
        {desc: "Contract assets",               key: "NCAS", link:"NCAS", fld: ""},
        {desc: "Contract costs",                key: "NCC",  link:"NCC",  fld: ""},
        {desc: "Deferred tax asset",            key: "NDTA", link:"NDTA", fld: ""},
      ]},
      {name:"Current assets",         totLine: "Total current assets",         lines: [
        {desc: "Inventories",                   key: "N6", note: "", link: "N6", ed1: false, ed2: true, fld: ""},
        {desc: "Trade and other receivables",   key: "N7", note: "", link: "N7", ed1: false, ed2: true, fld: ""},
        {desc: "Advances, deposits and prepayments",key:"N8", note:"",link:"N8", ed1: false, ed2: true, fld: ""},
        {desc: "Receivables from related parties", key: "N9", note: "",link:"N9", ed1: false, ed2: true, fld: ""},
        {desc: "Advance income tax",            key:"N10",  note: "", link: "N10", ed1: false, ed2: false, fld: ""},
        {desc: "Current financial assets",      key:"NCFA", note: "", link:"NCFA", ed1: false, ed2: false, fld: ""},
        {desc: "Finance lease receivables",     key:"NFLR", note: "", link:"NFLR", ed1: false, ed2: false, fld: ""},
        {desc: "Cash and cash equivalents",     key:"N11", note: "", link: "N11", ed1: false, ed2: true, fld: ""},
      ], heads: [
        // {desc: "Finance lease receivables",     key: "NFLR", link:"NFLR", fld: ""},
        {desc: "Contract assets",               key: "NCAS", link:"NCAS", fld: ""},
        {desc:"Right to returned goods assets", key: "NRRG", link:"NRRG", fld: ""},
        // {desc: "Trade and other receivables",   key: "NTOR", link:"NTOR", fld: ""},
        // {desc:"Receivables from related parties",key:"NRRP", link:"NRRP", fld: ""},
        // {desc: "Investment in associates",      key: "NIAS", link:"NIAS", fld: ""},
        {desc: "Investments in financial assets under current assets",key: "NIFCA", link:"NIFCA", fld: ""},
        {desc:"Assets classified as held for sale",key:"NHFS", link:"NHFS", fld: ""},
      ]},
    ]},
    { name: "EQUITY AND LIABILITIES", totLine: "Total equity and liabilities", subTot: "Total liabilities", link: "G1,G2", groups: [
      {name:"Shareholders' equity",   totLine: "Total equity",                 lines: [
        {desc: "Share capital",                 key: "N12", note: "", link: "N12.02", ed1: false, ed2: true, fld: ""},
        {desc: "Calls-in-arrear",               key: "N13", note: "", link: "N13|M", ed1: false, ed2: true, fld: ""},
        {desc: "Share money deposit",           key: "N14", note: "", link: "N14", ed1: false, ed2: true, fld: ""},
        {desc: "Retained earnings",             key: "N15", note: "", link: "N15.OR-", ed1: false, ed2: false, fld: ""},
      ], heads: [
        {desc: "Capital reserve",               key: "NCPR", link:"NCPR", fld: ""},
      ]},
      {name:"Non-current liabilities",totLine: "Total non-current liabilities",lines: [
        {desc: "Borrowings from bank",          key:"N16", note: "", link: "N16|num", ed1: true, ed2: true, fld: "num"},
        {desc: "Financial liabilities with related parties",key:"N17",note: "", link: "N17", ed1: false, ed2: true, fld: ""},
        {desc: "Retirement benefit obligations",key:"NRBO",note: "", link: "NRBO", ed1: false, ed2: true, fld: ""},
        {desc: "Deferred tax liabilities",      key:"NDTL",note: "", link: "NDTL", ed1: false, ed2: true, fld: ""},
      ], heads: [
        {desc: "Lease liabilities",             key: "NLL",  link:"NLL",   fld: ""},
        {desc: "Contract liabilities",          key: "NCL",  link:"NCL",   fld: ""},
        {desc:"Liability for share-based payments",key:"NLSBP",link:"NLSBP", fld: ""},
        {desc: "Deferred income",               key:"NDFIN", link:"NDFIN", fld: ""},
        {desc: "Provisions",                    key:"NPRV",  link:"NPRV",  fld: ""},
      ]},
      {name:"Current liabilities",    totLine: "Total current liabilities",    lines: [
        {desc: "Borrowings from bank - current portion",key:"",note: "N16", link: "N16|rest", ed1: false, ed2: true, fld: "rest"},
        {desc: "Advance received from customers", key:"N18", note: "", link: "N18", ed1: false, ed2: true, fld: ""},
        {desc: "Trade and other payables",        key:"N19", note: "", link: "N19", ed1: false, ed2: true, fld: ""},
        {desc: "Short-term financial liabilities",key:"NSTFL",note:"", link:"NSTFL",ed1: false, ed2: true, fld: ""},
        {desc: "Current tax payable",             key:"N20", note: "", link: "N20", ed1: false, ed2: true, fld: ""},
        {desc: "Provision for expense",           key:"N21", note: "", link: "N21", ed1: false, ed2: true, fld: ""},
      ], heads: [
        {desc: "Financial liabilities with related parties",key:"N17", link: "N17", fld: ""},
      ]},
    ]},
  ];
  return sfp;
};

//----------------------------------------------------------------//

export const getSfpHeads = (i: number, gi: number) => {
  const heads: Array<string> = [" "], sfp = getSfp(), grp = sfp[i].groups[gi]; //console.log("grp:", grp);
  for(const line of grp.lines) heads.push(line.desc);
  for(const head of grp.heads) heads.push(head.desc); heads.sort(); //console.log("heads:", heads);
  return heads;
}

//----------------------------------------------------------------//

export const getSfpHead = (i: number, gi: number, head: string) => {
  let sfpHead: SfpHead|undefined;
  if(head===" ") sfpHead = {desc: head, key: "", link: "", fld: ""};
  else {
    const sfp = getSfp(), grp = sfp[i].groups[gi]; //console.log("grp:", grp);
    const line = grp.lines.find(ln => ln.desc===head);
    if(line) sfpHead = {desc: line.desc, key: line.key, link: line.link, fld: line.fld};
    else { sfpHead = grp.heads.find(ln => ln.desc===head); }
  } //console.log("sfpHead:", sfpHead);
  return sfpHead;
}

//----------------------------------------------------------------//
interface PnlLine {[key: string]: string | boolean;
  desc: string; key: string; note: string; link: string; lineBf: boolean; bold: boolean;
};

export interface PnlData {[key: string]: string | boolean | Array<PnlLine>;
  totLine: string; italic: boolean; bold: boolean; underln: boolean;
  lines: Array<PnlLine>;
};

export const getPnl = () => {
  const pnl: Array<PnlData> = [
    { totLine: "Gross profit",                            italic: true, bold: false, underln: true, lines: [
      {desc: "Revenue",                key:"N22", note: "", link: "N22",      lineBf: false, bold: true },
      {desc: "Cost of sales",          key:"N23", note: "", link: "N23.CI-|M", lineBf: false, bold: false},
    ]},
    { totLine: "Earnings before interest and tax (EBIT)", italic: true, bold: false, underln: true, lines: [
      {desc: "Administrative expense", key:"N24", note: "", link: "N24|M",    lineBf: true,  bold: false},
      {desc: "Distribution costs",     key:"N25", note: "", link: "N25|M",    lineBf: false, bold: false},
    ]},
    { totLine: "Profit before tax",                      italic: true, bold: false, underln: false, lines: [
      {desc: "Other income",           key:"N26", note: "", link: "N26",      lineBf: true,  bold: false},
      {desc: "Finance costs",          key:"N27", note: "", link: "N27|M",    lineBf: false, bold: false},
    ]},
    { totLine: "PROFIT FOR THE YEAR",                     italic: true, bold: true, underln: true,  lines: [
      {desc: "Income tax expense",     key:"N28", note: "", link: "N28|M",    lineBf: false, bold: false},
    ]},
    { totLine: "TOTAL COMPREHENSIVE INCOME FOR THE YEAR", italic: false, bold: true, underln: true, lines: [
      {desc: "Other comprehensive income", key:"", note: "", link: "",       lineBf: true,  bold: false},
    ]},
  ];
  return pnl;
};

//----------------------------------------------------------------//

export const getHead = (sec: number, grp: number, line: number, sfpHeads: Array<SfpLineHead>) => {
  const found = sfpHeads && sfpHeads.find(h => h?(h.sec===sec&& h.grp===grp&& h.line===line):false);
  if(found) return found.head; else return null;
}

//----------------------------------------------------------------//

export interface KeyNote {[key: string]: string| boolean | number; //Sfp_Line
  key: string; note: number; hidden: boolean;
};

export const getNoteNumbers = (keyNotes: KeyNote[]) => {
  const notes: Array<number> = [];
  for(const kn of keyNotes) { if(kn.note) notes.push(kn.note); }
  return notes;
};

export const getKeyNotes = (sfp: SfpData[], pnl: PnlData[], sfpHeads: Array<SfpLineHead>) => {
  const lines: Array<KeyNote> = []; //console.log("sfp:", sfp);
  //for(const lnHd of sfpHeads) {}
  for(const [si, sec] of Object.entries(sfp)) { //console.log("sec:", sec);
    for(const [sgi, grp] of Object.entries(sec.groups)) { //console.log("grp:", grp);
      for(const [sli, line] of Object.entries(grp.lines)) { let note = 0, n = 0; //console.log("line:", line);
        //-----------------------------------------------------------------//
        const hd = getHead(parseInt(si), parseInt(sgi), parseInt(sli), sfpHeads);
        if(hd) { let key = hd.key, li = lines.length; //console.log(si, sgi, sli, ", hd:", hd);
          if(n=parseInt(line.note)) { note = n; //Numeric note
            if(!key) { key = `L${li}`; hd.key = key; }
            //console.log("li:", li, ", key:", key, ", note:", note, ", hd:", hd);
          } else {
            if(line.note) { //Non-numeric note
              for(let i = li-1; i >= 0; i--) {
                if(lines[i].key===line.note) { note = lines[i].note; break; }
            } } else { if(hd.desc) {
                for(let i = li-1; i >= 0; i--) { //console.log("i:", i, ", lines[i]:", lines[i]);
                  if(lines[i].note && lines[i].key!==key) { note = lines[i].note + 1; break; }
                }
          } } }
          if(key) { const kn = lines.find((l,i) => (i<li && l.key===key)); //console.log("kn:", kn);
            if(kn) { kn.note = note; kn.hidden = (note<1); }
            else { const ln = { key, note, hidden: (note<1) };
              if(!lines.find(l => l.key===ln.key)) lines.push(ln);
            }
        } }
        //-----------------------------------------------------------------//
        const key = line.key;
        if(hd) note = 0; else if(n=parseInt(line.note)) note = n; else { const li = lines.length;
          if(line.note) {
            for(let i = li-1; i >= 0; i--) { //console.log("i:", i, ", lines[i]:", lines[i], ", line:", line);
              if(lines[i].key===line.note) { note = lines[i].note; break; } //line.link
          } } else { if(line.desc) { // Prabably doesn't need any more modification
              for(let i = li-1; i >= 0; i--) { //console.log("i:", i, ", lines[i]:", lines[i]);
                if(lines[i].note) { note = lines[i].note + 1; break; }
              }
        } } }
        if(key) { const ln = {key, note, hidden: (note<1) };
          if(!lines.find(l => l.key===ln.key)) lines.push(ln);
      } }
      for(const head of grp.heads) { const key = head.key; let note = 0; //console.log("head:", head);
        if(key) { const ln = {key, note, hidden: (note<1) };
          if(!lines.find(l => l.key===ln.key)) lines.push(ln);
      } }
  } }
  for(const grp of pnl) { //console.log("grp:", grp);
    for(const line of grp.lines) { const key = line.key; let note = 0, n = 0; //console.log("line:", line);
      if(n=parseInt(line.note)) note = n; else { const li = lines.length;
        if(line.note) {
          for(let i = li-1; i >= 0; i--) { //console.log("i:", i, ", lines[i]:", lines[i], ", line:", line);
            if(lines[i].key===line.note) { note = lines[i].note; break; } //line.link
        } } else if(line.desc) {
          for(let i = li-1; i >= 0; i--) { //console.log("i:", i, ", lines[i]:", lines[i]);
            if(lines[i].note) { note = lines[i].note + 1; break; }
      } } }
      if(key) { const ln = {key, note, hidden: (note<1) };
        if(!lines.find(l => l.key===ln.key)) lines.push(ln);
      }
  } } console.log("lines:", lines);
  return lines;
};

//----------------------------------------------------------------//

export const getKeyNote = (keyNotes: KeyNote[], key: string, sfpHeads: Array<SfpLineHead>) => {
  let keyNote: KeyNote|null = null, lineHead: SfpLineHead|null = null; //console.log("key:", key, ", sfpHeads:", sfpHeads);
  for(const kn of keyNotes) { if(kn.key===key) { //ln.line.link===link
    keyNote = kn; break;
  } } if(keyNote) return keyNote;
  const found = sfpHeads && sfpHeads.find(h => h?(h.head && h.head.key===key):false); //console.log("found:", found);
  //if(key==="NDTL") console.log("key:", key, ", found:", found);
  if(found && found.head) { let note = 0;
    const sfp = getSfp(), grp = sfp[found.sec].groups[found.grp]; //console.log("grp:", grp);
    for(const line of grp.lines) { let n = 0; //console.log("line:", line);
      if(n=parseInt(line.note)) note = n; else { //const li = found.line; //keyNotes.length;
        if(found.head.desc) {
          if(found.head.desc===" ") { note = 0; //console.log("found:", found);
          } else { //if(key==="NDTL") console.log("key:", key, ", found.head:", found.head);
            let li = keyNotes.findIndex(kn => key===kn.key); if(li<1) li = keyNotes.length;
            for(let i = li-1; i >= 0; i--) { //console.log("i:", i, ", keyNotes[i]:", keyNotes[i]);
              //if(key==="NDTL") console.log("i:", i, ", keyNotes[i]:", keyNotes[i], keyNotes[i].note);
              if(keyNotes[i].note && keyNotes[i].key!==key) {
                //if(key==="NDTL") console.log("key:", key, i, ", keyNotes[i]:", keyNotes[i], keyNotes[i].note);
                note = keyNotes[i].note + 1; //if(key==="NDTL") console.log("key:", key, i, ", note:", note);
                break;
              }
            }
          }
        }
    } }
    keyNote = {key: found.head.key, note, hidden: (note<1)}; //console.log("keyNote:", keyNote);
  } /* else {
    for(const kn of keyNotes) { if(kn.key===key) { //ln.line.link===link
      keyNote = kn; break;
    } }
  } */
  return keyNote;
}

//----------------------------------------------------------------//

export const findValues = (name: string, year: string, data: FormValues[]) => {
  const yDt = year? (new Date(year)): null;
  if(name && yDt) { //console.log("findValues:: name:", name, ", year:", year);
    const found = data.find((values, i) => {
      const dt = values.yrendDate? (new Date(values.yrendDate)): null;
      return dt? (values.name===name && dt.getDate()===yDt.getDate() && dt.getMonth()===
        yDt.getMonth() && dt.getFullYear()===yDt.getFullYear()): false;
    });
    if(found) { //console.log("foundValues:", found);
      found.notes = getNotes(found.id, data);
      found.balances = getBalances(found.id, data);
      found.balance1 = getBalances(found.id, data, 1);
      found.balance2 = getBalances(found.id, data, 2);
      found.annexes = getAnnexes(found.id, data);
      found.annex1 = getAnnexes(found.id, data, 1);
      found.annex2 = getAnnexes(found.id, data, 2);
      found.sces = getSces(found.id, data);
      found.sce1 = getSces(found.id, data, 1);
      found.sce2 = getSces(found.id, data, 2);
      const values: FormValues = { ...found }; return values;
  } }
  return false;
}

//----------------------------------------------------------------//

export const getNoteBal = (note: string, bals: Array<NoteBal>) => {
  const fBals: Array<NoteBal> = bals? bals.filter(nbl => nbl.note===note): [];
  const blank: NoteBal = {note:"", line:0, desc:"", bal:0, num:0}, total = fBals.length>0 &&
    fBals.reduce((tot,nbl) => ({note:"", line:0, desc:"", bal:tot.bal+nbl.bal, num:tot.num+nbl.num}));
  if(total) return total; else return blank;
}

//----------------------------------------------------------------//

export const getNoteMax = (note: string, bals: Array<NoteBal>) => {
  const fBals: Array<NoteBal> = bals? bals.filter(nbl => nbl.note===note): [];
  const blank: NoteBal = {note:"", line:0, desc:"", bal:0, num:0}, maxm = fBals.length>0 &&
    fBals.reduce((max,nbl) => ({note:"", line:0, desc:"", bal:Math.max(max.bal,nbl.bal), num:Math.max(max.num,nbl.num)}));
  if(maxm) return maxm; else return blank;
}

//----------------------------------------------------------------//

export const getAnnexTotal = (i: number, values: FormValues, pre = 0) => {
  const anx = (pre>1? values.annex2[i]: (pre>0? values.annex1[i]: values.annexes[i]));
  const total = anx.lines.reduce((tot, line) => {
    return tot? { sl:0, desc:"", head:"", link:"", rate:0,
      addition:tot.addition+line.addition, disposal:tot.disposal+line.disposal, bal:tot.bal+line.bal,
      depAmt: tot.depAmt+line.depAmt, depAdj: tot.depAdj+line.depAdj, depBal: tot.depBal+line.depBal}: {
      sl:0,desc:"",head:"",link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0
    };
  }); return total;
}

//----------------------------------------------------------------//

export const getSceTotal = (i: number, values: FormValues, pre = 0) => { //console.log("getSceTotal:: i:", i, ", pre:", pre, ", values:", values)
  const sce = (pre>1? values.sce2[i]: (pre>0? values.sce1[i]: values.sces[i])); //console.log("sce:", sce);
  const total = sce.lines.reduce((tot, line) => {
    return { desc:"", link:"",
      capital:tot.capital+line.capital, arrear:tot.arrear+line.arrear,
      deposit: tot.deposit+line.deposit, earning: tot.earning+line.earning,
    };
  }); return total;
}

//----------------------------------------------------------------//

export const getBalances = (id: string, data: FormValues[], pre = 0) => {
  const found = data.find((values, i) => values.id === id);
  if(found) { let foundBals = (pre>1? found.balance2: (pre>0? found.balance1: found.balances));
    if(pre>0 && (!foundBals || foundBals.length<1)) { const dt = new Date(found.yrendDate); //console.log("pre:", pre, ", dt:", dt, ", data:", data);
      data.every(fn => { const d = new Date(fn.yrendDate); //console.log("d:", d);
        if(fn.name===found.name && d.getMonth()===dt.getMonth() && d.getDate()===dt.getDate()) {
          if (d.getFullYear()===(dt.getFullYear()-pre)) { //console.log("found:: fn:", fn);
            foundBals = fn.balances; return false;
          }
        } return true;
      }); //console.log("foundAnx:", foundAnx);
    }
    if(foundBals && foundBals.length>0) { //console.log("getBalances:: foundBals:", foundBals);
      const balances = foundBals.map(bal => {
        const note = bal.note = (' '+bal.note).slice(1);
        return ({...bal, note});
      }); return balances; // [...found.balances];
  } }
  const defaultBalances: Array<NoteBal> = [ // Tax rates
    {note:"28.01TR-", line:0, desc:"", bal:27.5, num:0},
    {note:"28.01TR-", desc:"", line:1, bal:0.6, num:0},
  ]; //console.log("getBalances:: defaultBalances:", defaultBalances);
  return defaultBalances;
}

//----------------------------------------------------------------//

export const getAnnexesCopy = (values: FormValues, pre = 0) => {
  let foundAnx = (pre>1? values.annex2: (pre>0? values.annex1: values.annexes));
  const annexes: Array<AnxData> = foundAnx.map(anx => {
    const lines = anx.lines.map(line => ({...line})); //console.log("getAnnexes:: lines:", lines);
    return ({...anx, lines});
  }); return annexes; // [...found.annexes];
}

//----------------------------------------------------------------//

export const getAnnexes = (id: string, data: FormValues[], pre = 0) => {
  const found = data.find((values, i) => values.id === id);
  if(found) {
    let foundAnx = (pre>1? found.annex2: (pre>0? found.annex1: found.annexes));
    if(pre>0 && (!foundAnx || foundAnx.length<1)) { const dt = new Date(found.yrendDate); //console.log("pre:", pre, ", dt:", dt, ", data:", data);
      data.every(fn => { const d = new Date(fn.yrendDate); //console.log("d:", d);
        if(fn.name===found.name && d.getMonth()===dt.getMonth() && d.getDate()===dt.getDate()) {
          if (d.getFullYear()===(dt.getFullYear()-pre)) { //console.log("found:: fn:", fn);
            foundAnx = fn.annexes; return false;
        } } return true;
      }); //console.log("foundAnx:", foundAnx);
    }
    if(foundAnx && foundAnx.length>0) {
      const annexes: Array<AnxData> = foundAnx.map(anx => {
        const lines = anx.lines.map(line => ({...line})); //console.log("getAnnexes:: lines:", lines);
        return ({...anx, lines});
      }); return annexes; // [...found.annexes];
  } }
  const defaultAnnexes: Array<AnxData> = [
    {note: "4", type: "D", cos: 0, link: "C:N23.03MO-|L19,A:N24|L28,V:N24.01A-|L1,D:N28.01|L1", lines: [ //C:N23.03MO-|L6,A:N24|L11,
      {sl: 1,desc:"Land (freehold)",       head:"Registration costs",link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 2,desc:"Buildings",             head:"Factory building",  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 3,desc:"Machinery",             head:"Machinery",         link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 4,desc:"Motor vehicles",        head:"Motor car",         link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 5,desc:"Furniture and fixtures",head:"Furniture",         link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 6,desc:"Low value assets (LVA)",head:"Refrigerator",      link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 7,desc:"Office equipment",      head:"Computer, printer", link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 8,desc:"Assets under construction (AUC)",head:"Construction work in progress",link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
    ], heads: ["Land", "Land (leasehold)", "Land (mortgaged)", "Ships", "Aircraft", "Bearer plants", ""]},
    {note: "5", type: "A", cos: 0, link: "C:N23.03MO-|L20,A:N24|L29,V:N24.01B-|L1", lines: [ //C:N23.03MO-|L7,A:N24|L12,
      {sl: 1,desc:"Computer software",     head:"Software",          link:"",addition:0,disposal:0,rate:0,bal:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 2,desc:"Brand names",           head:"Brand registration",link:"",addition:0,disposal:0,rate:0,bal:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 0,desc:"",                      head:"",                  link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
      {sl: 3,desc:"Intangibles assets under development (AUD)",head:"Preliminary expenses",link:"W:N5.01",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0}, //|L0
      {sl: 4,desc:"Intangibles assets under development (AUD)",head:"Un-allocated revenue expenditure",link:"W:N5.02",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0},
    ], heads: ["Licences and franchises", "Copyright, patents, property rights, service and operating rights",
      "Mastheads and publishing titles", "Recipes, formulae, models, designs and prototypes", ""
    ]},
  ];
  return defaultAnnexes;
};

//----------------------------------------------------------------//

export const getAnxHeads = (i: number) => {
  const heads: Array<string> = [" "], annexes = getAnnexes("",[]), grp = annexes[i]; //console.log("grp:", grp);
  for(const line of grp.lines) if(line.desc) heads.push(line.desc);
  for(const head of grp.heads) heads.push(head); heads.sort(); //console.log("heads:", heads);
  return heads;
}

//----------------------------------------------------------------//

export const getSfpBal = (link: string, values: FormValues, pre = 0) => {
  let bal = 0; const lnks = link.split(","); //console.log("getSfpBal:: link:", link, ", pre:", pre);
  lnks.forEach(lnk => { const typ = lnk[0];
    let note = "", sign = 1, fld = "bal"; note = lnk.substring(1);
    if(note) { let arr = note.split("|"); note = arr[0];
      if(arr.length>1) { if(arr[1]==="M") sign = -1; else fld = arr[1]; }
      if(arr.length>2) { if(arr[2]==="M") sign = -1; else fld = arr[2]; }
    }
    if(typ==="N" || typ==="M") {
      if(note) { let li = -1; if(fld[0]==="L") li = parseInt(fld.substring(1));
        let py = false; if(fld==="PY"){ py = true; fld = "bal"; };
        if(py) {
          pre += 1; //console.log("link:", link, ", pre:", pre);
        }
        const bals = (pre>1? values.balance2: (pre>0? values.balance1: values.balances));
        if(!isNaN(li) && li>=0) { //console.log("fld:", fld, ", li:", li, ", note:", note, ", bals:", bals);
          const bi = balIndex(note, li, bals); //console.log("bi:", bi);
          bal += sign * (fld==="bal"?bals[bi].bal:(fld==="num"?bals[bi].num:bals[bi].bal-bals[bi].num));
        } else {
          const nBal = getNoteBal(note, bals);
          bal += sign * (fld==="bal"?nBal.bal:(fld==="num"?nBal.num:nBal.bal-nBal.num));
          if(typ==="M") { //console.log("typ:", typ, ", note:", note);
            const bals1 = (pre>0? values.balance2: values.balance1);
            const nBal1 = getNoteBal(note, bals1);
            bal -= sign * (fld==="bal"?nBal1.bal:(fld==="num"?nBal1.num:nBal1.bal-nBal1.num));
          }
      } }
    } else if(typ==="A") { //console.log("typ:", typ, ", lnk:", lnk, ", note:", note, "fld:", fld);
      const anxs = (pre>1? values.annex2: (pre>0? values.annex1: values.annexes));
      const ai = anxs.findIndex(anx => anx.note===note); //console.log("ai:", ai, ", sign:", sign);
      const nAnx = getAnnexTotal(ai, values, pre);
      bal += sign * (fld==="bal"?nAnx.bal:(fld==="addition"?nAnx.addition:nAnx.disposal));
    }
  }); //console.log("getSfpBal:: bal:", bal);
  return bal;
}

//----------------------------------------------------------------//

interface CfLine {[key: string]: string | boolean;
  desc: string; indent: boolean; link: string;
};

interface CfGroup {[key: string]: string | boolean | Array<CfGroup> | Array<CfLine>;
  seq: string; name: string; totLine: string; indent: boolean; italic: boolean; bold: boolean;
  underln: boolean; lineBf: boolean; groups: Array<CfGroup>; lines: Array<CfLine>;
};

export const getCfLine = (line: string, gtot: number) => {
  let rtLine = line, formula = "", frText = "";
  const ai = rtLine.indexOf("&=["); //console.log("getCfLine:: line:", line, ", gtot:", gtot);
  if(ai>=0) { const bfLine = rtLine.substring(0, ai); rtLine = rtLine.substring(ai);
    //console.log("getCfLine:: bfLine:", bfLine, ", rtLine:", rtLine);
    let result = ""; if(rtLine[2]==="[") { const fi = rtLine.indexOf("]");
      if(fi>=0) { frText = rtLine.substring(0, fi+1); rtLine = rtLine.substring(fi+1);
        //console.log("frText:", frText, ", rtLine:", rtLine);
        formula = "("+frText.substring(3, frText.length-1)+")"; //console.log("formula:", formula);
        let frFld = ""; const ni = formula.indexOf("{"); if(ni>=0) {
          const ne = formula.indexOf("}"); if(ne>=0) { //console.log("getCfLine:: formula:", formula);
            frFld = formula.substring(ni+1, ne); //console.log("frFld:", frFld);
            if(frFld) { formula = formula.replace(`{${frFld}}`,gtot.toString()); result = eval(formula);
      } } } } //console.log("getCfLine:: result:", result, "rtLine:", rtLine);
      rtLine = line.replace(frText, result); //result + rtLine;
    } //console.log("formula:", formula, ", result:", result, ", rtLine:", rtLine);
  } return rtLine;
}

export const getCf1 = () => {
  const cf: Array<CfGroup> = [
    { seq:"", name:"", totLine:"Net &=[{gtot}<0?'(decrease)/increase':'increase/(decrease)'] in cash and cash equivalents(A+B+C)",
      indent:false, italic: true, bold: true, underln: true, lineBf: false, groups: [
        {seq:"A)", name:"Cash flow from operating activities",
        totLine:"Net cash generated from/(used in) operating activities", indent:true, italic:true, bold:false, underln:true, lineBf:false, groups:[
          { seq:"", name:"", totLine:"Operating cash flows before movements in working capital",
          indent:true, italic:true, bold:true, underln:false, lineBf:false, groups:[], lines:[
            {desc: "Profit before tax", indent: true, link: "N22,N23.CI-|M,N24|M,N25|M,N26,N27|M"},
            {desc: "Finance costs",     indent: true, link: "N27"},
            {desc:"(Gain) loss on disposal of property, plant and equipment", indent:true, link:"N24.01BT-|M"},
            {desc: "Depreciation of property, plant and equipment", indent:true, link:"M4|M,A4|addition,N24.01A-|L1|M"},
            {desc: "Amortization of intangible assets", indent:true, link:"M5|M,A5|addition,N24.01B-|L1|M"},
          ]},
        ], lines:[
          {desc: "Decreased/(increased) in Inventories", indent:true, link:"M6|M"},
          {desc: "Trade and other receivables",          indent:true, link:"M7|M"},
          {desc: "Decreased/(increased) in Advances, deposits and prepayments", indent:true, link:"M8|M"},
          {desc: "(Increased)/decreased in Receivables from related parties", indent:true, link:"M9|M"},
          {desc: "Increased/(decreased) in Advance received from customers", indent:true, link:"M18"},
          {desc: "Increased/(decreased) in Trade and other payables", indent:true, link:"M19"},
          {desc: "Increased/(decreased) in Provision for expense", indent:true, link:"M21"},
          {desc: "Finance costs paid", indent:true, link:"N27|M"},
          {desc: "Income tax paid",    indent:true, link:"M10|M,M20,N28|M"}, //M10|M
        ]},
        {seq:"B)", name:"Cash flow from investing activities",
        totLine:"Net cash (used in)/generated from investing activities", indent:true, italic:true, bold:false, underln:true, lineBf:true, groups: [], lines: [
          {desc: "Proceeds from disposal of  Property, plant and equipment", indent:true, link:"N24.01A-|L0"},
          {desc: "Acquisition of Property, plant and equipment", indent:true, link:"A4|addition|M"},
          {desc: "Acquisition of Intangible assets", indent:true, link:"A5|addition|M"},
        ]},
        {seq:"C)", name:"Cash flows from financing activities",
        totLine:"Net cash generated from/(used in) financing activities", indent:true, italic:true, bold:false, underln:false, lineBf:true, groups: [], lines: [
          {desc: "Received from Share capital", indent:true, link:"M12.02,M13|M,M14"},
          {desc: "Proceeds from Borrowings from bank",     indent:true, link:"M16"},
          {desc: "Proceeds from Financial liabilities with related parties", indent:true, link:"M17"},
        ]},
      ], lines: [
    ] },
    { seq:"", name:"", totLine:"Cash and cash equivalents at end of year",
      indent:false, italic:false, bold: true, underln: true, lineBf: true, groups:[], lines:[
        {desc: "Cash and cash equivalents at beginning of year", indent:false, link: "N11|PY"}, //N11
        {desc: "Effect of foreign exchange rate changes",        indent:false, link: ""   },
    ] },
  ];
  return cf;
};

//----------------------------------------------------------------//

export const getSces = (id: string, data: FormValues[], pre = 0) => {
  const found = data.find((values, i) => values.id === id);
  if(found) { let foundSce = (pre>1? found.sce2: (pre>0? found.sce1: found.sces));
    if(pre>0 && (!foundSce || foundSce.length<1)) { const dt = new Date(found.yrendDate); //console.log("pre:", pre, ", dt:", dt, ", data:", data);
      data.every(fn => { const d = new Date(fn.yrendDate); //console.log("d:", d);
        if(fn.name===found.name && d.getMonth()===dt.getMonth() && d.getDate()===dt.getDate()) {
          if (d.getFullYear()===(dt.getFullYear()-pre)) { //console.log("found:: fn:", fn);
            foundSce = fn.sces; return false;
        } } return true;
      }); //console.log("foundAnx:", foundAnx);
    }
    if(foundSce && foundSce.length>0) {
      const sces: Array<SceData> = foundSce.map(sce => {
        const lines = sce.lines.map(line => ({...line})); //console.log("getAnnexes:: lines:", lines);
        return ({...sce, lines});
      }); return sces; // [...found.sces];
  } }
  const defaultSces: Array<SceData> = [
    {type:"B", link:"N12.02,N13|M,N14,N15.OR-", capital:0, arrear:0, deposit:0, earning:0, lines: [
      {desc: "Movement during the year", link:"", capital: 0, arrear: 0, deposit: 0, earning: 0},
      {desc: "", link:"", capital: 0, arrear: 0, deposit: 0, earning: 0},
      {desc: "", link:"", capital: 0, arrear: 0, deposit: 0, earning: 0},
      {desc: "", link:"", capital: 0, arrear: 0, deposit: 0, earning: 0},
      {desc: "", link:"", capital: 0, arrear: 0, deposit: 0, earning: 0},
      {desc:"TI",link:"N22,N23.CI-|M,N24|M,N25|M,N26,N27|M,N28|M",capital:0,arrear:0,deposit:0,earning:0},
    ]},
    {type:"R", link:"", capital:0, arrear:0, deposit:0, earning:0, lines: [
      {desc: "Changes in accounting policy", link:"", capital:0, arrear:0, deposit:0, earning:0},
    ]},
  ];
  return defaultSces;
};

//----------------------------------------------------------------//

// interface AcHead {[key: string]: string | number | Array<FinAcc>;
//   code: string; name: string; accounts: Array<FinAcc>;
//   //descTot: string; tot1: number; tot2: number; tot3: number;
// };

// interface Ledger {[key: string]: string | number | Array<AcHead>;
//   name: string; heads: Array<AcHead>;
//   descTot: string; tot1: number; tot2: number; tot3: number;
// };

// export const getSfp = (id: string) => {};

