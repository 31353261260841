import { useState, useEffect, useRef } from "react";
import { Autocomplete, FormControl, FormGroup, Paper, Button } from "@mui/material";
import { TextField, SelectChangeEvent, Dialog, Alert, AlertTitle } from "@mui/material";
import { FormLabel, Select, MenuItem, ListItemText, InputAdornment } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Stack } from "@mui/system";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Company, FormValues, findValues, getBalances, getAnnexes, getSces, SfpData, PnlData, KeyNote,
  getKeyNote, getSfpBal, getSfpHeads, getSfpHead, getNoteBal, balIndex, getHead, headIndex } from "../../Data/FinancialData";
import FinExcel, { getFinExData } from "../../Data/FinExcel";
import { commaFormat, negBracket, removeComma } from "../../Data/DataUtility";
import { dfNotes } from "./Notes";

let btn1Handler = () => {}, btn2Handler = () => {};

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
// export const getFinValues = async (company: Company, sDt: Date) => {
//   const yr = `${sDt.getDate()}-${months[sDt.getMonth()].substring(0,3)}-${sDt.getFullYear()}`;
//   const url = '/api/fin-values/'+company.id+'?year='+yr; //console.log("url:", url);
//   const res = await fetch(url).then(response => { //console.log("response:", response);
//     return response; //.json();
//   });
//   const resData = await res.json(); //console.log("Notes:: /api/fin-values/:compId?year=yr - resData:", resData);
//   return resData; //.finData;
// };

export const refreshFinData = (company: Company, sDt: Date,
  getDefaultFormValues: (data: Array<FormValues>|null, id: string) => FormValues,
  setFinancialData: React.Dispatch<React.SetStateAction<FormValues[]>>,
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>,
  setCurrentId: React.Dispatch<React.SetStateAction<string>>,
  func: ((values: FormValues) => void)|null = null) => {
  const getFinValues = async () => {
    const yr = `${sDt.getDate()}-${months[sDt.getMonth()].substring(0,3)}-${sDt.getFullYear()}`;
    const url = '/api/fin-values/'+company.id+'?year='+yr; //console.log("url:", url);
    const res = await fetch(url).then(response => { //console.log("response:", response);
      return response; //.json();
    });
    const resData = await res.json(); //console.log("Notes:: /api/fin-values/:compId?year=yr - resData:", resData);
    return resData; //.finData;
  };
  getFinValues().then(resData => { //console.log("resData.finData:", resData.finData);
    let data = resData.finData as Array<FormValues>, idSet = false;
    if(resData.id) { setCurrentId(resData.id); idSet = true; }
    setFinancialData(oldData => { const newData = [...oldData]; //console.log("oldData:", oldData);
      for(const [si, values] of Object.entries(data)) { let index = -1;
        //console.log("Trying to find values.id:", values.id, " in oldData");
        index = newData.findIndex(f => f.id===values.id); //console.log("index:", index);
        if(index < 0) newData.push(values); //console.log("values:", values); }
        else newData[index] = data[parseInt(si)]; //console.log("si:", si, data[parseInt(si)]); }
        if(!idSet) { const fDt = new Date(values.yrendDate);
          if(fDt.getDate()===sDt.getDate() && fDt.getMonth()===sDt.getMonth() &&
          fDt.getFullYear()===sDt.getFullYear()) { resData.id = values.id;
            setCurrentId(values.id); idSet = true;
        } }
      } //console.log("newData:", newData);
      setFormValues(oldValues => {
        const values = getDefaultFormValues(newData, resData.id); //console.log("values:", values);
        if(func) func(values); return {...values};
      }); return newData;
    });
  });
};

interface IProps { //
  financialData: FormValues[], sfp: SfpData[], pnl: PnlData[], keyNotes: KeyNote[],
  setFinancialData: React.Dispatch<React.SetStateAction<FormValues[]>>,
  companies: Company[], //setSfp: React.Dispatch<React.SetStateAction<SfpData[]>>,
  currentId: string, setCurrentId: React.Dispatch<React.SetStateAction<string>>,
  getDefaultFormValues: (data: Array<FormValues>|null, id: string) => FormValues,
  formValues: FormValues, setFormValues: React.Dispatch<React.SetStateAction<FormValues>>,
  inputMode: number, setInputMode: React.Dispatch<React.SetStateAction<number>>,
  userEmail: string,
};

export default function FinancialPosition({ financialData, setFinancialData, companies,
  currentId, setCurrentId, getDefaultFormValues, formValues, setFormValues, //setSfp,
  sfp, pnl, keyNotes, inputMode, setInputMode}: IProps) { //console.log("keyNotes:", keyNotes);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMesg, setAlertMesg] = useState("");
  const [btn1Text, setBtn1Text] = useState("");  // Ok
  const [btn2Text, setBtn2Text] = useState("");  // Cancel
  const textRef = useRef<HTMLInputElement>();
  const autoRef = useRef<HTMLInputElement>();
  const dateRef = useRef<HTMLInputElement>();
  const [selName, setSelName] = useState("");
  const [selYear, setSelYear] = useState("");

  //const sfp = getSfp(), keyNotes = getKeyNotes(sfp);

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { name, value } = event.target; console.log("name:", name, ", value:", value);
    let fld = name, num = (value==="-"||value===""?0:parseFloat(removeComma(value)));
    let fldSet = false; const neg = ((value.substring(0,1)==="-" && value.length>1) || value==="0-");
    if(value.indexOf(".")>=0) {
      const intg = Math.floor(Math.abs(num));
      let dec = Math.abs(num) - intg; if(dec<0.001) dec = 0.1;
      num = (intg + dec) * (neg? -1: 1);
    }
    if(name==="name") { console.log("handleTextFieldChange:: selYear:", selYear);
      if(value && selYear) {
        console.log("Trying to find value:", value, ", selYear:", selYear, " in financialData:", financialData);
        const found = findValues(value, selYear, financialData); console.log("found:", found);
        if(found) { fldSet = true; setCurrentId(found.id);
          setFormValues(formValues => ({...found})); setInputMode(2); //Edit
        } else {
          const company = companies.find(comp => comp.name===value), sDt = new Date(selYear);
          if(company) { refreshFinData(company, sDt, getDefaultFormValues,
            setFinancialData, setFormValues, setCurrentId); setInputMode(2); //Edit
          }
      } } setSelName(value);
    } else { const flds = name.split("_"); fld = flds[0]; //console.log("flds:", flds, ", fld:", fld);
      if(flds.length>1) { const i = parseInt(flds[1]); //console.log(`sfp[${i}]:`, sfp[i]);
        if(flds.length>2) { const gi = parseInt(flds[2]);
          if(flds.length>3) { const li = parseInt(flds[3]);
            //console.log(`sfp[${i}].groups[${gi}].lines[${li}]:`, sfp[i].groups[gi].lines[li]);
            const link = sfp[i].groups[gi].lines[li].link; //console.log("link:", link);
            const fds = link.substring(1).split("|"); //console.log("fds:", fds);
            const note = fds[0], sign = (fds.length>1 && fds[1]==="M"? -1: 1);
            const ni = dfNotes.findIndex(nt => nt.note===note); //formValues.notes
            //console.log("note:", note, ", sign:", sign, ", ni:", ni, ", num:", num);
            if(fld==="bal2") {
              setFormValues(formValues => { let newValues = {...formValues};
                const bal2 = newValues.balance2, t2 = getNoteBal(note, bal2); //console.log("t2:", t2);
                const nb20 = bal2[balIndex(note, 0, bal2)]; nb20.bal += (num * sign - t2.bal);
                return newValues;
              }); fldSet = true;
            } else
            if(fld==="num1") {
              setFormValues(formValues => { let newValues = {...formValues};
                const bal1 = newValues.balance1, t1 = getNoteBal(note, bal1); //console.log("t1:", t1);
                const nb10 = bal1[balIndex(note, 0, bal1)]; nb10.num += (num * sign - t1.num);
                return newValues;
              }); fldSet = true;
            } else
            if(fld==="num2") {
              setFormValues(formValues => { let newValues = {...formValues};
                const bal2 = newValues.balance2, t2 = getNoteBal(note, bal2); //console.log("t2:", t2);
                const nb20 = bal2[balIndex(note, 0, bal2)], chng = (num * sign - t2.num);
                nb20.num += chng; nb20.bal += chng; return newValues;
              }); fldSet = true;
            } else
            if(fld==="rest2") {
              setFormValues(formValues => { let newValues = {...formValues};
                const bal2 = newValues.balance2, t2 = getNoteBal(note, bal2); //console.log("t2:", t2);
                const nb20 = bal2[balIndex(note, 0, bal2)], chng = (num * sign - (t2.bal-t2.num));
                nb20.bal += chng; return newValues;
              }); fldSet = true;
          } } //else //flds.length>2
    } } }
    if(!fldSet) setFormValues((formValues) => ({...formValues, [fld]: value}));
    //updateTotals(name, value);
  };

  const handleAutoCompleteChange = (fld: string, event: React.SyntheticEvent<Element, Event>,
    value: string | null) => { console.log("fld:", fld, ", value:", value, ", event:", event);
    if(fld==="name" || fld==="yrendDate") {
      if(value){ let index = -1, refrsh = false, sDt = new Date();
        if(fld==="name") { //console.log("selYear:", selYear, "compName:", value);
          if(selYear) { sDt = (new Date(selYear)); //console.log("sDt:", sDt);
            console.log("Trying to find value:", value, ", selYear:", selYear, " in financialData:", financialData);
            index = financialData.findIndex(values => { let retVal = false;
              const dt = new Date(values.yrendDate); //console.log("dt:", dt);
              retVal = (values.name===value && dt.getDate()===sDt.getDate() && dt.getMonth()===sDt.getMonth() && dt.getFullYear()===sDt.getFullYear());
              return retVal;
            }); refrsh = (index < 0);
            console.log("index:", index, refrsh, (index>=0?financialData[index]:""));
          } else setTimeout(() => dateRef.current?.focus(), 100);
        }
        else if(fld==="yrendDate") { console.log("selName:", selName, "finYear:", value);
          sDt = new Date(value); console.log("sDt:", sDt);
          if(selName && sDt) {
            console.log("Trying to find selName:", selName, ", value:", value, " in financialData:", financialData);
            index = financialData.findIndex(values => { let retVal = false;
              const dt = new Date(values.yrendDate); //console.log("dt:", dt);
              retVal = (values.name===selName && dt.getDate()===sDt.getDate() && dt.getMonth()===sDt.getMonth() && dt.getFullYear()===sDt.getFullYear());
              return retVal;
            }); refrsh = (index < 0);
            console.log("index:", index, (index>=0?financialData[index]:""));
        } }
        if(index >= 0) {
          let compInfo = { ...financialData[index] }, id = compInfo.id;
          // compInfo.notes = getNotes(id, financialData);
          compInfo.balances = getBalances(id, financialData);
          compInfo.balance1 = getBalances(id, financialData, 1);
          compInfo.balance2 = getBalances(id, financialData, 2);
          compInfo.annexes = getAnnexes(id, financialData);
          compInfo.annex1 = getAnnexes(id, financialData, 1);
          compInfo.annex2 = getAnnexes(id, financialData, 2);
          compInfo.sces = getSces(id, financialData);
          compInfo.sce1 = getSces(id, financialData, 1);
          compInfo.sce2 = getSces(id, financialData, 2);
          setFormValues(compInfo); setCurrentId(compInfo.id); console.log("compInfo:", compInfo);
          //---------------------------------------------------------------------------------------//
          //---------------------------------------------------------------------------------------//
        }
        if(refrsh) { console.log("refrsh:", refrsh, ", companies:", companies);
          //const company = companies.find(comp => comp.name===selName);
          const company = companies.find(comp => comp.name===(fld==="name"?value:selName));
          //const yr = `${sDt.getDate()}-${months[sDt.getMonth()].substring(0,3)}-${sDt.getFullYear()}`;
          console.log("refrsh:", refrsh, ", name:",(fld==="name"?value:selName), ", company:", company, ", sDt:", sDt);
          if(company) { refreshFinData(company, sDt,
            getDefaultFormValues, setFinancialData, setFormValues, setCurrentId);
            // getFinValues(company, sDt).then(resData => { console.log("resData.finData:", resData.finData);
            //   let data = resData.finData as Array<FormValues>, idSet = false;
            //   if(resData.id) { setCurrentId(resData.id); idSet = true; }
            //   setFinancialData(oldData => { const newData = [...oldData];
            //     console.log("oldData:", oldData);
            //     for(const [si, values] of Object.entries(data)) { let index = -1;
            //       console.log("Trying to find values.id:", values.id, " in oldData");
            //       index = newData.findIndex(f => f.id===values.id);
            //       console.log("index:", index);
            //       if(index < 0) newData.push(values); else newData[index] = data[parseInt(si)];
            //       if(!idSet) { const fDt = new Date(values.yrendDate);
            //         if(fDt.getDate()===sDt.getDate() && fDt.getMonth()===sDt.getMonth() &&
            //         fDt.getFullYear()===sDt.getFullYear()) { resData.id = values.id;
            //           setCurrentId(values.id); idSet = true;
            //       } }
            //     } console.log("newData:", newData);
            //     setFormValues(oldValues => {
            //       const values = getDefaultFormValues(newData, resData.id)
            //       return {...values};
            //     }); return newData;
            //   });
            // });
        } }
        //setFormValues((formValues) => ({...formValues, [fld]: value}));
        const val:string = value; setFormValues((formValues) => ({...formValues, [fld]:val}));
        //updateTotals(fld, value);
      } else { console.log("fld:", fld, ", value:", value);
        setFormValues(getDefaultFormValues(null,"")); setCurrentId("");
      }
      if(fld==="yrendDate") {
        if(value) { setSelYear(value);
          console.log("selYear set to value:", value);
        } else {setSelName(""); setSelYear("")};
      }
      else if(fld==="name") { if(value) setSelName(value); else {setSelName(""); setSelYear("")}; }
    } else {
      const flds = fld.split("_"); fld = flds[0]; console.log("flds:", flds, ", fld:", fld, ", value:", value);
      if(flds.length>1) { const i = parseInt(flds[1]); //console.log(`sfp[${i}]:`, sfp[i]);
        if(flds.length>2) { const gi = parseInt(flds[2]);
          if(flds.length>3) { const li = parseInt(flds[3]); console.log(`sfp[${i}].groups[${gi}].lines[${li}]:`, sfp[i].groups[gi].lines[li]);
            //---------------------------------------------------------------------------------//
            if(fld==='head') { if(!value) value = " ";
              const head = getSfpHead(i, gi, value); console.log("head:", head);
              if(head) setFormValues(oldValues => { const newValues = {...oldValues};
                let rmHd = false; if(value===" ") { //Trying to fix redundant head problem
                  const line = sfp[i].groups[gi].lines[li]; //console.log("head:", head, ", line:", line);
                  // Need to add logic for removing sfpHead for (line.desc && line.desc===hd.head.desc)
                  if(!line.desc) { const sfpHeads = []; //hi = headIndex(i,gi,li,newValues.sfpHeads),
                    for(const hd of newValues.sfpHeads) {
                      if(!(hd&&(hd.sec===i&& hd.grp===gi&& hd.line===li))) sfpHeads.push(hd);
                    } //delete newValues.sfpHeads[hi];
                    newValues.sfpHeads = sfpHeads; console.log("sfpHeads:", sfpHeads);
                    rmHd = true; //console.log(`Removed newValues.sfpHeads[${hi}]:`);
                } }
                if(!rmHd) newValues.sfpHeads[headIndex(i,gi,li,newValues.sfpHeads)].head = head; console.log("newValues:", newValues);
                return newValues;
              });
              // setSfp((oldSfp) => { const newSfp = [...oldSfp];
              //   const line = newSfp[i].groups[gi].lines[li];
              //   const oldLn = {desc: line.desc, key: line.key, link: line.link, fld: line.fld};
              //   line.desc = head.desc; line.key = head.key;
              //   line.link = head.link; line.fld = head.fld;
              //   const hi = newSfp[i].groups[gi].heads.findIndex(hd => hd.desc===head.desc);
              //   if(hi>=0) newSfp[i].groups[gi].heads[hi] = oldLn; console.log("newSfp:", newSfp);
              //   return newSfp;
              // });
            }
            //---------------------------------------------------------------------------------//
      } } }
    }
  };

  const getCompanyNames = (yrendDate="") => { //console.log("getCompanyNames:: yrendDate:", yrendDate);
    const names: Array<string> = [""]; const yDt = yrendDate? (new Date(yrendDate)): null;
    financialData.forEach(values => {
      if(yDt) { const dt = new Date(values.yrendDate); //console.log("yDt:", yDt, ", dt:", dt);
        if(yDt.getFullYear()===dt.getFullYear() && yDt.getMonth()===dt.getMonth()
        && yDt.getDate()===dt.getDate() && !names.includes(values.name)) names.push(values.name)
      } else if(!names.includes(values.name)) names.push(values.name);
    }); //console.log("names:", names);
    return names;
  };
  const getCompNames = () => {
    const names: Array<string> = [""]; //const yDt = yrendDate? (new Date(yrendDate)): null;
    companies.forEach(comp => {
      // if(yDt) { const dt = new Date(values.yrendDate); //console.log("yDt:", yDt, ", dt:", dt);
      //   if(yDt.getFullYear()===dt.getFullYear() && yDt.getMonth()===dt.getMonth()
      //   && yDt.getDate()===dt.getDate() && !names.includes(values.name)) names.push(values.name)
      // } else
      if(!names.includes(comp.name)) names.push(comp.name);
    }); //console.log("names:", names);
    return names;
  };

  // const getFinancialYears = (name="") => { //console.log("getFinancialYears:: name:", name);
  //   const finYears: Array<string> = [""];
  //   financialData.forEach(values => { const fDt = new Date(values.yrendDate);
  //     const finYear = `${fDt.getDate()} ${months[fDt.getMonth()]} ${fDt.getFullYear()}`;
  //     if((!name || name === values.name) && !finYears.includes(finYear)) finYears.push(finYear);
  //   }); //console.log("finYears:", finYears);
  //   return finYears;
  // };
  const getFinYears = (name="") => { //console.log("getFinYears:: name:", name);
    const finYears: Array<string> = [""];
    financialData.forEach(values => { const fDt = new Date(values.yrendDate);
      const finYear = `${fDt.getDate()} ${months[fDt.getMonth()]} ${fDt.getFullYear()}`;
      if((name === values.name) && !finYears.includes(finYear)) finYears.push(finYear);
    }); finYears.sort(); finYears.reverse(); //console.log("finYears:", finYears);
    if(name && finYears.length<2) { //console.log("getFinYears:: companies:", companies);
      const company = companies.find(comp => comp.name===name); //console.log("getFinYears:: company:", company);
      if(company) {
        const getYears = async () => {
          const res = await fetch('/api/fin-years/'+company.id).then(response => { //console.log("response:", response);
            return response; //.json();
          });
          const resData = await res.json(); //console.log("Notes:: /api/fin-years/:compId - resData:", resData);
          return resData.years;
        };
        getYears().then(years => { //console.log("years:", years);
          let yrs = years as Array<string>;
          yrs.forEach(yr => { //console.log("yr:", yr, (typeof yr));
            const yDt = new Date(yr); //console.log("yDt:", yDt, (typeof yDt));
            const finYear = `${yDt.getDate()} ${months[yDt.getMonth()]} ${yDt.getFullYear()}`;
            if(!finYears.includes(finYear)) finYears.push(finYear);
          }); finYears.sort(); finYears.reverse(); //console.log("finYears:", finYears);
        });
    } } //console.log("getFinYears:: finYears:", finYears);
    return finYears;
  };

  const handleDatePickerChange = (value: Dayjs | null) => { console.log(value);
    if(value) {
      const dt = value; //as unknown as {month: ()=>string, date: ()=>string, year: ()=>string};
      const yrendDate = `${dt.month() + 1}/${dt.date()}/${dt.year()}`;
      // setFormValues({...formValues, yrendDate: yrendDate}); //value?.toString()
      setFormValues((formValues) => ({...formValues, yrendDate: yrendDate}));
      if(selName && dt) {
        console.log("Trying to find selName:", selName, ", dt:", dt, " in financialData:", financialData);
        const found = findValues(selName, dt.toString(), financialData); console.log("found:", found);
        if(found) { setCurrentId(found.id);
          setFormValues(formValues => ({...found})); setInputMode(2); //Edit
        } else {
          const company = companies.find(comp => comp.name===selName);
          if(company) { refreshFinData(company, (new Date(dt.toString())), getDefaultFormValues,
            setFinancialData, setFormValues, setCurrentId); setInputMode(2); //Edit
          }
      } } setSelYear(dt.toString());
    } else setSelYear("");
  };

  const handleSubmit = async () => {
    const dataPack = {...formValues }; //console.log("Saving dataPack:", dataPack);
    try {
      let hasError = false;
      const res = await fetch('/api/financial-data', { // /api/company-data
        method: 'POST', body: JSON.stringify(dataPack),
        headers: {'Content-Type': 'application/json'}
      });
      if (!res.ok) hasError = true;
      const resData = await res.json();
      if (hasError) throw new Error(resData.message);
      const financialInfo = resData.financialInfo;  //console.log("rersData:", resData);
      setFinancialData((prevData: Array<FormValues>) => {
        const newData = prevData.map((data: FormValues) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === financialInfo.id) { index = i; return true; } return false;
        });
        newData[index] = financialInfo; //console.log("newData:", newData); // Update existing record
        setFormValues(financialInfo); setCurrentId(financialInfo.id); //console.log("financialInfo:", financialInfo);
        return newData;
      });

      //console.log("autoRef.current:", autoRef.current);
      //console.log("autoRef.current?.focus:", autoRef.current?.focus);
      setInputMode(0); autoRef.current?.focus();
      setAlertMesg("Form Saved"); setBtn1Text("OK");
      btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      //setTimeout(() => autoRef.current?.focus(), 100); //clearValues();
      //setTimeout(() => autoRef?.focus(), 100);
    } catch (error: any) {
      alert(error.message || 'Something went wrong!');
    }
  };

  const handleClearClick = () => { clearValues(); if(inputMode>0) setInputMode(0); };
  const clearValues = () => {
    const id = currentId, values = getDefaultFormValues(null, id);
    //console.log("currentId:", currentId, ", documentData:", documentData);
    //setFormValues(getDefaultFormValues(null, (inputMode===1? "": currentId)));
    setFormValues(values); setSelName(""); setSelYear("");
  };
  const handleAddNew = () => { setInputMode(1);
    const id = "", values = getDefaultFormValues(null, id); //console.log("AddNew:: values:", values);
    setFormValues(values); setSelName(""); setSelYear("");
    setTimeout(() => textRef.current?.focus(), 100); //console.log("currentId:", currentId);
  };
  const handleEdit = () => { setInputMode(2); setTimeout(() => textRef.current?.focus(), 100); };
  const handleAlertClick = () => { setAlertOpen(false); };

  const deleteId = async () => {
    return fetch('/api/financial-data/'+formValues.id, { // /api/company-data/
        method: 'DELETE', body: JSON.stringify(formValues),
        headers: {'Content-Type': 'application/json'}
    }).then(response => { //console.log("response:", response);
      return response; //.json();
    });
  };
  const handleDelete = () => {
    setAlertMesg("Do you want to delete the company " + formValues.name);
    btn1Handler = handleConfirmDelete; //console.log("handleDelete:: btn1Handler is set.");
    btn2Handler = () => { handleClearClick(); handleAlertClick(); };
    setBtn1Text("Yes"); setBtn2Text("No"); setAlertOpen(true);
  }
  const handleConfirmDelete = () => {
    deleteId().then((json) => { console.log("handleDelete:: json:", json);
      // handle success
      let index = -1, id = ""; const deleteId = formValues.id;
      const filteredData = financialData.filter((values, i) => {
       if (values.id === deleteId) { index = i; return false; } return true;
      }); setFinancialData(filteredData);
      if(index >= filteredData.length) index -= 1;
      if(index >= 0) id = filteredData[index].id; setCurrentId(id);
      setFormValues(getDefaultFormValues(filteredData, id));
//
      setInputMode(0); setTimeout(() => { autoRef.current?.focus();
        setAlertMesg("Deleted company info"); setBtn1Text("OK");
        btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      }, 100);
    })
    .catch(error => console.log("handleDelete:: error:", error));
  };

  // const finExcel = [
  //   { "First Name": "Arul", "Last Name": "proshant", "Sales": 20 },
  //   { "First Name": "Balu", "Last Name": "subramani", "Sales": 30 },
  //   { "First Name": "Chandru", "Last Name": "kumar", "Sales": 40 },
  //   { "First Name": "Total", "Last Name": "", "Sales": {t: "n", v: 90, f: "SUM(C2:C4)"} }, //C2+C3+C4
  // ];
  // const finExcel = [
  //   [ "First Name", "Last Name", "Sales" ],
  //   [ "Arul",       "proshant",  {v:20, s:{alignment:{horizontal:"center"}}} ],
  //   [ "Balu",       "subramani", 30 ],
  //   [ "Chandru",    "kumar",     40 ],
  //   [ "Total", "", {t: "n", v: 90, f: "SUM(C2:C4)"} ], //C2+C3+C4
  // ];
  // const finExcel = {"Sheet 1": [
  //   [ "First Name", "Last Name", "Sales" ],
  //   [ "Arul",       "proshant",  {v:20, s:{alignment:{horizontal:"center"}}} ],
  //   [ "Balu",       "subramani", 30 ],
  //   [ "Chandru",    "kumar",     40 ],
  //   [ "Total", "", {t: "n", v: 90, f: "SUM(C2:C4)"} ], //C2+C3+C4
  // ]};
  // const fmS = {alignment:{horizontal:"center"}}, fmZ = "#,##0_);[Red]\\(#,##0\\)";
  // const finExcel = {"Sheet 1": {data: [
  //   [ "First Name", "Last Name", "Sales" ],
  //   [ "Arul",       "proshant",  {t:"n", v:2000, s:fmS, z:fmZ} ],
  //   [ "Balu",       "subramani", {t:"n", v:3000, s:fmS, z:fmZ} ],
  //   [ "Chandru",    "kumar",     {t:"n", v:4000, s:fmS, z:fmZ} ],
  //   [ "Total", "", {t: "n", v: 9000, f: "SUM(C2:C4)", s:fmS, z:fmZ} ], //C2+C3+C4
  // ], cols: [{width:20},{width:20},{width:10}]}, "Sheet 2": {data: [
  //   [ "First Name", "Last Name", "Sales" ],
  //   [ "Arul",       "proshant",  {t:"n", v:20000, s:fmS} ], //{alignment:{horizontal:"center"}}
  //   [ "Balu",       "subramani", 30000 ],
  //   [ "Chandru",    "kumar",     40000 ],
  //   [ "Total", "", {t:"n", v:90000, f:"SUM(C2:C4)", s:fmS, z:fmZ} ],
  // ], cols: [{width:15},{width:25},{width:12}]} };
  // //const finCols = [{width:15},{width:20},{width:10 }];

  //const finExData = getFinExData(formValues);
  const periods = [
    "for the year ended"  , "for the year ending",
    "for the period ended", "for the period ending",
  ];

  let x: any; //console.log("inputMode:", inputMode, ", currentId:", currentId, currentId.length);
  const d = formValues.yrendDate? (new Date(formValues.yrendDate)): null; //console.log("formValues:", formValues);

  return (<>
    <Paper>
      <form>
        <div style={{ width:"75vw", height:"10vh", position:"fixed", zIndex:"99", backgroundColor:"#fff" }}>{/*68vw*/}
          {/* <h3 style={{ width:"12vw", textAlign:"center", display:"inline-block", verticalAlign:"middle" }}>Financial Position</h3> */}
          <div style={{ width:"12vw", textAlign:"center", display:"inline-block", verticalAlign:"middle" }}>{ currentId.length>4?
            <FinExcel excelSheets={getFinExData(formValues, sfp, pnl, keyNotes)}
            fileName="Excel Export" disabled={inputMode>0}>Download</FinExcel>: null }
          </div>
          <div style={{ width:"45vw", display:"inline-block", verticalAlign:"middle" }}>{/*43vw | 10vw*/}
            {/* ------------------------------------------------------------------------------------ */}
            <FormControl sx={{paddingTop: 1}}>
              <FormGroup row sx={{justifyContent: "space-between"}}>
                <FormGroup sx={{width:"6vw", alignItems:"start"}}>
                  <Stack>
                    <Button onClick={handleDelete} sx={{paddingLeft:3}}
                      disabled={inputMode<2}>Delete</Button>
                  </Stack>
                </FormGroup>
                <FormLabel sx={{paddingLeft: 1, width:"14vw"}}></FormLabel>
                <FormGroup sx={{width:"6vw", paddingLeft: 1, alignItems: "start"}}>
                  <Stack>
                    <Button onClick={handleAddNew} disabled={inputMode>0}>Add</Button>
                    <Button onClick={handleEdit} sx={{paddingTop:0}}
                      disabled={inputMode>0||currentId.length<4}>Edit</Button>
                  </Stack>
                </FormGroup>
                <FormGroup sx={{width:"19vw", paddingRight:2, alignItems:"end"}}>{/*17vw*/}
                  <Stack>
                    <Button onClick={handleSubmit} disabled={inputMode<1}>Save</Button>
                    <Button onClick={handleClearClick} sx={{paddingTop:0}}
                      disabled={inputMode<1}>{inputMode>0? "Cancel": "Clear"}</Button>
                  </Stack>
                </FormGroup>
              </FormGroup>
            </FormControl>
            {/* ------------------------------------------------------------------------------------ */}
          </div>
          <div style={{ width:"18vw", display:"inline-block", verticalAlign:"middle" }}>{/* width:"15vw" */}
            <FormControl sx={{paddingLeft: 2}}>
              <FormLabel sx={{width:"14.3vw", padding:1, paddingRight:0, //, border:1, fontStyle:"italic", fontWeight:"bold" //13.3vw
                 textAlign:"center", color:"#000"}}>{/*&nbsp;*/}<h3>Financial Position</h3>
              </FormLabel>
            </FormControl>
          </div>
        </div>
        <FormControl sx={{paddingTop: 1, marginTop: "10vh"}}>
          <FormGroup row sx={{paddingTop: (inputMode<1? 1: 0), justifyContent: "space-between"}}>
            <Autocomplete options={getCompNames()} sx={{width: "57.5vw", display: (inputMode<1?
              "block": "none")}} renderInput={(params) => (<TextField  // 52.5vw
                inputRef={autoRef} name="name" {...params} autoFocus label={selName?"":"Company Name"}
              />)} getOptionLabel={(option) => `${option}`}
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)}
              value={formValues.name || ""} disablePortal size="small"
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange.bind(null, "name")}  // onInputChange
            />{/*14.75vw*/}
            {/* <FormLabel sx={{width:"16.75vw", textAlign:"center", display: (inputMode<1? "block": "none")}}><h3>{/*Financial Position* /}</h3></FormLabel> */}
            <Autocomplete options={getFinYears(selName)} sx={{width: "16.75vw", display: (inputMode<1? "block": "none")}}
              renderInput={(params) => (<TextField inputRef={dateRef} name="yrendDate" {...params} label={selYear?"":"As At Date"} />)}
              getOptionLabel={(option) => `${option}`} disablePortal disabled={inputMode>0} //14.75vw
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)} size="small"
              value={(formValues.yrendDate?(x=new Date(formValues.yrendDate), `${x.getDate()} ${months[x.getMonth()]} ${x.getFullYear()}`):"")} // || ""
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange.bind(null, "yrendDate")} />
          </FormGroup>
          <FormGroup row sx={{paddingTop: (inputMode<1? 0: 1), justifyContent: "space-between", height: (inputMode<1? 0: 1)}}>
            <TextField id="name" name="name" variant="outlined" size="small"  // label="Name of Company"
              sx={{width: "57.5vw", visibility: (inputMode<1? "hidden": "visible")}} //"52.5vw"
              value={formValues.name} autoFocus disabled={inputMode<1}
              onChange={handleTextFieldChange} inputRef={textRef}
            />{/*14.75vw*/}
            {/* <FormLabel sx={{width:"16.75vw", textAlign:"center", visibility:(inputMode<1?"hidden":"visible")}}><h3>{/*Financial Position* /}</h3></FormLabel> */}
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker format="DD/MM/YYYY" sx={{width: "16.75vw", visibility:(inputMode<1?"hidden":"visible")}}  // label="Date"
                value={dayjs(formValues.yrendDate)} onChange={handleDatePickerChange} //14.75
                slotProps={{ textField: {size: "small"} }} disabled={inputMode<1} />
            </LocalizationProvider>
          </FormGroup>
        </FormControl>

        <div style={{display: (currentId.length>4 ||
          (inputMode>0 && formValues.name && formValues.yrendDate)? "block": "none")}}>
          <FormControl sx={{paddingTop: 0}}>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              {inputMode>0? <><FormLabel sx={{width:"5.35vw", paddingTop: 1, paddingLeft: 2, textAlign: "left"}}>
                Address</FormLabel>
              <TextField id="address" name="address" variant="outlined" size="small"
                sx={{width: "68.85vw"}} value={formValues.address} disabled={inputMode<1}
                onChange={handleTextFieldChange} //59.7vw
              /></>:
              <FormLabel sx={{width:"74.2vw", paddingTop:1, paddingBottom:1, paddingLeft:2}}>{formValues.address}</FormLabel>}
              <FormLabel sx={{width:"0.55vw"}}></FormLabel>{/*2.7vw*/}
            </FormGroup>
          </FormControl>

          <FormControl sx={{paddingTop: 0}}>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"0.5vw"}}></FormLabel>
              <FormLabel sx={{width:"36.25vw", borderTop: 1, fontWeight:"bold",
                fontSize:"110%"}}>Statement of financial position</FormLabel>
              <FormLabel sx={{width:"11vw", borderTop: 1}}></FormLabel>
              <FormLabel sx={{width:"27vw", borderTop: 1}}></FormLabel>
            </FormGroup>
          </FormControl>

          <FormControl sx={{paddingTop: 0}}>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"0.5vw"}}></FormLabel>
              <FormLabel sx={{width:"28.4vw"}}>As at {(d? //{/*18.15vw*/}
                `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`:"")}</FormLabel>
              <FormLabel sx={{width:"6.35vw"}}></FormLabel>{/*14.75vw*/}
              <FormLabel sx={{width:"31.5vw", borderBottom:1, paddingTop: 1, paddingRight: 1,
                textAlign: "center"}}>{formValues.curr}</FormLabel>
              <FormLabel sx={{width:"8vw"}}></FormLabel>
            </FormGroup>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"28.9vw"}}></FormLabel>{/*18.15vw*/}
              <FormLabel sx={{width:"5.35vw", paddingTop: 1, paddingRight: 1, borderBottom:1, textAlign: "right"}}>
                Notes</FormLabel>
              <FormLabel sx={{width:"1vw"}}></FormLabel>
              <FormLabel sx={{width:"12.5vw", paddingTop: 1, paddingRight: 1, borderBottom:1, textAlign: "right"}}>
                {(d?`${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`:"Year 1")}</FormLabel>{/*14.75vw*/}
              <FormLabel sx={{width:"1vw"}}></FormLabel>
              <FormLabel sx={{width:"12.5vw", paddingTop: 1, paddingRight: 1, borderBottom:1, textAlign: "right"}}>
                {(d?`${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()-1}`:"Year 2")}</FormLabel>
              <FormLabel sx={{width:"1vw"}}></FormLabel>
              <FormLabel sx={{width:"12.5vw", paddingTop: 1, paddingRight: 1, borderBottom:1, textAlign: "right"}}>
                {(d?`${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()-2}`:"Year 3")}</FormLabel>
            </FormGroup>
          </FormControl>

          {sfp.map((sec, i) => { //console.log("sec:", sec);
            let gtot=0, gtot1=0, gtot2=0, stot=0, stot1=0, stot2=0, sgps: Array<number> = [];
            if(sec.link) { let arr = sec.link.split(",");
              arr.forEach(gp => { let si = -1;
                if(gp.length>1) si = parseInt(gp.substring(1));
                if(!isNaN(si) && si>=0) sgps.push(si);
              }); //console.log("sec.link:", sec.link, ", sgps:", sgps);
            }
            return (<div key={i}>
              <FormControl sx={{paddingTop: 0}}>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"0.5vw"}}></FormLabel>
                  <FormLabel sx={{width:"36.25vw", fontWeight:"bold", fontSize:"105%"}}>{sec.name}</FormLabel>
                  <FormLabel sx={{width:"11vw", textAlign:"center"}}></FormLabel>
                  <FormLabel sx={{width:"27vw"}}></FormLabel>
                </FormGroup>
              </FormControl>
              {sec.groups.map((grp, gi) => { //console.log("grp:", grp, (gi<(sec.groups.length-1)));
                let bal=0, tot=0, bal1=0, tot1=0, bal2=0, tot2=0;
                return <div key={gi}>
                <FormControl sx={{paddingTop: 0}}>
                  <FormGroup row sx={{justifyContent: "space-between"}}>
                    <FormLabel sx={{width:"0.5vw"}}></FormLabel>
                    <FormLabel sx={{width:"36.25vw", fontWeight:"bold", fontSize:"103%"}}>{grp.name}</FormLabel>
                    <FormLabel sx={{width:"11vw", textAlign:"center"}}></FormLabel>
                    <FormLabel sx={{width:"27vw"}}></FormLabel>
                  </FormGroup>
                </FormControl>
                {grp.lines.map((line, li) => { //console.log("i:", i, ", gi:", gi, ", li:", li, ", line:", line);
                  const ln = getHead(i, gi, li, formValues.sfpHeads);
                  const kn = getKeyNote(keyNotes, ln? ln.key: (line.key? line.key: line.note), formValues.sfpHeads);
                  //console.log("ln:", ln, ", kn:", kn);
                  return (inputMode>0|| !(kn&& kn.hidden))&& <FormControl sx={{paddingTop: 0}} key={li}>
                    <FormGroup row sx={{justifyContent: "space-between"}}>
                      <FormLabel sx={{width:"1.75vw"}}></FormLabel>
                      {inputMode<1? <FormLabel sx={{width:"27.15vw"}}>{ln&& ln.desc? ln.desc: line.desc}</FormLabel>:
            <Autocomplete options={getSfpHeads(i,gi)} sx={{width: "27.15vw"}}
            renderInput={(params) => (<TextField name={`head_${i}_${gi}_${li}`} {...params} />)}
            getOptionLabel={(option) => `${option}`} //27.15vw
            renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)} size="small"
            value={(ln&& ln.desc? ln.desc: line.desc)} //line.desc
            isOptionEqualToValue={(option, value) => option === value || value === ""}
            onChange={handleAutoCompleteChange.bind(null, `head_${i}_${gi}_${li}`)} />
                      }

                      <FormLabel sx={{width:"5.35vw", textAlign:"right"}}>{kn&& kn.note? kn.note: ""}</FormLabel>
                      <FormLabel sx={{width:"1vw"}}></FormLabel>
                      <FormLabel sx={{width:"12.5vw", textAlign:"right"}}>
                        {(bal = getSfpBal(line.link,formValues), tot += bal, negBracket(bal))}</FormLabel>
                      <FormLabel sx={{width:"1vw"}}></FormLabel>
                      {inputMode<1 || !line.ed1?<FormLabel sx={{width:"12.5vw", textAlign:"right"}}>
                        {(bal1=getSfpBal(line.link,formValues,1),tot1+=bal1,negBracket(bal1))}</FormLabel>:
                      <TextField id="bal1" name={`${line.fld?line.fld:"bal"}1_${i}_${gi}_${li}`}
                        variant="outlined" size="small" sx={{width: "12.5vw"}}
                        value={(bal1=getSfpBal(line.link,formValues,1),tot1+=bal1,commaFormat(bal1))}
                        onChange={handleTextFieldChange} />}
                      <FormLabel sx={{width:"1vw"}}></FormLabel>
                      {inputMode<1 || !line.ed2?<FormLabel sx={{width:"12.5vw", textAlign:"right"}}>
                        {(bal2=getSfpBal(line.link,formValues,2),tot2+=bal2,negBracket(bal2))}</FormLabel>:
                      <TextField id="bal2" name={`${line.fld?line.fld:"bal"}2_${i}_${gi}_${li}`}
                        variant="outlined" size="small" sx={{width: "12.5vw"}}
                        value={(bal2=getSfpBal(line.link,formValues,2),tot2+=bal2,commaFormat(bal2))}
                        onChange={handleTextFieldChange} />}
                    </FormGroup>
                  </FormControl>
                })}
                <FormControl sx={{paddingTop: 0}}>
                  <FormGroup row sx={{justifyContent: "space-between"}}>
                    <FormLabel sx={{width:"1.75vw"}}></FormLabel>
                    <FormLabel sx={{width:"32.5vw", borderTop:1, borderBottom:(gi<(sec.groups.length-1)?1:0),
                      fontStyle:"italic", fontSize:"103%"}}>{grp.totLine}</FormLabel>
                    <FormLabel sx={{width:"1vw"}}></FormLabel>
                    <FormLabel sx={{width:"12.5vw", borderTop:1, borderBottom:(gi<(sec.groups.length-1)?1:0),
                      fontStyle:"italic", fontSize:"103%", textAlign: "right"}}>
                      {(gtot += tot, stot+=(sgps.includes(gi)?tot:0), negBracket(tot))}</FormLabel>
                    <FormLabel sx={{width:"1vw"}}></FormLabel>
                    <FormLabel sx={{width:"12.5vw", borderTop:1, borderBottom:(gi<(sec.groups.length-1)?1:0),
                      fontStyle:"italic", fontSize:"103%", textAlign: "right"}}>
                      {(gtot1+=tot1,stot1+=(sgps.includes(gi)?tot1:0),negBracket(tot1))}</FormLabel>
                    <FormLabel sx={{width:"1vw"}}></FormLabel>
                    <FormLabel sx={{width:"12.5vw", borderTop:1, borderBottom:(gi<(sec.groups.length-1)?1:0),
                      fontStyle:"italic", fontSize:"103%", textAlign: "right"}}>
                      {(gtot2+=tot2,stot2+=(sgps.includes(gi)?tot2:0),negBracket(tot2))}</FormLabel>
                  </FormGroup>
                </FormControl>
              </div>})}
              {sec.subTot? <FormControl sx={{paddingTop: 0}}>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"1.75vw"}}></FormLabel>
                  <FormLabel sx={{width:"32.5vw", borderTop:1, fontStyle:"italic", fontSize:"103%"}}>{sec.subTot}</FormLabel>
                  <FormLabel sx={{width:"1vw"}}></FormLabel>
                  <FormLabel sx={{width:"12.5vw", borderTop:1, fontStyle:"italic", fontSize:"103%",
                    textAlign:"right"}}>{negBracket(stot)}</FormLabel>
                  <FormLabel sx={{width:"1vw"}}></FormLabel>
                  <FormLabel sx={{width:"12.5vw", borderTop:1, fontStyle:"italic", fontSize:"103%",
                    textAlign:"right"}}>{negBracket(stot1)}</FormLabel>
                  <FormLabel sx={{width:"1vw"}}></FormLabel>
                  <FormLabel sx={{width:"12.5vw", borderTop:1, fontStyle:"italic", fontSize:"103%",
                    textAlign:"right"}}>{negBracket(stot2)}</FormLabel>
                </FormGroup>
              </FormControl>: null}
              <FormControl sx={{paddingTop: 0}}>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"0.5vw"}}></FormLabel>
                  <FormLabel sx={{width:"33.75vw", fontWeight:"bold", fontSize:"105%",
                    borderTop: 1, borderBottom: 1}}>{sec.totLine}</FormLabel>
                  <FormLabel sx={{width:"13.5vw", fontWeight:"bold", fontSize:"105%",
                    borderTop: 1, borderBottom: 1, textAlign: "right"}}>{negBracket(gtot)}</FormLabel>
                  <FormLabel sx={{width:"13.5vw", fontWeight:"bold", fontSize:"105%",
                    borderTop: 1, borderBottom: 1, textAlign: "right"}}>{negBracket(gtot1)}</FormLabel>
                  <FormLabel sx={{width:"13.5vw", fontWeight:"bold", fontSize:"105%",
                    borderTop: 1, borderBottom: 1, textAlign: "right"}}>{negBracket(gtot2)}</FormLabel>
                </FormGroup>
              </FormControl>
              <FormControl sx={{paddingTop: 0}}>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"0.5vw"}}></FormLabel>
                  <FormLabel sx={{width:"36.25vw", borderTop: 1}}>&nbsp;</FormLabel>
                  <FormLabel sx={{width:"11vw", borderTop: 1}}></FormLabel>
                  <FormLabel sx={{width:"27vw", borderTop: 1}}></FormLabel>
                </FormGroup>
              </FormControl>
            </div>);
          })}
        </div>
      </form>
    </Paper>
    <Dialog open={alertOpen} onClose={handleAlertClick}>
      <Alert onClose={handleAlertClick}>
        <AlertTitle>Success!</AlertTitle>
        {alertMesg}
        <div style={{marginTop: 20, display: "flex", justifyContent: "space-between"}}>
          {btn2Text? <Button style={{textTransform: "none", paddingLeft: 0,
           justifyContent: "left"}} onClick={btn2Handler}>{btn2Text}</Button>: null}
          {btn1Text? <Button style={{textTransform: "none", paddingRight: 0, marginLeft: 10,
           justifyContent: "right"}} onClick={btn1Handler}>{btn1Text}</Button>: null}
        </div>
      </Alert>
    </Dialog>
  </>)
};
