
export const removeComma = (amount: string) => {
  if (!amount) return amount;
  return amount.replace(/,/g, "");
};

export const commaFormat = (amount: number) => {
  const neg = amount < 0; if(neg) amount = -amount;
  amount = (Math.round(amount*1000))/1000;
  const thou = Math.floor(amount/1000), hund = amount % 1000;
  let sHnd = hund.toString();
  if(sHnd.includes(".")) { let c =sHnd.substring(sHnd.length-1);
    while(c==="0") { sHnd = sHnd.substring(0, sHnd.length-1);
      c = sHnd.substring(sHnd.length-1);
      if(c===".") { sHnd = sHnd.substring(0, sHnd.length-1); break; }
  } }
  let retVal = (neg?"-":"") + (thou? thou.toString()+",": "") +
    (hund? ((thou? (hund<10?"00":(hund<100?"0":"")): "") + sHnd): (thou?"000":"0"));
  let di = retVal.indexOf("."); if(di>0 && di<(retVal.length-4)) { //console.log("commaFormat:: retVal:", retVal);
    retVal = retVal.substring(0, di+4);
  }
  return retVal;
};

export const negBracket = (amount: number) => {
  const neg = amount < 0; if(neg) amount = -amount;
  return (neg?"(":"") + commaFormat(Math.round(amount)) + (neg?")":"")
};

