import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams, GridRenderCellParams } from "@mui/x-data-grid";
import { contactData } from "../../Data/ContactData";

// const columns = [
//   { field: "name", headerName: "Name", minWidth: 150
//   , renderCell: (cellValues: GridRenderCellParams<string>) => ({cellValues.value}) },
//   { field: "role", headerName: "Role", minWidth: 100
//   , renderCell: (cellValues: GridRenderCellParams<string>) => ({cellValues.value}) },
// ];

function getFullName(params: GridValueGetterParams) {
  return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
}

const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'fullName',
    headerName: 'Full name',
    width: 160,
    valueGetter: getFullName,
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon' },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei' },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime' },
  { id: 4, lastName: 'Stark', firstName: 'Arya' },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys' },
];

export default function ContactDataGrid() {
  return (
    // <DataGrid rows={contactData} columns={columns} />
    // <DataGrid
    //   columns={[{ field: 'username' }, { field: 'age' }]}
    //   rows={[
    //     { id: 1, username: '@MUI', age: 20 },
    //     { id: 2, username: 'Mahbub', age: 60 },
    //     { id: 3, username: 'RS', age: 40 },
    //   ]}
    // />
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid rows={rows} columns={columns} />
    </Box>
  );
};
