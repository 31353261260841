import React, { useState, useEffect } from 'react';
// import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from './App';

// const root = ReactDOM.createRoot(document.getElementById('root'));
function RootApp() { // console.log('screen.width: ', window.screen.width);
  // console.log('window.innerWidth: ', window.innerWidth);
  // const [scrollTop, setScrollTop] = useState(0);
  let scrollTop = 0;
  function scrollHandler(event) { // console.log('scrollHandler');
    // console.log('scrollTop: ', event.currentTarget.scrollTop);
    // console.log('offsetHeight: ', event.currentTarget.offsetHeight);
    // setScrollTop(event.currentTarget.scrollTop);
    scrollTop = event.currentTarget.scrollTop;
  }
  // useEffect(()=>{ console.log('RootApp: i fire once'); },[]);
  return (
    <div style={{height: '100vh', overflow: 'auto'}} onScroll={scrollHandler}>
      <Provider store={store} >
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <App scrollTop={scrollTop} />
        </GoogleOAuthProvider>
      </Provider>
    </div>
  );
}
// root.render(
ReactDOM.render(
  <React.StrictMode>
    <RootApp />
  </React.StrictMode>, document.getElementById('root')
);
