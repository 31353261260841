import { TextField, TextFieldProps } from "@mui/material";
import { minWidth } from "../ContactForm";

export default function BeautifulTextField (props: TextFieldProps) {
  return (
    <TextField id="name" name="name" label="Name" variant="outlined"
      sx={{minWidth: minWidth, marginRight: 2}} // value={props.value}
      {...props} // onChange={props.onChange}
    />
  );
}
