export interface FormValues {[key: string]: string;
  id: string; name: string; yrendDate: string; address: string;
  statCoi: string; statDfin: string; statLfin: string; statMoa: string; statEtin: string;
  statTlic: string; statBin: string; statFxii: string; statSchx: string; statVcrd: string;
  statF23b: string; statAbst: string;
  mtStart: string; mtEnd: string; mtDuration: string;
  rqDays: string;  rqTime: string; urgency: string; hrDelivery: string;
  period: string;  fsType: string;
  email: string;   mobile: string;     bzCard: string; revenue: string; revnLyr: string;
  tAssets: string; tAsstLy: string;   borrows: string; borrwLy: string;
  profLos: string; prLosLy: string;   rs1Name: string; rs2Name: string; rs3Name: string;
  rs4Name: string; rsMnames: string;
  agreed: string; qLnItems: string; qBnAccts: string; qLnAccts: string; corrErrs: string;
  qToDToC: string; qSbTests: string; mangLetr: string; fsDfBnSt: string; visiting: string;
  rsNet: string; rs1Tot: string; rs2Tot: string; rs3Tot: string;
  feeLyear: string; feeIcab: string; feeMar: string; fsNet: string; visitNet: string;
  reguSup: string; rjscRet: string; taxReturn: string; oth1Amt: string; oth1Txt: string;
  oth2Amt: string; oth2Txt: string; profCost: string; discount: string; hideDtl: string;
  efficiency: string;
};

const today = new Date();
export const documentData: Array<FormValues> = [
  { id: "1", name: "Justified Company Limited", yrendDate:
    `6/30/${today.getMonth()<6?today.getFullYear()-1:today.getFullYear()}`, address:
    "23/D/1, Box Culvert Road, Free School Street, Panthapath, Dhaka-1205, Bangladesh.",
    statCoi: "N", statDfin: "N", statLfin: "N", statMoa: "N", statEtin: "N",
    statTlic: "N", statBin: "N/A", statFxii: "N", statSchx: "N", statVcrd: "N",
    statF23b: "N", statAbst: "N",
    mtStart: today.toString(), mtEnd: today.toString(), mtDuration: "0 : 00",
    rqDays: "7", rqTime: "", urgency: "", hrDelivery: "",
    period: "for the year ended", fsType: "FS_(0)_Op",
    email: "", mobile: "", bzCard: "Y", revenue: "25,030,000", revnLyr: "",
    tAssets: "20,000,000", tAsstLy: "", borrows: "6,000,000", borrwLy: "",
    profLos: "-2,500,000", prLosLy: "", rs1Name: "Rouf", rs2Name: "Ayasa", rs3Name: "Mitu",
    rs4Name: "Rupa", rsMnames: "Ayasa,Mitu",
    agreed: "Y", qLnItems: "15", qBnAccts: "1", qLnAccts: "1", corrErrs: "Y",
    qToDToC: "3", qSbTests: "29", mangLetr: "N", fsDfBnSt: "N", visiting: "N",
    rsNet: "59,200", rs1Tot: "41,167", rs2Tot: "26,568", rs3Tot: "17,489",
    feeLyear: "10,000", feeIcab: "50,000", feeMar: "35,000", fsNet: "9,600", visitNet: "25,200",
    reguSup: "0", rjscRet: "0", taxReturn: "0", oth1Amt: "0", oth1Txt: "Others",
    oth2Amt: "0", oth2Txt: "Others", profCost: "15,000", discount: "0", hideDtl: "",
    efficiency: "50",
  },
];

// const defaultAddress = "23/D/1, Box Culvert Road, Free School Street, Panthapath, Dhaka-1205, Bangladesh.";
// export const getDefaultFormValues = (data: Array<FormValues>, id: string) => {
//   //console.log("getDefaultFormValues:: id:", id);
//   let retVal = null; if(!data) data = documentData;
//   const filteredData = data.filter(values => values.id===id);
//   //console.log("getDefaultFormValues:: filteredData:", filteredData);
//   if(filteredData.length > 0) retVal = filteredData[0]; else retVal = {
//     id: (data.length+1).toString(), name: "Justified Company Limited", yrendDate:
//     `6/30/${today.getMonth()<6?today.getFullYear()-1:today.getFullYear()}`,
//     address: defaultAddress, statCoi: "N", statDfin: "N", statLfin: "N", statMoa: "N",
//     statEtin: "N", statTlic: "N",statBin:"N/A",statFxii:"N",statSchx:"N",statVcrd:"N",
//     statF23b: "N", statAbst: "N",
//     mtStart: today.toString(), mtEnd: today.toString(), mtDuration: "0 : 00",
//     rqTime: "", hrDelivery: "", period: "for the year ended", fsType: "FS_(0)_Op",
//     email: "", mobile: "", bzCard: "Y", revenue: "BDT 2,503,000", revnLyr: "",
//     tAssets: "BDT 2,000,000", tAsstLy: "", borrows: "BDT 600,000", borrwLy: "",
//     profLos: "(BDT 250,000)", prLosLy: "", efficiency: "50",
//   };
//   //console.log("getDefaultFormValues:: retVal:", retVal);
//   return retVal;
// }

export interface Allocation {[key: string]: string;
  sl: string; proc: string; time: string; resource: string; minAmt: string;
  // payRate: string; amt: string; totCost: string;
};
export interface Allocations {
  [key: string]: string | Array<Allocation>;
  id: string, allocations: Array<Allocation>
};

// export interface UpldFile {[key: string]: string;
//   name:  string; path:  string; mime:  string; gcsUrl:  string;
//   name1: string; path1: string; mime1: string; gcsUrl1: string;
// };
export interface SingleFile {[key: string]: string | any;
  name:  string; path:  string; mime:  string; gcsUrl:  string;
  name1: string; path1: string; mime1: string; gcsUrl1: string;
  desc: string;  stat: string;  user: string;  time: string; usr: any;
};
export interface UpldFile { // MltpFiles
  [key: string]: string | Array<SingleFile>;
  desc: string, numDocs: string, files: Array<SingleFile>
};
export interface UpldFiles {
  [key: string]: string | Array<UpldFile>;
  id: string, upldFiles: Array<UpldFile>
};
export const getUpldFiles = (id: string, data: Array<UpldFiles>) => {
  const found = data.find((uploads, i) => uploads.id === id);
  if(found) return found.upldFiles;
  return [];
};

//const allocationData: Array<Allocations> = [];

export const getAllocations = (id: string, data: Array<Allocations>) => {
  const found = data.find((allocs, i) => allocs.id === id);
  if(found) return found.allocations;
  const defaultAllocations: Array<Allocation> = [
    { sl: "1", proc:"I_discussion",           time:  "0", resource: "A Razzak", minAmt: "0" },
    { sl: "2", proc:"Allocation of assignments", time: "15", resource: "Ayasa", minAmt: "0" },
    { sl: "3", proc:"Client entry in group",     time: "10", resource:  "Mitu", minAmt: "0" },
    { sl: "4", proc:"List of doc collect, forward, filing",time:"60",resource:"A Razzak",minAmt:"0"},
    { sl: "5", proc:"Professional clearance", time: "15",  resource: "Sagor",  minAmt:"100" },
    { sl: "6", proc:"Appointment letter",  time: "10",     resource: "Sagor",  minAmt: "50" },
    { sl: "7", proc:"Quotation/EOI",       time: "20",     resource: "Sagor",  minAmt: "50" },
    { sl: "8", proc:"Audit engagement letter", time: "60", resource: "Sagor",  minAmt:"100" },
    { sl: "9", proc:"Team deputation, if any", time: "10", resource: "Sagor",  minAmt: "50" },
    { sl:"10", proc:"Independence declaration", time: "15", resource: "Sagor", minAmt: "50" },
    { sl:"11", proc:"IFRS FS_formatting",       time:"0", resource: "Sifat", minAmt:  "0" }, //630
    { sl:"12", proc:"Correction of errors",     time:"0", resource:  "Rouf", minAmt:  "0" }, //120
    { sl:"13", proc:"Mangement representation", time: "15", resource: "Sifat", minAmt:  "0" },
    { sl:"14", proc:"Going concern representation",time:"10",resource: "Sifat", minAmt: "0" },
    { sl:"15", proc:"Cash_Analysis (major transactions)",time:"0",resource:"Sifat",minAmt:"0"}, //10
    { sl:"16", proc:"Loan_Analysis",          time: "15",  resource:  "Sifat", minAmt:  "0" },
    { sl:"17", proc:"Materiality - initial",  time: "10",  resource: "Sifat",  minAmt:  "0" },
    { sl:"18", proc:"Risk Anal-initial [ISA 520]",time:"23", resource: "Sifat", minAmt: "0" },
    { sl:"19", proc:"Risk FS",                time: "0", resource: "Sifat",  minAmt:  "0" }, //102
    { sl:"20", proc:"Planning  [ISA 300]",    time: "30",  resource: "Sifat",  minAmt:  "0" },
    { sl:"21", proc:"Form 23B [2 sets 2 years]",time:"30", resource: "Sagor",  minAmt:  "0" },
    { sl:"22", proc:"ToD/ToC - CASH",         time: "0",  resource: "Sifat",  minAmt:  "0" }, //15
    { sl:"23", proc:"ToD/ToC - CAPITAL",      time: "45",  resource: "Sifat",  minAmt:  "0" },
    { sl:"24", proc:"ToD/ToC - PPE",          time: "60",  resource: "Sifat",  minAmt:  "0" },
    { sl:"25", proc:"ToD/ToC - BORROWINGS(loans,   interest)",time:"30",resource:"Sifat",minAmt:"0"},
    { sl:"26", proc:"ToD/ToC - INVESTMENTS(Inv,    interest)",time:"0", resource:"Sifat",minAmt:"0"},
    { sl:"27", proc:"ToD/ToC - REVENUE(+TR, Adv fm customers)",time:"0",resource:"Sifat",minAmt:"0"}, //460
    { sl:"28", proc:"ToD/ToC - INVENTORIES(+CoS, Prod,   TP)", time:"0",resource:"Sifat",minAmt:"0"}, //455
    { sl:"29", proc:"ToD/ToC - EXP (Adv., prov fr exp, admin, dist)",time:"15",resource:"Sifat",minAmt:"0"},
    { sl:"30", proc:"ToD/ToC - TAX (AIT, D/Tx, CTx, Tx exp)",time:"15",resource:"Sifat",minAmt:"0"},
    { sl:"31", proc:"Movement analysis",      time: "40",  resource: "Sifat",  minAmt:  "0" },
    { sl:"32", proc:"Substantive Tests - CASH",time: "15", resource: "Sifat",  minAmt:  "0" },
    { sl:"33", proc:"Substantive Tests - REVENUE",time:"0", resource: "Sifat", minAmt: "0" }, //45
    { sl:"34", proc:"Substantive Tests - PPE",time: "15",  resource: "Sifat",  minAmt:  "0" },
    { sl:"35", proc:"Substantive Tests - INVENTORIES (purchase)",time:"0",resource:"Sifat",minAmt:"0"},
    { sl:"36", proc:"Substantive Tests - BORROWINGS",time:"30",resource:"Sifat", minAmt:"0" },
    { sl:"37", proc:"Manager's review",       time: "64",  resource: "Ayasa",  minAmt:  "0" },
    { sl:"38", proc:"Partner's review",       time: "60",  resource:  "Rouf",  minAmt:  "0" },
    { sl:"39", proc:"Print/setting",        time: "32", resource: "A Razzak",  minAmt:  "0" },
    { sl:"40", proc:"F/check",                time: "11",  resource:  "Mitu",  minAmt:  "0" },
    { sl:"41", proc:"Initial",                time: "10",  resource:  "Rouf",  minAmt:  "0" },
    { sl:"42", proc:"Delivery",             time: "15", resource: "A Razzak",  minAmt:  "0" },
    { sl:"43", proc:"Full signature",         time: "15",  resource:  "Rouf",  minAmt:  "0" },
  ];
  return defaultAllocations;
};

export const getMgLtrAllocs = (id: string, data: Array<Allocations>) => {
  const found = data.find((allocs, i) => allocs.id === id);
  if(found) return found.allocations;
  const defaultAllocs: Array<Allocation> = [
    { sl:"44", proc:"Mangement letter", time: "0", resource:"Iftakhar", minAmt: "0" }, //150
    { sl:"45", proc:"Mangement letter", time: "0", resource:  "Ayasa",  minAmt: "0" }, //100
    { sl:"46", proc:"Mangement letter", time: "0", resource:   "Rouf",  minAmt: "0" }, //50
  ];
  return defaultAllocs;
};

export const getFsDftAllocs = (id: string, data: Array<Allocations>) => {
  const found = data.find((allocs, i) => allocs.id === id);
  if(found) return found.allocations;
  const defaultAllocs: Array<Allocation> = [
    { sl:"47", proc:"FS drafting from bank statement", time: "0", resource: "Sifat", minAmt: "0" },
    { sl:"48", proc:"FS drafting from bank statement", time: "0", resource: "Ayasa", minAmt: "0" },
    { sl:"49", proc:"FS drafting from bank statement", time: "0", resource:  "Rouf", minAmt: "0" },
  ];
  return defaultAllocs;
};

export const sumAllocation = (allocations: Array<Allocation>, resource1: string = "", resource2: string = "") => {
  const resources = resource1 + (resource2? ","+resource2: "");
  const aRes = resources.split(",");
  //console.log("sumAllocation:: resources:", resources, ", aRes:", aRes);
  const getSum = (total: number, alloc: Allocation) => {
    let sum = 0, time = parseFloat(alloc.time); if(isNaN(time)) time = 0;
    if(resources) {
      aRes.forEach(res => { if(res === alloc.resource) sum += time; });
    } else sum += time;
    return total + sum; //(resource? (resource===alloc.resource?time:0): time);
  }
  return allocations? allocations.reduce(getSum, 0): 0;
};

export const sumAllocPayAmt = (allocations: Array<Allocation>, resource: string = "") => {
  const getSum = (total: number, alloc: Allocation) => {
    let time = parseFloat(alloc.time); if(isNaN(time)) time = 0;
    let amt = (
      Math.ceil(Math.abs((getResourcePay(alloc.resource)*time/60)) /10) * 10
    )*(time<0? -1: 1);
    return total + (resource? (resource===alloc.resource?amt:0): amt);
  }
  return allocations? allocations.reduce(getSum, 0): 0;
};

export const sumAllocTotCost = (allocations: Array<Allocation>, efficiency: number,
  resource: string = "") => {
  const getSum = (total: number, alloc: Allocation) => {
    let time = parseFloat(alloc.time); if(isNaN(time)) time = 0;
    let minAmt, pay, cost = (Math.ceil(Math.abs((
      minAmt=parseFloat(alloc.minAmt), pay=getResourcePay(alloc.resource)
      *time/60, minAmt<1? pay*(1 + efficiency/100): Math.max(pay,minAmt)
    )) /10) * 10)*(time<0? -1: 1);
    return total + (resource? (resource===alloc.resource?cost:0): cost);
  }
  return allocations? allocations.reduce(getSum, 0): 0;
};

export const removeComma = (amount: string) => {
  if (!amount) return amount;
  return amount.replace(/,/g, "");
};
export const commaFormat = (amount: number) => {
  const thou = Math.floor(amount/1000), hund = amount % 1000;
  return (thou? thou.toString()+",": "") +
  (hund? ((hund<10?"00":(hund<100?"0":"")) + hund.toString()): (thou?"000":"0"));
};

export const duration2Minutes = (duration: string) => {
  const ar = duration.split(":"), hr = ar[0], min = ar[1];
  return parseFloat(hr)*60 + parseFloat(min);
};

export const getReqDays = (allocations: Array<Allocation>,
  mgLtrAllocs: Array<Allocation>, fsDftAllocs: Array<Allocation>) => {
  const hours = (
    sumAllocation(allocations)+sumAllocation(mgLtrAllocs)+sumAllocation(fsDftAllocs)
  )/60;
  return Math.round(hours/3.5);
};

const resources = [
  { name: "Rouf", icabRate: 6000, payRate: 2900, commonCost: 600, totRate: 3500,
    email: "rouf106rrh@gmail.com", mobile: ""
  },
  { name: "Ayasa", icabRate: 2000, payRate: 680, commonCost: 300, totRate: 980,
    email: "ayasa.afruza81@gmail.com", mobile: ""
  },
  { name: "Mitu", icabRate: 600, payRate: 257, commonCost: 0, totRate: 257, email: "", mobile: ""},
  { name: "A Razzak", icabRate: 400, payRate: 130, commonCost: 100, totRate: 230, email: "", mobile: ""},
  { name: "P_HC", icabRate: 400, payRate: 83, commonCost: 0, totRate: 183, email: "", mobile: ""},
  { name: "Iftakhar", icabRate: 400, payRate: 150, commonCost: 0, totRate: 150, email: "", mobile: ""},
  { name: "Rupa", icabRate: 400, payRate: 100, commonCost: 0, totRate: 100, email: "", mobile: ""},
  { name: "Sifat", icabRate: 400, payRate: 100, commonCost: 0, totRate: 100, email: "", mobile: ""},
  { name: "Siam", icabRate: 400, payRate: 100, commonCost: 0, totRate: 100, email: "", mobile: ""},
  { name: "Mahboob", icabRate: 400, payRate: 100, commonCost: 0, totRate: 100, email: "", mobile: ""},
  { name: "Faiza", icabRate: 400, payRate: 100, commonCost: 0, totRate: 100, email: "", mobile: ""},
  { name: "Sagor", icabRate: 400, payRate: 100, commonCost: 0, totRate: 100, email: "", mobile: ""},
];

export const getResourceNames = () => {
  const names = resources.map((resource) => resource.name);
  //console.log("names:", names);
  return names;
};

export const getResourcePay = (name: string) => {
  //console.log("getResourcePay:: name:", name);
  const found = resources.find((resource) => resource.name === name);
  //console.log("getResourcePay:: found:", found);
  if(found) return found.totRate;
  return 0;
};

export const getResourceFee = (name: string) => {
  const found = resources.find((resource) => resource.name === name);
  if(found) return found.icabRate;
  return 0;
};

const fsTypes = [
  { type: "FS_Seg 3+_35_Note", weight: 10, multiplyer: 8.0 },
  { type: "FS_Seg 2_30_Note",  weight:  6, multiplyer: 5.0 },
  { type: "FS_Production",     weight:  4, multiplyer: 3.5 },
  { type: "FS_Reg+borrow",     weight:  3, multiplyer: 2.5 },
  { type: "FS_Regular",        weight:  2, multiplyer: 2.0 },
  { type: "FS_(0)_Op",         weight:  1, multiplyer: 1.0 },
  { type: "Prof_Service",      weight:  0, multiplyer: 0.0 },
];

export const getFsWeight = (type: string) => {
  const found = fsTypes.find((fsType) => fsType.type === type);
  if(found) return found.weight;
  return 0;
};

export const getMultiplyer = (fsType: string) => {
  const found = fsTypes.find((fs) => fs.type === fsType);
  if(found) return found.multiplyer;
  return 0;
};
