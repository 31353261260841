import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UpldFile } from "../Data/DocumentData";
//import type { RootState } from "./store";

//interface UpldFilesState { value: Array<{name:string, path:string}> };
interface UpldFilesState { value: Array<UpldFile> };
const initialState: UpldFilesState = { value: [] };

export const upldFilesSlice = createSlice({
  name: "upldFiles", initialState, reducers: { setUpldFiles: (state, action:
    PayloadAction< Array<UpldFile> >) => {
      //console.log("setUpldFiles:: state.value:", state.value, ", action.payload:", action.payload);
      state.value = action.payload;
      //console.log("setUpldFiles:: state.value:", state.value);
    }
  }
});

export const { setUpldFiles } = upldFilesSlice.actions;
export default upldFilesSlice.reducer;
