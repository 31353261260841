import { useState } from "react";
import { Navigate } from "react-router-dom";
import { Box, Button, Container, CssBaseline, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

export const Signup = (props) => {
  const [redirect, setRedirect] = useState(false);
  const handleSubmit = async (event) => { event.preventDefault();
    const data = new FormData(event.currentTarget);
    const first_name = data.get('fname'), last_name = data.get('lname');
    const email = data.get('email'), password = data.get('password');
    const password_confirm = data.get('cpass');
    //console.log({email, password}, JSON.stringify({email, password}));
    const resp = await fetch("/api/register", { //http://localhost:8000
      method: "POST", credentials: "include", //mode: "cors",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({first_name, last_name, email, password, password_confirm})
    });
    const jsnData = await resp.json(); console.log(jsnData);
    if(jsnData.message==="success") {
      const resp = await fetch("/api/login", { //http://localhost:8000
        method: "POST", credentials: "include", //mode: "cors",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({email, password})
      });
      const jsnData = await resp.json(); //setRedirect(true); //console.log(jsnData);
    }
    setRedirect(true);
  };

  if(redirect) {
    if(typeof props.onSignup === 'function') props.onSignup();
    else return (<Navigate to="/" />);
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{marginTop:8, display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Typography component="h1" variant="h5">Please sign up</Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField margin="normal" id="fname" name="fname"
              label="First Name" autoFocus required fullWidth />
            <TextField margin="normal" id="lname" name="lname"
              label="Last Name" required fullWidth />
            <TextField margin="normal" id="email" name="email" autoComplete="email"
              label="Email Address" required fullWidth />
            <TextField margin="normal" id="password" name="password" type="password"
              label="Password" required fullWidth />
            <TextField margin="normal" id="cpass" name="cpass" type="password"
              label="Confirm Password" required fullWidth />
            <Button type="submit" variant="contained" sx={{ mt:3, mb:2 }} fullWidth>
              Sign Up
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
