import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../redux/authSlice";
import TopBar from "../../components/topbar/TopBar";
import FinancialPosition from "../../components/Form/FinancialPosition";
import ProfitLoss from "../../components/Form/ProfitLoss";
import CashFlow from "../../components/Form/CashFlow";
import EquityChanges from "../../components/Form/EquityChanges";
import Notes from "../../components/Form/Notes";
import AnnexureA from "../../components/Form/AnnexureA";
import { Login } from "../../components/Login";
import { Signup } from "../../components/Signup";
import { getNotes, getBalances, getSfpLineHeads, getAnnexes, getSces,
  getSfp, getPnl, getKeyNotes } from "../../Data/FinancialData";
import "./FinancialsPage.css";

const FinancialsPage = ({ scrollTop, lang }) => {
  const dispatch = useDispatch(); //, svBals = { bals: [] };
  const auth = useSelector(state => state.auth.value);
  const today = new Date(); //console.log("LettersPage:: today:", today);
  const [financialData, setFinancialData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [currentId, setCurrentId] = useState((financialData.length).toString());

  const bangla = (lang.toLowerCase()==="bn");
  const [form, setForm] = useState(0);
  const [login, setLogin] = useState(true);
  const [signup, setSignup] = useState(false);
  const [message, setMessage] = useState(""); //You are not logged in
  const [userEmail, setUserEmail] = useState("");
  const [userType, setUserType] = useState("");
  const [pass, setPass] = useState(0); //console.log("keyNotes:", keyNotes);

  //const [svBals, setSvBals] = useState([]);
  //const cheqSvBals = () => { console.log("cheqSvBals::", svBals); }
  //const [sfp, setSfp] = useState(getSfp()); // sfp = getSfp(),

  const getDefaultFormValues = (data, id=currentId) => {
    //console.log("getDefaultFormValues:: id:", id); //, ", data:", data);
    let retVal = null; if(!data) data = financialData;
    const filteredData = data.filter(values => values.id===id);
    //console.log("getDefaultFormValues:: filteredData:", filteredData);
    if(filteredData.length > 0) { retVal = { ...filteredData[0] }; //filteredData[0];
      retVal.notes = getNotes(retVal.id, filteredData);
      retVal.balances = getBalances(id, filteredData);
      retVal.balance1 = getBalances(id, data, 1);
      retVal.balance2 = getBalances(id, data, 2);
      retVal.annexes = getAnnexes(retVal.id, filteredData);
      retVal.annex1 = getAnnexes(retVal.id, data, 1);
      retVal.annex2 = getAnnexes(retVal.id, data, 2);
      retVal.sces = getSces(retVal.id, filteredData);
      retVal.sce1 = getSces(retVal.id, data, 1);
      retVal.sce2 = getSces(retVal.id, data, 2);
      retVal.sfpHeads = getSfpLineHeads(id, filteredData);
    } else retVal = {
      id: (data.length+1).toString(), yrendDate: "", name: "", address: "", notes: getNotes("", []),
      //`6/30/${today.getMonth()<6?today.getFullYear()-1:today.getFullYear()}`,
      curr: "In Bangladesh Taka", balances: getBalances(id,[]), balance1:getBalances(id,[],1),
      balance2:getBalances(id,[],2), annexes:getAnnexes("",[]), annex1: getAnnexes("",[],1),
      annex2: getAnnexes("",[],2), sces:getSces("",[]), sce1: getSces("",[],1),
      sce2: getSces("",[],2), sfpHeads: getSfpLineHeads("",[]),
      ntChanged: false, processing: false, user: userEmail,
    }; //console.log("getDefaultFormValues:: retVal:", retVal);
    return retVal;
  }
  const [formValues, setFormValues] = useState(getDefaultFormValues());
  const [inputMode, setInputMode] = useState(0); // 0 - Read Only, 1 - Add New, 2 - Edit
  const sfp = getSfp(), pnl = getPnl(), keyNotes = getKeyNotes(sfp, pnl, formValues.sfpHeads);
  useEffect(() => {
    (async () => {
      try {
        let resp = await fetch("/api/user", { //http://localhost:8000
          credentials: "include"}); //console.log(resp); //mode:"cors",
        if(!resp.ok){
          resp = await fetch("/api/refresh", { //http://localhost:8000
            method:"POST", credentials: "include"}); //console.log(resp); //, mode:"cors"
          resp = await fetch("/api/user", {credentials:"include"}); //http://localhost:8000
          //console.log(resp);
        }
        if(resp.ok) { dispatch(setAuth(true));
          const data = await resp.json(); //console.log("FinancialsPage:: user data:", data);
          setMessage(`Welcome, ${data.first_name} ${data.last_name}`); //user
          setUserEmail(data.email); setUserType(data.type);
        }
      } catch(e) { dispatch(setAuth(false));
        //setMessage("You are not logged in");
      }
    })();
  }, [dispatch, login, signup]);
  if(auth && login) {
    setLogin(false); //console.log("auth:", auth, ", login:", login);
  }
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); let id;
      const res = await fetch('/api/companies'); // /financial-data // /api/company-data
      const resData = await res.json(); //console.log("FinancialsPage:: /api/companies - resData:", resData);
      //setFinancialData(resData.financialData);
      setCompanies(resData.companies);
      if(resData.companies.length > 0) { //financialData
        //id = resData.financialData[resData.financialData.length-1].id;
        //setCurrentId(id); console.log("useEffect:: id:", id);
      }
      //const values = getDefaultFormValues(resData.financialData, id);
      //setFormValues(values);
      setIsLoading(false);
    };
    fetchData();
  }, [login]); //console.log("FinancialPage: companies:", companies);

  const formHandler = (i, e) => { //console.log("formHandler:: i =", i);
    e.stopPropagation(); setLogin(false); setSignup(false); setForm(i); return false;
  }
  const loginHandler = (b, e) => { //console.log("b =", b);
    if(e) e.stopPropagation();
    setLogin(b); setSignup(false); setForm(0); return false;
  }
  const signupHandler = (b, e) => { //console.log("b =", b);
    if(e) e.stopPropagation();
    setSignup(b); setLogin(0); setForm(0); return false;
  }
  const logoutHandler = async (e) => { e.stopPropagation();
    const resp = await fetch("/api/logout", { //http://localhost:8000
      method:"POST", credentials: "include"
    });
    const jsnData = await resp.json(); //console.log(jsnData);
    dispatch(setAuth(false));
    setLogin(false); setForm(0); return false;
  }

  return (
    <div>
      <TopBar scrollTop={scrollTop} bangla={bangla} />
      <div className="fp-sidebar"  onClick={formHandler.bind(this,0)}>
        <div className="fp-sidemenu">
          <h1>IFRS financial statements</h1>
          <ul>
            <li onClick={formHandler.bind(this,1)}><a href="#">Statement of financial position</a></li>
            <li onClick={formHandler.bind(this,2)}><a href="#">Statement of profit or loss</a></li>
            <li onClick={formHandler.bind(this,3)}><a href="#">Statement of changes in equity</a></li>
            <li onClick={formHandler.bind(this,4)}><a href="#">Statement of cash flows</a></li>
            <li onClick={formHandler.bind(this,5)}><a href="#">Notes</a></li>
            <li onClick={formHandler.bind(this,6)}><a href="#">Annexure A</a></li>
          </ul>
        </div>
      </div>
      <div style={{ marginLeft:"26.5%", width:"64.5%", height:"5.9vh", position:"fixed", //marginLeft:"33.5%", width:"57.5%"
        zIndex:"99", backgroundColor:"#fff" }}>
        <h3 style={{textAlign:"center", fontSize:"113%", fontWeight:"400"}}>{/*43, 50 :: 40, 53*/}
          {auth? message: "You are not logged in"}</h3>
        {auth? (<button className="fp-logout" onClick={logoutHandler}>Logout</button>):
        (<><button className="fp-login" onClick={loginHandler.bind(this,true)}>Login</button>
        <button className="fp-signup" onClick={signupHandler.bind(this,true)}>Register</button></>)}
      </div>
      {login? (<div style={{ marginLeft:"27%", width:"69%", marginTop:"5.9vh" }}>{/*34, 62*/}
        <Login onLogin={loginHandler.bind(this,false)} />
      </div>): (signup? (<div style={{ marginLeft:"27%", width:"69%", marginTop:"5.9vh" }}>{/*34, 62*/}
        <Signup onSignup={signupHandler.bind(this,false)} />
      </div>): null)}

      {form? (<div style={{ marginLeft: "24%", width: "76%", position: "relative"  //marginLeft: "31%", width: "69%"
        , marginTop:"5.9vh" }}>{/* 5.8vh */}
        {form===1 &&auth? (<FinancialPosition getDefaultFormValues={getDefaultFormValues}
          financialData={financialData} setFinancialData={setFinancialData}
          companies={companies} sfp={sfp} pnl={pnl} keyNotes={keyNotes}
          formValues={formValues} setFormValues={setFormValues}
          currentId={currentId} setCurrentId={setCurrentId} //setSfp={setSfp}
          inputMode={inputMode} setInputMode={setInputMode} userEmail={userEmail}
        />): null}
        {form===2 &&auth? (<ProfitLoss getDefaultFormValues={getDefaultFormValues}
          financialData={financialData} setFinancialData={setFinancialData}
          companies={companies} sfp={sfp} pnl={pnl} keyNotes={keyNotes}
          formValues={formValues} setFormValues={setFormValues}
          currentId={currentId} setCurrentId={setCurrentId}
          inputMode={inputMode} setInputMode={setInputMode} userEmail={userEmail}
        />): null}
        {form===3 &&auth? (<EquityChanges getDefaultFormValues={getDefaultFormValues}
          financialData={financialData} setFinancialData={setFinancialData}
          companies={companies} sfp={sfp} pnl={pnl} keyNotes={keyNotes}
          formValues={formValues} setFormValues={setFormValues}
          currentId={currentId} setCurrentId={setCurrentId}
          inputMode={inputMode} setInputMode={setInputMode} userEmail={userEmail}
        />): null}
        {form===4 &&auth? (<CashFlow getDefaultFormValues={getDefaultFormValues}
          financialData={financialData} setFinancialData={setFinancialData}
          companies={companies}
          formValues={formValues} setFormValues={setFormValues}
          currentId={currentId} setCurrentId={setCurrentId}
          inputMode={inputMode} setInputMode={setInputMode} userEmail={userEmail}
        />): null}
        {form===5 &&auth? (<Notes getDefaultFormValues={getDefaultFormValues}
          financialData={financialData} setFinancialData={setFinancialData}
          companies={companies} sfp={sfp} pnl={pnl} keyNotes={keyNotes}
          formValues={formValues} setFormValues={setFormValues} pass={pass}
          currentId={currentId} setCurrentId={setCurrentId} setPass={setPass}
          inputMode={inputMode} setInputMode={setInputMode} userEmail={userEmail} userType={userType}
        />): null}
        {form===6 &&auth? (<AnnexureA getDefaultFormValues={getDefaultFormValues}
          financialData={financialData} setFinancialData={setFinancialData}
          companies={companies} sfp={sfp} pnl={pnl} keyNotes={keyNotes}
          formValues={formValues} setFormValues={setFormValues}
          currentId={currentId} setCurrentId={setCurrentId} setPass={setPass}
          inputMode={inputMode} setInputMode={setInputMode} userEmail={userEmail}
        />): null}
      </div>): null}

    </div>
  );
};

export default FinancialsPage;
