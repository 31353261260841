import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUpldFiles } from "../../redux/upldFilesSlice";
import { setDrtyFiles } from "../../redux/drtyFilesSlice";
import { setOvflFiles } from "../../redux/ovflFilesSlice";
import { setUpldFilesData } from "../../redux/upldFilesDataSlice";
import { RootState } from "../../redux/store";
import { NumericFormat } from "react-number-format";
import { Autocomplete, FormControl, FormGroup, Paper, Button, Checkbox } from "@mui/material";
import { TextField, SelectChangeEvent, Dialog, Alert, AlertTitle, FormControlLabel } from "@mui/material";
import { FormLabel, Select, MenuItem, ListItemText, InputAdornment } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
//import advancedFormat from "dayjs/plugin/advancedFormat";
import { Stack } from "@mui/system";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormValues, Allocation, Allocations, getAllocations, getMgLtrAllocs, getFsDftAllocs, UpldFiles,
  getUpldFiles, getReqDays, getResourceFee, getResourcePay, getFsWeight, getMultiplyer, sumAllocation,
  sumAllocPayAmt, sumAllocTotCost, commaFormat, removeComma } from "../../Data/DocumentData";
import AllocationRow from "./FormSubComponents/AllocationRow";

//dayjs.extend(advancedFormat);

export const col_1 = "2vw", col_2 = "21.6vw", col_3 = "5.25vw", col_4 = "24.25vw";
const col_2Less = "19vw", col_13 = "5.05vw", col_4Less = "24vw";
let btn1Handler = () => {}, btn2Handler = () => {};
//let oldValues;

interface IProps { //
  documentData: FormValues[],
  setDocumentData: React.Dispatch<React.SetStateAction<FormValues[]>>,
  currentId: string, setCurrentId: React.Dispatch<React.SetStateAction<string>>,
  getDefaultFormValues: (data: Array<FormValues>|null, id: string) => FormValues,
  formValues: FormValues, setFormValues: React.Dispatch<React.SetStateAction<FormValues>>,
  allocations: Array<Allocation>, allocationData: Array<Allocations>,
  setAllocations: React.Dispatch<React.SetStateAction<Allocation[]>>,
  setAllocationData: React.Dispatch<React.SetStateAction<Allocations[]>>,
  mgLtrAllocs: Array<Allocation>, mgLtrAllocData: Array<Allocations>,
  setMgLtrAllocs: React.Dispatch<React.SetStateAction<Allocation[]>>,
  setMgLtrAllocData: React.Dispatch<React.SetStateAction<Allocations[]>>,
  fsDftAllocs: Array<Allocation>, fsDftAllocData: Array<Allocations>,
  setFsDftAllocs: React.Dispatch<React.SetStateAction<Allocation[]>>,
  setFsDftAllocData: React.Dispatch<React.SetStateAction<Allocations[]>>,
  inputMode: number, setInputMode: React.Dispatch<React.SetStateAction<number>>,
};

export default function DiscussionMemo({ documentData, setDocumentData,
  currentId, setCurrentId, getDefaultFormValues, formValues, setFormValues,
  allocations, setAllocations, allocationData, setAllocationData,
  mgLtrAllocs, setMgLtrAllocs, mgLtrAllocData, setMgLtrAllocData,
  fsDftAllocs, setFsDftAllocs, fsDftAllocData, setFsDftAllocData,
  inputMode, setInputMode}: IProps) {
  const dispatch = useDispatch();
  const upldFiles = useSelector((state: RootState) => state.upldFiles.value);
  const drtyFiles = useSelector((state: RootState) => state.drtyFiles.value);
  const ovflFiles = useSelector((state: RootState) => state.ovflFiles.value);
  const upldFilesData = useSelector((state: RootState) => state.upldFilesData.value);
  //console.log("DiscussionMemo:: upldFiles:", upldFiles);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMesg, setAlertMesg] = useState("");
  const [btn1Text, setBtn1Text] = useState("");  // Ok
  const [btn2Text, setBtn2Text] = useState("");  // Cancel
  //const [inputMode, setInputMode] = useState(0); // 0 - Read Only, 1 - Add New, 2 - Edit
  const textRef = useRef<HTMLInputElement>(); //let autoRef: HTMLInputElement | undefined;
  const autoRef = useRef<HTMLInputElement>();
  let x, p, m, s, pt:number, mt:number, st:number, t:number;

  const updFsDftAllocs = (name: string, value: string) => {
    const bnac = isNaN(x=parseFloat(name==="qBnAccts"?value:formValues.qBnAccts))?0:x;
    const lnac = isNaN(x=parseFloat(name==="qLnAccts"?value:formValues.qLnAccts))?0:x;
    const dfst = (name==="fsDfBnSt"?value:formValues.fsDfBnSt);
    //if(name==="fsDfBnSt")
    setFsDftAllocs((allocs) => {
      const newAllocs = allocs.map((alloc,i) => (
        alloc.time=(dfst==="Y"?((bnac+lnac)*(i<1?30:(i<2?15:5))*
        getFsWeight(formValues.fsType)).toString():"0"),{...alloc}
      )); //console.log("fsDftAllocs:", fsDftAllocs);
      setFormValues(values => ({...values, rqDays:getReqDays(allocations,mgLtrAllocs,newAllocs).toString()}));
      return newAllocs;
    });
  };
  const updFsFormatng = (name: string, value: string) => {
    const litm = isNaN(x=parseFloat(name==="qLnItems"?value:formValues.qLnItems))?0:x;
    const bnac = isNaN(x=parseFloat(name==="qBnAccts"?value:formValues.qBnAccts))?0:x;
    const lnac = isNaN(x=parseFloat(name==="qLnAccts"?value:formValues.qLnAccts))?0:x;
    setAllocations((allocs) => {
      const newAllocs = allocs.map((alloc,i) => (
        i===10? (alloc.time=Math.round((litm*10+(bnac+lnac)*15)*getMultiplyer(formValues.fsType)).toString(),{...alloc}
      ): alloc)); //console.log("newAllocs[11]:", newAllocs[11]);
      setFormValues(values => ({...values, rqDays:getReqDays(newAllocs,mgLtrAllocs,fsDftAllocs).toString()}));
      return newAllocs;
    });
  };
  const updateTotals = (name: string, value: string) => {
    p = (sumAllocation(allocations, formValues.rs1Name) +
        sumAllocation(mgLtrAllocs, formValues.rs1Name) +
        (isNaN(x=parseFloat(formValues.qSbTests))?0:x) * 5);
    m = (sumAllocation(allocations, formValues.rsMnames) +
        sumAllocation(mgLtrAllocs, formValues.rsMnames) +
        (isNaN(x=parseFloat(formValues.qSbTests))?0:x) * 15);
    s = (sumAllocation(allocations) + sumAllocation(mgLtrAllocs) - p - m);
    //console.log("p:", p, ", m:", m, ", s:", s, ", (p+m+s)/(60*3.5):", Math.round((p+m+s)/(60*3.5)));
    //console.log("getReqDays(newAllocs,mgLtrAllocs,fsDftAllocs):", getReqDays(allocations,mgLtrAllocs,fsDftAllocs));
    pt= Math.round((getResourceFee(formValues.rs1Name) *
        ( p/60 + (isNaN(x=parseFloat(formValues.urgency))?0:x)*0.3 ))/10)*10;
    mt= Math.round((getResourceFee(formValues.rs2Name) *
        ( m/60 + (isNaN(x=parseFloat(formValues.urgency))?0:x)*0.6 ))/10)*10;
    st= Math.round((getResourceFee(formValues.rs3Name) * ( s/60 ))/10)*10;
    //console.log("pt:", pt, ", mt:", mt, ", st:", st);
    const urgc = isNaN(x=parseFloat(name==="urgency"?value:formValues.urgency))?0:x;
    t = Math.round((pt + mt + st + (getResourcePay(formValues.rs1Name) *
        urgc*0.3) + //(isNaN(x=parseFloat(formValues.urgency))?0:x)
        (getResourcePay(formValues.rs2Name) * (isNaN(x)?0:x)*0.6) +
        (getResourcePay(formValues.rs3Name) * (isNaN(x)?0:x)*0.9))/100)*100;
    //console.log("t:", t, ", x:", x, "2300+t:", (2300+t));
        //x = parseFloat(formValues.urgency);
    const p1 = Math.round(getResourcePay(formValues.rs1Name) * (p/60 + (isNaN(x)?0:x)*0.3));
    const m1 = Math.round(getResourcePay(formValues.rs2Name) * (m/60 + (isNaN(x)?0:x)*0.6));
    const s1 = Math.round(getResourcePay(formValues.rs4Name) * (s/60)) + 2300;
    //console.log("p1:", p1, ", m1:", m1, ", s1:", s1);
    const fstp = (name==="fsType"? value: formValues.fsType);
    const fsFlat = (formValues.fsDfBnSt==="Y"? Math.round(getFsWeight(fstp)*2500): 0);
    const fsTot = (
      getResourceFee(formValues.rs1Name) * (p=sumAllocation(fsDftAllocs,formValues.rs1Name))/60 +
      getResourceFee(formValues.rs2Name) * (m=sumAllocation(fsDftAllocs,formValues.rs2Name))/60 +
      getResourceFee(formValues.rs4Name) * (sumAllocation(fsDftAllocs) - p - m)/60
    );
    //console.log("updateTotals:: fsFlat:", fsFlat, ", fsTot:", fsTot, ", Math.max(fsFlat,fsTot):", Math.max(fsFlat,fsTot));
    const vstg = (name==="visiting"? value: formValues.visiting);
    const vsFlat = (vstg==="N"? 0: Math.round(getFsWeight(fstp)*4000));
    const vsTot = (
      getResourceFee(formValues.rs1Name) * (vstg==="Out_Dhk"?4:(vstg==="Dhk"?3:0)) +
      getResourceFee(formValues.rs2Name) * (vstg==="Out_Dhk"?4:(vstg==="Dhk"?3:0)) +
      getResourceFee(formValues.rs4Name) * (vstg==="Out_Dhk"?4:(vstg==="Dhk"?3:0))
    );
    //console.log("updateTotals:: vsFlat:", vsFlat, ", vsTot:", vsTot, ", Math.max(vsFlat,vsTot):", Math.max(vsFlat,vsTot));
    const revn = isNaN(x=parseFloat(removeComma(name==="revenue"?value:formValues.revenue)))?0:x;
    const asst = isNaN(x=parseFloat(removeComma(name==="tAssets"?value:formValues.tAssets)))?0:x;
    const borr = isNaN(x=parseFloat(removeComma(name==="borrows"?value:formValues.borrows)))?0:x;
    const plos = isNaN(x=parseFloat(removeComma(name==="profLos"?value:formValues.profLos)))?0:x;
    //console.log("updateTotals:: revn:", revn, ", asst:", asst, ", Math.max(revn,asst):", Math.max(revn,asst));
    const rvAst = Math.max(revn,asst,borr,Math.abs(plos)); let icab: number, mar = 0;
    switch(true){
      case rvAst>2500000000: //console.log("rvAst>2500000000:: ", rvAst);
        icab = Math.round((500000 + 25 *(rvAst-2500000000)/150000)/1000)*1000; break; //525000
      case rvAst>1500000000: //console.log("rvAst>1500000000:: ", rvAst);
        icab = Math.round((300000 + 24 *(rvAst-1500000000)/120000)/1000)*1000; break; //500000
      case rvAst>1000000000: //console.log("rvAst>1000000000:: ", rvAst);
        icab = Math.round((225000 +22.5*(rvAst-1000000000)/150000)/1000)*1000; break; //300000
      case rvAst >500000000: //console.log("rvAst >500000000:: ", rvAst);
        icab = Math.round((150000 + 7.5*(rvAst-500000000)/50000) / 1000)*1000; break; //225000
      case rvAst >250000000: //console.log("rvAst >250000000:: ", rvAst);
        icab = Math.round((100000 + 8 *(rvAst -250000000)/40000) / 1000)*1000; break; //150000
      case rvAst > 50000000: //console.log("rvAst > 50000000:: ", rvAst);
        icab = Math.round(( 50000 + 5 *(rvAst - 50000000)/20000) / 1000)*1000; break; //100000
      default: icab = 50000;
    }
    switch(true){
      case rvAst <   500000: mar = 20000; break;
      case rvAst < 10000000: mar = 25000; break;
      case rvAst < 30000000: mar = 35000; break;
      case rvAst < 40000000: mar = 40000; break;
      case rvAst < 50000000: mar = 50000; break;
      default: mar = Math.round(icab * 0.95);
    }
    //console.log("updateTotals:: rvAst:", rvAst, ", icab:", icab, ", mar:", mar);
    //-----------------------------------------------------------------------------------------//
    // const qtNet = commaFormat(
    //   mar + parseFloat(removeComma(formValues.discount))
    //     + (allocations.length>11 && parseFloat(allocations[11].time)>0?
    //     (parseFloat(allocations[11].time)/60)*getResourcePay(formValues.rs1Name):0) //cErr
    //     + (Math.round( Math.max(fsFlat,fsTot) /10)*10) + // fsNet
    //   (Math.round( Math.max(vsFlat,vsTot) /10)*10)  + // visitNet
    //   parseFloat(removeComma(formValues.reguSup)) +
    //   parseFloat(removeComma(formValues.rjscRet))+parseFloat(removeComma(formValues.taxReturn))+
    //   parseFloat(removeComma(formValues.oth1Amt)) +parseFloat(removeComma(formValues.oth2Amt))
    // );
    // console.log("updateTotals::", formValues.name, ":: rsNet:",(2300+t), ", Quotation:", qtNet);
    // console.log("mar:", mar, ", discount:", formValues.discount, ", cErr:"
    // , (parseFloat(allocations[11].time)/60)*getResourcePay(formValues.rs1Name)
    // , ", fsNet:", (Math.round( Math.max(fsFlat,fsTot) /10)*10)
    // , ", visitNet:", (Math.round( Math.max(vsFlat,vsTot) /10)*10)
    // , ", reguSup:", formValues.reguSup, ", rjscRet:", formValues.rjscRet, ", taxRet:", formValues.taxReturn
    // , ", oth1Amt:", formValues.oth1Amt, ", oth2Amt:", formValues.oth2Amt);
    //-----------------------------------------------------------------------------------------//
    setFormValues((formValues) => ({...formValues,
      rsNet: (2300+t).toString(), rs1Tot: (p1+pt).toString(),
      rs2Tot: (m1+mt).toString(), rs3Tot: (s1+st).toString(),
      feeIcab: commaFormat(icab), feeMar: commaFormat(mar),
      fsNet: commaFormat(Math.round( Math.max(fsFlat,fsTot) /10)*10),
      visitNet: commaFormat(Math.round( Math.max(vsFlat,vsTot) /10)*10),
      rqDays: getReqDays(allocations,mgLtrAllocs,fsDftAllocs).toString(),
    }));
  };
  const updateSbTests = (name: string, value: string) => {
    //console.log("removeComma(revenue):", removeComma(formValues.revenue));
    const revn = isNaN(x=parseFloat(removeComma(name==="revenue"?value:formValues.revenue)))?0:x;
    const todc = isNaN(x=parseFloat(name==="qToDToC"?value:formValues.qToDToC))?0:x;
    const qsbt = (todc + Math.ceil(revn/1000000));
    //console.log("revn:", revn, ", todc:", todc, ", qsbt:", qsbt);
    setFormValues((formValues) => ({...formValues, qSbTests: qsbt.toString()}));
    const litm = isNaN(x=parseFloat(name==="qLnItems"?value:formValues.qLnItems))?0:x;
    const bnac = isNaN(x=parseFloat(name==="qBnAccts"?value:formValues.qBnAccts))?0:x;
    const lnac = isNaN(x=parseFloat(name==="qLnAccts"?value:formValues.qLnAccts))?0:x;
    const fstp = (name==="fsType"? value: formValues.fsType);
    const cErr = (name==="corrErrs"? value: formValues.corrErrs);
    const rv = Math.ceil(revn/10000000);
    setAllocations((allocs) => {
      const newAllocs = allocs.map((alloc,i) => (
        i===18? (alloc.time=((bnac+lnac+todc+qsbt)*3).toString(),{...alloc}): ( //19: Risk FS
          i===26? (alloc.time=((rv>0?10:0)+(rv>1?rv*5:0)+qsbt*15).toString(),{...alloc}): (
            i===27? (alloc.time=(getFsWeight(fstp)*5+qsbt*15).toString(),{...alloc}): (
              i===32? (alloc.time=(rv*15).toString(),{...alloc}): ( //33: Subs Test - Revn
                i===14? (alloc.time=(bnac*10).toString(),{...alloc}): ( //15: Cash Analysis
                  i===21? (alloc.time=((bnac+2)*5).toString(),{...alloc}): ( //22: ToD/ToC - Cash
                    i===34? (alloc.time=((x=getFsWeight(fstp))>3?x*10:0).toString(),{...alloc}): (
                      i===11? (alloc.time=((cErr==="Y"?(30*getFsWeight(fstp)):0).toString()),{...alloc}): (
                        i===10? (alloc.time=Math.round((litm*10+(bnac+lnac)*15)*getMultiplyer(fstp)).toString(),{...alloc}):alloc
        ) ) ) ) ) ) ) )
      ) ); //console.log("newAllocs[11]:", newAllocs[11]);
      setFormValues(values => ({...values, rqDays:getReqDays(newAllocs,mgLtrAllocs,fsDftAllocs).toString()}));
      return newAllocs;
    });
  };
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    // setFormValues({...formValues, [name]: value});
    if(name==="qToDToC"||name==="revenue"||name==="qBnAccts"||name==="qLnAccts") updateSbTests(name,value);
    if(name==="qLnItems"||name==="qBnAccts"||name==="qLnAccts") updFsFormatng(name, value);
    if(name==="qBnAccts"||name==="qLnAccts") updFsDftAllocs(name, value);
    setFormValues((formValues) => ({...formValues, [name]: value}));
    updateTotals(name, value);
  }; // console.log("formValues:", formValues);
  //console.log("currentId:", currentId, "documentData:", documentData);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { name } = event.target; //console.log("handleCheckboxChange:: checked:", checked, ", name:", name);
    setFormValues((formValues) => ({...formValues, [name]: (checked?"1":"")}));
  };

  const handleAllocationTextFieldChange = (index: number, alType: string,
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    //console.log("handleAllocationTextFieldChange:: index =", index, ", name:", name, ", value:", value);
    const setAllocs = alType? (alType==="mg"?setMgLtrAllocs:setFsDftAllocs): setAllocations;
    setAllocs((allocs) => {
      let newAllocs = allocs.map((alloc, i) => { //console.log("allocs.map:: i =",i,", alloc:",alloc);
        return (i === index? {...alloc, [name]: value}: alloc);
      }); //console.log("setAllocations:: newAllocs:", newAllocs);
      if(name==="time") { //console.log("Updating rqDays:: time =", value);
        setFormValues((values) => ({...values, rqDays:getReqDays((alType?allocations:newAllocs),
          (alType==="mg"? newAllocs: mgLtrAllocs), (alType==="fs"? newAllocs: fsDftAllocs)
        ).toString()}));
      }
      return newAllocs;
    });
  }; // console.log("allocations:", allocations);

  const handleAllocationAutoCompleteChange = (index: number, alType: string,
    fld: string, event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    //console.log("handleAllocationAutoCompleteChange:: index:", index, ", fld:", fld, ", value:", value, ", event:", event);
    if(value){
      const setAllocs = alType? (alType==="mg"?setMgLtrAllocs:setFsDftAllocs): setAllocations;
      setAllocs((allocs) => {
        let newAllocs = allocs.map((alloc, i) => { //console.log("allocs.map:: i =",i,", alloc:",alloc);
          return (i === index? {...alloc, [fld]: value}: alloc);
        }); //console.log("setAllocations:: newAllocs:", newAllocs);
        return newAllocs;
      });
    }
  };
  //--------------------------------------------------------------------------------------------//
  useEffect(() => { updateTotals("", "");
    // const qtNet = commaFormat(
    //   parseFloat(removeComma(formValues.feeMar))+parseFloat(removeComma(formValues.discount))
    //     + (allocations.length>11 && parseFloat(allocations[11].time)>0?
    //     (parseFloat(allocations[11].time)/60)*getResourcePay(formValues.rs1Name):0) //cErr
    //     + parseFloat(removeComma(formValues.fsNet)) +
    //   parseFloat(removeComma(formValues.visitNet))+parseFloat(removeComma(formValues.reguSup)) +
    //   parseFloat(removeComma(formValues.rjscRet))+parseFloat(removeComma(formValues.taxReturn))+
    //   parseFloat(removeComma(formValues.oth1Amt)) +parseFloat(removeComma(formValues.oth2Amt))
    // );
    // console.log(formValues.name,":: rsNet:",formValues.rsNet,", Quotation:",qtNet);
  }, [ currentId, allocations ]);
  //--------------------------------------------------------------------------------------------//
  const handleAutoCompleteChange = (fld: string, event: React.SyntheticEvent<Element, Event>,
    value: string | null) => { //console.log("value:", value, ", event:", event);
    if(value){
      if(fld==="name") { let index = -1;
        if((index = documentData.findIndex(values=>values.name===value)) >= 0) {
          let companyInfo = documentData[index], id = companyInfo.id;
          setFormValues(companyInfo); setCurrentId(companyInfo.id);
          if((index = allocationData.findIndex(allocs=>allocs.id===id)) >= 0) {
            setAllocations(allocationData[index].allocations);
          }
          if((index = mgLtrAllocData.findIndex(allocs=>allocs.id===id)) >= 0) {
            setMgLtrAllocs(mgLtrAllocData[index].allocations);
          }
          if((index = fsDftAllocData.findIndex(allocs=>allocs.id===id)) >= 0) {
            setFsDftAllocs(fsDftAllocData[index].allocations);
          }
          if((index = upldFilesData.findIndex(uploads=>uploads.id===id)) >= 0) {
            //console.log("index:", index, ", upldFilesData[index]:", upldFilesData[index]);
            dispatch(setUpldFiles(upldFilesData[index].upldFiles));
          } dispatch(setDrtyFiles([])); dispatch(setOvflFiles([]));
          //---------------------------------------------------------------------------------------//
          //---------------------------------------------------------------------------------------//
        } } else {
        if(fld==="fsType") updateSbTests(fld, value);
        setFormValues((formValues) => ({...formValues, [fld]: value}));
      }
      updateTotals(fld, value);
    }
  };
  const getCompanyNames = () => {
    const names = documentData.map((values) => values.name);
    //console.log("names:", names);
    return names;
  };
  const fsTypes = [
    "FS_Seg 3+_35_Note", "FS_Seg 2_30_Note", "FS_Production",
    "FS_Reg+borrow", "FS_Regular", "FS_(0)_Op", "Prof_Service",
  ];
  const periods = [
    "for the year ended"  , "for the year ending",
    "for the period ended", "for the period ending",
  ];
  const cards = ["Y", "N", "N/A"];
  const urgencies = ["", "1", "2", "3", "4", "5", "6", "7"];

  const handleDatePickerChange = (value: Dayjs | null) => { //console.log(value);
    if(value) {
      const dt = value; //as unknown as {month: ()=>string, date: ()=>string, year: ()=>string};
      const yrendDate = `${dt.month() + 1}/${dt.date()}/${dt.year()}`;
      // setFormValues({...formValues, yrendDate: yrendDate}); //value?.toString()
      setFormValues((formValues) => ({...formValues, yrendDate: yrendDate}));
    }
  };
  const updateHrDelivery = (mtEnd: Date | null) => {
    const mtStart = new Date(formValues.mtStart);
    if(!mtEnd) mtEnd = new Date(formValues.mtEnd); //console.log("mtEnd:", mtEnd);
    let min = Math.round((mtEnd.getTime()-mtStart.getTime())/60000); //console.log("min:", min);
    if(inputMode===1) setAllocations((allocs) => {
      const min = Math.round((mtEnd!.getTime()-mtStart.getTime())/60000);
      const newAllocs = allocs.map((alloc,i) => (
        i>0? alloc: (alloc.time=min.toString(),{...alloc})
      )); //console.log("newAllocs[0]:",newAllocs[0],", min:",min,", mtEnd:",mtEnd);
      return newAllocs;
    });
    let hr = Math.floor(min/60); min = min % 60; //console.log("hr:", hr, ", min:", min);
    const dur = `${hr} : ${min<10?"0":""}${min}`; //console.log("dur:", dur);

    let urgc = parseFloat(formValues.urgency);
    let days = isNaN(urgc)? parseFloat(formValues.rqDays): urgc;
    if(isNaN(days)) days = 7;
    const rqDtm = new Date(mtEnd.getTime() + days*864E5);
    hr = rqDtm.getHours(); min = rqDtm.getMinutes();
    const mnths = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const rqTime = `${rqDtm.getDate()}-${mnths[rqDtm.getMonth()]}-${rqDtm.getFullYear()%100}` +
    ` at ${hr>13?(hr-12):(hr<1?12:hr)}:${min<10?"0":""}${min} ${hr<12?"AM":"PM"}`;
    min = Math.round((rqDtm.getTime()-(new Date()).getTime())/60000); //console.log("min:", min);
    hr = Math.floor(min/60); min = min % 60; //console.log("hr:", hr, ", min:", min);
    setFormValues((formValues) => ({...formValues,
      rqTime: rqTime, hrDelivery: `${hr} : ${min}`, mtDuration: dur
    }));
  };
  const handleDateTimePickerChange = (fld: string, value: Dayjs | null) => {
    //console.log("fld:", fld, ", value:", value);
    //const mtStart = new Date(fld==="mtStart"? value!.toString(): formValues.mtStart);
    const mtEnd = new Date(fld==="mtEnd"? value!.toString(): formValues.mtEnd);
    // let min = Math.round((mtEnd.getTime()-mtStart.getTime())/60000); //console.log("min:", min);
    // let hr = Math.floor(min/60); min = min % 60; //console.log("hr:", hr, ", min:", min);
    // const dur = `${hr} : ${min<10?"0":""}${min}`; //console.log("dur:", dur);
    setFormValues((formValues) => ({...formValues, [fld]: value!.toString()}));
    updateHrDelivery(mtEnd);
  };
  useEffect(() => { updateHrDelivery(null);
    const interval = setInterval(() => { updateHrDelivery(null); }, 60000);
    //console.log("useEffect:: setInterval():", interval, ", mtEnd:", formValues.mtEnd);
    return () => { //console.log("useEffect:: clearInterval():", interval);
      clearInterval(interval);
    };
  }, [formValues.mtEnd, formValues.rqDays, formValues.urgency]);

  const handleSelectChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const { target: {name, value} } = event;
    console.log("handleSelectChange:: name:", name, ", value:", value);
    if(name==="corrErrs") updateSbTests(name, value);
    if(name==="mangLetr") setMgLtrAllocs((allocs) => {
      const newAllocs = allocs.map((alloc,i) => (
        alloc.time=(value==="Y"?(i<1?"150":(i<2?"100":"50")):"0"),{...alloc}
      )); //console.log("mgLtrAllocs:", mgLtrAllocs);
      setFormValues(values => ({...values, rqDays:getReqDays(allocations,newAllocs,fsDftAllocs).toString()}));
      return newAllocs;
    });
    if(name==="fsDfBnSt") updFsDftAllocs(name, value);
    setFormValues((formValues) => ({...formValues, [name]: value}));
    updateTotals(name, value);
  };

  const handleSubmit = async () => {
    const dataPack = {...formValues,
      //allocations: allocations, mgLtrAllocs: mgLtrAllocs, fsDftAllocs: fsDftAllocs
      allocations, mgLtrAllocs, fsDftAllocs, upldFiles, drtyFiles, ovflFiles
    };
    try {
      let hasError = false;
      const res = await fetch('/api/company-data', { // http://localhost:3100
        method: 'POST', body: JSON.stringify(dataPack),   // formValues
        headers: {'Content-Type': 'application/json'}
      });
      if (!res.ok) hasError = true;
      const resData = await res.json();
      if (hasError) throw new Error(resData.message);
      const companyInfo = resData.companyInfo;
      const allocationInfo = resData.allocationInfo;
      const mgLtrAllocInfo = resData.mgLtrAllocInfo;
      const fsDftAllocInfo = resData.fsDftAllocInfo;
      const upldFilesInfo = resData.upldFilesInfo; //console.log("upldFilesInfo:", upldFilesInfo);
      setDocumentData((prevData: Array<FormValues>) => {
        const newData = prevData.map((data: FormValues) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === companyInfo.id) { index = i; return true; } return false;
        });
        newData[index] = companyInfo; // Update existing record
        setFormValues(companyInfo); setCurrentId(companyInfo.id);
        return newData;
      });
      setAllocationData((prevData: Array<Allocations>) => {
        const newData = prevData.map((data: Allocations) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === allocationInfo.id) { index = i; return true; } return false;
        });
        newData[index] = allocationInfo; setAllocations(allocationInfo.allocations);
        return newData;
      });
      setMgLtrAllocData((prevData: Array<Allocations>) => {
        const newData = prevData.map((data: Allocations) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === mgLtrAllocInfo.id) { index = i; return true; } return false;
        }); //console.log("mgLtrAllocInfo:", mgLtrAllocInfo);
        newData[index] = mgLtrAllocInfo; setMgLtrAllocs(mgLtrAllocInfo.allocations);
        return newData;
      });
      setFsDftAllocData((prevData: Array<Allocations>) => {
        const newData = prevData.map((data: Allocations) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === fsDftAllocInfo.id) { index = i; return true; } return false;
        });
        newData[index] = fsDftAllocInfo; setFsDftAllocs(fsDftAllocInfo.allocations);
        return newData;
      });

      const newData = upldFilesData.map((data: UpldFiles) => data );
      let index = newData.length; newData.some((values, i) => {
        if (values.id === upldFilesInfo.id) { index = i; return true; } return false;
      });
      newData[index] = upldFilesInfo; dispatch(setUpldFiles(upldFilesInfo.upldFiles));
      dispatch(setUpldFilesData(newData)); dispatch(setDrtyFiles([]));
      dispatch(setOvflFiles([]));

      //console.log("autoRef.current:", autoRef.current);
      //console.log("autoRef.current?.focus:", autoRef.current?.focus);
      setInputMode(0); autoRef.current?.focus();
      setAlertMesg("Form Saved"); setBtn1Text("OK");
      btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      //setTimeout(() => autoRef.current?.focus(), 100); //clearValues();
      //setTimeout(() => autoRef?.focus(), 100);
    } catch (error: any) {
      alert(error.message || 'Something went wrong!');
    }
  };

  const handleClearClick = () => { clearValues(); if(inputMode>0) setInputMode(0); };
  const clearValues = () => {
    const id = currentId, values = getDefaultFormValues(null, id);
    //console.log("currentId:", currentId, ", documentData:", documentData);
    //setFormValues(getDefaultFormValues(null, (inputMode===1? "": currentId)));
    setFormValues(values); setAllocations(getAllocations(id, allocationData));
    setMgLtrAllocs(getMgLtrAllocs(id, mgLtrAllocData));
    setFsDftAllocs(getFsDftAllocs(id, fsDftAllocData));
    dispatch(setUpldFiles(getUpldFiles(id, upldFilesData)));
    dispatch(setDrtyFiles([])); dispatch(setOvflFiles([]));
  };
  const handleAddNew = () => { setInputMode(1);
    const id = "", values = getDefaultFormValues(null, id); //console.log("AddNew:: values:", values);
    setFormValues(values); setAllocations(getAllocations(id, []));
    setMgLtrAllocs(getMgLtrAllocs(id, [])); setFsDftAllocs(getFsDftAllocs(id, []));
    setTimeout(() => textRef.current?.focus(), 100); //console.log("currentId:", currentId);
  };
  const handleEdit = () => { setInputMode(2); setTimeout(() => textRef.current?.focus(), 100); };
  const handleAlertClick = () => { setAlertOpen(false); };
  const deleteId = async () => {
    return fetch('/api/company-data/'+formValues.id, {
        method: 'DELETE', body: JSON.stringify(formValues),
        headers: {'Content-Type': 'application/json'}
    }).then(response => { //console.log("response:", response);
      return response; //.json();
    });
  };
  const handleDelete = () => {
    setAlertMesg("Do you want to delete the company " + formValues.name);
    btn1Handler = handleConfirmDelete; //console.log("handleDelete:: btn1Handler is set.");
    btn2Handler = () => { handleClearClick(); handleAlertClick(); };
    setBtn1Text("Yes"); setBtn2Text("No"); setAlertOpen(true);
  }
  const handleConfirmDelete = () => {
    deleteId().then((json) => { console.log("handleDelete:: json:", json);
      // handle success
      let index = -1, id = ""; const deleteId = formValues.id;
      const filteredData = documentData.filter((values, i) => {
       if (values.id === deleteId) { index = i; return false; } return true;
      }); setDocumentData(filteredData);
      if(index >= filteredData.length) index -= 1;
      if(index >= 0) id = filteredData[index].id; setCurrentId(id);
      setFormValues(getDefaultFormValues(filteredData, id));
      const filteredAllocData = allocationData.filter((allocs) => (allocs.id !== deleteId));
      setAllocationData(filteredAllocData); setAllocations(getAllocations(id, filteredAllocData));
      const fltMgLtrAllocData = mgLtrAllocData.filter((allocs, i) => (allocs.id !== deleteId));
      setMgLtrAllocData(fltMgLtrAllocData); setMgLtrAllocs(getMgLtrAllocs(id, fltMgLtrAllocData));
      const fltFsDftAllocData = fsDftAllocData.filter((allocs, i) => (allocs.id !== deleteId));
      setFsDftAllocData(fltFsDftAllocData); setFsDftAllocs(getFsDftAllocs(id, fltFsDftAllocData));
//
      const fltUpldFilesData = upldFilesData.filter((uploads, i) => (uploads.id !== deleteId));
      dispatch(setUpldFilesData(fltUpldFilesData)); dispatch(setDrtyFiles([]));
      dispatch(setUpldFiles(getUpldFiles(id, fltUpldFilesData))); dispatch(setOvflFiles([]));

      setInputMode(0); setTimeout(() => { autoRef.current?.focus();
        setAlertMesg("Deleted company info"); setBtn1Text("OK");
        btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      }, 100);
    })
    .catch(error => console.log("handleDelete:: error:", error));
  };

  const totWidth = "53vw"; //"51.8vw"; //51.2
  const col1="1.7vw", col23="18.35vw", col4="5.8vw";
  const col23L="11.35vw", col4M="8.8vw"; //col23L="15.35vw" - 4
  const col5="10vw", col6="5.1vw", col7="6vw", col8="6.05vw";

  const col2 = "6.4vw", col3 = "4.75vw", col23M = "21.65vw";
  const col4L = "2.9vw", col5M = "12.5vw", col4L5 = "12.9vw", col56M = "18vw";
  const col234 = "24.15vw", col234L = "20.15vw", col67 = "11.1vw";
  const col2345 = "34.15vw", col5L = "8vw", col67M = "17.1vw"; //col67M = "13.1vw" + 4
  const col45 = "15.8vw", col678 = "17.15vw", col78 = "12.05vw";
  const col234M = "25.15vw", col678M = "26.15vw";

  return (<>
    <Paper>
      <form>
        <div style={{ width:"63vw", height:"10vh", position:"fixed", zIndex:"99", backgroundColor:"#fff" }}>
          <h3 style={{ width:"10vw", textAlign:"center", display:"inline-block",
              verticalAlign:"middle" }}>Discussion Memo</h3>
          <div style={{ width:"43vw", display:"inline-block", verticalAlign:"middle" }}>
            {/* ------------------------------------------------------------------------------------ */}
            <FormControl sx={{paddingTop: 1}}>
              <FormGroup row sx={{justifyContent: "space-between"}}>
                <FormGroup sx={{width:"6vw", alignItems:"start"}}>
                  <Stack>
                    <Button onClick={handleDelete} sx={{paddingLeft:3}}
                      disabled={inputMode<2}>Delete</Button>
                  </Stack>
                </FormGroup>
                <FormLabel sx={{paddingLeft: 1, width:"14vw"}}></FormLabel>
                <FormGroup sx={{width:"6vw", paddingLeft: 1, alignItems: "start"}}>
                  <Stack>
                    <Button onClick={handleAddNew} disabled={inputMode>0}>Add</Button>
                    <Button onClick={handleEdit} sx={{paddingTop:0}}
                      disabled={inputMode>0||currentId.length<4}>Edit</Button>
                  </Stack>
                </FormGroup>
                <FormGroup sx={{width:"17vw", paddingRight:2, alignItems:"end"}}>
                  <Stack>
                    <Button onClick={handleSubmit} disabled={inputMode<1}>Save</Button>
                    <Button onClick={handleClearClick} sx={{paddingTop:0}}
                      disabled={inputMode<1}>{inputMode>0? "Cancel": "Clear"}</Button>
                  </Stack>
                </FormGroup>
              </FormGroup>
            </FormControl>
            {/* ------------------------------------------------------------------------------------ */}
          </div>
          <div style={{ width:"10vw", display:"inline-block", verticalAlign:"middle" }}>
            <FormControl sx={{paddingLeft: 2}}>
              <FormLabel sx={{width:"8.3vw", padding:1, paddingRight:0, border:2, textAlign:"center",
                fontStyle:"italic", fontWeight:"bold"}}>{ commaFormat(
                  //parseFloat(removeComma((Math.ceil(parseFloat(formValues.rsNet)/1000)*1000).toString()))
              parseFloat(removeComma(formValues.feeMar))+parseFloat(removeComma(formValues.discount))
                  + (allocations.length>11 && parseFloat(allocations[11].time)>0?
                  (parseFloat(allocations[11].time)/60)*getResourcePay(formValues.rs1Name):0) //cErr
                  + parseFloat(removeComma(formValues.fsNet)) +
              parseFloat(removeComma(formValues.visitNet))+parseFloat(removeComma(formValues.reguSup)) +
              parseFloat(removeComma(formValues.rjscRet))+parseFloat(removeComma(formValues.taxReturn))+
              parseFloat(removeComma(formValues.oth1Amt)) +parseFloat(removeComma(formValues.oth2Amt))
                )}</FormLabel>
            </FormControl>
          </div>
        </div>
        <FormControl sx={{paddingTop: 1, marginTop: "10vh"}}>
          <FormGroup row sx={{paddingTop: 1, justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1, paddingTop: 1, textAlign: "right",
              paddingRight: 1}}> 1
            </FormLabel>
            <FormLabel sx={{width: col234L, paddingTop: 1}}>
              Meeting date and time
            </FormLabel>
            <FormLabel sx={{width: col5L, paddingTop: 1, textAlign: "center", border: 1,
              borderBottom: 0}}>Meeting start
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DateTimePicker value={dayjs(formValues.mtStart)}
                format="DD/MM/YYYY hh:mm A" ampm={true} sx={{width: col67M}}
                onChange={handleDateTimePickerChange.bind(null, "mtStart")}
                slotProps={{ textField: {size: "small"} }}  disabled={inputMode<1}
              />
            </LocalizationProvider>
            <FormLabel sx={{width: col8, paddingTop: 1, textAlign: "center", border: 1,
              borderBottom: 0, borderLeft: 0}}>Hr/Min
            </FormLabel>
          </FormGroup>
          <FormGroup row sx={{paddingBottom: 1, justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1, paddingRight: 1}}></FormLabel>
            <FormLabel sx={{width: col234L}}></FormLabel>{/* col234 */}
            <FormLabel sx={{width: col5L, paddingTop: 1, textAlign: "center", border: 1}}>
              Meeting end
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DateTimePicker value={dayjs(formValues.mtEnd)}
                format="DD/MM/YYYY hh:mm A" ampm={true} sx={{width: col67M}}
                onChange={handleDateTimePickerChange.bind(null, "mtEnd")}
                slotProps={{ textField: {size: "small"} }}  disabled={inputMode<1}
              />
            </LocalizationProvider>
            <FormLabel sx={{width: col8, paddingTop: 1, textAlign: "center", border: 1,
              borderLeft: 0}}>{formValues.mtDuration}
            </FormLabel>
          </FormGroup>
          <FormGroup row sx={{justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1, paddingRight: 1}}></FormLabel>
            <FormLabel sx={{width: col23L}}></FormLabel>
            <FormLabel sx={{width: col4M, paddingTop: 1, textAlign: "center", border: 1,
              borderBottom: 0}}>Required time
            </FormLabel>
            <FormGroup row sx={{width: col5L, paddingTop:1, border: 1, borderBottom:0, borderLeft:0}}>
              <TextField id="rq-days" name="rqDays" variant="standard" size="small"
                sx={{marginLeft: "1.3vw", width: "3vw"}} value={formValues.rqDays}
                disabled={true} onChange={handleTextFieldChange} //inputMode<1
              />
              <FormLabel sx={{width: "3.3vw"}}>days</FormLabel>
            </FormGroup>
            <FormLabel sx={{width: col67M, paddingTop: 1, textAlign: "center", border: 1,
              borderBottom: 0, borderLeft: 0}}>{formValues.rqTime}
            </FormLabel>
            <FormLabel sx={{width: col8, paddingTop: 1, textAlign: "center", border: 1,
              borderBottom: 0, borderLeft: 0}}>hours
            </FormLabel>
          </FormGroup>
          <FormGroup row sx={{paddingBottom: 1, justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1, paddingRight: 1}}></FormLabel>
            <FormLabel sx={{width: col23L}}></FormLabel>
            <FormLabel sx={{width: col4M, paddingTop: 1, border: 1, borderTop: 0,
              textAlign: "center"}}>URGENT
            </FormLabel>
            <FormControl sx={{paddingTop: 0}} size="small" disabled={inputMode<1}>
              <Select renderValue={(selected: string) => selected} value={formValues.urgency}
                id="urgency" sx={{width: col5L}} name="urgency" onChange={handleSelectChange}>
                {urgencies.map(urgc => (
                  <MenuItem value={urgc} key={urgc}><ListItemText primary={urgc} /></MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormLabel sx={{width: col67M, paddingBottom: 1, textAlign: "center", border: 1,
              borderTop: 0, borderLeft: 0}}>{formValues.hrDelivery}
            </FormLabel>
            <FormLabel sx={{width: col8, paddingBottom: 1, textAlign: "center", border: 1,
              borderTop: 0, borderLeft: 0}}>Delivery
            </FormLabel>
          </FormGroup>
        </FormControl>

        <FormControl sx={{paddingTop: 1}}>
          <FormGroup row sx={{paddingTop: (inputMode<1? 1: 0), justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1, paddingTop: 1, paddingRight: 1, textAlign: "right",
              display: (inputMode<1? "block": "none")}}> 2
            </FormLabel>
            <FormLabel sx={{width: col234M, paddingTop: 1, textAlign: "left", display: (inputMode<1?
              "block": "none")}}>Name of the client entity [see COI]
            </FormLabel>
            <Autocomplete options={getCompanyNames()} sx={{width: col678M, display: (inputMode<1?
              "block": "none")}} renderInput={(params) => (<TextField
                inputRef={autoRef} label="Name" name="name" {...params} autoFocus
              />)} getOptionLabel={(option) => `${option}`}
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)}
              value={formValues.name || ""} disablePortal size="small"
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange.bind(null, "name")}  // onInputChange
            />
          </FormGroup>
          <FormGroup row sx={{paddingTop: (inputMode<1? 0: 1), justifyContent: "space-between", height: (inputMode<1? 0: 1)}}>
            <FormLabel sx={{width: col1, paddingTop: 1, paddingRight: 1, textAlign: "right",
              display: (inputMode<1? "none": "block")}}> 2
            </FormLabel>
            <FormLabel sx={{width: col234M, paddingTop: 1, display: (inputMode<1?
              "none": "block")}}>Name of the client entity [see COI]
            </FormLabel>
            <TextField id="name" name="name" label="Name" variant="outlined" size="small"
              sx={{width: col678M, visibility: (inputMode<1? "hidden": "visible")}}
              value={formValues.name} autoFocus disabled={inputMode<1}
              onChange={handleTextFieldChange} inputRef={textRef}
            />
          </FormGroup>
          <FormGroup row sx={{justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1, paddingRight: 1}}></FormLabel>
            <FormLabel sx={{width: col23, paddingTop: 1, paddingRight: 1, textAlign: "right"}}>
              Period
            </FormLabel>
            <FormLabel sx={{width: col4L}}></FormLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                label="Date" format="DD/MM/YYYY" sx={{width: col4L5}}
                value={dayjs(formValues.yrendDate)} onChange={handleDatePickerChange}
                slotProps={{ textField: {size: "small"} }} disabled={inputMode<1}
              />
            </LocalizationProvider>
            <Autocomplete options={periods} sx={{width: col678}}
              renderInput={(params) => (<TextField name="period" {...params} />)}
              getOptionLabel={(option) => `${option}`} disabled={inputMode<1}
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)}
              value={formValues.period || ""} disablePortal size="small"
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange.bind(null, "period")}
            />
          </FormGroup>
          <FormGroup row sx={{justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: col23M}}></FormLabel>
            <FormLabel sx={{width: col5M, paddingTop: 1, textAlign: "center"}}>FS type</FormLabel>
            <Autocomplete options={fsTypes} sx={{width: col678}}
              renderInput={(params) => (<TextField name="fsType" {...params} />)}
              getOptionLabel={(option) => `${option}`} disabled={inputMode<1}
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)}
              value={formValues.fsType || ""} disablePortal size="small"
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange.bind(null, "fsType")}
            />
          </FormGroup>

          <FormGroup row sx={{justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: col23, paddingTop: 1, paddingRight: 1, textAlign: "right",
              border: 1, borderBottom: 0}}>Email
            </FormLabel>
            <FormLabel sx={{width: col4L}}></FormLabel>
            <TextField id="email" name="email" variant="outlined" size="small"
              sx={{width: col56M}} value={formValues.email} disabled={inputMode<1}
              onChange={handleTextFieldChange}
            />
            <FormLabel sx={{width: col78}}></FormLabel>
          </FormGroup>
          <FormGroup row sx={{justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: col23, paddingTop: 1, paddingRight: 1, textAlign: "right",
              border: 1, borderTop: 0, borderBottom: 0}}>Mobile
            </FormLabel>
            <FormLabel sx={{width: col4L}}></FormLabel>
            <TextField id="mobile" name="mobile" variant="outlined" size="small"
              sx={{width: col56M}} value={formValues.mobile} disabled={inputMode<1}
              onChange={handleTextFieldChange}
            />
            <FormLabel sx={{width: col78}}></FormLabel>
          </FormGroup>
          <FormGroup row sx={{paddingBottom: 1, justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: col23, paddingTop: 1, paddingRight: 1, textAlign: "right",
              border: 1, borderTop: 0}}>Business Card
            </FormLabel>
            <FormLabel sx={{width: col4L}}></FormLabel>
            <FormControl sx={{paddingTop: 0}} size="small" disabled={inputMode<1}>
              <Select renderValue={(selected: string) => selected} value={formValues.bzCard} id="bz-card"
                sx={{width: col4L5}} name="bzCard" onChange={handleSelectChange}>
                {cards.map(card => (
                  <MenuItem value={card} key={card}><ListItemText primary={card} /></MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormLabel sx={{width: col678}}></FormLabel>
          </FormGroup>
        </FormControl>

        {/* ------------------------------------------------------------------------------------ * /}
        <FormControl sx={{paddingTop: 2}}>
          <FormGroup row sx={{justifyContent: "space-between"}}>
            <FormGroup sx={{width: col_13, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleDelete} disabled={inputMode<2} style={{paddingLeft: 0}}>Delete</Button>
              </Stack>
            </FormGroup>
            <FormLabel sx={{paddingTop: 1, paddingLeft: 1, width: col_2Less}}></FormLabel>
            <FormGroup sx={{width: col_13, paddingLeft: 1, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleAddNew} disabled={inputMode>0}>Add</Button>
                <Button onClick={handleEdit} disabled={inputMode>0||currentId.length<4}>Edit</Button>
              </Stack>
            </FormGroup>
            <FormGroup sx={{width: col_4Less, paddingLeft: 1, alignItems: "end"}}>
              <Stack>
                <Button onClick={handleSubmit} disabled={inputMode<1}>Save</Button>
                <Button onClick={handleClearClick} disabled={inputMode<1}>{inputMode>0? "Cancel": "Clear"}</Button>
              </Stack>
            </FormGroup>
          </FormGroup>
        </FormControl>
        {/* ------------------------------------------------------------------------------------ */}

        <FormControl sx={{paddingTop: 1, paddingBottom: 1}}>
          <FormGroup row sx={{paddingTop: 1, justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1, paddingTop: 1, paddingRight: 1,
              textAlign: "right"}}>3</FormLabel>
            <FormLabel sx={{width: col23, paddingTop: 1}}>
              Size of business</FormLabel>
            <FormLabel sx={{width: col4L}}></FormLabel>
            <FormLabel sx={{width: col4L5, padding: 1, border: 1, borderRight: 0}}>
              Current year</FormLabel>
            <FormLabel sx={{width: col67, padding: 1, border: 1}}>
              Last year</FormLabel>
            <FormLabel sx={{width: col8}}></FormLabel>
          </FormGroup>
          <FormGroup row sx={{justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: col23, paddingTop: 1, paddingRight: 1, textAlign: "right",
              border: 1, borderBottom: 0}}>Revenue/sales</FormLabel>
            <FormLabel sx={{width: col4L}}></FormLabel>
            {/* <TextField id="revenue" name="revenue" variant="outlined" size="small"
              sx={{width: col4L5}} value={formValues.revenue} disabled={inputMode<1}
              inputProps={{style: {textAlign:"right"}}} onChange={handleTextFieldChange} /> */}
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width: col4L5}}
              renderText={(value) => <FormLabel sx={{width: col4L5, border:1, padding: 1,
                textAlign:"right"}} disabled>{(x=value[0])==="-"?"(":""} {x?"BDT":""}
                &nbsp; {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0 "}
              </FormLabel>} id="revenue" name="revenue" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.revenue} onChange={handleTextFieldChange} />
            {/* <TextField id="revnLyr" name="revnLyr" variant="outlined" size="small"
              sx={{width: col67}} value={formValues.revnLyr} disabled={inputMode<1}
              inputProps={{style: {textAlign:"right"}}} onChange={handleTextFieldChange} /> */}
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width: col67}}
              renderText={(value) => <FormLabel sx={{width: col67, border:1, padding: 1,
                textAlign:"right"}} disabled>{(x=value[0])==="-"?"(":""} {x?"BDT":""}
                &nbsp; {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0 "}
              </FormLabel>} id="revn-ly" name="revnLyr" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.revnLyr} onChange={handleTextFieldChange} />
            <FormLabel sx={{width: col8}}></FormLabel>
          </FormGroup>
          <FormGroup row sx={{justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: col23, paddingTop: 1, paddingRight: 1, textAlign: "right",
              border: 1, borderTop: 0, borderBottom: 0}}>Total assets</FormLabel>
            <FormLabel sx={{width: col4L}}></FormLabel>
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width: col4L5}}
              renderText={(value) => <FormLabel sx={{width: col4L5, border:1, padding: 1,
                textAlign:"right"}} disabled>{(x=value[0])==="-"?"(":""} {x?"BDT":""}
                &nbsp; {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0 "}
              </FormLabel>} id="tassets" name="tAssets" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.tAssets} onChange={handleTextFieldChange} />
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width: col67}}
              renderText={(value) => <FormLabel sx={{width: col67, border:1, padding: 1,
                textAlign:"right"}} disabled>{(x=value[0])==="-"?"(":""} {x?"BDT":""}
                &nbsp; {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0 "}
              </FormLabel>} id="tasst-ly" name="tAsstLy" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.tAsstLy} onChange={handleTextFieldChange} />
            <FormLabel sx={{width: col8}}></FormLabel>
          </FormGroup>
          <FormGroup row sx={{justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: col23, paddingTop: 1, paddingRight: 1, textAlign: "right",
              border: 1, borderTop: 0, borderBottom: 0}}>Borrowings</FormLabel>
            <FormLabel sx={{width: col4L}}></FormLabel>
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width: col4L5}}
              renderText={(value) => <FormLabel sx={{width: col4L5, border:1, padding: 1,
                textAlign:"right"}} disabled>{(x=value[0])==="-"?"(":""} {x?"BDT":""}
                &nbsp; {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0 "}
              </FormLabel>} id="prof-los" name="borrows" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.borrows} onChange={handleTextFieldChange} />
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width: col67}}
              renderText={(value) => <FormLabel sx={{width: col67, border:1, padding: 1,
                textAlign:"right"}} disabled>{(x=value[0])==="-"?"(":""} {x?"BDT":""}
                &nbsp; {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0 "}
              </FormLabel>} id="borrw-ly" name="borrwLy" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.borrwLy} onChange={handleTextFieldChange} />
            <FormLabel sx={{width: col8}}></FormLabel>
          </FormGroup>
          <FormGroup row sx={{justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: col23, paddingTop: 1, paddingRight: 1, textAlign: "right",
              border: 1, borderTop: 0}}>Profit/(loss)</FormLabel>
            <FormLabel sx={{width: col4L}}></FormLabel>
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width: col4L5}}
              renderText={(value) => <FormLabel sx={{width: col4L5, border:1, padding: 1,
                textAlign:"right"}} disabled>{(x=value[0])==="-"?"(":""} {x?"BDT":""}
                &nbsp; {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0"}
              </FormLabel>} id="prof-los" name="profLos" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.profLos} onChange={handleTextFieldChange} />
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width: col67}}
              renderText={(value) => <FormLabel sx={{width: col67, border:1, padding: 1,
                textAlign:"right"}} disabled>{(x=value[0])==="-"?"(":""} {x?"BDT":""}
                &nbsp; {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0"}
              </FormLabel>} id="prlos-ly" name="prLosLy" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.prLosLy} onChange={handleTextFieldChange} />
            <FormLabel sx={{width: col8}}></FormLabel>
          </FormGroup>
        </FormControl>

        <FormControl sx={{paddingTop: 1}}>
          <FormGroup row sx={{paddingTop: 1, justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1, paddingTop: 1, paddingRight: 1,
              textAlign: "right"}}>4</FormLabel>
            <FormLabel sx={{width: "25.97vw", paddingTop: 1}}>
              Deliverables & activities</FormLabel>
            <FormLabel sx={{width: "6.2vw"}}></FormLabel>
            <FormLabel sx={{width: "6.23vw", padding: 1, borderTop:1, borderLeft:1,
              textAlign:"center"}} disabled={inputMode<1}>{formValues.rs1Name}
            </FormLabel>
            <FormLabel sx={{width: "6.27vw", padding: 1, borderTop:1, borderLeft:1,
              textAlign:"center"}} disabled={inputMode<1}>{formValues.rs2Name}
            </FormLabel>
            <FormLabel sx={{width: "6.33vw", padding: 1, border: 1, borderBottom:0,
              textAlign:"center"}} disabled={inputMode<1}>Others
            </FormLabel>
            <FormLabel sx={{width: "0.3vw"}}></FormLabel>
          </FormGroup>
          <FormGroup row sx={{paddingTop: 0}}>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: "10.1vw", padding: 1, border: 1, borderRight:0}}>Client requirement</FormLabel>
            <FormLabel sx={{width: "5.9vw",  padding: 1, border: 1, borderRight:0,
              textAlign:"center"}}>Agreed</FormLabel>
            <FormLabel sx={{width: "10vw", padding:1, border: 1, borderRight:0,
              textAlign:"center"}}>Activity</FormLabel>
            <FormLabel sx={{width: "6.325vw", padding:1, border:1, borderRight:0,
              textAlign:"center"}}>Qty</FormLabel>
            <FormLabel sx={{width: "6.325vw", padding:1, border:1, borderRight:0,
              textAlign:"center"}}>P</FormLabel>
            <FormLabel sx={{width: "6.325vw", padding:1, border:1, borderRight:0,
              textAlign:"center"}}>M</FormLabel>
            <FormLabel sx={{width: "6.325vw", padding:1, border:1,
              textAlign:"center"}}>S</FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: "10.1vw", padding:1, border:1, borderTop:0,
              textAlign:"center"}}>S/Audit FS</FormLabel>
            <FormControl sx={{paddingTop: 0}} size="small" disabled={inputMode<1}>
              <Select renderValue={(selected: string) => selected} value={formValues.agreed}
                id="agreed" sx={{width: "5.9vw"}} name="agreed" onChange={handleSelectChange}>
                {["Y", "N"].map(agrd => (
                  <MenuItem value={agrd} key={agrd}><ListItemText primary={agrd} /></MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormLabel sx={{width: "10vw", padding:1, border:1, borderTop:0,
              textAlign:"center"}}>FS line items</FormLabel>
            <TextField id="ln-items" name="qLnItems" variant="outlined" size="small"
              sx={{width: "6.325vw"}} value={formValues.qLnItems} disabled={inputMode<1}
              onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"6.325vw", padding:1, border:1, borderRight:0, borderTop:0,
              textAlign: "center"}}>{Math.round(((
                p = (sumAllocation(allocations, formValues.rs1Name) +
                sumAllocation(mgLtrAllocs, formValues.rs1Name) +
                (isNaN(x=parseFloat(formValues.qSbTests))?0:x) * 5)
              )/60 + (isNaN(x=parseFloat(formValues.urgency))?0:x)*0.3 )*10)/10
            } hrs</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1, border:1, borderRight:0, borderTop:0,
              textAlign:"center"}}>{Math.round(((
                m = (sumAllocation(allocations, formValues.rsMnames) +
                sumAllocation(mgLtrAllocs, formValues.rsMnames) +
                (isNaN(x=parseFloat(formValues.qSbTests))?0:x) * 15)
              )/60 + (isNaN(x=parseFloat(formValues.urgency))?0:x)*0.6 )*10)/10
            } hrs</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1, border:1, borderTop:0,
              textAlign:"center"}}>{Math.round(((
                s = (sumAllocation(allocations) + sumAllocation(mgLtrAllocs) - p - m)
              )/60  )*10)/10
            } hrs</FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: "10.1vw", borderRight:0, borderTop:0}}></FormLabel>
            <FormLabel sx={{width: "5.9vw", borderTop: 1, borderRight:0}}></FormLabel>
            <FormLabel sx={{width: "10vw", padding:1, border: 1, borderTop: 0,
              textAlign:"center"}}>Bank accounts</FormLabel>
            <TextField id="bn-accts" name="qBnAccts" variant="outlined" size="small"
              sx={{width: "6.325vw"}} value={formValues.qBnAccts} disabled={inputMode<1}
              onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"6.325vw", borderLeft:1, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderLeft:0, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderTop: 0, borderRight:1}}></FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: "16vw", borderLeft:0, borderRight:0}}></FormLabel>
            <FormLabel sx={{width: "10vw", padding: 1, border: 1, borderTop: 0,
              textAlign:"center"}}>Loan accounts</FormLabel>
            <TextField id="ln-accts" name="qLnAccts" variant="outlined" size="small"
              sx={{width: "6.325vw"}} value={formValues.qLnAccts} disabled={inputMode<1}
              onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"6.325vw", borderLeft:1, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderLeft:0, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderTop: 0, borderRight:1}}></FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: "16vw", borderLeft: 0, borderRight:0}}></FormLabel>
            <FormLabel sx={{width: "10vw", border: 1, borderTop: 0, lineHeight: 1.25,
              textAlign:"center"}}>Correction of<br />errors</FormLabel>
            <FormControl sx={{paddingTop: 0}} size="small" disabled={inputMode<1}>
              <Select renderValue={(selected: string) => selected} value={formValues.corrErrs}
                id="agreed" sx={{width:"6.325vw"}} name="corrErrs" onChange={handleSelectChange}>
                {["Y", "N"].map(corr => (
                  <MenuItem value={corr} key={corr}><ListItemText primary={corr} /></MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormLabel sx={{width:"6.325vw", borderLeft:1, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderLeft:0, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderTop: 0, borderRight:1}}></FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: "16vw", borderLeft:0, borderRight:0}}></FormLabel>
            <FormLabel sx={{width: "10vw", padding: 1, border: 1, borderTop: 0,
              textAlign:"center"}}>ToD/ToC</FormLabel>
            <TextField id="tod-toc" name="qToDToC" variant="outlined" size="small"
              sx={{width: "6.325vw"}} value={formValues.qToDToC} disabled={inputMode<1}
              onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"6.325vw", borderLeft:1, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderLeft:0, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderTop: 0, borderRight:1}}></FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: "16vw", borderLeft:0, borderRight:0}}></FormLabel>
            <FormLabel sx={{width: "10vw", border: 1, borderTop: 0, lineHeight: 1.25,
              textAlign:"center"}}>Substative<br />Tests</FormLabel>
            <TextField id="qSbTests" name="qSbTests" variant="outlined" size="small"
              sx={{width: "6.325vw"}} value={formValues.qSbTests} disabled={inputMode<1}
              onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"6.325vw", borderLeft:1, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderLeft:0, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderTop: 0, borderRight:1}}></FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: "16vw", borderLeft: 0, borderRight:0}}></FormLabel>
            <FormLabel sx={{width: "10vw", padding: 1, border: 1, borderTop: 0,
              textAlign:"center"}}>Mang_letter</FormLabel>
            <FormControl sx={{paddingTop: 0}} size="small" disabled={inputMode<1}>
              <Select renderValue={(selected: string) => selected} value={formValues.mangLetr}
                id="mang-letr" sx={{width:"6.325vw"}} name="mangLetr" onChange={handleSelectChange}>
                {["Y", "N"].map(letr => (
                  <MenuItem value={letr} key={letr}><ListItemText primary={letr} /></MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormLabel sx={{width:"6.325vw", borderLeft:1, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderLeft:0, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderTop: 0, borderRight:1}}></FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: "16vw", borderLeft: 0, borderRight: 0}}></FormLabel>
            <FormLabel sx={{width: "10vw", padding: 1, border: 1, borderBottom: 0,
              borderTop: 0, textAlign:"center"}}>URGENT SUPPORT</FormLabel>
            <FormLabel sx={{width:"6.325vw", paddingTop:1, paddingLeft:1, borderRight:1
              }}>{(x=parseFloat(formValues.urgency),(isNaN(x)?0:x))}.00 day(s)
            </FormLabel>
            <FormLabel sx={{width:"6.325vw", borderLeft:1, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderLeft:0, borderRight:1}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", borderTop: 0, borderRight:1}}></FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width:"16vw"}}></FormLabel>
            <FormLabel sx={{width:"16.325vw", border:1, borderLeft:0, borderBottom:2}}></FormLabel>
            <FormLabel sx={{width:"6.325vw", padding: 1, border:1, borderTop:0, borderBottom:2,
              textAlign: "right"}}>{commaFormat(pt=Math.round((getResourceFee(formValues.rs1Name)
                * ( p/60 + (isNaN(x=parseFloat(formValues.urgency))?0:x)*0.3 ))/10)*10)}
            </FormLabel>
            <FormLabel sx={{width:"6.325vw", padding: 1, borderRight:1, borderBottom:2,
              textAlign: "right"}}>{commaFormat(mt=Math.round((getResourceFee(formValues.rs2Name)
                * ( m/60 + (isNaN(x=parseFloat(formValues.urgency))?0:x)*0.6 ))/10)*10)}
            </FormLabel>
            <FormLabel sx={{width:"6.325vw", padding: 1, borderRight:1, borderBottom:2,
              textAlign: "right"}}>{commaFormat(st=Math.round((getResourceFee(formValues.rs3Name)
                * ( s/60 ))/10)*10)}
            </FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width:"16vw"}}></FormLabel>
            <FormLabel sx={{width:"9.28vw", padding: 1}}>F/cost_based</FormLabel>
            <FormLabel sx={{width:"2.42vw", paddingTop: 1}}>BDT</FormLabel>
            <FormLabel sx={{width:"6.025vw", padding:1}}>{commaFormat(
              Math.round((t = (pt + mt + st + (getResourcePay(formValues.rs1Name) *
              (isNaN(x=parseFloat(formValues.urgency))?0:x)*0.3) +
              (getResourcePay(formValues.rs2Name) * (isNaN(x)?0:x)*0.6) +
              (getResourcePay(formValues.rs3Name) * (isNaN(x)?0:x)*0.9))) /100)*100
            )}</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1}}>{commaFormat(
              Math.round(getResourcePay(formValues.rs1Name) * (p/60 + (isNaN(x)?0:x)*0.3))
            )}</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1}}>{commaFormat(
              Math.round(getResourcePay(formValues.rs2Name) * (m/60 + (isNaN(x)?0:x)*0.6))
            )}</FormLabel>
            <FormLabel sx={{width:"4.925vw", padding:1}}>{commaFormat(
              Math.round(getResourcePay(formValues.rs4Name) * (s/60))
            )}</FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width:"16vw"}}></FormLabel>
            <FormLabel sx={{width:"9.28vw", padding: 1, borderBottom: 1,
              paddingTop: 0}}>Print/conveynce</FormLabel>
            <FormLabel sx={{width:"2.42vw", paddingBottom:1, borderBottom:1}}>BDT</FormLabel>
            <FormLabel sx={{width:"6.025vw", paddingLeft:2, paddingBottom:1, borderBottom:1}}>2,300</FormLabel>
            <FormLabel sx={{width:"6.325vw", paddingLeft:2, paddingBottom:1, borderBottom:1}}>0</FormLabel>
            <FormLabel sx={{width:"6.325vw", paddingLeft:2, paddingBottom:1, borderBottom:1}}>0</FormLabel>
            <FormLabel sx={{width:"4.925vw", paddingLeft:1, paddingBottom:1, borderBottom:1}}>2,300</FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width:"12vw", padding: 1, border: 1,
              textAlign: "center"}}>Agreed Fee</FormLabel>
            <FormLabel sx={{width:"4vw"}}></FormLabel>
            <FormLabel sx={{width:"8.1vw", padding: 1, border: 1,
              borderLeft: 0}}>Total</FormLabel>
            <FormLabel sx={{width:"2.8vw", paddingTop: 1, paddingLeft: 2,
              borderTop:1, borderBottom:1}}>BDT</FormLabel>
            <FormLabel sx={{width:"5.525vw", paddingTop:1, border:1, borderLeft:0,
              borderRight:0, textAlign:"right"}}>{ commaFormat( 2300 +
                Math.round(t /100)*100
              )}</FormLabel>
            <FormLabel sx={{width:"6.225vw", paddingTop:1, border:1, borderLeft:0,
              paddingRight:1, textAlign:"right"}}>{commaFormat(
                Math.round(getResourcePay(formValues.rs1Name) * (p/60 + (isNaN(x)?0:x)*0.3))
              )}</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1, border:1, borderLeft: 0,
              textAlign:"right"}}>{commaFormat(
                Math.round(getResourcePay(formValues.rs2Name) * (m/60 + (isNaN(x)?0:x)*0.6))
              )}</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1, border:1, borderRight:0, paddingRight:2,
              borderLeft:0, textAlign:"right"}}>{commaFormat( 2300 +
                Math.round(getResourcePay(formValues.rs4Name) * (s/60))
              )}</FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width:"17.7vw", padding:1}}> </FormLabel>
            <FormLabel sx={{width:"35.3vw", padding:1, borderTop:1}}> </FormLabel>
          </FormGroup>

          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width:"12vw", padding:1, border:1, textAlign:"center"}}>BDT {commaFormat(
              formValues.fsDfBnSt==="Y"? Math.round(getFsWeight(formValues.fsType)*2500): 0
            )}</FormLabel>
            <FormLabel sx={{width:"4vw"}}></FormLabel>
            <FormLabel sx={{width:"11.9vw", padding: 1, border: 1, borderRight: 0,
              borderLeft: 0}}>FS_draft_bank stat</FormLabel>
            {/* <FormLabel sx={{width:"4.525vw", paddingTop:1, border:1, borderLeft:0,
              borderRight:0, textAlign:"center"}}>Y</FormLabel> */}
            <FormControl sx={{paddingTop: 0}} size="small" disabled={inputMode<1}>
              <Select renderValue={(selected: string) => selected} value={formValues.fsDfBnSt}
                id="fsdf-bnst" sx={{width:"4.525vw"}} name="fsDfBnSt" onChange={handleSelectChange}>
                {["Y", "N"].map(item => (
                  <MenuItem value={item} key={item}><ListItemText primary={item} /></MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormLabel sx={{width:"6.225vw", paddingTop:1, border:1, borderLeft:0,
              paddingRight:1, textAlign:"right"}}>{commaFormat(Math.round((
              getResourceFee(formValues.rs1Name) * (p=sumAllocation(fsDftAllocs,formValues.rs1Name))/60
            ) /10)*10)}</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1, border:1, borderLeft: 0,
              textAlign:"right"}}>{commaFormat(Math.round((
              getResourceFee(formValues.rs2Name) * (m=sumAllocation(fsDftAllocs,formValues.rs2Name))/60
            ) /10)*10)}</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1, border:1, borderRight:0, paddingRight:2,
              borderLeft:0, textAlign:"right"}}>{commaFormat(Math.round((
              getResourceFee(formValues.rs4Name) * (sumAllocation(fsDftAllocs) - p - m)/60
            ) /10)*10)}</FormLabel>
          </FormGroup>

          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width:"12vw", padding:1, border:1, borderTop:0, textAlign:"center"}}>BDT {commaFormat(
              formValues.visiting==="N"? 0: Math.round(getFsWeight(formValues.fsType)*4000)
            )}</FormLabel>
            <FormLabel sx={{width:"4vw"}}></FormLabel>
            <FormLabel sx={{width:"11.9vw", padding: 1, border: 1, borderTop:0, borderRight: 0,
              borderLeft: 0}}>Visit hour(s) costs</FormLabel>
            <FormControl sx={{paddingTop: 0}} size="small" disabled={inputMode<1}>
              <Select renderValue={(selected: string) => selected} value={formValues.visiting}
                id="fsdf-bnst" sx={{width:"4.525vw"}} name="visiting" onChange={handleSelectChange}>
                {["Out_Dhk", "Dhk", "N"].map(item => (
                  <MenuItem value={item} key={item}><ListItemText primary={item} /></MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormLabel sx={{width:"6.225vw", paddingTop:1, border:1, borderTop:0, borderLeft:0,
              paddingRight:1, textAlign:"right"}}>{commaFormat(Math.round(( getResourceFee(formValues.rs1Name)
              * (formValues.visiting==="Out_Dhk"?4:(formValues.visiting==="Dhk"?3:0))
            ) /10)*10)}</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1, border:1, borderTop:0, borderLeft: 0,
              textAlign:"right"}}>{commaFormat(Math.round(( getResourceFee(formValues.rs2Name)
              * (formValues.visiting==="Out_Dhk"?4:(formValues.visiting==="Dhk"?3:0))
            ) /10)*10)}</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1, border:1, borderTop:0, borderRight:0, paddingRight:2,
              borderLeft:0, textAlign:"right"}}>{commaFormat(Math.round(( getResourceFee(formValues.rs4Name)
              * (formValues.visiting==="Out_Dhk"?4:(formValues.visiting==="Dhk"?3:0))
            ) /10)*10)}</FormLabel>
          </FormGroup>

          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width:"12vw"}}
              renderText={(value) => <FormLabel sx={{width:"12vw", border:1, borderTop:0, padding:1,
                paddingRight:0, textAlign:"center"}}>{(x=value[0])==="-"?"(":""} {x?"BDT ":""}
                {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0"}
              </FormLabel>} id="reguSup" name="reguSup" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.reguSup} onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"4vw"}}></FormLabel>
            <FormLabel sx={{width:"35.3vw", padding: 1, border: 1, borderTop:0, borderRight: 0,
              borderLeft: 0}}>Regulatory support costs</FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width:"12vw"}}
              renderText={(value) => <FormLabel sx={{width:"12vw", border:1, borderTop:0, padding:1,
                paddingRight:0, textAlign:"center"}}>{(x=value[0])==="-"?"(":""} {x?"BDT ":""}
                {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0"}
              </FormLabel>} id="rjscRet" name="rjscRet" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.rjscRet} onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"4vw"}}></FormLabel>
            <FormLabel sx={{width:"35.3vw", padding: 1, border: 1, borderTop:0, borderRight: 0,
              borderLeft: 0}}>RJSC filing return</FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width:"12vw"}}
              renderText={(value) => <FormLabel sx={{width:"12vw", border:1, borderTop:0, padding:1,
                paddingRight:0, textAlign:"center"}}>{(x=value[0])==="-"?"(":""} {x?"BDT ":""}
                {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0"}
              </FormLabel>} id="taxReturn" name="taxReturn" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.taxReturn} onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"4vw"}}></FormLabel>
            <FormLabel sx={{width:"35.3vw", padding: 1, border: 1, borderTop:0, borderRight: 0,
              borderLeft: 0}}>Annual Tax return</FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width:"12vw"}}
              renderText={(value) => <FormLabel sx={{width:"12vw", border:1, borderTop:0, padding:1,
                paddingRight:0, textAlign:"center"}}>{(x=value[0])==="-"?"(":""} {x?"BDT ":""}
                {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0"}
              </FormLabel>} id="oth1Amt" name="oth1Amt" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.oth1Amt} onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"4vw"}}></FormLabel>
            <TextField id="oth1Txt" name="oth1Txt" variant="outlined" size="small"
              sx={{width: "35.3vw"}} value={formValues.oth1Txt} disabled={inputMode<1}
              onChange={handleTextFieldChange} />
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width:"12vw"}}
              renderText={(value) => <FormLabel sx={{width:"12vw", border:1, borderTop:0, padding:1,
                paddingRight:0, textAlign:"center"}}>{(x=value[0])==="-"?"(":""} {x?"BDT ":""}
                {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0"}
              </FormLabel>} id="oth2Amt" name="oth2Amt" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.oth2Amt} onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"4vw"}}></FormLabel>
            {/* <FormLabel sx={{width:"35.3vw", padding: 1, border: 1, borderTop:0, borderRight: 0,
              borderLeft: 0}}>Others</FormLabel> */}
            <TextField id="oth2Txt" name="oth2Txt" variant="outlined" size="small"
              sx={{width: "35.3vw"}} value={formValues.oth2Txt} disabled={inputMode<1}
              onChange={handleTextFieldChange} />
          </FormGroup>

          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width:"12vw"}}
              renderText={(value) => <FormLabel sx={{width:"12vw", border:1, borderTop:0, padding:1,
                paddingRight:0, textAlign:"center"}}>{(x=value[0])==="-"?"(":""} {x?"BDT ":""}
                {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0"}
              </FormLabel>} id="profCost" name="profCost" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.profCost} onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"4vw"}}></FormLabel>
            <FormLabel sx={{width:"35.3vw", padding: 1, border: 1, borderTop:0, borderRight: 0,
              borderLeft: 0}}>Professional costs - designing and formatting latest IFRS</FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <NumericFormat customInput={TextField} displayType={inputMode<1?"text":"input"}
              valueIsNumericString={true} thousandSeparator="," style={{width:"12vw"}}
              renderText={(value) => <FormLabel sx={{width:"12vw", border:1, borderTop:0, padding:1,
                paddingRight:0, textAlign:"center"}}>{(x=value[0])==="-"?"(":""} {x?"BDT ":""}
                {x==="-"?value.substring(1):value} {x==="-"?")":"\u00A0"}
              </FormLabel>} id="discount" name="discount" size="small"
              inputProps={{style: {textAlign:"right"}}} variant="outlined"
              InputProps={{startAdornment:<InputAdornment position="end">BDT</InputAdornment>}}
              value={formValues.discount} onChange={handleTextFieldChange} />
            <FormLabel sx={{width:"4vw"}}></FormLabel>
            <FormLabel sx={{width:"35.3vw", padding: 1, border: 1, borderTop:0, borderRight: 0,
              borderLeft: 0}}>Discount</FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width:"17.7vw", padding:1}}> </FormLabel>
            <FormLabel sx={{width:"35.3vw", padding:1, borderBottom:1}}> </FormLabel>
          </FormGroup>

          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            {/* <FormLabel sx={{width:"16vw"}}></FormLabel> */}
            <FormControlLabel sx={{width:"16vw"}} control={<Checkbox
              checked={formValues.hideDtl==="1"?true:false} onChange={handleCheckboxChange} name="hideDtl" />}
              label="Hide Details in Quotation" disabled={inputMode<1} />
            <FormLabel sx={{width:"8.1vw", padding: 1, border: 1, borderBottom: 2,
              borderLeft: 0}}>Total</FormLabel>
            <FormLabel sx={{width:"2.8vw", paddingTop: 1, paddingLeft:2, borderBottom:2,
              borderTop:1}}>BDT</FormLabel>
            <FormLabel sx={{width:"5.525vw", paddingTop:1, border:1, borderLeft:0, borderBottom:2,
              borderRight:0, textAlign:"right"}}>{ commaFormat(
                parseFloat(removeComma(formValues.rsNet))
              )}</FormLabel>
            <FormLabel sx={{width:"6.225vw", paddingTop:1, border:1, borderLeft:0, borderBottom:2,
              paddingRight:1, textAlign:"right"}}>{commaFormat(
                parseFloat(removeComma(formValues.rs1Tot))
              )}</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1, border:1, borderLeft:0, borderBottom:2,
              textAlign:"right"}}>{commaFormat(
                parseFloat(removeComma(formValues.rs2Tot))
              )}</FormLabel>
            <FormLabel sx={{width:"6.325vw", padding:1, border:1, borderRight:0, borderBottom:2,
              paddingRight:2, borderLeft:0, textAlign:"right"}}>{commaFormat(
                parseFloat(removeComma(formValues.rs3Tot))
              )}</FormLabel>
          </FormGroup>
          <FormGroup row>
            <FormLabel sx={{width:"17.7vw", padding:1}}> </FormLabel>
            <FormLabel sx={{width:"35.3vw", padding:1}}> </FormLabel>
          </FormGroup>

          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width:"12vw", padding: 1, border: 2, textAlign:"right",
              fontStyle:"italic", fontWeight:"bold"}}>Required Time</FormLabel>
            <FormLabel sx={{width:"4vw"}}></FormLabel>
            <FormLabel sx={{width:"8.1vw", padding:1, border: 2, textAlign:"center",
              fontWeight: "bold"}}>{formValues.rqDays}</FormLabel>
            <FormLabel sx={{width:"2.8vw"}}></FormLabel>
            <FormLabel sx={{width:"11.75vw"}}></FormLabel>
            <FormLabel sx={{width:"12.65vw"}}></FormLabel>
          </FormGroup>
        </FormControl>

        {/* ------------------------------------------------------------------------------------ */}

        <FormControl sx={{paddingTop: 1, paddingBottom: 1}}>
          <FormGroup row sx={{paddingTop: 1, justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1, paddingTop: 1, paddingRight: 1,
              textAlign: "right"}}>5</FormLabel>
            <FormLabel sx={{width: "16vw", paddingTop: 1}}>
              Fees calculation</FormLabel>
            <FormLabel sx={{width: "8vw",  paddingTop: 1, border: 1, borderRight: 0,
              textAlign:"center", paddingBottom:1}}>Agreed fee</FormLabel>
            <FormLabel sx={{width: "7.325vw", paddingTop:1, border:1, borderRight:0,
              textAlign:"center", paddingBottom:1}}>ICAB</FormLabel>
            <FormLabel sx={{width: "7.325vw", paddingTop:1, border:1, borderRight:0,
              textAlign:"center", paddingBottom:1}}>Work_based</FormLabel>
            <FormLabel sx={{width: "6.325vw", paddingTop:1, border:1,
              textAlign:"center", paddingBottom:1}}>Last year</FormLabel>
            <FormLabel sx={{width: "6.325vw"}}></FormLabel>
          </FormGroup>
          <FormGroup row sx={{justifyContent: "space-between"}}>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: "12.4vw", padding: 1, border: 1,
              borderRight: 0}}>Fee structure</FormLabel>
            <FormLabel sx={{width: "3.6vw", paddingTop: 1, paddingBottom: 1, border: 1,
              borderLeft:0, borderRight:0, textAlign:"center"}}>BDT</FormLabel>
            <FormLabel sx={{width: "8vw", border:1, borderTop:0, borderRight:0}}></FormLabel>
            <FormLabel sx={{width: "7.325vw", padding:1, paddingLeft:0, paddingRight:1, border:1,
              borderTop:0, borderRight:0, textAlign:"center"}}>{formValues.feeIcab}</FormLabel>
            <FormLabel sx={{width: "7.325vw", padding:1, paddingLeft:2, paddingRight:0, border:1,
              borderTop:0, borderRight:0}}>{ commaFormat(parseFloat(removeComma(
                (Math.ceil(parseFloat(formValues.rsNet)/1000)*1000).toString()
              )))}</FormLabel>
            {/* <FormLabel sx={{width: "6.325vw", border:1, borderTop:0, borderRight:0}}></FormLabel> */}
            <TextField id="feeLyear" name="feeLyear" variant="outlined" size="small"
              sx={{width: "6.325vw"}} value={formValues.feeLyear} disabled={inputMode<1}
              onChange={handleTextFieldChange} />
            <FormLabel sx={{width: "6.325vw"}}></FormLabel>
          </FormGroup>
        </FormControl>

        {/* ------------------------------------------------------------------------------------ */}
        <FormControl sx={{paddingTop: 2}}>
          <FormGroup row sx={{justifyContent: "space-between"}}> {/* padding: 1, paddingLeft: 0, */}
            <FormGroup sx={{width: col_13, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleDelete} disabled={inputMode<2} style={{paddingLeft: 0}}>Delete</Button>
              </Stack>
            </FormGroup>
            <FormLabel sx={{paddingTop: 1, paddingLeft: 1, width: col_2Less}}></FormLabel>
            <FormGroup sx={{width: col_13, paddingLeft: 1, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleAddNew} disabled={inputMode>0}>Add</Button>
                <Button onClick={handleEdit} disabled={inputMode>0||currentId.length<4}>Edit</Button>
              </Stack>
            </FormGroup>
            <FormGroup sx={{width: col_4Less, paddingLeft: 1, alignItems: "end"}}>
              <Stack>
                <Button onClick={handleSubmit} disabled={inputMode<1}>Save</Button>
                <Button onClick={handleClearClick} disabled={inputMode<1}>{inputMode>0? "Cancel": "Clear"}</Button>
              </Stack>
            </FormGroup>
          </FormGroup>
        </FormControl>
        {/* ------------------------------------------------------------------------------------ */}

        <h3 style={{width: totWidth, paddingTop: "4vh"}}>Allocation</h3>
        <FormControl sx={{paddingTop: 0}} disabled={inputMode<1}>
          <FormGroup row> {/* sx={{padding: 0, justifyContent: "space-between"}} */}
            <FormLabel sx={{paddingTop: 1, paddingBottom: 1, textAlign: "center", width: col1,
              border: 1}}>Sl</FormLabel>
            <FormLabel sx={{paddingTop: 1, paddingBottom: 1, paddingLeft: 1, width: col23,
              border: 1}}>Working procedures</FormLabel>
            <FormLabel sx={{paddingTop: 1, paddingBottom: 1, textAlign: "center", width: col4,
              border: 1}}>Time[min]</FormLabel>
            <FormLabel sx={{paddingTop: 1, paddingBottom: 1, textAlign: "center", width: col5,
              border: 1}}>Resources</FormLabel>
            <FormLabel sx={{paddingTop: 1, paddingBottom: 1, textAlign: "center", width: col6,
              border: 1}}>Pay</FormLabel>
            <Stack sx={{paddingRight: 1, border: 1, width: col7}}>
              <FormLabel sx={{textAlign: "right"}}>Efficiency</FormLabel>
              <TextField id="efficiency" name="efficiency" variant="standard" size="small"
                value={formValues.efficiency} disabled={inputMode<1}
                sx={{marginLeft: 3}} onChange={handleTextFieldChange} />
            </Stack>
            <Stack sx={{paddingRight: 1, border: 1, width: col8}}>
              <FormLabel sx={{textAlign: "right"}}>Total_cost</FormLabel>
              <FormLabel sx={{textAlign:"center"}}>{formValues.efficiency} %</FormLabel>
            </Stack>
          </FormGroup>
          {allocations.map((alloc, i) => (
            <AllocationRow allocation={alloc} disabled={inputMode<1}
              textProps={{onChange: handleAllocationTextFieldChange.bind(null, i, "")}}
              autoProps={{onChange: handleAllocationAutoCompleteChange.bind(null,i,"","resource")}}
              efficiency={parseFloat(formValues.efficiency)} key={i}
            />
          ))}
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: col23}}></FormLabel>
            <FormLabel sx={{paddingTop: 1, paddingBottom: 1, textAlign: "center", width: col4,
              border: 1}}>{Math.round((sumAllocation(allocations)/60)*10)/10} hrs</FormLabel>
            <FormLabel sx={{width: col5}}></FormLabel>
            <FormLabel sx={{width: col6, padding: 1, textAlign: "right"}}>BDT</FormLabel>
            <FormLabel sx={{paddingTop: 1, paddingRight: 1, textAlign: "right", width: col7,
              border: 1}}>{commaFormat(sumAllocPayAmt(allocations))}</FormLabel>
            <FormLabel sx={{paddingTop:1,paddingRight:1,textAlign:"right",width:col8,border:1}}>
              {commaFormat(sumAllocTotCost(allocations, parseFloat(formValues.efficiency)))}
            </FormLabel>
          </FormGroup>
        </FormControl>

        <FormControl sx={{paddingTop: 4}} disabled={inputMode<1}>
          {mgLtrAllocs && mgLtrAllocs.map((alloc, i) => (
            <AllocationRow allocation={alloc} disabled={inputMode<1}
              textProps={{onChange: handleAllocationTextFieldChange.bind(null, i, "mg")}}
              autoProps={{onChange: handleAllocationAutoCompleteChange.bind(null,i,"mg","resource")}}
              efficiency={parseFloat(formValues.efficiency)} key={i}
            />
          ))}
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: col23}}></FormLabel>
            <FormLabel sx={{paddingTop: 1, paddingBottom: 1, textAlign: "center", width: col4,
              border: 1}}>{Math.round((sumAllocation(mgLtrAllocs)/60)*10)/10} hrs</FormLabel>
            <FormLabel sx={{width: col5}}></FormLabel>
            <FormLabel sx={{width: col6, padding: 1, textAlign: "right"}}>BDT</FormLabel>
            <FormLabel sx={{paddingTop: 1, paddingRight: 1, textAlign: "right", width: col7,
              border: 1}}>{commaFormat(sumAllocPayAmt(mgLtrAllocs))}</FormLabel>
            <FormLabel sx={{paddingTop:1,paddingRight:1,textAlign:"right",width:col8,border:1}}>
              {commaFormat(sumAllocTotCost(mgLtrAllocs, parseFloat(formValues.efficiency)))}
            </FormLabel>
          </FormGroup>
        </FormControl>

        <FormControl sx={{paddingTop: 4}} disabled={inputMode<1}>
          {fsDftAllocs && fsDftAllocs.map((alloc, i) => (
            <AllocationRow allocation={alloc} disabled={inputMode<1}
              textProps={{onChange: handleAllocationTextFieldChange.bind(null, i, "fs")}}
              autoProps={{onChange: handleAllocationAutoCompleteChange.bind(null,i,"fs","resource")}}
              efficiency={parseFloat(formValues.efficiency)} key={i}
            />
          ))}
          <FormGroup row>
            <FormLabel sx={{width: col1}}></FormLabel>
            <FormLabel sx={{width: col23}}></FormLabel>
            <FormLabel sx={{paddingTop: 1, paddingBottom: 1, textAlign: "center", width: col4,
              border: 1}}>{Math.round((sumAllocation(fsDftAllocs)/60)*10)/10} hrs</FormLabel>
            <FormLabel sx={{width: col5}}></FormLabel>
            <FormLabel sx={{width: col6, padding: 1, textAlign: "right"}}>BDT</FormLabel>
            <FormLabel sx={{paddingTop: 1, paddingRight: 1, textAlign: "right", width: col7,
              border: 1}}>{commaFormat(sumAllocPayAmt(fsDftAllocs))}</FormLabel>
            <FormLabel sx={{paddingTop:1,paddingRight:1,textAlign:"right",width:col8,border:1}}>
              {commaFormat(sumAllocTotCost(fsDftAllocs, parseFloat(formValues.efficiency)))}
            </FormLabel>
          </FormGroup>
        </FormControl>
        {/* ------------------------------------------------------------------------------------ */}
        <FormControl sx={{paddingTop: 3}}>
          <FormGroup row sx={{justifyContent: "space-between"}}> {/* padding: 1, paddingLeft: 0, */}
            <FormGroup sx={{width: col_13, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleDelete} disabled={inputMode<2} style={{paddingLeft: 0}}>Delete</Button>
              </Stack>
            </FormGroup>
            <FormLabel sx={{paddingTop: 1, paddingLeft: 1, width: col_2Less}}></FormLabel>
            <FormGroup sx={{width: col_13, paddingLeft: 1, alignItems: "start"}}>
              <Stack>
                <Button onClick={handleAddNew} disabled={inputMode>0}>Add</Button>
                <Button onClick={handleEdit} disabled={inputMode>0||currentId.length<4}>Edit</Button>
              </Stack>
            </FormGroup>
            <FormGroup sx={{width: col_4Less, paddingLeft: 1, alignItems: "end"}}>
              <Stack>
                <Button onClick={handleSubmit} disabled={inputMode<1}>Save</Button>
                <Button onClick={handleClearClick} disabled={inputMode<1}>{inputMode>0? "Cancel": "Clear"}</Button>
              </Stack>
            </FormGroup>
          </FormGroup>
        </FormControl>
        {/* ------------------------------------------------------------------------------------ */}
      </form>
    </Paper>
    <Dialog open={alertOpen} onClose={handleAlertClick}>
      <Alert onClose={handleAlertClick}>
        <AlertTitle>Success!</AlertTitle>
        {alertMesg}
        <div style={{marginTop: 20, display: "flex", justifyContent: "space-between"}}>
          {btn2Text? <Button style={{textTransform: "none", paddingLeft: 0,
           justifyContent: "left"}} onClick={btn2Handler}>{btn2Text}</Button>: null}
          {btn1Text? <Button style={{textTransform: "none", paddingRight: 0, marginLeft: 10,
           justifyContent: "right"}} onClick={btn1Handler}>{btn1Text}</Button>: null}
        </div>
      </Alert>
    </Dialog>
  </>);
};
