import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UpldFiles } from "../Data/DocumentData";
//import type { RootState } from "./store";

//interface UpldFilesDataState {value: Array<{id:string, upldFiles:Array<{name:string, path:string}>}>};
interface UpldFilesDataState { value: Array<UpldFiles> };
const initialState: UpldFilesDataState = { value: [] };

export const upldFilesDataSlice = createSlice({
  name: "upldFilesData", initialState, reducers: { setUpldFilesData: (state, action:
    PayloadAction< Array<UpldFiles> >) => {
      //console.log("setUpldFiles:: state.value:", state.value, ", action.payload:", action.payload);
      state.value = action.payload;
      //console.log("setUpldFiles:: state.value:", state.value);
    }
  }
});

export const { setUpldFilesData } = upldFilesDataSlice.actions;
export default upldFilesDataSlice.reducer;
