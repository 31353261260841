import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UpldFile as OvflFile } from "../Data/DocumentData";
//import type { RootState } from "./store";

//interface UpldFilesState { value: Array<{name:string, path:string}> };
interface OvflFilesState { value: Array<OvflFile> };
const initialState: OvflFilesState = { value: [] };

export const ovflFilesSlice = createSlice({
  name: "ovflFiles", initialState, reducers: { setOvflFiles: (state, action:
    PayloadAction< Array<OvflFile> >) => {
      //console.log("setOvflFiles:: state.value:", state.value, ", action.payload:", action.payload);
      state.value = action.payload;
      //console.log("setOvflFiles:: state.value:", state.value);
    }
  }
});

export const { setOvflFiles } = ovflFilesSlice.actions;
export default ovflFilesSlice.reducer;
