import { useState } from "react";
// import { Radio } from "@mui/icons-material";
// import { TextField, Select, Autocomplete, ListItemText, MenuItem } from "@mui/material";
import { Paper, SelectChangeEvent, Dialog, Alert, AlertTitle, Button } from "@mui/material";
import { Radio, FormControl, FormGroup, FormControlLabel, FormLabel } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import dayjs, { Dayjs } from "dayjs";
import { Stack } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { contactData, FormValues } from "../../Data/ContactData";
import BeautifulTextField from "./FormSubComponents/BeautifulTextField";
import BeautifulAutocomplete from "./FormSubComponents/BeautifulAutocomplete";
import BeautifulSelect from "./FormSubComponents/BeautifulSelect";

//const skills = ["React", "Angular", "Python", "NodeJS", "Machine Learning"]; // "",
//const roles = ["Software Dev", "Architect", "Designer", "Business Analyst"];
export const minWidth = 300;
const defaultPreference = "Work From Home"
const today = new Date();

export default function ContactForm() {
  const getDefaultFormValues = () => ({
    id: contactData.length + 1, name: "", skills: ["React"], startDate:  //, role: ""
    `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`, preference: defaultPreference
  });
  const [formValues, setFormValues] = useState<FormValues>(getDefaultFormValues());
  // const [dateValue, setDateValue] = useState<Dayjs>(dayjs(`${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`));
  const [alertOpen, setAlertOpen] = useState(false);
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormValues({...formValues, [name]: value});
  };
  const handleAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    setFormValues({...formValues, role: value || ""});
  };
  const handleSelectChange = (event: SelectChangeEvent<string[]>, child: React.ReactNode) => {
    const { target: {value} } = event; // const { value } = event.target;
    setFormValues({...formValues, skills: typeof value === "string"? value.split(", "): value});
  };
  //const handleDatePickerChange = (PickerChangeHandler<dayjs.Dayjs | null, DateValidationError>) => {};
  const handleDatePickerChange = (value: Dayjs | null) => { //console.log(value);
    if(value) {
      const dt = value; //as unknown as {month: ()=>string, date: ()=>string, year: ()=>string};
      const startDate = `${dt.month() + 1}/${dt.date()}/${dt.year()}`;
      setFormValues({...formValues, startDate: startDate}); //value?.toString()
    }
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const { name } = event.target;
    setFormValues({...formValues, [name]: value});
  };
  const handleSubmit = () => {
    contactData.push(formValues); console.log(contactData);
    setAlertOpen(true); clearValues();
  };
  const handleClearClick = () => { clearValues(); };
  const clearValues = () => { setFormValues(getDefaultFormValues()); };
  const handleAlertClick = () => { setAlertOpen(false); };
  return (<>
    <Paper>
      <form>
        <FormControl>
          <FormGroup row sx={{padding: 2, justifyContent: "space-between"}}>
            {/* <TextField id="name" name="name" label="Name" variant="outlined"
              sx={{minWidth: minWidth, marginRight: 2}} value={formValues.name}
              onChange={handleTextFieldChange}
            /> */}
            <BeautifulTextField value={formValues.name}
              onChange={handleTextFieldChange}
            />
            {/* <Autocomplete options={roles} sx={{minWidth: minWidth}}
              renderInput={(params) => (<TextField name="role" {...params} />)}
              getOptionLabel={(roleOption) => `${roleOption}`}
              renderOption={(props,option) => (<li {...props}>{`${option}`}</li>)}
              value={formValues.role || ""}
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onInputChange={handleAutoCompleteChange}
            /> */}
            <BeautifulAutocomplete value={formValues.role || ""}
              onInputChange={handleAutoCompleteChange}
            />
          </FormGroup>
          <FormGroup row sx={{padding: 2, justifyContent: "space-between"}}>
            {/* <Select id="skill-select" renderValue={(selected: string[]) => selected.join(", ")}
            sx={{minWidth: minWidth, marginRight: 2}} value={formValues.skills || ""}
            onChange={handleSelectChange}>
              {skills.map(skillName => (
                <MenuItem value={skillName} key={skillName}>
                  <ListItemText primary={skillName} />
                </MenuItem>
              ))}
            </Select> */}
            <BeautifulSelect value={formValues.skills || ""} onChange={handleSelectChange} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date" input-format="MM/DD/YYYY" sx={{minWidth: minWidth}}
                // render-input={() => <TextField />}
                // value={formValues.startDate}
                // value={dayjs(dateValue)}
                // onChange={(newValue) => setDateValue(dayjs(newValue))}
                value={dayjs(formValues.startDate)}
                // onChange={newValue => setFormValues(oldValues => {
                //   const newValues = {...oldValues};
                //   newValues.startDate = newValue?.toString();
                //   return newValues;
                // })}
                onChange={handleDatePickerChange}
              />
            </LocalizationProvider>
          </FormGroup>
          <FormGroup row sx={{padding: 2, justifyContent: "space-between"}}>
            <FormGroup sx={{minWidth: minWidth, marginRight: 2}}>
              <FormLabel component="legend">Work Preference</FormLabel>
              <RadioGroup id="preference-type-radio" name="preference" value={formValues.preference}
              onChange={handleRadioChange}>
                <FormControlLabel control={<Radio />} label={defaultPreference} value={defaultPreference} />
                <FormControlLabel control={<Radio />} label="Hybrid" value="Hybrid" />
                <FormControlLabel control={<Radio />} label="In Office" value="In Office" />
              </RadioGroup>
            </FormGroup>
            <Stack>
              <Button onClick={handleSubmit}>Submit</Button>
              <Button onClick={handleClearClick}>Clear</Button>
            </Stack>
          </FormGroup>
        </FormControl>
      </form>
    </Paper>
    <Dialog open={alertOpen} onClose={handleAlertClick}>
      <Alert onClose={handleAlertClick}>
        <AlertTitle>Success!</AlertTitle>
        Form Submitted
      </Alert>
    </Dialog>
  </>);
};
