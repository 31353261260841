import { useState, useEffect, useRef } from "react";
import { Autocomplete, FormControl, FormGroup, Paper, Button } from "@mui/material";
import { TextField, SelectChangeEvent, Dialog, Alert, AlertTitle } from "@mui/material";
import { FormLabel, Select, MenuItem, ListItemText, InputAdornment } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Stack } from "@mui/system";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Company, FormValues, findValues, getBalances, getAnnexes, SfpData, PnlData, //SceData,
  getKeyNote, KeyNote, getSces, getSceTotal, getSfpBal, SceLine } from "../../Data/FinancialData";
import FinExcel, { getFinExData } from "../../Data/FinExcel";
import { commaFormat, negBracket, removeComma } from "../../Data/DataUtility";
import { months, refreshFinData } from "./FinancialPosition";

let btn1Handler = () => {}, btn2Handler = () => {};

interface IProps { //
  financialData: FormValues[],
  setFinancialData: React.Dispatch<React.SetStateAction<FormValues[]>>,
  companies: Company[], sfp: SfpData[], pnl: PnlData[], keyNotes: KeyNote[],
  currentId: string, setCurrentId: React.Dispatch<React.SetStateAction<string>>,
  getDefaultFormValues: (data: Array<FormValues>|null, id: string) => FormValues,
  formValues: FormValues, setFormValues: React.Dispatch<React.SetStateAction<FormValues>>,
  inputMode: number, setInputMode: React.Dispatch<React.SetStateAction<number>>,
  userEmail: string,
};

export default function EquityChanges({ financialData, setFinancialData, companies,
  currentId, setCurrentId, getDefaultFormValues, formValues, setFormValues,
  sfp, pnl, keyNotes, inputMode, setInputMode}: IProps) {

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMesg, setAlertMesg] = useState("");
  const [btn1Text, setBtn1Text] = useState("");  // Ok
  const [btn2Text, setBtn2Text] = useState("");  // Cancel
  const textRef = useRef<HTMLInputElement>();
  const autoRef = useRef<HTMLInputElement>();
  const dateRef = useRef<HTMLInputElement>();
  const [selName, setSelName] = useState("");
  const [selYear, setSelYear] = useState("");

  //const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { name, value } = event.target; //console.log("name:", name, ", value:", value);
    let fld = name, num = (value==="-"||value===""?0:parseFloat(removeComma(value))), pre = 0;
    let fldSet = false; const neg = ((value.substring(0,1)==="-" && value.length>1) || value==="0-");
    if(value.indexOf(".")>=0) {
      const intg = Math.floor(Math.abs(num));
      let dec = Math.abs(num) - intg; if(dec<0.001) dec = 0.1;
      num = (intg + dec) * (neg? -1: 1);
    }
    if(name==="name") { //console.log("handleTextFieldChange:: selName:", selName);
      if(value && selYear) { const found = findValues(value, selYear, financialData);
        if(found) { fldSet = true; setCurrentId(found.id);
          setFormValues(formValues => ({...found})); setInputMode(2); //Edit
        } else {
          const company = companies.find(comp => comp.name===value), sDt = new Date(selYear);
          if(company) { refreshFinData(company, sDt, getDefaultFormValues,
            setFinancialData, setFormValues, setCurrentId); setInputMode(2); //Edit
        } }
      } setSelName(value);
    } else { let flds = name.split("_"); fld = flds[0]; //console.log("flds:", flds);
      if(flds.length>1) { const i = parseInt(flds[1]);
        if(flds.length>2) { const li = parseInt(flds[2]);
          if(fld==="desc" || fld==="dsc1") {
            setFormValues(formValues => { let newValues = {...formValues};
              switch(fld) {
                case "desc" : newValues.sces[i].lines[li].desc = value; break;
                case "dsc1" : newValues.sce1[i].lines[li].desc = value; break;
              } //console.log("newValues:", newValues);
              return newValues;
            }); fldSet = true;
          } else
          if(!isNaN(num)) { //console.log("fld:", fld, "num:", num, ", i:", i, ", li:", li);
            if(fld==="cap2"||fld==="arr2"||fld==="dep2"||fld==="ern2") {
              setFormValues(formValues => { let newValues = {...formValues};
                const t2Line = getSceTotal(i, newValues, 2);
                switch(fld) {
                  case "cap2" : if(i>0) newValues.sce2[i].capital =
                    newValues.sce2[i-1].capital + t2Line.capital + (num - newValues.sce2[i].lines[li].capital);
                    newValues.sce2[i].lines[li].capital = num; break;
                  case "arr2" : if(i>0) newValues.sce2[i].arrear =
                    newValues.sce2[i-1].arrear + t2Line.arrear + (num - newValues.sce2[i].lines[li].arrear);
                    newValues.sce2[i].lines[li].arrear = num; break;
                  case "dep2" : if(i>0) newValues.sce2[i].deposit =
                    newValues.sce2[i-1].deposit + t2Line.deposit + (num - newValues.sce2[i].lines[li].deposit);
                    newValues.sce2[i].lines[li].deposit = num; break;
                  case "ern2" : if(i>0) newValues.sce2[i].earning =
                    newValues.sce2[i-1].earning + t2Line.earning + (num - newValues.sce2[i].lines[li].earning);
                    newValues.sce2[i].lines[li].earning = num; break;
                }
                return newValues;
              }); fldSet = true; pre = 2;
            } else
            if(fld==="cap1"||fld==="arr1"||fld==="dep1"||fld==="ern1") {
              setFormValues(formValues => { let newValues = {...formValues};
                //const t1Line = getSceTotal(i, newValues, 1);
                switch(fld) {
                  case "cap1": newValues.sce1[i].lines[li].capital = num; break;
                  case "arr1": newValues.sce1[i].lines[li].arrear  = num; break;
                  case "dep1": newValues.sce1[i].lines[li].deposit = num; break;
                  case "ern1": newValues.sce1[i].lines[li].earning = num; break;
                } //console.log("newValues:", newValues);
                return newValues;
              }); fldSet = true; pre = 1;
            } else
            if(fld==="cap"||fld==="arr"||fld==="dep"||fld==="ern") {
              setFormValues(formValues => { let newValues = {...formValues};
                //const t1Line = getSceTotal(i, newValues, 1);
                switch(fld) {
                  case "cap" : newValues.sces[i].lines[li].capital = num; break;
                  case "arr" : newValues.sces[i].lines[li].arrear  = num; break;
                  case "dep" : newValues.sces[i].lines[li].deposit = num; break;
                  case "ern" : newValues.sces[i].lines[li].earning = num; break;
                } //console.log("newValues:", newValues);
                return newValues;
              }); fldSet = true; pre = 0;
            }
        } } else
        if(!isNaN(num)) {
          if(fld==="tcap2"||fld==="tarr2"||fld==="tdep2"||fld==="tern2") {
            setFormValues(formValues => { let newValues = {...formValues};
              const t2Line = getSceTotal(i+1, newValues, 2);
              // T2Bcap_Old - T2Lcap_Old = num - T2Lcap_New
              // T2Lcap_New = T2Lcap_Old + num - T2Bcap_Old
              // 02Lcap_New + R2Lcap_Old = T2Lcap_Old + num - T2Bcap_Old
              // 02Lcap_New = T2Lcap_Old - R2Lcap_Old + num - T2Bcap_Old
              // 02Lcap_New = 02Lcap_Old + R2Lcap_Old - R2Lcap_Old + num - T2Bcap_Old
              // 02Lcap_New += num - T2Bcap_Old
              switch(fld) {
                case "tcap2": if(i<(newValues.sce2.length-1)) { //console.log("num:", num, ", t2Line:", t2Line);
                  //const capital = num + t2Line.capital; console.log("capital:", capital);
                  newValues.sce2[i+1].capital = num + t2Line.capital; }
                  newValues.sce2[i].lines[4].capital += (num - newValues.sce2[i].capital);
                  newValues.sce2[i].capital = num; break;
                case "tarr2": if(i<(newValues.sce2.length-1)) {
                  newValues.sce2[i+1].arrear = num + t2Line.arrear; }
                  newValues.sce2[i].lines[4].arrear += (num - newValues.sce2[i].arrear);
                  newValues.sce2[i].arrear = num; break;
                case "tdep2": if(i<(newValues.sce2.length-1)) {
                  newValues.sce2[i+1].deposit = num + t2Line.deposit; }
                  newValues.sce2[i].lines[4].deposit += (num - newValues.sce2[i].deposit);
                  newValues.sce2[i].deposit = num; break;
                case "tern2": if(i<(newValues.sce2.length-1)) {
                  newValues.sce2[i+1].earning = num + t2Line.earning; }
                  newValues.sce2[i].lines[4].earning += (num - newValues.sce2[i].earning);
                  newValues.sce2[i].earning = num; break;
              } //console.log("newValues.annex1:", newValues.annex1);
              return newValues;
            }); fldSet = true; pre = 2;
        } }
    } }
    if(!fldSet) setFormValues((formValues) => ({...formValues, [fld]: value}));
    //updateTotals(name, value);
  };

  const handleAutoCompleteChange = (fld: string, event: React.SyntheticEvent<Element, Event>,
    value: string | null) => { //console.log("fld:", fld, "value:", value, ", event:", event);
    if(value){ let index = -1, refrsh = false, sDt = new Date();
      if(fld==="name") { //console.log("selYear:", selYear, "compName:", value);
        if(selYear) { sDt = (new Date(selYear)); //console.log("sDt:", sDt);
          index = financialData.findIndex(values => { let retVal = false;
            const dt = new Date(values.yrendDate); //console.log("dt:", dt);
            retVal = (values.name===value && dt.getDate()===sDt.getDate() && dt.getMonth()===sDt.getMonth() && dt.getFullYear()===sDt.getFullYear());
            return retVal;
          }); refrsh = (index < 0);
        } else setTimeout(() => dateRef.current?.focus(), 100);
      }
      else if(fld==="yrendDate") { //console.log("selName:", selName, "finYear:", value);
        sDt = new Date(value); //console.log("sDt:", sDt);
        if(selName && sDt) {
          index = financialData.findIndex(values => { let retVal = false;
            const dt = new Date(values.yrendDate); //console.log("dt:", dt);
            retVal = (values.name===selName && dt.getDate()===sDt.getDate() && dt.getMonth()===sDt.getMonth() && dt.getFullYear()===sDt.getFullYear());
            return retVal;
          }); refrsh = (index < 0);
      } } else {
        //if(fld==="fsType") updateSbTests(fld, value);
        ///setFormValues((formValues) => ({...formValues, [fld]: value}));
      }
      if(index >= 0) {
        let compInfo = { ...financialData[index] }, id = compInfo.id;
        // compInfo.notes = getNotes(id, financialData);
        compInfo.balances = getBalances(id, financialData);
        compInfo.balance1 = getBalances(id, financialData, 1);
        compInfo.balance2 = getBalances(id, financialData, 2);
        compInfo.annexes = getAnnexes(id, financialData);
        compInfo.annex1 = getAnnexes(id, financialData, 1);
        compInfo.annex2 = getAnnexes(id, financialData, 2);
        compInfo.sces = getSces(id, financialData);
        compInfo.sce1 = getSces(id, financialData, 1);
        compInfo.sce2 = getSces(id, financialData, 2);
        setFormValues(compInfo); setCurrentId(compInfo.id); console.log("compInfo:", compInfo);
        //---------------------------------------------------------------------------------------//
        //---------------------------------------------------------------------------------------//
      }
      if(refrsh) { //const company = companies.find(comp => comp.name===selName); //index<0
        const company = companies.find(comp => comp.name===(fld==="name"?value:selName));
        //const yr = `${sDt.getDate()}-${months[sDt.getMonth()].substring(0,3)}-${sDt.getFullYear()}`;
        //console.log("selName:", selName, ", company:", company, ", yr:", yr);
        if(company) { refreshFinData(company, sDt,
          getDefaultFormValues, setFinancialData, setFormValues, setCurrentId);
          // const getFinValues = async () => {
          //   const url = '/api/fin-values/'+company.id+'?year='+yr; console.log("url:", url);
          //   const res = await fetch(url).then(response => { //console.log("response:", response);
          //     return response; //.json();
          //   });
          //   const resData = await res.json(); console.log("EquityChanges:: /api/fin-values/:compId?year=yr - resData:", resData);
          //   return resData; //.finData;
          // };
          // getFinValues().then(resData => { //console.log("resData.finData:", resData.finData);
          //   let data = resData.finData as Array<FormValues>, idSet = false;
          //   if(resData.id) { setCurrentId(resData.id); idSet = true; }
          //   setFinancialData(oldData => { const newData = [...oldData];
          //     data.forEach(values => {
          //       if(financialData.findIndex(f => f.id===values.id)<0) newData.push(values);
          //       if(!idSet) { const fDt = new Date(values.yrendDate);
          //         if(fDt.getDate()===sDt.getDate() && fDt.getMonth()===sDt.getMonth() &&
          //         fDt.getFullYear()===sDt.getFullYear()) { resData.id = values.id;
          //           setCurrentId(values.id); idSet = true;
          //       } }
          //     }); //console.log("newData:", newData);
          //     setFormValues(getDefaultFormValues(newData, resData.id));
          //     return newData;
          //   });
          // });
      } }
      setFormValues((formValues) => ({...formValues, [fld]: value}));
      //updateTotals(fld, value);
    } else {
      setFormValues(getDefaultFormValues(null,"")); setCurrentId("");
    }
    if(fld==="yrendDate") { if(value) setSelYear(value); else {setSelName(""); setSelYear("")}; }
    else if(fld==="name") { if(value) setSelName(value); else {setSelName(""); setSelYear("")}; }
  };

  const getCompanyNames = (yrendDate="") => { //console.log("getCompanyNames:: yrendDate:", yrendDate);
    const names: Array<string> = [""]; const yDt = yrendDate? (new Date(yrendDate)): null;
    financialData.forEach(values => {
      if(yDt) { const dt = new Date(values.yrendDate); //console.log("yDt:", yDt, ", dt:", dt);
        if(yDt.getFullYear()===dt.getFullYear() && yDt.getMonth()===dt.getMonth()
        && yDt.getDate()===dt.getDate() && !names.includes(values.name)) names.push(values.name)
      } else if(!names.includes(values.name)) names.push(values.name);
    }); //console.log("names:", names);
    return names;
  };
  const getCompNames = () => {
    const names: Array<string> = [""]; //const yDt = yrendDate? (new Date(yrendDate)): null;
    companies.forEach(comp => {
      // if(yDt) { const dt = new Date(values.yrendDate); //console.log("yDt:", yDt, ", dt:", dt);
      //   if(yDt.getFullYear()===dt.getFullYear() && yDt.getMonth()===dt.getMonth()
      //   && yDt.getDate()===dt.getDate() && !names.includes(values.name)) names.push(values.name)
      // } else
      if(!names.includes(comp.name)) names.push(comp.name);
    }); //console.log("names:", names);
    return names;
  };

  // const getFinancialYears = (name="") => { //console.log("getFinancialYears:: name:", name);
  //   const finYears: Array<string> = [""];
  //   financialData.forEach(values => { const fDt = new Date(values.yrendDate);
  //     const finYear = `${fDt.getDate()} ${months[fDt.getMonth()]} ${fDt.getFullYear()}`;
  //     if((!name || name === values.name) && !finYears.includes(finYear)) finYears.push(finYear);
  //   }); //console.log("finYears:", finYears);
  //   return finYears;
  // };
  const getFinYears = (name="") => { //console.log("getFinYears:: name:", name);
    const finYears: Array<string> = [""];
    financialData.forEach(values => { const fDt = new Date(values.yrendDate);
      const finYear = `${fDt.getDate()} ${months[fDt.getMonth()]} ${fDt.getFullYear()}`;
      if((name === values.name) && !finYears.includes(finYear)) finYears.push(finYear);
    }); finYears.sort(); finYears.reverse(); //console.log("finYears:", finYears);
    if(name && finYears.length<2) { //console.log("getFinYears:: companies:", companies);
      const company = companies.find(comp => comp.name===name); //console.log("getFinYears:: company:", company);
      if(company) {
        const getYears = async () => {
          const res = await fetch('/api/fin-years/'+company.id).then(response => { //console.log("response:", response);
            return response; //.json();
          });
          const resData = await res.json(); //console.log("Notes:: /api/fin-years/:compId - resData:", resData);
          return resData.years;
        };
        getYears().then(years => { //console.log("years:", years);
          let yrs = years as Array<string>;
          yrs.forEach(yr => { //console.log("yr:", yr, (typeof yr));
            const yDt = new Date(yr); //console.log("yDt:", yDt, (typeof yDt));
            const finYear = `${yDt.getDate()} ${months[yDt.getMonth()]} ${yDt.getFullYear()}`;
            if(!finYears.includes(finYear)) finYears.push(finYear);
          }); finYears.sort(); finYears.reverse(); //console.log("finYears:", finYears);
        });
    } } //console.log("getFinYears:: finYears:", finYears);
    return finYears;
  };

  const handleDatePickerChange = (value: Dayjs | null) => { //console.log(value);
    if(value) {
      const dt = value; //as unknown as {month: ()=>string, date: ()=>string, year: ()=>string};
      const yrendDate = `${dt.month() + 1}/${dt.date()}/${dt.year()}`;
      // setFormValues({...formValues, yrendDate: yrendDate}); //value?.toString()
      setFormValues((formValues) => ({...formValues, yrendDate: yrendDate}));
      if(selName && dt) {
        console.log("Trying to find selName:", selName, ", dt:", dt, " in financialData:", financialData);
        const found = findValues(selName, dt.toString(), financialData); console.log("found:", found);
        if(found) { setCurrentId(found.id);
          setFormValues(formValues => ({...found})); setInputMode(2); //Edit
        } else {
          const company = companies.find(comp => comp.name===selName);
          if(company) { refreshFinData(company, (new Date(dt.toString())), getDefaultFormValues,
            setFinancialData, setFormValues, setCurrentId); setInputMode(2); //Edit
          }
      } } setSelYear(dt.toString());
    } else setSelYear("");
  };

  const handleSubmit = async () => {
    const dataPack = {...formValues }; console.log("dataPack:", dataPack);
    try {
      let hasError = false;
      const res = await fetch('/api/financial-data', { // /api/company-data
        method: 'POST', body: JSON.stringify(dataPack),
        headers: {'Content-Type': 'application/json'}
      });
      if (!res.ok) hasError = true;
      const resData = await res.json();
      if (hasError) throw new Error(resData.message);
      const financialInfo = resData.financialInfo;  console.log("rersData:", resData);
      setFinancialData((prevData: Array<FormValues>) => {
        const newData = prevData.map((data: FormValues) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === financialInfo.id) { index = i; return true; } return false;
        });
        newData[index] = financialInfo; //console.log("newData:", newData); // Update existing record
        setFormValues(financialInfo); setCurrentId(financialInfo.id); console.log("financialInfo:", financialInfo);
        return newData;
      });

      //console.log("autoRef.current:", autoRef.current);
      //console.log("autoRef.current?.focus:", autoRef.current?.focus);
      setInputMode(0); autoRef.current?.focus();
      setAlertMesg("Form Saved"); setBtn1Text("OK");
      btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      //setTimeout(() => autoRef.current?.focus(), 100); //clearValues();
      //setTimeout(() => autoRef?.focus(), 100);
    } catch (error: any) {
      alert(error.message || 'Something went wrong!');
    }
  };

  const handleClearClick = () => { clearValues(); if(inputMode>0) setInputMode(0); };
  const clearValues = () => {
    const id = currentId, values = getDefaultFormValues(null, id);
    //console.log("currentId:", currentId, ", documentData:", documentData);
    //setFormValues(getDefaultFormValues(null, (inputMode===1? "": currentId)));
    setFormValues(values); setSelName(""); setSelYear("");
  };
  const handleAddNew = () => { setInputMode(1);
    const id = "", values = getDefaultFormValues(null, id); //console.log("AddNew:: values:", values);
    setFormValues(values); setSelName(""); setSelYear("");
    setTimeout(() => textRef.current?.focus(), 100); //console.log("currentId:", currentId);
  };
  const handleEdit = () => { setInputMode(2); setTimeout(() => textRef.current?.focus(), 100); };
  const handleAlertClick = () => { setAlertOpen(false); };

  const deleteId = async () => {
    return fetch('/api/financial-data/'+formValues.id, { // /api/company-data/
        method: 'DELETE', body: JSON.stringify(formValues),
        headers: {'Content-Type': 'application/json'}
    }).then(response => { //console.log("response:", response);
      return response; //.json();
    });
  };
  const handleDelete = () => {
    setAlertMesg("Do you want to delete the company " + formValues.name);
    btn1Handler = handleConfirmDelete; //console.log("handleDelete:: btn1Handler is set.");
    btn2Handler = () => { handleClearClick(); handleAlertClick(); };
    setBtn1Text("Yes"); setBtn2Text("No"); setAlertOpen(true);
  }
  const handleConfirmDelete = () => {
    deleteId().then((json) => { console.log("handleDelete:: json:", json);
      // handle success
      let index = -1, id = ""; const deleteId = formValues.id;
      const filteredData = financialData.filter((values, i) => {
       if (values.id === deleteId) { index = i; return false; } return true;
      }); setFinancialData(filteredData);
      if(index >= filteredData.length) index -= 1;
      if(index >= 0) id = filteredData[index].id; setCurrentId(id);
      setFormValues(getDefaultFormValues(filteredData, id));
//
      setInputMode(0); setTimeout(() => { autoRef.current?.focus();
        setAlertMesg("Deleted company info"); setBtn1Text("OK");
        btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      }, 100);
    })
    .catch(error => console.log("handleDelete:: error:", error));
  };

  const periods = [
    "for the year ended"  , "for the year ending",
    "for the period ended", "for the period ending",
  ];

  let x: any, t: SceLine, t1: SceLine, t2: SceLine; //console.log("inputMode:", inputMode, ", currentId:", currentId, currentId.length);
  const d = formValues.yrendDate? (new Date(formValues.yrendDate)): null; //console.log("formValues:", formValues);
  const d1 = d?(new Date(d)):null; if(d1){d1.setDate(d1.getDate()+1); d1.setFullYear(d1.getFullYear()-1);}
  let gtot=0, gtot1=0;

  return (<>
    <Paper>
      <form>
        <div style={{ width:"75vw", height:"10vh", position:"fixed", zIndex:"99", backgroundColor:"#fff" }}>{/*68vw*/}
          {/* <h3 style={{ width:"12vw", textAlign:"center", display:"inline-block", verticalAlign:"middle" }}>Financial Position</h3> */}
          <div style={{ width:"12vw", textAlign:"center", display:"inline-block", verticalAlign:"middle" }}>{ currentId.length>4?
            <FinExcel excelSheets={getFinExData(formValues, sfp, pnl, keyNotes)}
            fileName="Excel Export" disabled={inputMode>0}>Download</FinExcel>: null }
          </div>
          <div style={{ width:"45vw", display:"inline-block", verticalAlign:"middle" }}>{/*43vw | 10vw*/}
            {/* ------------------------------------------------------------------------------------ */}
            <FormControl sx={{paddingTop: 1}}>
              <FormGroup row sx={{justifyContent: "space-between"}}>
                <FormGroup sx={{width:"6vw", alignItems:"start"}}>
                  <Stack>
                    <Button onClick={handleDelete} sx={{paddingLeft:3}}
                      disabled={inputMode<2}>Delete</Button>
                  </Stack>
                </FormGroup>
                <FormLabel sx={{paddingLeft: 1, width:"14vw"}}></FormLabel>
                <FormGroup sx={{width:"6vw", paddingLeft: 1, alignItems: "start"}}>
                  <Stack>
                    <Button onClick={handleAddNew} disabled={inputMode>0}>Add</Button>
                    <Button onClick={handleEdit} sx={{paddingTop:0}}
                      disabled={inputMode>0||currentId.length<4}>Edit</Button>
                  </Stack>
                </FormGroup>
                <FormGroup sx={{width:"19vw", paddingRight:2, alignItems:"end"}}>{/*17vw*/}
                  <Stack>
                    <Button onClick={handleSubmit} disabled={inputMode<1}>Save</Button>
                    <Button onClick={handleClearClick} sx={{paddingTop:0}}
                      disabled={inputMode<1}>{inputMode>0? "Cancel": "Clear"}</Button>
                  </Stack>
                </FormGroup>
              </FormGroup>
            </FormControl>
            {/* ------------------------------------------------------------------------------------ */}
          </div>
          <div style={{ width:"18vw", display:"inline-block", verticalAlign:"middle" }}>{/* width:"15vw" */}
            <FormControl sx={{paddingLeft: 2}}>
              <FormLabel sx={{width:"14.3vw", padding:1, paddingRight:0, //, border:1, fontStyle:"italic", fontWeight:"bold" //13.3vw
                 textAlign:"center", color:"#000"}}>{/*&nbsp;*/}<h3>Changes in Equity</h3>
              </FormLabel>
            </FormControl>
          </div>
        </div>
        <FormControl sx={{paddingTop: 1, marginTop: "10vh"}}>
          <FormGroup row sx={{paddingTop: (inputMode<1? 1: 0), justifyContent: "space-between"}}>
            <Autocomplete options={getCompNames()} sx={{width: "57.5vw", display: (inputMode<1?
              "block": "none")}} renderInput={(params) => (<TextField  // 52.5vw
                inputRef={autoRef} name="name" {...params} autoFocus label={selName?"":"Company Name"}
              />)} getOptionLabel={(option) => `${option}`}
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)}
              value={formValues.name || ""} disablePortal size="small"
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange.bind(null, "name")}  // onInputChange
            />{/*14.75vw*/}
            {/* <FormLabel sx={{width:"16.75vw", textAlign:"center", display: (inputMode<1? "block": "none")}}><h3>{/*Financial Position* /}</h3></FormLabel> */}
            <Autocomplete options={getFinYears(selName)} sx={{width: "16.75vw", display: (inputMode<1? "block": "none")}}
              renderInput={(params) => (<TextField inputRef={dateRef} name="yrendDate" {...params} label={selYear?"":"As At Date"} />)}
              getOptionLabel={(option) => `${option}`} disablePortal disabled={inputMode>0} //14.75vw
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)} size="small"
              value={(formValues.yrendDate?(x=new Date(formValues.yrendDate), `${x.getDate()} ${months[x.getMonth()]} ${x.getFullYear()}`):"")} // || ""
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange.bind(null, "yrendDate")} />
          </FormGroup>
          <FormGroup row sx={{paddingTop: (inputMode<1? 0: 1), justifyContent: "space-between", height: (inputMode<1? 0: 1)}}>
            <TextField id="name" name="name" variant="outlined" size="small"  // label="Name of Company"
              sx={{width: "57.5vw", visibility: (inputMode<1? "hidden": "visible")}} //"52.5vw"
              value={formValues.name} autoFocus disabled={inputMode<1}
              onChange={handleTextFieldChange} inputRef={textRef}
            />{/*14.75vw*/}
            {/* <FormLabel sx={{width:"16.75vw", textAlign:"center", visibility:(inputMode<1?"hidden":"visible")}}><h3>{/*Financial Position* /}</h3></FormLabel> */}
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker format="DD/MM/YYYY" sx={{width: "16.75vw", visibility:(inputMode<1?"hidden":"visible")}}  // label="Date"
                value={dayjs(formValues.yrendDate)} onChange={handleDatePickerChange} //14.75
                slotProps={{ textField: {size: "small"} }} disabled={inputMode<1} />
            </LocalizationProvider>
          </FormGroup>
        </FormControl>

        <div style={{display: (currentId.length>4 ||
          (inputMode>0 && formValues.name && formValues.yrendDate)? "block": "none")}}>
          <FormControl sx={{paddingTop: 0}}>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              {inputMode>0? <><FormLabel sx={{width:"5.35vw", paddingTop: 1, paddingLeft: 2, textAlign: "left"}}>
                Address</FormLabel>
              <TextField id="address" name="address" variant="outlined" size="small"
                sx={{width: "68.85vw"}} value={formValues.address} disabled={inputMode<1}
                onChange={handleTextFieldChange} //59.7vw
              /></>:
              <FormLabel sx={{width:"74.2vw", paddingTop:1, paddingBottom:1, paddingLeft:2}}>{formValues.address}</FormLabel>}
              <FormLabel sx={{width:"0.55vw"}}></FormLabel>{/*2.7vw*/}
            </FormGroup>
          </FormControl>

          <FormControl sx={{paddingTop: 0}}>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"0.5vw"}}></FormLabel>
              <FormLabel sx={{width:"36.25vw", borderTop: 1, fontWeight:"bold",fontSize:"110%"
                }}>Statement of changes in equity</FormLabel>
              <FormLabel sx={{width:"11vw", borderTop: 1}}></FormLabel>
              <FormLabel sx={{width:"27vw", borderTop: 1}}></FormLabel>
            </FormGroup>
          </FormControl>

          <FormControl sx={{paddingTop: 0}}>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"0.5vw"}}></FormLabel>
              <FormLabel sx={{width:"32.9vw"}}>As at {(d? //{/*18.15vw*/}
                `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`:"")}</FormLabel>
              <FormLabel sx={{width:"14.35vw"}}></FormLabel>{/*14.75vw*/}
              <FormLabel sx={{width:"14vw"}}></FormLabel>
              <FormLabel sx={{width:"13vw", paddingTop: 1, paddingRight: 1, textAlign: "right"}}>
                {formValues.curr}</FormLabel>
            </FormGroup>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"19.9vw"}}></FormLabel>{/*18.15vw*/}
              <FormLabel sx={{width:"12.85vw"}}></FormLabel>{/*14.75vw*/}
              <FormLabel sx={{width:"8vw", paddingTop: 0, borderLeft: 1, borderTop:1, textAlign: "center"}}>
                Share</FormLabel>
              <FormLabel sx={{width:"7.5vw", paddingTop: 0, borderLeft: 1, borderTop:1, textAlign: "center"}}>
                Calls-in-</FormLabel>
              <FormLabel sx={{width:"8.5vw", paddingTop: 0, borderLeft: 1, borderTop:1, textAlign: "center"}}>
                Share Money</FormLabel>
              <FormLabel sx={{width:"8vw", paddingTop: 0, borderLeft: 1, borderTop:1, borderRight:1, textAlign: "center"}}>
                Retained</FormLabel>
              <FormLabel sx={{width:"0.5vw", borderTop:1}}></FormLabel>
              <FormLabel sx={{width:"9.5vw", borderLeft: 1, borderTop:1, borderRight:1}}></FormLabel>
            </FormGroup>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"19.9vw"}}></FormLabel>{/*18.15vw*/}
              <FormLabel sx={{width:"12.85vw"}}></FormLabel>{/*14.75vw*/}
              <FormLabel sx={{width:"8vw", paddingTop: 0, borderLeft: 1, borderBottom:1, textAlign: "center"}}>
                capital</FormLabel>
              <FormLabel sx={{width:"7.5vw", paddingTop: 0, borderLeft: 1, borderBottom:1, textAlign: "center"}}>
                arrear</FormLabel>
              <FormLabel sx={{width:"8.5vw", paddingTop: 0, borderLeft: 1, borderBottom:1, textAlign: "center"}}>
                deposit</FormLabel>
              <FormLabel sx={{width:"8vw", paddingTop: 0, borderLeft: 1, borderBottom:1, borderRight:1, textAlign: "center"}}>
                earnings</FormLabel>
              <FormLabel sx={{width:"0.5vw", borderBottom:1}}></FormLabel>
              <FormLabel sx={{width:"9.5vw", paddingTop: 0, borderLeft: 1, borderBottom:1, borderRight:1, textAlign: "center"}}>
                Total equity</FormLabel>
            </FormGroup>
          </FormControl>

          <FormControl sx={{paddingTop: 0}}>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"19.9vw", borderBottom: 1}}></FormLabel>{/*18.15vw*/}
              <FormLabel sx={{width:"16.5vw", borderBottom: 1}}></FormLabel>{/*14.75vw*/}
              <FormLabel sx={{width:"38.35vw", borderBottom:1}}>&nbsp;</FormLabel>
            </FormGroup>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"74.75vw"}}>&nbsp;</FormLabel>
            </FormGroup>
          </FormControl>

          {formValues.sce2 && formValues.sce2.map((sec, i) => { //console.log("sec:", sec);
            let cap=0, arr=0, dep=0, ern=0, bal=0; //gtot=0, gtot1=0;
            return <FormControl sx={{paddingTop: 0}} key={i}>
              {sec.type==="R" && sec.lines && sec.lines.map((line,li) => {
                return <FormGroup row sx={{justifyContent: "space-between"}} key={li}>
                  <FormLabel sx={{width:"1.25vw"}}></FormLabel>
                  <FormLabel sx={{width:"31.5vw", paddingTop:1}}>{line.desc}</FormLabel>
                  {inputMode<1?<FormLabel sx={{width:"8vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(cap=line.capital,negBracket(cap))}</FormLabel>:
                  <TextField id="cap2" name={`cap2_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "8vw"}}
                    value={(cap=line.capital,commaFormat(cap))} onChange={handleTextFieldChange} />}
                  {inputMode<1?<FormLabel sx={{width:"7.5vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(arr=line.arrear,negBracket(arr))}</FormLabel>:
                  <TextField id="arr2" name={`arr2_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "7.5vw"}}
                    value={(arr=line.arrear,commaFormat(arr))} onChange={handleTextFieldChange} />}
                  {inputMode<1?<FormLabel sx={{width:"8.5vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(dep=line.deposit,negBracket(dep))}</FormLabel>:
                  <TextField id="dep2" name={`dep2_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "8.5vw"}}
                    value={(dep=line.deposit,commaFormat(dep))} onChange={handleTextFieldChange} />}
                  {inputMode<1?<FormLabel sx={{width:"8vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(ern=line.earning,negBracket(ern))}</FormLabel>:
                  <TextField id="ern2" name={`ern2_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "8vw"}}
                    value={(ern=line.earning,commaFormat(ern))} onChange={handleTextFieldChange} />}
                  <FormLabel sx={{width:"0.5vw"}}></FormLabel>
                  <FormLabel sx={{width:"9.5vw", paddingTop:1, paddingRight:1,
                    textAlign:"right"}}>{(bal=cap+arr+dep+ern,negBracket(bal))}</FormLabel>
                </FormGroup>;
              })}
              <FormGroup row sx={{justifyContent: "space-between"}}>
                <FormLabel sx={{width:"1.25vw"}}></FormLabel>
                <FormLabel sx={{width:"31.5vw", borderTop:(sec.type==="R"?1:0), paddingTop:1, fontWeight: "bold", fontSize:"103%"}}>
                  Balance at {d1? `${d1.getDate().toString().length<2?'0':''}${d1.getDate()} ${months[d1.getMonth()]} ${d1.getFullYear()-1}`
                  : ""}{sec.type==="R"? " - Restated": ""}</FormLabel>
                {inputMode<1||sec.type==="R"?<FormLabel sx={{width:"8vw", borderTop:(sec.type==="R"?1:0),
                  paddingRight:1, paddingTop:1, textAlign:"right"}}>{(cap=sec.capital,negBracket(cap))}</FormLabel>:
                <TextField id="tcap2" name={`tcap2_${i}`} variant="outlined" size="small"
                  disabled={inputMode<1} sx={{width: "8vw", borderTop:(sec.type==="R"?1:0)}}
                  value={(cap=sec.capital,commaFormat(cap))} onChange={handleTextFieldChange} />}
                {inputMode<1||sec.type==="R"?<FormLabel sx={{width:"7.5vw", borderTop:(sec.type==="R"?1:0),
                  paddingRight:1, paddingTop:1, textAlign:"right"}}>{(arr=sec.arrear,negBracket(arr))}</FormLabel>:
                <TextField id="tarr2" name={`tarr2_${i}`} variant="outlined" size="small"
                  disabled={inputMode<1} sx={{width: "7.5vw", borderTop:(sec.type==="R"?1:0)}}
                  value={(arr=sec.arrear,commaFormat(arr))} onChange={handleTextFieldChange} />}
                {inputMode<1||sec.type==="R"?<FormLabel sx={{width:"8.5vw", borderTop:(sec.type==="R"?1:0),
                  paddingRight:1, paddingTop:1, textAlign:"right"}}>{(dep=sec.deposit,negBracket(dep))}</FormLabel>:
                <TextField id="tdep2" name={`tdep2_${i}`} variant="outlined" size="small"
                  disabled={inputMode<1} sx={{width: "8.5vw", borderTop:(sec.type==="R"?1:0)}}
                  value={(dep=sec.deposit,commaFormat(dep))} onChange={handleTextFieldChange} />}
                {inputMode<1||sec.type==="R"?<FormLabel sx={{width:"8vw", borderTop:(sec.type==="R"?1:0),
                  paddingRight:1, paddingTop:1, textAlign:"right"}}>{(ern=sec.earning,negBracket(ern))}</FormLabel>:
                <TextField id="tern2" name={`tern2_${i}`} variant="outlined" size="small"
                  disabled={inputMode<1} sx={{width: "8vw", borderTop:(sec.type==="R"?1:0)}}
                  value={(ern=sec.earning,commaFormat(ern))} onChange={handleTextFieldChange} />}
                <FormLabel sx={{width:"0.5vw", borderTop:(sec.type==="R"?1:0)}}></FormLabel>
                <FormLabel sx={{width:"9.5vw", borderTop:(sec.type==="R"?1:0), paddingTop:1,
                  paddingRight:1, textAlign:"right"}}>{(bal=cap+arr+dep+ern,negBracket(bal))}</FormLabel>
              </FormGroup>
            </FormControl>;
          })}
          {formValues.sce1 && formValues.sce1.map((sec, i) => { //console.log("sec:", sec);
            let cap=0, arr=0, dep=0, ern=0, bal=0; //gtot=0, gtot1=0;
            return <FormControl sx={{paddingTop: 0}} key={i}>
              {sec.type==="B" && sec.lines && sec.lines.map((line,li) => {
                return <FormGroup row sx={{justifyContent: "space-between"}} key={li}>
                  <FormLabel sx={{width:"1.25vw"}}></FormLabel>
                  {inputMode<1||(line.link && line.desc==="TI")?<FormLabel sx={{width:"31.5vw",
                    paddingTop:1}}>{line.link && line.desc==="TI"?"Total comprehensive income for the year ended "+
                    (d? `${d.getDate().toString().length<2?'0':''}${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()-1}`
                    : ""):line.desc}</FormLabel>:
                  <TextField id="dsc1" name={`dsc1_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "31.5vw"}}
                    value={line.desc} onChange={handleTextFieldChange} />}
                  {inputMode<1||(line.link && line.desc==="TI")?<FormLabel sx={{width:"8vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(cap=line.capital,negBracket(cap))}</FormLabel>:
                  <TextField id="cap1" name={`cap1_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "8vw"}}
                    value={(cap=line.capital,commaFormat(cap))} onChange={handleTextFieldChange} />}
                  {inputMode<1||(line.link && line.desc==="TI")?<FormLabel sx={{width:"7.5vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(arr=line.arrear,negBracket(arr))}</FormLabel>:
                  <TextField id="arr1" name={`arr1_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "7.5vw"}}
                    value={(arr=line.arrear,commaFormat(arr))} onChange={handleTextFieldChange} />}
                  {inputMode<1||(line.link && line.desc==="TI")?<FormLabel sx={{width:"8.5vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(dep=line.deposit,negBracket(dep))}</FormLabel>:
                  <TextField id="dep1" name={`dep1_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "8.5vw"}}
                    value={(dep=line.deposit,commaFormat(dep))} onChange={handleTextFieldChange} />}
                  {inputMode<1||(line.link && line.desc==="TI")?<FormLabel sx={{width:"8vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(ern=(line.link?getSfpBal(line.link,formValues,1):line.earning),
                    line.earning=ern,negBracket(ern))}</FormLabel>:
                  <TextField id="ern1" name={`ern1_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "8vw"}}
                    value={(ern=line.earning,commaFormat(ern))} onChange={handleTextFieldChange} />}
                  <FormLabel sx={{width:"0.5vw"}}></FormLabel>
                  <FormLabel sx={{width:"9.5vw", paddingTop:1, paddingRight:1,
                    textAlign:"right"}}>{(bal=cap+arr+dep+ern,negBracket(bal))}</FormLabel>
                </FormGroup>;
              })}
              {sec.type==="B"?<FormGroup row sx={{justifyContent: "space-between"}}>
                <FormLabel sx={{width:"1.25vw", borderTop:1, paddingBottom:2}}>&nbsp;</FormLabel>
                <FormLabel sx={{width:"31.5vw", borderTop:1, paddingTop:1, fontWeight: "bold", fontSize:"103%"}}>
                  Balance at {d? `${d.getDate().toString().length<2?'0':''}${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()-1}`
                  : ""}</FormLabel>
                <FormLabel sx={{width:"8vw", borderTop:1, paddingRight:1, paddingTop:1,
                  textAlign:"right"}}>{(t1=getSceTotal(i,formValues,1),cap=(formValues.sce2[1].
                  capital+t1.capital),formValues.sce1[0].capital=cap,negBracket(cap))}</FormLabel>
                <FormLabel sx={{width:"7.5vw", borderTop:1,
                  paddingRight:1, paddingTop:1, textAlign:"right"}}>{(arr=(formValues.sce2[1].
                  arrear+t1.arrear),formValues.sce1[0].arrear=arr,negBracket(arr))}</FormLabel>
                <FormLabel sx={{width:"8.5vw", borderTop:1,
                  paddingRight:1, paddingTop:1, textAlign:"right"}}>{(dep=(formValues.sce2[1].
                  deposit+t1.deposit),formValues.sce1[0].deposit=dep,negBracket(dep))}</FormLabel>
                <FormLabel sx={{width:"8vw", borderTop:1,
                  paddingRight:1, paddingTop:1, textAlign:"right"}}>{(ern=(formValues.sce2[1].
                  earning+t1.earning),formValues.sce1[0].earning=ern,negBracket(ern))}</FormLabel>
                <FormLabel sx={{width:"0.5vw", borderTop:1}}></FormLabel>
                <FormLabel sx={{width:"9.5vw", borderTop:1, paddingTop:1, paddingRight:1,
                  textAlign:"right"}}>{(bal=cap+arr+dep+ern,negBracket(bal))}</FormLabel>
              </FormGroup>:null}
              {sec.type==="B"?<FormGroup row sx={{justifyContent: "space-between"}}>
                <FormLabel sx={{width:"56.75vw", borderTop:2, paddingTop:1, paddingRight:1,
                  textAlign:"right"}}>{(bal=getSfpBal(sec.link,formValues,1),
                  x=(bal-(cap+arr+dep+ern)),Math.abs(x)>0.5?"Wrong":"\u00A0")}</FormLabel>
                <FormLabel sx={{width:"8vw", borderTop:2, paddingTop:1, paddingRight:1,
                  textAlign:"right"}}>{(Math.abs(x)>0.5?negBracket(x):"\u00A0")}</FormLabel>
                <FormLabel sx={{width:"10vw", borderTop:2, paddingTop:1, paddingRight:1,
                  textAlign:"right"}}>{(Math.abs(x)>0.5?negBracket(bal):"")}</FormLabel>
              </FormGroup>:null}
              {sec.type==="B"?<FormGroup row sx={{justifyContent: "space-between"}}>
                <FormLabel sx={{width:"1.25vw", paddingBottom:0}}>&nbsp;</FormLabel>
                <FormLabel sx={{width:"31.5vw", paddingTop:1, fontWeight: "bold", fontSize:"103%"}}>
                  Balance at {d1? `${d1.getDate().toString().length<2?'0':''}${d1.getDate()} ${months[d1.getMonth()]} ${d1.getFullYear()}`
                  : ""}</FormLabel>
                <FormLabel sx={{width:"8vw", paddingRight:1, paddingTop:1, textAlign:"right"}}>
                  {(cap=formValues.sce1[0].capital,negBracket(cap))}</FormLabel>
                <FormLabel sx={{width:"7.5vw", paddingRight:1, paddingTop:1, textAlign:"right"}}>
                  {(arr=formValues.sce1[0].arrear,negBracket(arr))}</FormLabel>
                <FormLabel sx={{width:"8.5vw", paddingRight:1, paddingTop:1, textAlign:"right"}}>
                  {(dep=formValues.sce1[0].deposit,negBracket(dep))}</FormLabel>
                <FormLabel sx={{width:"8vw", paddingRight:1, paddingTop:1, textAlign:"right"}}>
                  {(ern=formValues.sce1[0].earning,negBracket(ern))}</FormLabel>
                <FormLabel sx={{width:"0.5vw"}}></FormLabel>
                <FormLabel sx={{width:"9.5vw", paddingTop:1, paddingRight:1, textAlign:"right"}}>
                  {(//bal=cap+arr+dep+ern, //,console.log("sec.link:",sec.link,", bal:",bal)
                    bal=getSfpBal(sec.link,formValues,1),negBracket(bal))}</FormLabel>
              </FormGroup>:null}
            </FormControl>;
          })}
          {formValues.sces && formValues.sces.map((sec, i) => { //console.log("sec:", sec);
            let cap=0, arr=0, dep=0, ern=0, bal=0;
            return <FormControl sx={{paddingTop: 0}} key={i}>
              {sec.type==="B" && sec.lines && sec.lines.map((line,li) => {
                return <FormGroup row sx={{justifyContent: "space-between"}} key={li}>
                  <FormLabel sx={{width:"1.25vw"}}></FormLabel>
                  {inputMode<1||(line.link && line.desc==="TI")?<FormLabel sx={{width:"31.5vw",
                    paddingTop:1}}>{line.link && line.desc==="TI"?"Total comprehensive income for the year ended "+
                    (d? `${d.getDate().toString().length<2?'0':''}${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`
                    : ""):line.desc}</FormLabel>:
                  <TextField id="desc" name={`desc_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "31.5vw"}}
                    value={line.desc} onChange={handleTextFieldChange} />}
                  {inputMode<1||(line.link && line.desc==="TI")?<FormLabel sx={{width:"8vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(cap=line.capital,negBracket(cap))}</FormLabel>:
                  <TextField id="cap" name={`cap_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "8vw"}}
                    value={(cap=line.capital,commaFormat(cap))} onChange={handleTextFieldChange} />}
                  {inputMode<1||(line.link && line.desc==="TI")?<FormLabel sx={{width:"7.5vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(arr=line.arrear,negBracket(arr))}</FormLabel>:
                  <TextField id="arr" name={`arr_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "7.5vw"}}
                    value={(arr=line.arrear,commaFormat(arr))} onChange={handleTextFieldChange} />}
                  {inputMode<1||(line.link && line.desc==="TI")?<FormLabel sx={{width:"8.5vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(dep=line.deposit,negBracket(dep))}</FormLabel>:
                  <TextField id="dep" name={`dep_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "8.5vw"}}
                    value={(dep=line.deposit,commaFormat(dep))} onChange={handleTextFieldChange} />}
                  {inputMode<1||(line.link && line.desc==="TI")?<FormLabel sx={{width:"8vw", paddingRight:1, paddingTop:1,
                    textAlign:"right"}}>{(ern=(line.link?getSfpBal(line.link,formValues):line.earning),
                    line.earning=ern,negBracket(ern))}</FormLabel>:
                  <TextField id="ern" name={`ern_${i}_${li}`} variant="outlined" size="small"
                    disabled={inputMode<1} sx={{width: "8vw"}}
                    value={(ern=line.earning,commaFormat(ern))} onChange={handleTextFieldChange} />}
                  <FormLabel sx={{width:"0.5vw"}}></FormLabel>
                  <FormLabel sx={{width:"9.5vw", paddingTop:1, paddingRight:1,
                    textAlign:"right"}}>{(bal=cap+arr+dep+ern,negBracket(bal))}</FormLabel>
                </FormGroup>;
              })}
              {sec.type==="B"?<FormGroup row sx={{justifyContent: "space-between"}}>
                <FormLabel sx={{width:"1.25vw", borderTop:1, paddingBottom:2}}>&nbsp;</FormLabel>
                <FormLabel sx={{width:"31.5vw", borderTop:1, paddingTop:1, fontWeight: "bold", fontSize:"103%"}}>
                  Balance at {d? `${d.getDate().toString().length<2?'0':''}${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`
                  : ""}</FormLabel>
                <FormLabel sx={{width:"8vw", borderTop:1, paddingRight:1, paddingTop:1,
                  textAlign:"right"}}>{(t=getSceTotal(i,formValues),cap=(formValues.sce1[0].
                  capital+t.capital),formValues.sces[0].capital=cap,negBracket(cap))}</FormLabel>
                <FormLabel sx={{width:"7.5vw", borderTop:1,
                  paddingRight:1, paddingTop:1, textAlign:"right"}}>{(arr=(formValues.sce1[0].
                  arrear+t.arrear),formValues.sces[0].arrear=arr,negBracket(arr))}</FormLabel>
                <FormLabel sx={{width:"8.5vw", borderTop:1,
                  paddingRight:1, paddingTop:1, textAlign:"right"}}>{(dep=(formValues.sce1[0].
                  deposit+t.deposit),formValues.sces[0].deposit=dep,negBracket(dep))}</FormLabel>
                <FormLabel sx={{width:"8vw", borderTop:1,
                  paddingRight:1, paddingTop:1, textAlign:"right"}}>{(ern=(formValues.sce1[0].
                  earning+t.earning),formValues.sces[0].earning=ern,negBracket(ern))}</FormLabel>
                <FormLabel sx={{width:"0.5vw", borderTop:1}}></FormLabel>
                <FormLabel sx={{width:"9.5vw", borderTop:1, paddingTop:1, paddingRight:1,
                  textAlign:"right"}}>{(bal=cap+arr+dep+ern,negBracket(bal))}</FormLabel>
              </FormGroup>:null}
              {sec.type==="B"?<FormGroup row sx={{justifyContent: "space-between"}}>
                <FormLabel sx={{width:"48.25vw", borderTop:2, paddingTop:1}}>
                  Annexed notes form an integral parts of these Financial Statements.</FormLabel>
                <FormLabel sx={{width:"8.5vw", borderTop:2, paddingTop:1, paddingRight:1,
                  textAlign:"right"}}>{(bal=getSfpBal(sec.link,formValues),
                  x=(bal-(cap+arr+dep+ern)),Math.abs(x)>0.5?"Wrong":"\u00A0")}</FormLabel>
                <FormLabel sx={{width:"8vw", borderTop:2, paddingTop:1, paddingRight:1,
                  textAlign:"right"}}>{(Math.abs(x)>0.5?negBracket(x):"\u00A0")}</FormLabel>
                <FormLabel sx={{width:"10vw", borderTop:2, paddingTop:1, paddingRight:1,
                  textAlign:"right"}}>{(Math.abs(x)>0.5?negBracket(bal):"")}</FormLabel>
              </FormGroup>:null}

            </FormControl>;
          })}

          <FormControl sx={{paddingTop: 0}}>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              <FormLabel sx={{width:"74.75vw"}}>&nbsp;</FormLabel>
            </FormGroup>
          </FormControl>
        </div>
      </form>
    </Paper>
    <Dialog open={alertOpen} onClose={handleAlertClick}>
      <Alert onClose={handleAlertClick}>
        <AlertTitle>Success!</AlertTitle>
        {alertMesg}
        <div style={{marginTop: 20, display: "flex", justifyContent: "space-between"}}>
          {btn2Text? <Button style={{textTransform: "none", paddingLeft: 0,
           justifyContent: "left"}} onClick={btn2Handler}>{btn2Text}</Button>: null}
          {btn1Text? <Button style={{textTransform: "none", paddingRight: 0, marginLeft: 10,
           justifyContent: "right"}} onClick={btn1Handler}>{btn1Text}</Button>: null}
        </div>
      </Alert>
    </Dialog>
  </>)
};
