import { Select, SelectChangeEvent, MenuItem, ListItemText } from "@mui/material";
import { minWidth } from "../ContactForm"; //, SelectProps

const skills = ["React", "Angular", "Python", "NodeJS", "Machine Learning"]; // "",

export default function BeautifulSelect(props: {value: string[] | undefined,
onChange: (event: SelectChangeEvent<string[]>, child: React.ReactNode) => void}) {
  return (
    <Select id="skill-select" renderValue={(selected: string[]) => selected.join(", ")}
    //value={formValues.skills || ""} onChange={handleSelectChange}
    sx={{minWidth: minWidth, marginRight: 2}} {...props}>
      {skills.map(skillName => (
        <MenuItem value={skillName} key={skillName}>
          <ListItemText primary={skillName} />
        </MenuItem>
      ))}
    </Select>
  );
};
