import { useState, useEffect, useRef } from "react";
import { Autocomplete, FormControl, FormGroup, Paper, Button } from "@mui/material";
import { TextField, SelectChangeEvent, Dialog, Alert, AlertTitle } from "@mui/material";
import { FormLabel, Select, MenuItem, ListItemText, InputAdornment } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Stack } from "@mui/system";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Company, FormValues, findValues, getBalances, getNote, getAnnexes, getAnnexesCopy, //, getNotes
  getAnxHeads, getSces, getAnnexTotal, getAddress, getNoteBal, AnxLine, balIndex,
  SfpData, PnlData, KeyNote } from "../../Data/FinancialData";
import FinExcel, { getFinExData } from "../../Data/FinExcel";
import { commaFormat, negBracket, removeComma } from "../../Data/DataUtility";
import { updNoteTotals } from "./Notes";
import { months, refreshFinData } from "./FinancialPosition";

let btn1Handler = () => {}, btn2Handler = () => {};
export const annexes = getAnnexes("", []);

const procAnxRule = (i:number, values:FormValues) => {
  const tA = getAnnexTotal(i, values); //console.log("procAnxRule:: i:", i, ", tA:", tA); //values.
  const anx = annexes[i], link = anx.link; if(link) { //console.log("procAnxRule:: i:", i, ", link:", link, ", anx:", anx);
    const rules = link.split(","); rules.forEach(rule => { if(rule) {
      const splt = rule.split(":"), tp = splt[0]; let rl="";
      if(splt.length > 1) rl = splt[1]; //console.log("tp:", tp, ", rl:", rl);;
      if(rl) { const spl = rl.split("|"), tgt = spl[0]; if(tgt) { //console.log("tgt:", tgt);
        if(tgt[0]==="N") { const note = tgt.substring(1); if(note) { let ln = -1 // Notes
          if(spl.length > 1) ln = parseInt(spl[1].substring(1)); //console.log("note:", note, ", ln:", ln);
          const bals = values.balances; //, bal1 = values.balance1;
          if(ln >= 0) { // Note Line Balance
            const nbl = bals[balIndex(note, ln, bals)]; //, nb1 = bal1[balIndex(note, ln, bal1)];
            const chg = (tp==="C"? anx.cos:(tp==="A"? tA.depAmt-anx.cos: tp==="D"? tA.depAmt:
              (tp==="V"? tA.disposal-tA.depAdj: 0))) - nbl.bal;
            //const ch1 = (tp==="B"? opBal:(tp==="D"? opDep: opBal-opDep)) - nb1.bal;
            nbl.bal += chg; //console.log("nbl:", nbl); //, "nb1:", nb1); //nb1.bal += ch1;
          } else { // Note Total Balance
            const nbl = getNoteBal(note, bals); //, nb1 = getNoteBal(note, bal1);
            const chg = (tp==="C"? anx.cos:(tp==="A"? tA.depAmt-anx.cos: tp==="D"? tA.depAmt:
              (tp==="V"? tA.disposal-tA.depAdj: 0))) - nbl.bal;
            //const ch1 = (tp==="B"? opBal:(tp==="D"? opDep: opBal-opDep)) - nb1.bal;
            bals[balIndex(note,0,bals)].bal += chg; //bal1[balIndex(note,0,bal1)].bal += ch1;
        }}}
    }}}});
} };

export const updateNoteBals = (values: FormValues,
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>,
  setPass: React.Dispatch<React.SetStateAction<number>>, pre: number = 2) => { //console.log("AnnexureA:: updateNoteBals");
  //formValues.annexes.forEach((anx, i) => { processAnxRules(anx, i);
  let anxEmpty = true;
  for(let i = 0; i < values.annexes.length; i++) { const anx = values.annexes[i]; //
    for(let li = 0; li < anx.lines.length; li++) { const line = anx.lines[li];
      if(line.rate>0) anxEmpty = false;
  } } //console.log("updateNoteBals:: anxEmty:", anxEmpty);
  //if(anxEmpty) { console.log("Returning without updaing NoteBals."); return; }
  if(!anxEmpty) {
    for(let i = 0; i < annexes.length; i++) { //values.
      const tA = getAnnexTotal(i, values), tA1 = getAnnexTotal(i, values, 1);
      const tA2 = getAnnexTotal(i, values, 2);
      tA1.bal = tA2.bal + tA1.addition + tA1.disposal; tA1.depBal = tA2.depBal + tA1.depAmt + tA1.depAdj;
      tA.bal  = tA1.bal + tA.addition  + tA.disposal;  tA.depBal  = tA1.depBal + tA.depAmt  + tA.depAdj;
      setFormValues(values => { //console.log("formValues:", formValues);
        let newValues = {...values}; const note = annexes[i].note; //newValues.
        const bals  = newValues.balances, bls1 = newValues.balance1, bls2 = newValues.balance2;
        const ntBl0 = bals.find(b=>b.note===note && b.line===0), ntBl1= bals.find(b=>b.note===note && b.line===1);
        const n1Bl0 = bls1.find(b=>b.note===note && b.line===0), n1Bl1= bls1.find(b=>b.note===note && b.line===1);
        const n2Bl0 = bls2.find(b=>b.note===note && b.line===0), n2Bl1= bls2.find(b=>b.note===note && b.line===1);
        if(pre > 1) {
          if(n2Bl0) n2Bl0.bal = tA2.bal; else bls2.push({note, line:0, desc:"", bal:tA2.bal, num:0});
          if(n2Bl1) n2Bl1.bal =-tA2.depBal; else bls2.push({note, line:1, desc:"", bal:-tA2.depBal, num:0});
        }
        if(pre > 0) {
          if(n1Bl0) n1Bl0.bal = tA1.bal; else bls1.push({note, line:0, desc:"", bal:tA1.bal, num:0});
          if(n1Bl1) n1Bl1.bal =-tA1.depBal; else bls1.push({note, line:1, desc:"", bal:-tA1.depBal, num:0});
        } //console.log("i:", i, "pre:", pre, "tA:", tA, ", tA1:", tA1, ", tA2:", tA2);

        if(ntBl0) ntBl0.bal = tA.bal; else bals.push({note, line:0, desc:"", bal:tA.bal, num:0});
        if(ntBl1) ntBl1.bal =-tA.depBal; else bals.push({note, line:1, desc:"", bal:-tA.depBal, num:0});
        return newValues; //console.log("updateNoteBals:: newValues:", newValues);
      }); procAnxRule(i, values);
    } //});
  }
  updNoteTotals(setFormValues, setPass, 2); //console.log("values:", values); //values,
  // setTimeout(() => updNoteTotals(setFormValues, 3), 20);  //30  //values,
  // setTimeout(() => updNoteTotals(setFormValues, 4), 40);  //60  //values,
  // setTimeout(() => updNoteTotals(setFormValues, 5), 60);  //90  //values,
  // setTimeout(() => updNoteTotals(setFormValues, 6), 80);  //120 //values,
  // setTimeout(() => updNoteTotals(setFormValues, 7), 100); //150 //values,
  // setTimeout(() => updNoteTotals(setFormValues, 8), 120); //180 //values,
  // setTimeout(() => updNoteTotals(setFormValues, 9), 140); //210 //values,
  // setTimeout(() => updNoteTotals(setFormValues,10), 160); //240 //values,
}

interface IProps { //
  financialData: FormValues[],
  setFinancialData: React.Dispatch<React.SetStateAction<FormValues[]>>,
  companies: Company[], sfp: SfpData[], pnl: PnlData[], keyNotes: KeyNote[],
  currentId: string, setCurrentId: React.Dispatch<React.SetStateAction<string>>,
  getDefaultFormValues: (data: Array<FormValues>|null, id: string) => FormValues,
  formValues: FormValues, setFormValues: React.Dispatch<React.SetStateAction<FormValues>>,
  inputMode: number, setInputMode: React.Dispatch<React.SetStateAction<number>>,
  userEmail: string, setPass: React.Dispatch<React.SetStateAction<number>>,
};

export default function AnnexureA({ financialData, setFinancialData, companies,
  currentId, setCurrentId, getDefaultFormValues, formValues, setFormValues,
  sfp, pnl, keyNotes, inputMode, setInputMode, setPass}: IProps) {

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMesg, setAlertMesg] = useState("");
  const [btn1Text, setBtn1Text] = useState("");  // Ok
  const [btn2Text, setBtn2Text] = useState("");  // Cancel
  const textRef = useRef<HTMLInputElement>();
  const autoRef = useRef<HTMLInputElement>();
  const dateRef = useRef<HTMLInputElement>();
  const [selName, setSelName] = useState("");
  const [selYear, setSelYear] = useState("");
  const [anxChanged, setAnxChanged] = useState(false);

  useEffect(() => { if(anxChanged) updateNoteBals(formValues, setFormValues, setPass); }, [anxChanged]);
  //const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const procAnxLineRule = (i:number, li:number, bal:number, dpBal:number, opBal:number, opDep:number,
    values:FormValues = formValues) => {
    //console.log("procAnxLineRule:: i:", i, ", li:", li, ", bal:", bal, ", dpBal:", dpBal, ", opBal:", opBal, ", opDep:", opDep);
    const link = (values.annexes[i].lines[li]? values.annexes[i].lines[li].link: "");
    if(link) { //console.log("link:", link);
      const rules = link.split(","); rules.forEach(rule => { if(rule) {
        const splt = rule.split(":"), tp = splt[0]; let rl="";
        if(splt.length > 1) rl = splt[1]; //console.log("tp:", tp, ", rl:", rl);;
        if(rl) { const spl = rl.split("|"), tgt = spl[0]; if(tgt) { //console.log("tgt:", tgt);
          if(tgt[0]==="N") { const note = tgt.substring(1); if(note) { let ln = -1 // Notes
            if(spl.length > 1) ln = parseInt(spl[1].substring(1)); //console.log("note:", note, ", ln:", ln);
            const bals = values.balances, bal1 = values.balance1;
            if(ln >= 0) { // Note Line Balance
              const nbl = bals[balIndex(note, ln, bals)], nb1 = bal1[balIndex(note, ln, bal1)];
              const chg = (tp==="B"?  bal: (tp==="D"? dpBal: bal - dpBal)) - nbl.bal;
              const ch1 = (tp==="B"? opBal:(tp==="D"? opDep: opBal-opDep)) - nb1.bal;
              nbl.bal += chg; nb1.bal += ch1; //console.log("nbl:", nbl, "nb1:", nb1);
            } else { // Note Total Balance
              const nbl = getNoteBal(note, bals), nb1 = getNoteBal(note, bal1);
              const chg = (tp==="B"?  bal: (tp==="D"? dpBal: bal - dpBal)) - nbl.bal;
              const ch1 = (tp==="B"? opBal:(tp==="D"? opDep: opBal-opDep)) - nb1.bal;
              bals[balIndex(note,0,bals)].bal += chg; bal1[balIndex(note,0,bal1)].bal += ch1;
          }}}
      }}}});
  } };

  // const processAnxRules = (values:FormValues = formValues) => { console.log("processAnxRules");
  //   values.annexes.forEach((anx, i) => {
  //     anx.lines.forEach((line, li) => { const lin1 = values.annex1[i].lines[li];
  //       if(line.link) procAnxLineRule(i, li, line.bal, line.depBal, lin1.bal, lin1.depBal, values);
  //     });
  //   });
  // }

  const updateAnxLine = (fld: string, num: number, i: number, li: number, pre: number) => { //console.log("fld:", fld, ", pre:", pre);
    const ln = formValues.annexes[i].lines[li], l1n = formValues.annex1[i].lines[li];
    const addtn = (fld==="addition"? num: (ln?ln.addition:0));
    const dspsl = (fld==="disposal"? num: (ln?ln.disposal:0));
    const rate  = (fld==="rate"?     num: (ln?ln.rate:0));
    const dpAdj = (fld==="depAdj"?   num: (ln?ln.depAdj:0));
    const opBal = (fld==="opBal"?    num: (l1n?l1n.bal:0));
    const opDep = (fld==="opDep"?    num: (l1n?l1n.depBal:0));
    const bal = opBal + addtn + dspsl; //console.log("i:", i, ", type:", formValues.annexes[i].type);
    //const dpAmt = Math.round((bal - (formValues.annexes[i].type==="D"?opDep:0)) * rate/100);
    const dpAmt = Math.max(Math.round((bal - (formValues.annexes[i].type==="D"?opDep:0)) * rate/100), 0);
    const dpBal = opDep + dpAmt + dpAdj;
    setFormValues(formValues => {let newValues = {...formValues}; //const note = newValues.annexes[i].note;
      const ln = newValues.annexes[i].lines[li];
      if(ln) { ln.bal = bal; ln.depAmt = dpAmt; ln.depBal = dpBal; } else {
        newValues.annexes[i].lines[li] = { sl:0,desc:"",head:"",link:"",
          addition:0,disposal:0, bal:bal, rate:0, depAmt:dpAmt, depAdj:0, depBal:dpBal
        };
      } return newValues;
    }); procAnxLineRule(i, li, bal, dpBal, opBal, opDep);  //, formValues
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { name, value } = event.target; //console.log("name:", name, ", value:", value);
    let fld = name, num = (value==="-"||value===""? 0: parseFloat(removeComma(value))), pre = 0;
    let fldSet = false; const neg = ((value.substring(0,1)==="-" && value.length>1) || value==="0-");
    if(value.indexOf(".")>=0) {
      const intg = Math.floor(Math.abs(num));
      let dec = Math.abs(num) - intg; if(dec<0.001) dec = 0.1;
      num = (intg + dec) * (neg? -1: 1);
    } //console.log("fld:", fld, ", value:", value);
    if(name==="name") { //console.log("handleTextFieldChange:: selName:", selName);
      if(value && selYear) { const found = findValues(value, selYear, financialData);
        if(found) { fldSet = true; setCurrentId(found.id);
          setFormValues(formValues => ({...found})); setInputMode(2); //Edit
        } else {
          const company = companies.find(comp => comp.name===value), sDt = new Date(selYear);
          if(company) { refreshFinData(company, sDt, getDefaultFormValues,
            setFinancialData, setFormValues, setCurrentId); setInputMode(2); //Edit
        } }
      } setSelName(value);
    } else { let flds = name.split("_"); fld = flds[0]; //console.log("flds:", flds);
      if(flds.length>1) { let i = parseInt(flds[1]); //console.log("fld:", fld);
        if(flds.length>2) { let li = parseInt(flds[2]);
          if(fld==="head") { //console.log("fld:", fld, ", i:", i, ", li:", li, ", value:", value);
            setFormValues(formValues => { let newValues = {...formValues};
              newValues.annexes[i].lines[li][fld] = value; //console.log("newValues.annexes:", newValues.annexes);
              return newValues;
            }); fldSet = true;
          } else if(!isNaN(num)) {
            if(neg && Math.abs(num) <= 0.0001) num = -0.0001;
            if(fld==="addition" || fld==="disposal" || fld==="rate" || fld==="depAdj") {  // || fld==="depAmt"
              setFormValues(formValues => { let newValues = {...formValues};
                newValues.annexes[i].lines[li][fld] = num; //console.log("newValues.annexes:", newValues.annexes);
                return newValues;
              }); fldSet = true;
            } else
            if(fld==="opBal") { //console.log("opBal:: fld:", fld, ", num:", num);
              setFormValues(formValues => { let newValues = {...formValues};
                newValues.annex1 = getAnnexesCopy(newValues, 1); console.log("newValues:", newValues);
                const l1n = newValues.annex1[i].lines[li], ln = newValues.annexes[i].lines[li];
                if(l1n) {
                  l1n.disposal += (num - l1n.bal); l1n.bal = num; //console.log("newValues.annex1:", newValues.annex1);
                } else newValues.annex1[i].lines[li] = { sl:(ln?ln.sl:0),desc:(ln?ln.desc:""),head:(ln?ln.head:""),
                  link:(ln?ln.link:""), addition:0, disposal:(num), bal:num, rate:0,depAmt:0,depAdj:0,depBal:0 };
                return newValues;
              }); fldSet = true; pre = 1;
            } else
            if(fld==="opDep") {
              setFormValues(formValues => { let newValues = {...formValues};
                newValues.annex1[i].lines[li].depAdj += (num - newValues.annex1[i].lines[li].depBal);
                newValues.annex1[i].lines[li].depBal = num; //console.log("newValues.annex1:", newValues.annex1);
                return newValues;
              }); fldSet = true; pre = 1;
            } if(fldSet) updateAnxLine(fld, num, i, li, pre);
        } } else
        if(!isNaN(num)) { //console.log("num:", num);
          if(fld==="t1Addition") {
            setFormValues(formValues => { let newValues = {...formValues};
              // Taddition_Old + Tdisposal_Old = num + Tdisposal_New
              // Tdisposal_New = Taddition_Old + Tdisposal_Old - num
              // 0disposal_New + Rdisposal_Old = Taddition_Old + Tdisposal_Old - num
              // 0disposal_New = Taddition_Old + Tdisposal_Old - Rdisposal_Old - num
              // 0disposal_New = 0disposal_Old + (Taddition_Old - num)
              const t1Line = getAnnexTotal(i, newValues, 1);
              newValues.annex1[i].lines[0].disposal += (t1Line.addition - num);
              newValues.annex1[i].lines[0].addition = num; //console.log("newValues.annex1:", newValues.annex1);
              return newValues;
            }); fldSet = true; pre = 1;
          } else
          if(fld==="t1DepAmt") {
            setFormValues(formValues => { let newValues = {...formValues};
              // TdepAmt_Old + TdepAdj_Old = num + TdepAdj_New
              // TdepAdj_New = TdepAmt_Old + TdepAdj_Old - num
              // 0depAdj_New + RdepAdj_Old = TdepAmt_Old + TdepAdj_Old - num
              // 0depAdj_New = TdepAmt_Old + TdepAdj_Old - RdepAdj_Old - num
              // 0depAdj_New = 0depAdj_Old + (TdepAmt_Old - num)
              const t1Line = getAnnexTotal(i, newValues, 1);
              newValues.annex1[i].lines[0].depAdj += (t1Line.depAmt - num);
              newValues.annex1[i].lines[0].depAmt = num; //console.log("newValues.annex1:", newValues.annex1);
              return newValues;
            }); fldSet = true; pre = 1;
          } else
          if(fld==="t1OpBal") {
            setFormValues(formValues => { let newValues = {...formValues};
              // T2bal_Old + T1disposal_Old = num + T1disposal_New
              // T1disposal_New = T2bal_Old + T1disposal_Old - num
              // 0disposal_New + Rdisposal_Old = T2bal_Old + T1disposal_Old - num
              // 0disposal_New = T2bal_Old + T1disposal_Old - Rdisposal_Old - num
              // 0disposal_New = 0disposal_Old + (T2bal_Old - num)
              const t2Line = getAnnexTotal(i, newValues, 2);
              newValues.annex1[i].lines[0].disposal += (t2Line.bal - num);
              //console.log("newValues.annex1:", newValues.annex1);

              // Tdisposal_New - Tdisposal_Old = num - Tbal_Old
              // Tdisposal_New = Tdisposal_Old + num - Tbal_Old
              // 0disposal_New + Rdisposal_Old = Tdisposal_Old + num - Tbal_Old
              // 0disposal_New = Tdisposal_Old - Rdisposal_Old + num - Tbal_Old
              // 0disposal_New = 0disposal_Old + (num - Tbal_Old)
              newValues.annex2[i].lines[0].disposal += (num - t2Line.bal);
              // 0bal_New + Rbal_Old = num
              // 0bal_New = num - Rbal_Old = num - (Tbal_Old - 0bal_Old)
              // 0bal_New = 0bal_Old + (num - Tbal_Old)
              newValues.annex2[i].lines[0].bal += (num - t2Line.bal);
              //console.log("newValues.annex2:", newValues.annex2);
              return newValues;
            }); fldSet = true; pre = 2;
          } else
          if(fld==="t1OpDep") {
            setFormValues(formValues => { let newValues = {...formValues};
              const t2Line = getAnnexTotal(i, newValues, 2);
              newValues.annex1[i].lines[0].depAdj += (t2Line.depBal - num);
              //console.log("newValues.annex1:", newValues.annex1);
              newValues.annex2[i].lines[0].depAdj += (num - t2Line.depBal);
              newValues.annex2[i].lines[0].depBal += (num - t2Line.depBal);
              //console.log("newValues.annex2:", newValues.annex2);
              return newValues;
            }); fldSet = true; pre = 2;
          } else
          if(fld==="cos") { //console.log("fld:", fld, ", num:", num);
            setFormValues(formValues => { let newValues = {...formValues};
              newValues.annexes[i].cos = num; //console.log("newValues.annexes:", newValues.annexes);
              return newValues;
            }); fldSet = true;
        } }
    } }
    if(!fldSet) setFormValues((formValues) => ({...formValues, [fld]: value}));
    setAnxChanged(false); setTimeout(() => setAnxChanged(true), 20); //updateTotals(name, value);
  };

  const handleAutoCompleteChange = (fld: string, event: React.SyntheticEvent<Element, Event>,
    value: string | null) => { console.log("fld:", fld, "value:", value, ", event:", event);
    if(fld==="name" || fld==="yrendDate") {
      if(value){ let index = -1, refrsh = false, sDt = new Date();
        if(fld==="name") { //console.log("selYear:", selYear, "compName:", value);
          if(selYear) { sDt = (new Date(selYear)); //console.log("sDt:", sDt);
            index = financialData.findIndex(values => { let retVal = false;
              const dt = new Date(values.yrendDate); //console.log("dt:", dt);
              retVal = (values.name===value && dt.getDate()===sDt.getDate() && dt.getMonth()===sDt.getMonth() && dt.getFullYear()===sDt.getFullYear());
              return retVal;
            }); refrsh = (index < 0);
          } else setTimeout(() => dateRef.current?.focus(), 100);
        }
        else if(fld==="yrendDate") { //console.log("selName:", selName, "finYear:", value);
          sDt = new Date(value); //console.log("sDt:", sDt);
          if(selName && sDt) {
            index = financialData.findIndex(values => { let retVal = false;
              const dt = new Date(values.yrendDate); //console.log("dt:", dt);
              retVal = (values.name===selName && dt.getDate()===sDt.getDate() && dt.getMonth()===sDt.getMonth() && dt.getFullYear()===sDt.getFullYear());
              return retVal;
            }); refrsh = (index < 0);
        } } else {
          //if(fld==="fsType") updateSbTests(fld, value);
          ///setFormValues((formValues) => ({...formValues, [fld]: value}));
        }
        if(index >= 0) {
          let compInfo = { ...financialData[index] }, id = compInfo.id;
          // compInfo.notes = getNotes(id, financialData);
          compInfo.balances = getBalances(id, financialData);
          compInfo.balance1 = getBalances(id, financialData, 1);
          compInfo.balance2 = getBalances(id, financialData, 2);
          compInfo.annexes = getAnnexes(id, financialData);
          compInfo.annex1 = getAnnexes(id, financialData, 1);
          compInfo.annex2 = getAnnexes(id, financialData, 2);
          compInfo.sces = getSces(id, financialData);
          compInfo.sce1 = getSces(id, financialData, 1);
          compInfo.sce2 = getSces(id, financialData, 2);
          setFormValues(compInfo); setCurrentId(compInfo.id); console.log("compInfo:", compInfo);
          //---------------------------------------------------------------------------------------//
          //---------------------------------------------------------------------------------------//
        } else
        if(refrsh) { //const company = companies.find(comp => comp.name===selName); //index<0
          const company = companies.find(comp => comp.name===(fld==="name"?value:selName));
          const yr = `${sDt.getDate()}-${months[sDt.getMonth()].substring(0,3)}-${sDt.getFullYear()}`;
          //console.log("selName:", selName, ", company:", company, ", yr:", yr);
          if(company) { refreshFinData(company, sDt,
            getDefaultFormValues, setFinancialData, setFormValues, setCurrentId);
            // const getFinValues = async () => {
            //   const url = '/api/fin-values/'+company.id+'?year='+yr; console.log("url:", url);
            //   const res = await fetch(url).then(response => { //console.log("response:", response);
            //     return response; //.json();
            //   });
            //   const resData = await res.json(); console.log("Notes:: /api/fin-values/:compId?year=yr - resData:", resData);
            //   return resData; //.finData;
            // };
            // getFinValues().then(resData => { //console.log("resData.finData:", resData.finData);
            //   let data = resData.finData as Array<FormValues>, idSet = false;
            //   if(resData.id) { setCurrentId(resData.id); idSet = true; }
            //   setFinancialData(oldData => { const newData = [...oldData];
            //     data.forEach(values => {
            //       if(financialData.findIndex(f => f.id===values.id)<0) newData.push(values);
            //       if(!idSet) { const fDt = new Date(values.yrendDate);
            //         if(fDt.getDate()===sDt.getDate() && fDt.getMonth()===sDt.getMonth() &&
            //         fDt.getFullYear()===sDt.getFullYear()) { resData.id = values.id;
            //           setCurrentId(values.id); idSet = true;
            //       } }
            //     }); //console.log("newData:", newData);
            //     setFormValues(getDefaultFormValues(newData, resData.id));
            //     return newData;
            //   });
            // });
        } }
        //setFormValues((formValues) => ({...formValues, [fld]: value}));
        const val:string = value; setFormValues((formValues) => ({...formValues, [fld]:val}));
        //updateTotals(fld, value);
      } else {
        setFormValues(getDefaultFormValues(null,"")); setCurrentId("");
      }
      if(fld==="yrendDate") { if(value) setSelYear(value); else {setSelName(""); setSelYear("")}; }
      else if(fld==="name") { if(value) setSelName(value); else {setSelName(""); setSelYear("")}; }
    } else {
      const flds = fld.split("_"); fld = flds[0]; console.log("flds:", flds, ", fld:", fld, ", value:", value);
      if(flds.length>1) { const i = parseInt(flds[1]); console.log(`sfp[${i}]:`, sfp[i]);
        //if(flds.length>2) { const gi = parseInt(flds[2]);
          if(flds.length>2) { const li = parseInt(flds[2]); console.log(`annexes[${i}].lines[${li}]:`, annexes[i].lines[li]);
            //---------------------------------------------------------------------------------//
            if(fld==='head') { if(!value) value = " "; console.log("fld:", fld);
              const head = value; console.log("head:", head); //getSfpHead(i, gi, value)
              if(head) setFormValues(oldValues => { const newValues = {...oldValues};
                let rmHd = false; //if(value===" ") { //Trying to fix redundant head problem
              //     const line = sfp[i].groups[gi].lines[li]; //console.log("head:", head, ", line:", line);
              //     // Need to add logic for removing sfpHead for (line.desc && line.desc===hd.head.desc)
              //     if(!line.desc) { const sfpHeads = []; //hi = headIndex(i,gi,li,newValues.sfpHeads),
              //       for(const hd of newValues.sfpHeads) {
              //         if(!(hd&&(hd.sec===i&& hd.grp===gi&& hd.line===li))) sfpHeads.push(hd);
              //       } //delete newValues.sfpHeads[hi];
              //       newValues.sfpHeads = sfpHeads; console.log("sfpHeads:", sfpHeads);
              //       rmHd = true; //console.log(`Removed newValues.sfpHeads[${hi}]:`);
              //   } }
              //   if(!rmHd) newValues.sfpHeads[headIndex(i,gi,li,newValues.sfpHeads)].head = head;
                if(!rmHd) {
                  if(newValues.annexes[i].lines[li]) newValues.annexes[i].lines[li].desc = head;
                  else { newValues.annexes[i].lines[li] = { sl:0,
                    desc:head,head:"",link:"",addition:0,disposal:0,bal:0,rate:0,depAmt:0,depAdj:0,depBal:0 };
                  } console.log("newValues:", newValues);
                }
                return newValues;
              });
            }
            //---------------------------------------------------------------------------------//
      } } //}
    }
  };

  const getCompanyNames = (yrendDate="") => { //console.log("getCompanyNames:: yrendDate:", yrendDate);
    const names: Array<string> = [""]; const yDt = yrendDate? (new Date(yrendDate)): null;
    financialData.forEach(values => {
      if(yDt) { const dt = new Date(values.yrendDate); //console.log("yDt:", yDt, ", dt:", dt);
        if(yDt.getFullYear()===dt.getFullYear() && yDt.getMonth()===dt.getMonth()
        && yDt.getDate()===dt.getDate() && !names.includes(values.name)) names.push(values.name)
      } else if(!names.includes(values.name)) names.push(values.name);
    }); //console.log("names:", names);
    return names;
  };
  const getCompNames = () => {
    const names: Array<string> = [""]; //const yDt = yrendDate? (new Date(yrendDate)): null;
    companies.forEach(comp => {
      if(!names.includes(comp.name)) names.push(comp.name);
    }); //console.log("names:", names);
    return names;
  };

  // const getFinancialYears = (name="") => { //console.log("getFinancialYears:: name:", name);
  //   const finYears: Array<string> = [""];
  //   financialData.forEach(values => { const fDt = new Date(values.yrendDate);
  //     const finYear = `${fDt.getDate()} ${months[fDt.getMonth()]} ${fDt.getFullYear()}`;
  //     if((!name || name === values.name) && !finYears.includes(finYear)) finYears.push(finYear);
  //   }); //console.log("finYears:", finYears);
  //   return finYears;
  // };
  const getFinYears = (name="") => { //console.log("getFinYears:: name:", name);
    const finYears: Array<string> = [""];
    financialData.forEach(values => { const fDt = new Date(values.yrendDate);
      const finYear = `${fDt.getDate()} ${months[fDt.getMonth()]} ${fDt.getFullYear()}`;
      if((name === values.name) && !finYears.includes(finYear)) finYears.push(finYear);
    }); finYears.sort(); finYears.reverse(); //console.log("finYears:", finYears);
    if(name && finYears.length<2) { //console.log("getFinYears:: companies:", companies);
      const company = companies.find(comp => comp.name===name); //console.log("getFinYears:: company:", company);
      if(company) {
        const getYears = async () => {
          const res = await fetch('/api/fin-years/'+company.id).then(response => { //console.log("response:", response);
            return response; //.json();
          });
          const resData = await res.json(); //console.log("Notes:: /api/fin-years/:compId - resData:", resData);
          return resData.years;
        };
        getYears().then(years => { //console.log("years:", years);
          let yrs = years as Array<string>;
          yrs.forEach(yr => { //console.log("yr:", yr, (typeof yr));
            const yDt = new Date(yr); //console.log("yDt:", yDt, (typeof yDt));
            const finYear = `${yDt.getDate()} ${months[yDt.getMonth()]} ${yDt.getFullYear()}`;
            if(!finYears.includes(finYear)) finYears.push(finYear);
          }); finYears.sort(); finYears.reverse(); //console.log("finYears:", finYears);
        });
    } } //console.log("getFinYears:: finYears:", finYears);
    return finYears;
  };

  const handleDatePickerChange = (value: Dayjs | null) => { //console.log(value);
    if(value) {
      const dt = value; //as unknown as {month: ()=>string, date: ()=>string, year: ()=>string};
      const yrendDate = `${dt.month() + 1}/${dt.date()}/${dt.year()}`;
      // if(selName && yrendDate) {
      //   const found = findValues(selName, yrendDate, financialData);
      //   if(found) {  setCurrentId(found.id);
      //     setFormValues(formValues => ({...found})); setInputMode(2); //Edit
      // } }
      // setSelYear(yrendDate);
      // setFormValues({...formValues, yrendDate: yrendDate}); //value?.toString()
      setFormValues((formValues) => ({...formValues, yrendDate: yrendDate}));
      if(selName && dt) {
        console.log("Trying to find selName:", selName, ", dt:", dt, " in financialData:", financialData);
        const found = findValues(selName, dt.toString(), financialData); console.log("found:", found);
        if(found) { setCurrentId(found.id);
          setFormValues(formValues => ({...found})); setInputMode(2); //Edit
        } else {
          const company = companies.find(comp => comp.name===selName);
          if(company) { refreshFinData(company, (new Date(dt.toString())), getDefaultFormValues,
            setFinancialData, setFormValues, setCurrentId); setInputMode(2); //Edit
          }
      } } setSelYear(dt.toString());
    } else setSelYear("");
  };

  const handleSubmit = async () => {
    const dataPack = {...formValues }; //console.log("dataPack:", dataPack);
    try {
      let hasError = false;
      const res = await fetch('/api/financial-data', { // /api/company-data
        method: 'POST', body: JSON.stringify(dataPack),
        headers: {'Content-Type': 'application/json'}
      });
      if (!res.ok) hasError = true;
      const resData = await res.json();
      if (hasError) throw new Error(resData.message);
      const financialInfo = resData.financialInfo; //console.log("resData:", resData);
      setFinancialData((prevData: Array<FormValues>) => {
        const newData = prevData.map((data: FormValues) => data );
        let index = newData.length; newData.some((values, i) => {
          if (values.id === financialInfo.id) { index = i; return true; } return false;
        });
        newData[index] = financialInfo; //console.log("newData:", newData); // Update existing record
        setFormValues(financialInfo); setCurrentId(financialInfo.id); //console.log("financialInfo:", financialInfo);
        return newData;
      });

      //console.log("autoRef.current:", autoRef.current);
      //console.log("autoRef.current?.focus:", autoRef.current?.focus);
      setInputMode(0); autoRef.current?.focus();
      setAlertMesg("Form Saved"); setBtn1Text("OK");
      btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      //setTimeout(() => autoRef.current?.focus(), 100); //clearValues();
      //setTimeout(() => autoRef?.focus(), 100);
    } catch (error: any) {
      alert(error.message || 'Something went wrong!');
    }
  };

  const handleClearClick = () => { clearValues(); if(inputMode>0) setInputMode(0); };
  const clearValues = () => {
    const id = currentId, values = getDefaultFormValues(null, id);
    //console.log("currentId:", currentId, ", documentData:", documentData);
    //setFormValues(getDefaultFormValues(null, (inputMode===1? "": currentId)));
    setFormValues(values); setSelName(""); setSelYear("");
  };
  const handleAddNew = () => { setInputMode(1); setCurrentId("");
    const id = "", values = getDefaultFormValues(null, id); //console.log("AddNew:: values:", values);
    setFormValues(values); setSelName(""); setSelYear("");
    setTimeout(() => textRef.current?.focus(), 100); //console.log("currentId:", currentId);
  };
  const handleEdit = () => { setInputMode(2); setTimeout(() => textRef.current?.focus(), 100); };
  const handleAlertClick = () => { setAlertOpen(false); };

  const deleteId = async () => {
    return fetch('/api/financial-data/'+formValues.id, { // /api/company-data/
        method: 'DELETE', body: JSON.stringify(formValues),
        headers: {'Content-Type': 'application/json'}
    }).then(response => { //console.log("response:", response);
      return response; //.json();
    });
  };
  const handleDelete = () => {
    setAlertMesg("Do you want to delete the company " + formValues.name);
    btn1Handler = handleConfirmDelete; //console.log("handleDelete:: btn1Handler is set.");
    btn2Handler = () => { handleClearClick(); handleAlertClick(); };
    setBtn1Text("Yes"); setBtn2Text("No"); setAlertOpen(true);
  }
  const handleConfirmDelete = () => {
    deleteId().then((json) => { console.log("handleDelete:: json:", json);
      // handle success
      let index = -1, id = ""; const deleteId = formValues.id;
      const filteredData = financialData.filter((values, i) => {
       if (values.id === deleteId) { index = i; return false; } return true;
      }); setFinancialData(filteredData);
      if(index >= filteredData.length) index -= 1;
      if(index >= 0) id = filteredData[index].id; setCurrentId(id);
      setFormValues(getDefaultFormValues(filteredData, id));
//
      setInputMode(0); setTimeout(() => { autoRef.current?.focus();
        setAlertMesg("Deleted company info"); setBtn1Text("OK");
        btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      }, 100);
    })
    .catch(error => console.log("handleDelete:: error:", error));
  }; //console.log("inputMode:", inputMode, ", currentId:", currentId, currentId.length);

  let x: any, t: AnxLine, t1: AnxLine, t2: AnxLine; //console.log("formValues:", formValues, ", currentId:", currentId);
  const d = formValues.yrendDate? (new Date(formValues.yrendDate)): null;
  const d1 = d?(new Date(d)):null; if(d1){d1.setDate(d1.getDate()+1); d1.setFullYear(d1.getFullYear()-1);}

  return (<>
    <Paper>
      <form>
        <div style={{ width:"75vw", height:"10vh", position:"fixed", zIndex:"99", backgroundColor:"#fff" }}>{/*68vw*/}
          {/* <h3 style={{ width:"12vw", textAlign:"center", display:"inline-block", verticalAlign:"middle" }}>{Annexure A}</h3> */}
          <div style={{ width:"12vw", textAlign:"center", display:"inline-block", verticalAlign:"middle" }}>{ currentId.length>4?
            <FinExcel excelSheets={getFinExData(formValues, sfp, pnl, keyNotes)}
            fileName="Excel Export" disabled={inputMode>0}>Download</FinExcel>: null }
          </div>
          <div style={{ width:"45vw", display:"inline-block", verticalAlign:"middle" }}>{/*43vw | 10vw*/}
            {/* ------------------------------------------------------------------------------------ */}
            <FormControl sx={{paddingTop: 1}}>
              <FormGroup row sx={{justifyContent: "space-between"}}>
                <FormGroup sx={{width:"6vw", alignItems:"start"}}>
                  <Stack>
                    <Button onClick={handleDelete} sx={{paddingLeft:3}}
                      disabled={inputMode<2}>Delete</Button>
                  </Stack>
                </FormGroup>
                <FormLabel sx={{paddingLeft: 1, width:"14vw"}}></FormLabel>
                <FormGroup sx={{width:"6vw", paddingLeft: 1, alignItems: "start"}}>
                  <Stack>
                    <Button onClick={handleAddNew} disabled={inputMode>0}>Add</Button>
                    <Button onClick={handleEdit} sx={{paddingTop:0}}
                      disabled={inputMode>0||currentId.length<4}>Edit</Button>
                  </Stack>
                </FormGroup>
                <FormGroup sx={{width:"19vw", paddingRight:2, alignItems:"end"}}>{/*17vw*/}
                  <Stack>
                    <Button onClick={handleSubmit} disabled={inputMode<1}>Save</Button>
                    <Button onClick={handleClearClick} sx={{paddingTop:0}}
                      disabled={inputMode<1}>{inputMode>0? "Cancel": "Clear"}</Button>
                  </Stack>
                </FormGroup>
              </FormGroup>
            </FormControl>
            {/* ------------------------------------------------------------------------------------ */}
          </div>
          <div style={{ width:"18vw", display:"inline-block", verticalAlign:"middle" }}>{/* width:"15vw" */}
            <FormControl sx={{paddingLeft: 2}}>
              <FormLabel sx={{width:"14.3vw", padding:1, paddingRight:0, //, border:1, fontStyle:"italic", fontWeight:"bold" //13.3vw
                textAlign:"center", color:"#000"}}>{/*&nbsp;*/}<h3>Annexure A</h3>
              </FormLabel>
            </FormControl>
          </div>
        </div>
        <FormControl sx={{paddingTop: 1, marginTop: "10vh"}}>
          <FormGroup row sx={{paddingTop: (inputMode<1? 1: 0), justifyContent: "space-between"}}>
            <Autocomplete options={getCompNames()} sx={{width: "57.5vw", display: (inputMode<1?
              "block": "none")}} renderInput={(params) => (<TextField  // 52.5vw
                inputRef={autoRef} name="name" {...params} autoFocus label={selName?"":"Company Name"}
              />)} getOptionLabel={(option) => `${option}`}
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)}
              value={formValues.name || ""} disablePortal size="small"
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange.bind(null, "name")}  // onInputChange
            />
            <Autocomplete options={getFinYears(selName)} sx={{width: "16.75vw", display: (inputMode<1? "block": "none")}}
              renderInput={(params) => (<TextField inputRef={dateRef} name="yrendDate" {...params} label={selYear?"":"As At Date"} />)}
              getOptionLabel={(option) => `${option}`} disablePortal disabled={inputMode>0} //14.75vw
              renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)} size="small"
              value={(formValues.yrendDate?(x=new Date(formValues.yrendDate), `${x.getDate()} ${months[x.getMonth()]} ${x.getFullYear()}`):"")} // || ""
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              onChange={handleAutoCompleteChange.bind(null, "yrendDate")} />
          </FormGroup>
          <FormGroup row sx={{paddingTop: (inputMode<1? 0: 1), justifyContent: "space-between", height: (inputMode<1? 0: 1)}}>
            <TextField id="name" name="name" variant="outlined" size="small"  // label="Name of Company"
              sx={{width: "57.5vw", visibility: (inputMode<1? "hidden": "visible")}} //"52.5vw"
              value={formValues.name} autoFocus disabled={inputMode<1}
              onChange={handleTextFieldChange} inputRef={textRef}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker format="DD/MM/YYYY" sx={{width: "16.75vw", visibility:(inputMode<1?"hidden":"visible")}}  // label="Date"
                value={dayjs(formValues.yrendDate)} onChange={handleDatePickerChange} //14.75
                slotProps={{ textField: {size: "small"} }} disabled={inputMode<1} />
            </LocalizationProvider>
          </FormGroup>
        </FormControl>

        <div style={{display: (currentId.length>4 ||
          (inputMode>0 && formValues.name && formValues.yrendDate)? "block": "none")}}>
          <FormControl sx={{paddingTop: 0}}>
            <FormGroup row sx={{justifyContent: "space-between"}}>
              {inputMode>0? <><FormLabel sx={{width:"5.35vw", paddingTop: 1, paddingLeft: 2, textAlign: "left"}}>
                Address</FormLabel>
              <TextField id="address" name="address" variant="outlined" size="small" sx={{width: "68.85vw"}}
                value={formValues.address? formValues.address: getAddress(formValues.name, financialData)}
                disabled={inputMode<1} onChange={handleTextFieldChange} //59.7vw
              /></>:
              <FormLabel sx={{width:"74.2vw", paddingTop:1, paddingBottom:1, paddingLeft:2}}>{formValues.address}</FormLabel>}
              <FormLabel sx={{width:"0.55vw"}}></FormLabel>{/*2.7vw*/}
            </FormGroup>
          </FormControl>

          {annexes.map((anx, i) => { let sl = 0; //formValues.annexes && formValues.
            return (
              <FormControl sx={{paddingTop: 0}} key={i}>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"30.5vw", paddingTop:1, paddingLeft:1, textAlign:"left", fontSize:"103%",
                    fontWeight:"bold"}}>{(x=getNote(anx.note, formValues), (x? x.name: ""))}</FormLabel>
                  <FormLabel sx={{width:"44.25vw"}}></FormLabel>{/*23.5vw*/}
                </FormGroup>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"30.5vw", paddingBottom: 1, paddingLeft: 1, textAlign: "left"}}>
                    As at {(d? `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`: "")}</FormLabel>
                  <FormLabel sx={{width:"44.25vw"}}></FormLabel>
                </FormGroup>
                {/*----------------------------------------------------------------------------------------------*/}
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"1.7vw", borderTop:1, borderRight:1}}></FormLabel>
                  <FormLabel sx={{width:"8.5vw", borderTop:1, borderRight:1}}></FormLabel>
                  <FormLabel sx={{width:"8.55vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "85%"}}>{/*as per company's*/}</FormLabel>{/*14vw*/}
                  <FormLabel sx={{width:"21vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "90%"}}>Costs</FormLabel>{/*18vw*/}
                  <FormLabel sx={{width:"25vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "90%"}}>Accumulated depreciation and impairment</FormLabel>{/*22.5*/}
                  <FormLabel sx={{width:"5vw", borderTop:1, borderRight:1}}></FormLabel>{/*4.5vw*/}
                  <FormLabel sx={{width:"5vw", borderTop:1}}></FormLabel>
                </FormGroup>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"1.7vw", borderTop:0, borderRight:1, textAlign: "center",
                    fontSize: "90%"}}>Sl</FormLabel>
                  <FormLabel sx={{width:"8.5vw", borderTop:0, borderRight:1, textAlign: "center",
                    fontSize: "90%"}}>Particulars</FormLabel>
                  {/* <FormLabel sx={{width:"8.5vw", borderTop:0, borderRight:1, textAlign: "center",
                    fontSize: "85%"}}>statement heads</FormLabel> */}
                  <FormLabel sx={{width:"8.55vw", borderTop:0, borderRight:1, textAlign: "center",
                    fontSize: "85%"}}>as per company's</FormLabel>{/*14vw*/}
                  <FormLabel sx={{width:"5.4vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "83%"}}>Balance at</FormLabel>{/*4.5vw*/}
                  <FormLabel sx={{width:"5.6vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "83%"}}>Addition for</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "87%"}}>Disposal/</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "90%"}}>Total</FormLabel>
                  <FormLabel sx={{width:"4vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "90%"}}>{/*Rate*/}</FormLabel>
                  <FormLabel sx={{width:"5.4vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "83%"}}>Balance at</FormLabel>
                  <FormLabel sx={{width:"5.6vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "83%"}}>Charged for</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "83%"}}>{/*Adjustment*/}</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "90%"}}>Total</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:0, borderRight:1, textAlign: "center",
                    fontSize: "90%"}}>W.D.V</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:0, paddingTop: 0, textAlign: "center",
                    fontSize: "90%"}}>W.D.V</FormLabel>
                </FormGroup>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"1.7vw", borderTop:0, borderRight:1}}></FormLabel>
                  <FormLabel sx={{width:"8.5vw", borderTop:0, borderRight:1}}></FormLabel>
                  {/* <FormLabel sx={{width:"8.5vw", borderTop:0, borderRight:1}}></FormLabel> */}
                  <FormLabel sx={{width:"8.55vw", borderTop:0, borderRight:1, textAlign: "center",
                    fontSize: "85%"}}>statement heads</FormLabel>
                  <FormLabel sx={{width:"5.4vw", borderTop:1, borderRight:1, textAlign: "center", fontSize: "85%"}}>
                    {d1? `${d1.getDate().toString().length<2?'0':''}${d1.getDate()}-${months[d1.getMonth()].substring(0,3)}-${(d1.getFullYear()).toString().substring(2)}`: ""}</FormLabel>
                  <FormLabel sx={{width:"5.6vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "90%"}}>the year</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "83%"}}>Adjustment</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderRight:1, textAlign: "center", fontSize: "90%"}}>
                    {d? `${d.getDate()}-${months[d.getMonth()].substring(0,3)}-${(d.getFullYear()).toString().substring(2)}`: ""}</FormLabel>
                  <FormLabel sx={{width:"4vw", borderRight:1, textAlign: "center",
                    fontSize: "90%"}}>Rate</FormLabel>
                  <FormLabel sx={{width:"5.4vw", borderTop:1, borderRight:1, textAlign: "center", fontSize: "90%"}}>
                    {d1? `${d1.getDate().toString().length<2?'0':''}${d1.getDate()}-${months[d1.getMonth()].substring(0,3)}-${(d1.getFullYear()).toString().substring(2)}`: ""}</FormLabel>
                  <FormLabel sx={{width:"5.6vw", borderTop:1, borderRight:1, textAlign: "center",
                    fontSize: "90%"}}>the year</FormLabel>
                  <FormLabel sx={{width:"5vw", borderRight:1, textAlign: "center",
                    fontSize: "83%"}}>Adjustment</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderRight:1, textAlign: "center", fontSize: "90%"}}>
                    {d? `${d.getDate()}-${months[d.getMonth()].substring(0,3)}-${(d.getFullYear()).toString().substring(2)}`: ""}</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderRight:1, textAlign: "center", fontSize: "90%"}}>
                    {d? `${d.getDate()}-${months[d.getMonth()].substring(0,3)}-${(d.getFullYear()).toString().substring(2)}`: ""}</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, paddingTop: 0, textAlign: "center", fontSize: "90%"}}>
                    {d? `${d.getDate()}-${months[d.getMonth()].substring(0,3)}-${(d.getFullYear()-1).toString().substring(2)}`: ""}</FormLabel>
                </FormGroup>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"1.7vw", borderBottom:2, borderRight:1}}></FormLabel>
                  <FormLabel sx={{width:"8.5vw", borderBottom:2, borderRight:1}}></FormLabel>
                  <FormLabel sx={{width:"8.55vw", borderBottom:2, borderRight:1}}></FormLabel>
                  <FormLabel sx={{width:"5.4vw", borderTop:1, borderBottom:2, borderRight:1,
                    fontSize: "90%", textAlign:"center"}}>Taka</FormLabel>
                  <FormLabel sx={{width:"5.6vw", borderTop:1, borderBottom:2, borderRight:1,
                    fontSize: "90%", textAlign:"center"}}>Taka</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderBottom:2, borderRight:1,
                    fontSize: "90%", textAlign:"center"}}>Taka</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderBottom:2, borderRight:1,
                    fontSize: "90%", textAlign:"center"}}>Taka</FormLabel>
                  <FormLabel sx={{width:"4vw", borderTop:1, borderBottom:2, borderRight:1,
                    fontSize: "95%", textAlign:"center"}}> % </FormLabel>
                  <FormLabel sx={{width:"5.4vw", borderTop:1, borderBottom:2, borderRight:1,
                    fontSize: "90%", textAlign:"center"}}>Taka</FormLabel>
                  <FormLabel sx={{width:"5.6vw", borderTop:1, borderBottom:2, borderRight:1,
                    fontSize: "90%", textAlign:"center"}}>Taka</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderBottom:2, borderRight:1,
                    fontSize: "90%", textAlign:"center"}}>Taka</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderBottom:2, borderRight:1,
                    fontSize: "90%", textAlign:"center"}}>Taka</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderBottom:2, borderRight:1,
                    fontSize: "90%", textAlign:"center"}}>Taka</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderBottom:2, paddingTop: 0,
                    fontSize: "90%", textAlign:"center"}}>Taka</FormLabel>
                </FormGroup>
                {/*----------------------------------------------------------------------------------------------*/}
                {anx.lines && anx.lines.map((line, li) => {
                  const ln = formValues.annexes[i].lines[li];
                  const shLn = (ln&&((ln.desc&&ln.desc!==" ")||ln.head||ln.addition||
                    ln.disposal||ln.bal||ln.rate||ln.depAmt||ln.depAdj||ln.depBal))||line.desc;
                  return (inputMode<1&& !shLn? null:
                    <FormControl sx={{paddingTop: 0}} key={li}>
                      <FormGroup row sx={{justifyContent: "space-between"}}>
                        <FormLabel sx={{width:"1.7vw", borderBottom:1, borderRight:1, textAlign:"center",
                          fontSize: "90%"}}>{shLn?((ln?ln:line).sl=++sl,sl):""}</FormLabel>
                        {inputMode<1? <FormLabel sx={{width:"8.5vw", borderBottom:1, borderRight:1,
                          fontSize: "90%"}}>{ln&& ln.desc? ln.desc: line.desc}</FormLabel>:
                        <Autocomplete options={getAnxHeads(i)} sx={{width: "8.5vw"}}
                          renderInput={(params) => (<TextField name={`head_${i}_${li}`} {...params} />)}
                          getOptionLabel={(option) => `${option}`} //
                          renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)} size="small"
                          value={(ln&& ln.desc? ln.desc: line.desc)} //line.desc
                          isOptionEqualToValue={(option, value) => option === value || value === ""}
                          onChange={handleAutoCompleteChange.bind(null, `head_${i}_${li}`)} />
                        }

                        {inputMode<1? <FormLabel sx={{width:"8.55vw", borderBottom:1, borderRight:1,
                          fontSize: "90%"}}>{ln&& ln.head? ln.head: line.head}</FormLabel>:
                        <TextField id="head" name={`head_${i}_${li}`} variant="outlined"
                          sx={{width: "8.55vw", fontSize: "90%"}} size="small" //disabled={inputMode<1}
                          value={ln&& ln.head? ln.head: line.head} onChange={handleTextFieldChange} />
                        }

                        {inputMode<1? <FormLabel sx={{width:"5.4vw", borderBottom:1, borderRight:1, fontSize:"93%",
                          textAlign:"center"}}>{negBracket(formValues.annex1[i].lines[li]?formValues.annex1[i].lines[li].bal:0)}</FormLabel>:
                        <TextField id="opBal" name={`opBal_${i}_${li}`} variant="outlined" size="small"
                          sx={{width: "5.4vw"}} value={commaFormat(formValues.annex1[i].lines[li]?formValues.annex1[i].lines[li].bal:0)}
                          onChange={handleTextFieldChange} />}
                        {inputMode<1? <FormLabel sx={{width:"5.6vw", borderBottom:1, borderRight:1, fontSize:"93%",
                          textAlign:"center"}}>{negBracket(formValues.annexes[i].lines[li]?formValues.annexes[i].lines[li].addition:0)}</FormLabel>:
                        <TextField id="addition" name={`addition_${i}_${li}`} variant="outlined" size="small"
                          sx={{width: "5.6vw"}} disabled={inputMode<1} value={commaFormat(
                          formValues.annexes[i].lines[li]?formValues.annexes[i].lines[li].addition:0)} onChange={handleTextFieldChange} />}
                        {inputMode<1? <FormLabel sx={{width:"5vw", borderBottom:1, borderRight:1, fontSize: "93%",
                          textAlign:"center"}}>{negBracket(formValues.annexes[i].lines[li]?formValues.annexes[i].lines[li].disposal:0)}</FormLabel>:
                        <TextField id="disposal" name={`disposal_${i}_${li}`} variant="outlined" size="small"
                          sx={{width: "5vw"}} disabled={inputMode<1} value={commaFormat(
                          formValues.annexes[i].lines[li]?formValues.annexes[i].lines[li].disposal:0)} onChange={handleTextFieldChange} />}
                        <FormLabel sx={{width:"5vw", borderBottom:1, borderRight:1, textAlign:"center",
                          fontSize: "93%"}}>{negBracket(formValues.annexes[i].lines[li]?formValues.annexes[i].lines[li].bal:0)}</FormLabel>

                        {inputMode<1? <FormLabel sx={{width:"4vw", borderBottom:1, borderRight:1, fontSize: "93%",
                          textAlign:"center"}}>{commaFormat(formValues.annexes[i].lines[li]?formValues.annexes[i].lines[li].rate:0)}%</FormLabel>:
                        <TextField id="rate" name={`rate_${i}_${li}`} variant="outlined" size="small"
                          sx={{width: "4vw"}} disabled={inputMode<1} value={commaFormat(
                          formValues.annexes[i].lines[li]?formValues.annexes[i].lines[li].rate:0)} onChange={handleTextFieldChange} />}

                        {inputMode<1? <FormLabel sx={{width:"5.4vw", borderBottom:1, borderRight:1, fontSize:"93%",
                          textAlign:"center"}}>{negBracket(formValues.annex1[i].lines[li]?formValues.annex1[i].lines[li].depBal:0)}</FormLabel>:
                        <TextField id="opDep" name={`opDep_${i}_${li}`} variant="outlined" size="small"
                          sx={{width: "5.4vw"}} value={commaFormat(formValues.annex1[i].lines[li]?formValues.annex1[i].lines[li].depBal:0)}
                          onChange={handleTextFieldChange} />}
                        <FormLabel sx={{width:"5.6vw", borderBottom:1, borderRight:1, textAlign:"center",
                          fontSize: "93%"}}>{commaFormat(formValues.annexes[i].lines[li]?formValues.annexes[i].lines[li].depAmt:0)}</FormLabel>
                        {inputMode<1? <FormLabel sx={{width:"5vw", borderBottom:1, borderRight:1, fontSize: "93%",
                          textAlign:"center"}}>{negBracket(formValues.annexes[i].lines[li]?formValues.annexes[i].lines[li].depAdj:0)}</FormLabel>:
                        <TextField id="depAdj" name={`depAdj_${i}_${li}`} variant="outlined" size="small"
                          sx={{width: "5vw"}} value={commaFormat(formValues.annexes[i].lines[li]?formValues.annexes[i].lines[li].depAdj:0)}
                          onChange={handleTextFieldChange} />}
                        <FormLabel sx={{width:"5vw", borderBottom:1, borderRight:1, textAlign:"center",
                          fontSize: "93%"}}>{negBracket(formValues.annexes[i].lines[li]?formValues.annexes[i].lines[li].depBal:0)}</FormLabel>

                        <FormLabel sx={{width:"5vw", borderBottom:1, borderRight:1, textAlign:"center",
                          fontSize: "93%"}}>{negBracket(formValues.annexes[i].lines[li]?(formValues.annexes[i].lines[li].bal -
                          formValues.annexes[i].lines[li].depBal):0)}</FormLabel>
                        <FormLabel sx={{width:"5vw", borderBottom:1, paddingTop:0, fontSize:"93%",
                          textAlign:"center"}}>{negBracket(formValues.annex1[i].lines[li]?(formValues.annex1[i].lines[li].bal -
                          formValues.annex1[i].lines[li].depBal):0)}</FormLabel>
                      </FormGroup>
                    </FormControl>
                  );
                })}
                {/*----------------------------------------------------------------------------------------------*/}
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"10.2vw", border:1, borderLeft:0, textAlign:"center", fontSize:"93%", fontWeight:"bold"}}>
                    {d? `${d.getDate()} ${months[d.getMonth()]} ${(d.getFullYear()).toString()}`: ""}</FormLabel>
                  <FormLabel sx={{width:"8.55vw", border:1, borderLeft:0}}></FormLabel>
                  <FormLabel sx={{width:"5.4vw", border:1, borderLeft:0, textAlign:"center", fontSize:"93%"}}>
                    {(t1=getAnnexTotal(i,formValues,1), t1? negBracket(t1.bal): "")}</FormLabel>
                  <FormLabel sx={{width:"5.6vw", border:1, borderLeft:0, textAlign:"center", fontSize:"93%"}}>
                    {(t=getAnnexTotal(i,formValues), t? negBracket(t.addition): "")}</FormLabel>
                  <FormLabel sx={{width:"5vw", border:1, borderLeft:0, textAlign: "center", fontSize: "93%"}}>
                    {(t? negBracket(t.disposal): "")}</FormLabel>
                  <FormLabel sx={{width:"5vw", border:1, borderLeft:0, textAlign: "center", fontSize: "93%"}}>
                    {(t? negBracket(t.bal): "")}</FormLabel>
                  <FormLabel sx={{width:"4vw", border:1, borderLeft:0}}></FormLabel>
                  <FormLabel sx={{width:"5.4vw", border:1, borderLeft:0, textAlign:"center", fontSize:"93%"}}>
                    {(t1? negBracket(t1.depBal): "")}</FormLabel>
                  <FormLabel sx={{width:"5.6vw", border:1, borderLeft:0, textAlign:"center", fontSize:"93%"}}>
                    {(t? negBracket(t.depAmt): "")}</FormLabel>
                  <FormLabel sx={{width:"5vw", border:1, borderLeft:0, textAlign: "center", fontSize: "93%"}}>
                    {(t? negBracket(t.depAdj): "")}</FormLabel>
                  <FormLabel sx={{width:"5vw", border:1, borderLeft:0, textAlign: "center", fontSize: "93%"}}>
                    {(t? negBracket(t.depBal): "")}</FormLabel>
                  <FormLabel sx={{width:"5vw", border:1, borderLeft:0, textAlign: "center", fontSize: "93%"}}>
                    {(t? negBracket(t.bal - t.depBal): "")}</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderBottom:1, textAlign:"center", fontSize:"93%"}}>
                    {(t1? negBracket(t1.bal - t1.depBal): "")}</FormLabel>
                </FormGroup>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"30.5vw", borderTop:1, borderBottom:1}}>&nbsp;</FormLabel>
                  <FormLabel sx={{width:"44.25vw", borderTop:1, borderBottom:1}}></FormLabel>
                </FormGroup>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"10.2vw", border:1, borderLeft:0, textAlign:"center", fontSize:"93%", fontWeight:"bold"}}>
                    {d? `${d.getDate()} ${months[d.getMonth()]} ${(d.getFullYear()-1).toString()}`: ""}</FormLabel>
                  <FormLabel sx={{width:"8.55vw", border:1, borderLeft:0}}></FormLabel>
                  {inputMode<1? <FormLabel sx={{width:"5.4vw", border:1, borderLeft:0, textAlign:"center", fontSize:"93%"}}>
                    {(t2=getAnnexTotal(i,formValues,2), t2? negBracket(t2.bal): "")}</FormLabel>:
                  <TextField id="t1OpBal" name={`t1OpBal_${i}`} variant="outlined" size="small"
                    sx={{width: "5.6vw"}} disabled={inputMode<1} value={(t2=getAnnexTotal(i,formValues,2),
                    t2? commaFormat(t2.bal): "")} onChange={handleTextFieldChange} />}

                  {inputMode<1? <FormLabel sx={{width:"5.6vw", border:1, borderLeft:0, textAlign:"center",
                    fontSize:"93%"}}>{(t1? negBracket(t1.addition): "")}</FormLabel>:
                  <TextField id="t1Addition" name={`t1Addition_${i}`} variant="outlined" size="small"
                    sx={{width: "5.6vw"}} disabled={inputMode<1} value={(t1? commaFormat(
                    t1.addition): "")} onChange={handleTextFieldChange} />}

                  <FormLabel sx={{width:"5vw", border:1, borderLeft:0, textAlign: "center", fontSize: "93%"}}>
                    {(t1? negBracket(t1.disposal): "")}</FormLabel>
                  <FormLabel sx={{width:"5vw", border:1, borderLeft:0, textAlign: "center", fontSize: "93%"}}>
                    {(t1? negBracket(t1.bal): "")}</FormLabel>
                  <FormLabel sx={{width:"4vw", border:1, borderLeft:0}}></FormLabel>
                  {inputMode<1? <FormLabel sx={{width:"5.4vw", border:1, borderLeft:0, textAlign:"center",
                    fontSize:"93%"}}>{(t2? negBracket(t2.depBal): "")}</FormLabel>:
                  <TextField id="t1OpDep" name={`t1OpDep_${i}`} variant="outlined" size="small"
                    sx={{width: "5.4vw"}} disabled={inputMode<1} value={(t2? commaFormat(
                    t2.depBal): "")} onChange={handleTextFieldChange} />}

                  {inputMode<1? <FormLabel sx={{width:"5.6vw", border:1, borderLeft:0, textAlign:"center",
                    fontSize:"93%"}}>{(t1? negBracket(t1.depAmt): "")}</FormLabel>:
                  <TextField id="t1DepAmt" name={`t1DepAmt_${i}`} variant="outlined" size="small"
                    sx={{width: "5.6vw"}} disabled={inputMode<1} value={(t1? commaFormat(
                    t1.depAmt): "")} onChange={handleTextFieldChange} />}

                  <FormLabel sx={{width:"5vw", border:1, borderLeft:0, textAlign: "center", fontSize: "93%"}}>
                    {(t1? negBracket(t1.depAdj): "")}</FormLabel>
                  <FormLabel sx={{width:"5vw", border:1, borderLeft:0, textAlign: "center", fontSize: "93%"}}>
                    {(t1? negBracket(t1.depBal): "")}</FormLabel>
                  <FormLabel sx={{width:"5vw", border:1, borderLeft:0, textAlign: "center", fontSize: "93%"}}>
                    {(t1? negBracket(t1.bal - t1.depBal): "")}</FormLabel>
                  <FormLabel sx={{width:"5vw", borderTop:1, borderBottom:1, textAlign:"center", fontSize:"93%"}}>
                    {(t2? negBracket(t2.bal - t2.depBal): "")}</FormLabel>
                </FormGroup>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"30.5vw", borderTop:1}}>&nbsp;</FormLabel>
                  <FormLabel sx={{width:"44.25vw", borderTop:1}}></FormLabel>
                </FormGroup>

                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"29.75vw"}}></FormLabel>
                  <FormLabel sx={{width:"19.4vw",paddingLeft:1,fontSize:"95%"}}>Cost of sales</FormLabel>

                  {inputMode<1? <FormLabel sx={{width:"5.6vw", textAlign:"center", fontSize:"93%"}}>
                    {negBracket(formValues.annexes[i].cos)}</FormLabel>:
                  <TextField id="cos" name={`cos_${i}`} variant="outlined" size="small"
                    sx={{width: "5.6vw"}} disabled={inputMode<1} value={commaFormat(
                    formValues.annexes[i].cos)} onChange={handleTextFieldChange} />}

                  <FormLabel sx={{width:"20vw"}}></FormLabel>
                </FormGroup>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"29.75vw"}}></FormLabel>
                  <FormLabel sx={{width:"19.4vw",paddingLeft:1,fontSize:"95%"}}>Administrative expense</FormLabel>

                  <FormLabel sx={{width:"5.6vw", textAlign:"center", fontSize:"93%"}}>
                    {(t? negBracket(t.depAmt - formValues.annexes[i].cos): "")}</FormLabel>

                  <FormLabel sx={{width:"20vw"}}></FormLabel>
                </FormGroup>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"49.15vw"}}></FormLabel>
                  <FormLabel sx={{width:"5.6vw", borderTop:1, borderBottom:1, textAlign:"center",
                    fontSize:"93%"}}>{(t? negBracket(t.depAmt): "")}</FormLabel>
                  <FormLabel sx={{width:"20vw"}}></FormLabel>
                </FormGroup>
                <FormGroup row sx={{justifyContent: "space-between"}}>
                  <FormLabel sx={{width:"49.15vw"}}></FormLabel>
                  <FormLabel sx={{width:"5.6vw", borderTop:1}}>&nbsp;</FormLabel>
                  <FormLabel sx={{width:"20vw"}}></FormLabel>
                </FormGroup>

              </FormControl>
            );
          })}
        </div>
      </form>
    </Paper>
    <Dialog open={alertOpen} onClose={handleAlertClick}>
      <Alert onClose={handleAlertClick}>
        <AlertTitle>Success!</AlertTitle>
        {alertMesg}
        <div style={{marginTop: 20, display: "flex", justifyContent: "space-between"}}>
          {btn2Text? <Button style={{textTransform: "none", paddingLeft: 0,
           justifyContent: "left"}} onClick={btn2Handler}>{btn2Text}</Button>: null}
          {btn1Text? <Button style={{textTransform: "none", paddingRight: 0, marginLeft: 10,
           justifyContent: "right"}} onClick={btn1Handler}>{btn1Text}</Button>: null}
        </div>
      </Alert>
    </Dialog>
  </>)
};
