import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import uniqNamyrReducer from "./uniqNamyrSlice";
import upldFilesReducer from "./upldFilesSlice";
import upldFilesDataReducer from "./upldFilesDataSlice";
import drtyFilesReducer from "./drtyFilesSlice";
import ovflFilesReducer from "./ovflFilesSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer, uniqNamyr: uniqNamyrReducer, upldFiles: upldFilesReducer,
    upldFilesData: upldFilesDataReducer, drtyFiles: drtyFilesReducer,
    ovflFiles: ovflFilesReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
