import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../redux/authSlice";
import { setUniqNamyr } from "../../redux/uniqNamyrSlice";
import { setUpldFiles } from "../../redux/upldFilesSlice";
import { setUpldFilesData } from "../../redux/upldFilesDataSlice";
import { Font, pdf, PDFViewer } from '@react-pdf/renderer'; //, Page, Text, View, Document
import { saveAs } from 'file-saver';
import { Buffer } from "buffer";
import RobotoRegular from "../../fonts/Roboto/Roboto-Regular.ttf";
import RobotoBold from "../../fonts/Roboto/Roboto-Bold.ttf";
import TopBar from "../../components/topbar/TopBar";
import DocumentList from "../../components/Form/DocumentList";
import DiscussionMemo from "../../components/Form/DiscussionMemo";
// import Allocation from "../../components/Form/Allocation";
import { Login } from "../../components/Login";
import letters, { getLetter } from "../../Data/LetterTemplates";
import { getAllocations, getMgLtrAllocs, getFsDftAllocs,
  getUpldFiles, getReqDays } from "../../Data/DocumentData";
import LettersDoc, { toHtml } from "./LettersDoc";
import styles from './LettersPage.styles';
import "./LettersPage.css";

Font.register({
  family: "Roboto", fonts: [
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoBold,    fontWeight: 700 },
  ]
});

let btn1Handler = () => {}, btn2Handler = () => {};
export const sendMail = async (objMail) => { //: {email:string, subject:string, html:string}
  return fetch('/api/send-mail', {
      method: 'POST', body: JSON.stringify(objMail),
      headers: {'Content-Type': 'application/json'}
  }).then(response => { //console.log("response:", response);
    return response; //.json();
  });
};

const LettersPage = ({ scrollTop, lang }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth.value);
  const uniqNamyr = useSelector(state => state.uniqNamyr.value);
  const defaultAddress = ""; //"23/D/1, Box Culvert Road, Free School Street, Panthapath, Dhaka-1205, Bangladesh.";
  const today = new Date(); //console.log("LettersPage:: today:", today);
  const [documentData, setDocumentData] = useState([]);
  const [currentId, setCurrentId] = useState((documentData.length).toString());
  const [allocationData, setAllocationData] = useState([]);
  const [allocations, setAllocations] = useState(getAllocations(currentId, allocationData));
  const [mgLtrAllocData, setMgLtrAllocData] = useState([]);
  const [mgLtrAllocs, setMgLtrAllocs] = useState(getMgLtrAllocs(currentId, mgLtrAllocData));
  const [fsDftAllocData, setFsDftAllocData] = useState([]);
  const [fsDftAllocs, setFsDftAllocs] = useState(getFsDftAllocs(currentId, fsDftAllocData));
  const getDefaultFormValues = (data, id=currentId) => {
    //console.log("getDefaultFormValues:: id:", id);
    let retVal = null; if(!data) data = documentData;
    const filteredData = data.filter(values => values.id===id);
    //console.log("getDefaultFormValues:: filteredData:", filteredData);
    if(filteredData.length > 0) retVal = filteredData[0]; else retVal = {
      id: (data.length+1).toString(), name: "", yrendDate: //Justified Company Limited
      `6/30/${today.getMonth()<6?today.getFullYear()-1:today.getFullYear()}`,
      address: defaultAddress, statCoi: "N", statDfin: "N", statLfin: "N", statMoa: "N",
      statEtin: "N", statTlic: "N",statBin:"N/A",statFxii:"N",statSchx:"N",statVcrd:"N",
      statF23b: "N", statAbst: "N",
      mtStart: today.toString(), mtEnd: today.toString(), mtDuration: "0 : 00",
      rqDays: `${getReqDays(allocations, mgLtrAllocs, fsDftAllocs)}`, // "7"
      rqTime: "", urgency: "", hrDelivery: "",
      period: "for the year ended", fsType: "FS_(0)_Op",
      email: "", mobile: "", bzCard: "N", revenue: "", revnLyr: "", //Y : 25,030,000
      tAssets: "", tAsstLy: "", borrows: "", borrwLy: "", //20,000,000 : 6,000,000
      profLos: "", prLosLy: "", rs1Name: "Rouf", rs2Name: "Ayasa", rs3Name: "Mitu", //-2,500,000
      rs4Name: "Rupa", rsMnames: "Ayasa,Mitu",
      agreed: "N", qLnItems: "0", qBnAccts: "0", qLnAccts: "0", corrErrs: "N", //Y : 15 : 1 : 1 : Y
      qToDToC: "0", qSbTests: "0", mangLetr: "N", fsDfBnSt: "N", visiting: "N", //3 : 29
      rsNet: "0", rs1Tot: "0", rs2Tot: "0", rs3Tot: "", //59,200 : 41,167 : 26,568 : 17,489
      feeLyear: "0", feeIcab: "0", feeMar: "0", fsNet: "0", visitNet: "0",
      reguSup: "0", rjscRet: "0", taxReturn: "0", oth1Amt: "0", oth1Txt: "",
      oth2Amt: "0", oth2Txt: "Others", profCost: "0", discount: "0", hideDtl: "",
      efficiency: "50",
    };
    //console.log("getDefaultFormValues:: retVal:", retVal);
    return retVal;
  }
  const [formValues, setFormValues] = useState(getDefaultFormValues());
  const [inputMode, setInputMode] = useState(0); // 0 - Read Only, 1 - Add New, 2 - Edit

  const bangla = (lang.toLowerCase()==="bn");
  const [option, setOption] = useState(0);
  const [form, setForm] = useState(0);
  const [login, setLogin] = useState(true);
  const [message, setMessage] = useState(""); //You are not logged in
  const [userEmail, setUserEmail] = useState("");
  const [userType, setUserType] = useState("");
  useEffect(() => {
    (async () => {
      try {
        let resp = await fetch("/api/user", { //http://localhost:8000
          credentials: "include"}); //console.log(resp); //mode:"cors",
        if(!resp.ok){
          resp = await fetch("/api/refresh", { //http://localhost:8000
            method:"POST", credentials: "include"}); //console.log(resp); //, mode:"cors"
          resp = await fetch("/api/user", {credentials:"include"}); //http://localhost:8000
          //console.log(resp);
        }
        if(resp.ok) { dispatch(setAuth(true));
          const data = await resp.json(); //console.log(data);
          setMessage(`Welcome, ${data.first_name} ${data.last_name}`); //user
          setUserEmail(data.email); setUserType(data.type);
        }
      } catch(e) { dispatch(setAuth(false));
        //setMessage("You are not logged in");
      }
    })();
  }, [dispatch, login]);
  if(auth && login) {
    setLogin(false); //console.log("auth:", auth, ", login:", login);
  }
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); let id;
      const res = await fetch('/api/company-data'); // http://localhost:3100
      const resData = await res.json();
      setDocumentData(resData.documentData);
      if(resData.documentData.length > 0) {
        id = resData.documentData[resData.documentData.length-1].id;
        setCurrentId(id); //console.log("useEffect:: id:", id);
      }
      const values = getDefaultFormValues(resData.documentData, id);
      setFormValues(values);
      setAllocations(getAllocations(id, resData.allocationData));
      setMgLtrAllocs(getMgLtrAllocs(id, resData.mgLtrAllocData));
      setFsDftAllocs(getFsDftAllocs(id, resData.fsDftAllocData));
      dispatch(setUpldFiles(getUpldFiles(id, resData.upldFilesData)));
      setFsDftAllocData(resData.fsDftAllocData);
      setMgLtrAllocData(resData.mgLtrAllocData);
      setAllocationData(resData.allocationData);
      dispatch(setUpldFilesData(resData.upldFilesData));
      //console.log("resData.upldFilesData[0].upldFiles[0].files[0]:", resData.upldFilesData[0].upldFiles[0].files[0]); //.usr
      setIsLoading(false);
    };
    fetchData();
  }, []); // option, form

  const saveHandler = (option, e) => { // console.log("saveHandler: option =", option);
    e.stopPropagation(); if(!option) return false;
    let fileName;
    switch(option) {
      case 1: fileName = "Quotation.pdf"; break;
      case 2: fileName = "Appointment_Letter.pdf"; break;
      case 3: fileName = "Engagement_Letter.pdf"; break;
    }
    if(option && fileName) { // console.log("option =", option);
      pdf( <LettersDoc letter={getLetter(option, formValues, allocations)} /> )
        .toBlob().then(blob => { saveAs(blob, fileName) });
      console.log("File saved:", fileName);
    }
  };
  const handleConfirmSendMail = async (blob, fileName) => { //console.log("handleConfirmSendMail: blob:", blob);
    const arBuffer = await blob.arrayBuffer(); //console.log("handleConfirmSendMail: arBuffer:", arBuffer);
    const buffer = Buffer.from(arBuffer); //console.log("handleConfirmSendMail: buffer:", buffer);
    const letr = toHtml(getLetter(option, formValues, allocations)); //console.log("letr:", letr);
    let html = `<html>
      <head><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,700&display=swap"
        rel="stylesheet"><style>
        * {margin:0;padding:0;box-sizing:border-box;}
        body {font-family:Roboto,"Helvetica Neue",Arial,sans-serif;font-size:1rem;line-height:1.5;}
        .page {background-color:#f4f4f4;} .bold {font-weight:700;}
        .title {text-align:center;font-weight:bold;} .underline {text-decoration:underline}
        .indented {margin:0 76px 0 38px;} .indentLeft {margin-left:38px;}
        .bordered {border:1px solid #000;} .borderBottom {border-bottom:1px solid #000;}
        .borderBottomThick {border-bottom:2px solid #000;} .borderTop {border-top:1px solid #000;}
        .relative {position:relative;}
        .text {width:100%;flex:1;} .right {flex:1;text-align:right;}
        .left {width:50%;position:absolute;left:-38px;top:0;margin-left:-38px !important;}
        .middle {margin-left:55.7%;padding-left:18px;width:38%;text-align:center;flex:1;}
        p span {display:inline-block;vertical-align:middle;}
      </style></head>
      <body><div style="box-sizing:border-box;">
        `; html+=letr; html+=`
      </div></body>
    </html>`; //console.log(html);
    const content = { //mahbubur.rahman.64@gmail.com, mahbub.bysontech@gmail.com
      email: formValues.email, subject: "Quotation - "+formValues.name, //"Quotation",
      html, fileName, buffer, cc: "rouf106rrh@gmail.com, ayasa.afruza81@gmail.com"
    };
    content.bcc = "mahbubur.rahman.64@gmail.com"+(userEmail?", "+userEmail:""); //if(userEmail)
    sendMail(content).then((json) => { //formValues.email
      console.log("sendMail:: json:", json);
      // setInputMode(0); setTimeout(() => { autoRef.current?.focus();
      //   setAlertMesg("Email sent to address: "+formValues.email); setBtn1Text("OK");
      //   btn1Handler = handleAlertClick; setBtn2Text(""); setAlertOpen(true);
      // }, 100);
    })
    .catch(error => console.log("sendMail:: error:", error));
  };
  const mailHandler = (option, e) => { //console.log("mailHandler: option =", option);
    e.stopPropagation(); if(!option) return false;
    let fileName;
    switch(option) {
      case 1: fileName = "Quotation.pdf"; break;
      case 2: fileName = "Appointment_Letter.pdf"; break;
      case 3: fileName = "Engagement_Letter.pdf"; break;
    }
    if(option && fileName) { // console.log("option =", option);
      pdf( <LettersDoc letter={getLetter(option, formValues, allocations)} /> )
        .toBlob().then(blob => { //console.log("mailHandler:: blob:", blob);
          //saveAs(blob, fileName) // To be replaced to handle Mail
          handleConfirmSendMail(blob, fileName);
          // setAlertMesg("Do you want to send email to address: " + "mahbub@gmail.com"); //formValues.email
          // btn1Handler = handleConfirmSendMail;
          // btn2Handler = () => { handleClearClick(); handleAlertClick(); };
          // setBtn1Text("Yes"); setBtn2Text("No"); setAlertOpen(true);
        });
      console.log("Mail sent:");
    }
  };
  const menuHandler = (i, e) => { // console.log("i =", i);
    e.stopPropagation(); setLogin(false); setForm(0); setOption(i); return false;
  }
  const formHandler = (i, e) => { // console.log("i =", i);
    e.stopPropagation(); setLogin(false); setForm(i); setOption(0); return false;
  }
  const loginHandler = (b, e) => { //console.log("b =", b);
    if(e) e.stopPropagation();
    setLogin(b); setForm(0); setOption(0); return false;
  }
  const logoutHandler = async (e) => { e.stopPropagation();
    const resp = await fetch("/api/logout", { //http://localhost:8000
      method:"POST", credentials: "include"
    });
    const jsnData = await resp.json(); //console.log(jsnData);
    dispatch(setAuth(false));
    setLogin(false); setForm(0); setOption(0); return false;
  }
  useEffect(() => { // console.log("useEffect");
    window.addEventListener("keyup", e => { // console.log("e.key:", e.key);
      if(e.key === "Escape") { setOption(0); setForm(0); }
    });
  }, [setOption, setForm]);
  useEffect(() => { if(inputMode===1) {
    const interval = setInterval(() => {
      setFormValues((formValues) => ({...formValues, mtEnd: (new Date()).toString()}));
    }, 15000);
    return () => clearInterval(interval);
  } }, [inputMode]);

  return (
    // <PDFViewer style={{width: '98%', height: '95vh'}}>
    // <PDFViewer scale={5.0} style={{ width: "98%", height: "95vh", scale: 5.0 }}>
    <div>
      <TopBar scrollTop={scrollTop} bangla={bangla} />
      <div style={styles.sidebar} className="sidebar" onClick={menuHandler.bind(this,0)}>
        <div style={styles.sidemenu}>
          <h1 style={styles.h1}>Quotation & Letters [ISA210]</h1>
          <ul style={styles.ul}>
            <li onClick={formHandler.bind(this,1)}><a href="#">Document List</a></li>
            <li onClick={formHandler.bind(this,2)}><a href="#">Discussion Memo</a></li>
            {/* <li onClick={formHandler.bind(this,3)}><a href="#">Allocation</a></li> */}
            <li onClick={menuHandler.bind(this,1)}><a href="#">Quotation</a></li>
            <li onClick={menuHandler.bind(this,2)}><a href="#">Appointment Letter</a></li>
            <li onClick={menuHandler.bind(this,3)}><a href="#">Audit Engagement Letter</a></li>
            <li onClick={menuHandler.bind(this,4)}><a href="#">Professional Clearance</a></li>
            <li onClick={menuHandler.bind(this,5)}><a href="#">Team Deputation</a></li>
            <li onClick={menuHandler.bind(this,6)}><a href="#">Independence Declaration</a></li>
            <li onClick={menuHandler.bind(this,7)}><a href="#">Form 23-B</a></li>
            <li onClick={menuHandler.bind(this,8)}><a href="#">Balance Confirmation</a></li>
          </ul>
        </div>
      </div>     {/*marginLeft:"36%", width:"55%" */}                  {/* relative */}
      <div style={{ marginLeft:"35.5%", width:"55.5%", height:"5.8vh", position:"fixed",
        zIndex:"99", backgroundColor:"#fff" }}>
        <h3 style={{textAlign:"center", fontSize:"113%", fontWeight:"400"}}>{/*43, 50 :: 40, 53*/}
          {auth? message: "You are not logged in"}</h3>
        {auth? (<button style={styles.login} onClick={logoutHandler}>Logout</button>):
        (<button style={styles.login} onClick={loginHandler.bind(this,true)}>Login</button>)}
      </div>
      {login? (<div style={{ marginLeft:"36%", width:"60%", marginTop:"5.8vh" }}>{/*46, 50*/}
        <Login onLogin={loginHandler.bind(this,false)} />{/*43, 53 :: 40, 56*/}
      </div>): null}
      {option && letters[option] &&auth &&["A","M","E"].includes(userType)? (<div style={{ marginLeft:"36%",
        width:"64%", position:"relative", marginTop:"5.8vh" }}>
        <PDFViewer style={styles.window}>{/*46, 100*/}{/*43, 100 :: 40, 100*/}
          <LettersDoc letter={getLetter(option, formValues, allocations)} />
        </PDFViewer>
        <button style={styles.save} onClick={saveHandler.bind(this,option)}>Save</button>
        <button style={styles.mail} onClick={mailHandler.bind(this,option)}>Send Mail</button>
      </div>): null}
      {form? (<div style={{ marginLeft: "33%", width: "67%", position: "relative"
        , marginTop:"5.8vh" }}>{/*46, 54*/}{/* */}
        {form===1 &&auth &&["A","M","E"].includes(userType)? (<DocumentList getDefaultFormValues={getDefaultFormValues} /*43, 57 :: 40, 60*/
          documentData={documentData} setDocumentData={setDocumentData}
          formValues={formValues} setFormValues={setFormValues}
          currentId={currentId} setCurrentId={setCurrentId}
          allocations={allocations} setAllocations={setAllocations}
          allocationData={allocationData} setAllocationData={setAllocationData}
          mgLtrAllocs={mgLtrAllocs} setMgLtrAllocs={setMgLtrAllocs}
          mgLtrAllocData={mgLtrAllocData} setMgLtrAllocData={setMgLtrAllocData}
          fsDftAllocs={fsDftAllocs} setFsDftAllocs={setFsDftAllocs}
          fsDftAllocData={fsDftAllocData} setFsDftAllocData={setFsDftAllocData}
          inputMode={inputMode} setInputMode={setInputMode} userEmail={userEmail}
        />): null}
        {form===2 &&auth &&["A","M","E"].includes(userType)? (<DiscussionMemo getDefaultFormValues={getDefaultFormValues}
          documentData={documentData} setDocumentData={setDocumentData}
          formValues={formValues} setFormValues={setFormValues}
          currentId={currentId} setCurrentId={setCurrentId}
          allocations={allocations} setAllocations={setAllocations}
          allocationData={allocationData} setAllocationData={setAllocationData}
          mgLtrAllocs={mgLtrAllocs} setMgLtrAllocs={setMgLtrAllocs}
          mgLtrAllocData={mgLtrAllocData} setMgLtrAllocData={setMgLtrAllocData}
          fsDftAllocs={fsDftAllocs} setFsDftAllocs={setFsDftAllocs}
          fsDftAllocData={fsDftAllocData} setFsDftAllocData={setFsDftAllocData}
          inputMode={inputMode} setInputMode={setInputMode}
        />): null}
        {/* {form===3? (<Allocation getDefaultFormValues={getDefaultFormValues}
          documentData={documentData} setDocumentData={setDocumentData}
          formValues={formValues} setFormValues={setFormValues}
          currentId={currentId} setCurrentId={setCurrentId}
          allocations={allocations} setAllocations={setAllocations}
        />): null} */}
      </div>): null}
    </div>
  )
};

export default LettersPage;
