import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Button, Tooltip } from "@mui/material";
import { getBal, getNote, getKeyNote, getNoteBal, getNoteMax, getSceTotal, //, getSfp, getPnl
  getCf1, getCfLine, getAnnexTotal, getSfpBal } from "./FinancialData"; //, balIndex
import { dfNotes } from "../components/Form/Notes";
import { annexes } from "../components/Form/AnnexureA";

export const getFinExData = (values, sfp, pnl, keyNotes) => {
  //console.log("values:", values, ", sfp:", sfp, ", pnl:", pnl, ", keyNotes:", keyNotes);
  const fmC = {alignment:{horizontal:"center"}}, fmZG = 'General', fmZP = '0%', //'Percentage',
    fmZ = '_(* #,##0_);_(* (#,##0);_(* "-"??_);_(@_)'; //"#,##0_);\\(#,##0\\)"; //;_(* "-"??_) //"#,##0_);[Red]\\(#,##0\\)";
  const fmCB = {alignment:{horizontal:"center"},border:{top:{style:"thin"},bottom:{style:"thin"}}};
  const fmCBB = {alignment:{horizontal:"center"},border:{bottom:{style:"thin"}}};
  const fmR = {alignment:{horizontal:"right"}}, fmL = {alignment:{horizontal:"left"}}, fmB15 = {font:{bold:true,sz:"15"}};
  //const fmRB = {alignment:{horizontal:"right"},border:{top:{style:"thin"},bottom:{style:"thin"}}};
  const fmBT = {border:{top:{style:"thin"}}}; //medium, blue - ,color:{rgb:"0000FF"}
  const fmBB = {border:{bottom:{style:"thin"}}}, fmBTT = {border:{top:{style:"medium"}}};
  const fmBT14 = {border:{top:{style:"thin"}},font:{bold:true,sz:"14"}};
  const fmB14 = {font:{bold:true,sz:"14"}}, fmB13L = {font:{bold:true,sz:"13"},alignment:{horizontal:"left"}};
  const fmB13 = {font:{bold:true,sz:"13"}}, fmB13I = {font:{bold:true,sz:"13",italic:true}};
  const fm13 = {font:{sz:"13"}},  fmBL = {font:{bold:true},alignment:{horizontal:"left"}};
  const fmB13IT = {font:{bold:true,sz:"13",italic:true},border:{top:{style:"thin"}}};
  const fmB12 = {font:{bold:true,sz:"12"}}, fmB13_6 = {font:{bold:true,sz:"13.6"}};
  const fmB13_7 = {font:{bold:true,sz:"13.7"}}, fmI = {font:{italic:true}}, fmB = {font:{bold:true}};
  const fmBCC = {font:{bold:true},alignment:{horizontal:"center",vertical:"center",wrapText:true}};
  const fm13ITB = {font:{italic:true,sz:"13"},border:{top:{style:"thin"},bottom:{style:"thin"}}};
  const fm13IT = {font:{italic:true,sz:"13"},border:{top:{style:"thin"}}};
  const fpData = [], fpRows = [], plData = [], plRows = [], ecData = [], ecRows = [],
        cfData = [], cfRows = [], ntData = [], ntRows = [], //aaData = [], aaRows = [],
        nxData = [], nxRows = [];
  //--------------------------------------------------------------------------------------------------------------------//
  const fsTotN = {font:{bold:true},border:{top:{style:"thin"},bottom:{style:"thin"}},
    alignment:{horizontal:"right"}}, fsTotNT = {font:{bold:true},border:{top:{style:"thin"},
    bottom:{style:"medium"}},alignment:{horizontal:"right"}};
  let r = 2, c = 1, ri = 0, cap=0, arr=0, dep=0, ern=0, bal=0, d=null, d1=null, d11=null, d2=null, t, t1, t2, x;
  for(const [key, data] of Object.entries(values)) { //console.log("key:", key, ", data:", data, ", r:", r, ", ri:", ri)
    switch(key) {
      case "name":    fpData[r] = [null,{v:data, s:fmB15}];
                      plData[r] = [null,{v:data, s:fmB15}];
                      ecData[r] = [null,{v:data, s:fmB15}];
                      cfData[r] = [null,{v:data, s:fmB15}];
                      ntData[r] = [null,{v:data, s:fmB15}];
                      nxData[r] = [null,{v:data, s:fmB15}];
                      //aaData[r] = [null,{v:data, s:fmB15}];
                      break;
      case "address": fpData[r+1] = [null,data]; plData[r+1] = [null,data]; ecData[r+1] = [null,data];
        cfData[r+1] = [null,data]; ntData[r+1] = [null,data]; nxData[r+1] = [null,data]; //aaData[r+1] = [null,data];
        fpData[r+2] = [null,{v:"Statement of financial position",s:fmB13_6}];
        plData[r+2] = [null,{v:"Statement of profit or loss and other comprehensive income",s:fmB13_6}];
        ecData[r+2] = [null,{v:"Statement of changes in equity",s:fmB13_6}];
        cfData[r+2] = [null,{v:"Statement of cash flows",s:fmB13_6}];
        ntData[r+2] = [null,"",null, "", "", "", "", {v:"In Bangladesh Taka", s:fmR}]; break;
      case "yrendDate": if(data) { d = (new Date(data));
        d1 = new Date(d); if(d1){d1.setFullYear(d1.getFullYear()-1);}
        d11 = new Date(d1); if(d11){d11.setDate( d11.getDate() + 1);}
        d2 = new Date(d); if(d2){d2.setFullYear(d2.getFullYear()-2);d2.setDate(d2.getDate()+1);}
        fpData[r+3] = [null,{t:"d",v:d,s:fmL,z:'"As at" dd Mmmm yyyy'},null, "", "", "", null, {v:"In Bangladesh Taka", s:fmC}];
        fpData[r+4] = [null,"",null, "", "", {v:"Notes",s:fmCBB}, null,
          {t:"d", v:d, s:fmCB, z:"dd Mmmm yyyy"}, {v:"",s:fmBT}, {t:"d", v:d1, s:fmCB, z:"dd Mmmm yyyy"},null,{t:"d",v:d2,s:fmCBB,z:"dd Mmmm yyyy"}];
        plData[r+3] = [null,{t:"d",v:d,s:fmL,z:'"For the year ended" dd Mmmm yyyy'},null,"","","",null,{v:"In Bangladesh Taka", s:fmC}];
        plData[r+4] = [null,"",null, "", "", {v:"Notes",s:fmCBB}, null,
          {t:"d", v:d, s:fmCB, z:"dd Mmmm yyyy"}, {v:"",s:fmBT}, {t:"d", v:d1, s:fmCB, z:"dd Mmmm yyyy"}];
        ecData[r+3] = [null,{t:"d",v:d,s:fmL,z:'"For the year ended" dd Mmmm yyyy'}];
        ecData[r+4] = [null,"",null, null,{v:"In Bangladesh Taka", s:fmR}]; //, "", "", ""
        ecData[r+5] = [null,"",null, null,{v:"Share",s:fmC},{v:"Calls-in-",s:fmC},{v:"Share",s:fmC},{v:"Retained",s:fmC}]; //, "", "", ""
        ecData[r+6] = [null,"",null, null,{v:"capital",s:fmC},{v:"arrear",s:fmC},{v:"money",s:fmC},{v:"earnings",s:fmC},null,{v:"Total equity",s:fmC}];

        //cfData[r+3] = [null,{t:"d",v:d,s:fmL,z:'"For the year ended" dd Mmmm yyyy'}];
        //cfData[r+4] = [null,"",null, null,{v:"In Bangladesh Taka", s:fmR}]; //, "", "", ""
        cfData[r+3] = [null,{t:"d",v:d,s:fmL,z:'"For the year ended" dd Mmmm yyyy'},null,null,{v:"In Bangladesh Taka", s:fmC}]; //,"","",""
        cfData[r+4] = [null,"",null, null, //, "", "", {v:"Notes",s:fmCBB}
          {t:"d", v:d, s:fmCB, z:"dd Mmmm yyyy"}, {v:"",s:fmBT}, {t:"d", v:d1, s:fmCB, z:"dd Mmmm yyyy"}];

        ntData[r+3] = [null,"",null, "", "", "", "",
          {t:"d", v:d, s:fmCB, z:"dd Mmmm yyyy"}, {v:"",s:fmBT}, {t:"d", v:d1, s:fmCB, z:"dd Mmmm yyyy"}];
        } break;
      //------------------------------------------------------------------------------------------------------------//
      // case "annexes": if(data) { ri = 0;
      //   data.forEach((anx, i) => { //console.log("annexes:: i:", i, ", anx:", anx, ", ri:", ri, ", r:", r);
      //     aaData[r+2+ri++] = [null,{v:(x=getNote(anx.note,values),(x?x.name:"")),s:fmB13,z:fmZG},null,null,null,
      //       "","","","","","","","","",(i<1?{v:"Annexure A",s:fm13}:"")];
      //     aaData[r+2+ri++] = [null,{t:"d",v:d,s:fmBL,z:'"As at" dd Mmmm yyyy'}]; ri++; aaRows[r+2+ri] = {'hpt':1};
      //     aaData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
      //     aaData[r+2+ri++] = [null,{v:"Sl",s:fmBCC},{v:"Particulars",s:fmBCC},{v:"as per company's statement heads",s:fmBCC},
      //       {v:"Costs",s:fmBCC},null,null,null,
      //       {v:"Accumulated depreciation and impairment",s:fmBCC},null,null,null,null
      //     ];
      //     aaData[r+2+ri++] = [null,null,null,null,
      //       {v:"Balance at",s:fmBCC},{v:"Addition for",s:fmBCC},{v:"Disposal/",s:fmBCC},{v:"Total",s:fmBCC},
      //       {v:"Rate",s:fmBCC},{v:"Balance at",s:fmBCC},{v:"Charged for",s:fmBCC},{v:"",s:fmBCC},
      //       {v:"Total",s:fmBCC},{v:"W.D.V",s:fmBCC},{v:"W.D.V",s:fmBCC}
      //     ];
      //     aaData[r+2+ri++] = [null,null,null,null,{t:"d",v:d11,s:fmBCC,z:'dd-Mmm-yy'},
      //       {v:"the year",s:fmBCC},{v:"Adjustment",s:fmBCC},{t:"d",v:d,s:fmBCC,z:'dd-Mmm-yy'},
      //       {v:"",s:fmBCC},{t:"d",v:d11,s:fmBCC,z:'dd-Mmm-yy'},{v:"the year",s:fmBCC},{v:"Adjustment",s:fmBCC},
      //       {t:"d",v:d,s:fmBCC,z:'dd-Mmm-yy'},{t:"d",v:d,s:fmBCC,z:'dd-Mmm-yy'},{t:"d",v:d1,s:fmBCC,z:'dd-Mmm-yy'}
      //     ];
      //     aaData[r+2+ri++] = [null,null,null,null,{v:"Taka",s:fmBCC},
      //       {v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},
      //       {v:"",s:fmBCC},{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},
      //       {v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC}
      //     ]; aaRows[r+2+ri] = {'hpt':1};
      //     aaData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
      //     anx.lines.forEach((line, li) => {
      //       const ln = values.annexes[i].lines[li], l1n = values.annex1[i].lines[li];
      //       aaData[r+2+ri++] = [null,(ln?ln.sl:line.sl),(ln&&ln.desc?ln.desc:line.desc),(ln&&ln.head?ln.head:line.head),{t:"n",v:(l1n?l1n.bal:0),z:fmZ},
      //         {t:"n",v:(ln?ln.addition:0),z:fmZ},
      //         {t:"n",v:(ln?ln.disposal:0),z:fmZ},
      //         {t:"n",v:(ln?ln.bal:0),z:fmZ},
      //         {t:"n",v:(ln?ln.rate/100:0),s:fmC,z:fmZP},
      //         {t:"n",v:(l1n?l1n.depBal:0),z:fmZ},
      //         {t:"n",v:(ln?ln.depAmt:0),z:fmZ},
      //         {t:"n",v:(ln?ln.depAdj:0),z:fmZ},
      //         {t:"n",v:(ln?ln.depBal:0),z:fmZ},
      //         {t:"n",v:ln?(ln.bal-ln.depBal):0,z:fmZ},
      //         {t:"n",v:l1n?(l1n.bal-l1n.depBal):0,z:fmZ},
      //         //{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC}
      //       ];
      //     }); aaRows[r+2+ri] = {'hpt':1};
      //     aaData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
      //     aaData[r+2+ri++] = [null,{t:"d",v:d,s:fmBCC,z:'dd Mmmm yyyy'},null,null,
      //       {t:"n",v:(t1=getAnnexTotal(i,values,1),t1?t1.bal:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t=getAnnexTotal(i,values),t?t.addition:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t?t.disposal:0),s:fmB,z:fmZ}, //t=getAnnexTotal(i,values),
      //       {t:"n",v:(t?t.bal:0),s:fmB,z:fmZ},null,
      //       {t:"n",v:(t1?t1.depBal:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t?t.depAmt:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t?t.depAdj:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t?t.depBal:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t?(t.bal-t.depBal):0),s:fmB,z:fmZ},
      //       {t:"n",v:(t1?(t1.bal-t1.depBal):0),s:fmB,z:fmZ},
      //     ]; aaRows[r+2+ri] = {'hpt':1};
      //     aaData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
      //     ri++; aaRows[r+2+ri] = {'hpt':1};
      //     aaData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
      //     aaData[r+2+ri++] = [null,{t:"d",v:d1,s:fmBCC,z:'dd Mmmm yyyy'},null,null,
      //       {t:"n",v:(t2=getAnnexTotal(i,values,2),t2?t2.bal:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t1?t1.addition:0),s:fmB,z:fmZ}, //t1=getAnnexTotal(i,values),
      //       {t:"n",v:(t1?t1.disposal:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t1?t1.bal:0),s:fmB,z:fmZ},null,
      //       {t:"n",v:(t2?t2.depBal:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t1?t1.depAmt:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t1?t1.depAdj:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t1?t1.depBal:0),s:fmB,z:fmZ},
      //       {t:"n",v:(t1?(t1.bal-t1.depBal):0),s:fmB,z:fmZ},
      //       {t:"n",v:(t2?(t2.bal-t2.depBal):0),s:fmB,z:fmZ},
      //     ]; aaRows[r+2+ri] = {'hpt':1};
      //     aaData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
      //     ri++;
      //     aaData[r+2+ri++] = [null,"","","","","","Cost of sales",null,null,null,{t:"n",v:values.annexes[i].cos,z:fmZ}];
      //     aaData[r+2+ri++] = [null,"","","","","","Administrative expense",null,null,null,
      //       {t:"n",v:(t?(t.depAmt-values.annexes[i].cos):0),z:fmZ}];
      //     aaData[r+2+ri++] = [null,"","","","","","",null,null,null,{t:"n",v:(t?t.depAmt:0),z:fmZ,s:fsTotNT}];
      //     ri++;
      //   });
      // } break;
      //------------------------------------------------------------------------------------------------------------//
      case "sce2": if(data) { ri = 0;
        ecData[r+7+ri++] = [null,{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}];
        data.forEach((sec, i) => { //console.log("sce2:: i:", i, ", sec:", sec, ", ri:", ri);
          if(sec.type==="R" && sec.lines) {
            sec.lines.forEach((line, li) => { //console.log("line:", line, ", ri:", ri, ", (r+7+ri):", (r+7+ri));
              ecData[r+7+ri++] = [null,null,line.desc,null,{t:"n",v:(cap=line.capital,cap),z:fmZ},{t:"n",v:(arr=line.arrear,arr),z:fmZ},
              {t:"n",v:(dep=line.deposit,arr),z:fmZ},{t:"n",v:(ern=line.earning,ern),z:fmZ},null,{t:"n",v:(bal=cap+arr+dep+ern,bal),z:fmZ}];
            }); ecRows[r+7+ri] = {'hpt':1};
            ecData[r+7+ri++] = [null,null,{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}];
          } if(sec.type==="B") ri++; //r++;
          ecData[r+7+ri++] = [null,null,{t:"d",v:d2,s:fmB13L,z:'"Balance at" dd Mmmm yyyy '+(sec.type==="R"?'" - restated"':"")},null,
            {t:"n",v:(cap=sec.capital,cap),z:fmZ},{t:"n",v:(arr=sec.arrear,arr),z:fmZ},{t:"n",v:(dep=sec.deposit,dep),z:fmZ},
            {t:"n",v:(ern=sec.earning,ern),z:fmZ},null,{t:"n",v:(bal=cap+arr+dep+ern,bal),z:fmZ}];
        });
      } break;
      case "sce1": if(data) { ri = 0; data.forEach((sec, i) => { //console.log("sce1:: i:", i, ", sec:", sec, ", ri:", ri);
        let cap=0, arr=0, dep=0, ern=0, bal=0;
        if(sec.type==="B") {
          if(sec.lines) sec.lines.forEach((line, li) => { //console.log("line:", line, ", ri:", ri, ", (r+7+ri):", (r+7+ri));
            ecData[r+13+ri++] = [null,null,{t:(line.desc==="TI"?"d":"s"),v:(line.desc==="TI"?d1:line.desc),s:fmL,
              z:'"Total comprehensive income for the year ended" dd Mmmm yyyy'},null,{t:"n",v:(cap=line.capital,cap),z:fmZ},
              {t:"n",v:(arr=line.arrear,arr),z:fmZ},{t:"n",v:(dep=line.deposit,dep),z:fmZ},
              {t:"n",v:(ern=line.earning,ern),z:fmZ},null,{t:"n",v:(bal=cap+arr+dep+ern,bal),z:fmZ}];
          }); ecRows[r+13+ri] = {'hpt':1};
          ecData[r+13+ri++] = [null,{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}];
          ecData[r+13+ri++] = [null,null,{t:"d",v:d1,s:fmB13L,z:'"Balance at" dd Mmmm yyyy'},null,{t:"n",v:(t1=getSceTotal(i,values,1),
            cap=(values.sce2[1].capital+t1.capital),values.sce1[0].capital=cap,cap),s:fmB13,z:fmZ},{t:"n",v:( //t1=getSceTotal(i,values,1),
            arr= (values.sce2[1].arrear+t1.arrear), values.sce1[0].arrear= arr,arr),s:fmB13,z:fmZ},{t:"n",v:(
            dep=(values.sce2[1].deposit+t1.deposit),values.sce1[0].deposit=dep,dep),s:fmB13,z:fmZ},{t:"n",v:(
            ern=(values.sce2[1].earning+t1.earning),values.sce1[0].earning=ern,ern),s:fmB13,z:fmZ},null,{t:"n",v:(
            bal=(cap+arr+dep+ern),bal),s:fmB13,z:fmZ}]; ecRows[r+13+ri] = {'hpt':1};
          ecData[r+14+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
          ri++; //r++;
          ecData[r+13+ri++] = [null,null,{t:"d",v:d11,s:fmB13L,z:'"Balance at" dd Mmmm yyyy'},null,{t:"n",v:(t1=getSceTotal(i,values,1),
            cap=(values.sce2[1].capital+t1.capital),values.sce1[0].capital=cap,cap),s:fmB13,z:fmZ},{t:"n",v:( //t1=getSceTotal(i,values,1),
            arr= (values.sce2[1].arrear+t1.arrear),values.sce1[0].arrear= arr,arr), s:fmB13,z:fmZ},{t:"n",v:(
            dep=(values.sce2[1].deposit+t1.deposit),values.sce1[0].deposit=dep,dep),s:fmB13,z:fmZ},{t:"n",v:(
            ern=(values.sce2[1].earning+t1.earning),values.sce1[0].earning=ern,ern),s:fmB13,z:fmZ},null,{t:"n",v:(
            bal=(cap+arr+dep+ern),bal),s:fmB13,z:fmZ}];
        }
      });} break;
      case "sces": if(data) { ri = 0; data.forEach((sec, i) => { //console.log("sces:: i:", i, ", sec:", sec, ", ri:", ri);
        //ecData[r+22+ri++] = [null,{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}]; //,{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}
        if(sec.type==="B" && sec.lines) {
          sec.lines.forEach((line, li) => { //console.log("line:", line, ", ri:", ri);
            ecData[r+24+ri++] = [null,null,{t:(line.desc==="TI"?"d":"s"),v:(line.desc==="TI"?d:line.desc),s:fmL,
            z:'"Total comprehensive income for the year ended" dd Mmmm yyyy'},null,
            {t:"n",v:(cap=line.capital,cap),z:fmZ},{t:"n",v:(arr=line.arrear,arr),z:fmZ},{t:"n",v:(dep=line.deposit,dep),z:fmZ},
            {t:"n",v:(ern=line.earning,ern),z:fmZ},null,{t:"n",v:(bal=cap+arr+dep+ern,bal),z:fmZ}];
          }); ecRows[r+24+ri] = {'hpt':1};
          ecData[r+24+ri++] = [null,{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}];
          ecData[r+24+ri++] = [null,null,{t:"d",v:d,s:fmB13L,z:'"Balance at" dd Mmmm yyyy'},null,{t:"n",v:(t=getSceTotal(i,values),
            cap=(values.sce1[0].capital+t.capital),values.sces[0].capital=cap,cap),s:fmB13,z:fmZ},{t:"n",v:( //t=getSceTotal(i,values),
            arr= (values.sce1[0].arrear+t.arrear),values.sces[0].arrear= arr,arr), s:fmB13,z:fmZ},{t:"n",v:(
            dep=(values.sce1[0].deposit+t.deposit),values.sces[0].deposit=dep,dep),s:fmB13,z:fmZ},{t:"n",v:(
            ern=(values.sce1[0].earning+t.earning),values.sces[0].earning=ern,ern),s:fmB13,z:fmZ},null,{t:"n",v:(
            bal=(cap+arr+dep+ern),bal),s:fmB13,z:fmZ}]; ecRows[r+24+ri] = {'hpt':1};
          ecData[r+24+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
          ecData[r+24+ri++] = [null,"Statement of changes in equityAnnexed notes form an integral parts of these Financial Statements."];
        }
      });} break;
    }
  }; //console.log("ntData:", ntData);
  //--------------------------------------------------------------------------------------------------------------------//
  ri = 0; const nxMrge = [];
  annexes.forEach((anx, i) => { let sl = 0; //console.log("annexes:: i:", i, ", anx:", anx, ", ri:", ri, ", r:", r);
    nxData[r+2+ri++] = [null,{v:(x=getNote(anx.note,values),(x?x.name:"")),s:fmB13,z:fmZG},null,null,null,
      "","","","","","","","","",(i<1?{v:"Annexure A",s:fm13}:"")];
    nxMrge.push({s:{c:c,r:(r+2+ri)}, e:{c:c+1,r:(r+2+ri)}}); //r:5
    nxData[r+2+ri++] = [null,{t:"d",v:d,s:fmBL,z:'"As at" dd Mmmm yyyy'}]; ri++; nxRows[r+2+ri] = {'hpt':1};
    nxData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
    nxMrge.push({s:{c:c, r:(r+2+ri)}, e:{c:c, r:(r+2+ri+3)}}); //r:8,11
    nxMrge.push({s:{c:c+1,r:(r+2+ri)},e:{c:c+1,r:(r+2+ri+3)}});
    nxMrge.push({s:{c:c+2,r:(r+2+ri)},e:{c:c+2,r:(r+2+ri+3)}});
    nxMrge.push({s:{c:c+3,r:(r+2+ri)},e:{c:c+6,r:(r+2+ri)}});  //r:8,8
    nxMrge.push({s:{c:c+7,r:(r+2+ri)},e:{c:c+11,r:(r+2+ri)}});
    nxData[r+2+ri++] = [null,{v:"Sl",s:fmBCC},{v:"Particulars",s:fmBCC},{v:"as per company's statement heads",s:fmBCC},
      {v:"Costs",s:fmBCC},null,null,null,
      {v:"Accumulated depreciation and impairment",s:fmBCC},null,null,null,null
    ];
    nxData[r+2+ri++] = [null,null,null,null,
      {v:"Balance at",s:fmBCC},{v:"Addition for",s:fmBCC},{v:"Disposal/",s:fmBCC},{v:"Total",s:fmBCC},
      {v:"Rate",s:fmBCC},{v:"Balance at",s:fmBCC},{v:"Charged for",s:fmBCC},{v:"",s:fmBCC},
      {v:"Total",s:fmBCC},{v:"W.D.V",s:fmBCC},{v:"W.D.V",s:fmBCC}
    ];
    nxData[r+2+ri++] = [null,null,null,null,{t:"d",v:d11,s:fmBCC,z:'dd-Mmm-yy'},
      {v:"the year",s:fmBCC},{v:"Adjustment",s:fmBCC},{t:"d",v:d,s:fmBCC,z:'dd-Mmm-yy'},
      {v:"",s:fmBCC},{t:"d",v:d11,s:fmBCC,z:'dd-Mmm-yy'},{v:"the year",s:fmBCC},{v:"Adjustment",s:fmBCC},
      {t:"d",v:d,s:fmBCC,z:'dd-Mmm-yy'},{t:"d",v:d,s:fmBCC,z:'dd-Mmm-yy'},{t:"d",v:d1,s:fmBCC,z:'dd-Mmm-yy'}
    ];
    nxData[r+2+ri++] = [null,null,null,null,{v:"Taka",s:fmBCC},
      {v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},
      {v:"",s:fmBCC},{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},
      {v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC}
    ]; nxRows[r+2+ri] = {'hpt':1};
    nxData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
    anx.lines.forEach((line, li) => {
      const ln = values.annexes[i].lines[li], l1n = values.annex1[i].lines[li];
      const shLn = (ln&&((ln.desc&&ln.desc!==" ")||ln.head||ln.addition||
        ln.disposal||ln.bal||ln.rate||ln.depAmt||ln.depAdj||ln.depBal))||line.desc;
      if(shLn) nxData[r+2+ri++] = [null, ++sl, //(ln?ln.sl:line.sl),
        (ln&&ln.desc?ln.desc:line.desc),(ln&&ln.head?ln.head:line.head),
        {t:"n",v:(l1n?l1n.bal:0),z:fmZ},
        {t:"n",v:(ln?ln.addition:0),z:fmZ},
        {t:"n",v:(ln?ln.disposal:0),z:fmZ},
        {t:"n",v:(ln?ln.bal:0),z:fmZ},
        {t:"n",v:(ln?ln.rate/100:0),s:fmC,z:fmZP},
        {t:"n",v:(l1n?l1n.depBal:0),z:fmZ},
        {t:"n",v:(ln?ln.depAmt:0),z:fmZ},
        {t:"n",v:(ln?ln.depAdj:0),z:fmZ},
        {t:"n",v:(ln?ln.depBal:0),z:fmZ},
        {t:"n",v:ln?(ln.bal-ln.depBal):0,z:fmZ},
        {t:"n",v:l1n?(l1n.bal-l1n.depBal):0,z:fmZ},
        //{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC},{v:"Taka",s:fmBCC}
      ];
    }); nxRows[r+2+ri] = {'hpt':1};
    nxData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
    nxMrge.push({s:{c:c,r:(r+2+ri)}, e:{c:c+1,r:(r+2+ri)}}); //r:22
    nxData[r+2+ri++] = [null,{t:"d",v:d,s:fmBCC,z:'dd Mmmm yyyy'},null,null,
      {t:"n",v:(t1=getAnnexTotal(i,values,1),t1?t1.bal:0),s:fmB,z:fmZ},
      {t:"n",v:(t=getAnnexTotal(i,values),t?t.addition:0),s:fmB,z:fmZ},
      {t:"n",v:(t?t.disposal:0),s:fmB,z:fmZ}, //t=getAnnexTotal(i,values),
      {t:"n",v:(t?t.bal:0),s:fmB,z:fmZ},null,
      {t:"n",v:(t1?t1.depBal:0),s:fmB,z:fmZ},
      {t:"n",v:(t?t.depAmt:0),s:fmB,z:fmZ},
      {t:"n",v:(t?t.depAdj:0),s:fmB,z:fmZ},
      {t:"n",v:(t?t.depBal:0),s:fmB,z:fmZ},
      {t:"n",v:(t?(t.bal-t.depBal):0),s:fmB,z:fmZ},
      {t:"n",v:(t1?(t1.bal-t1.depBal):0),s:fmB,z:fmZ},
    ]; nxRows[r+2+ri] = {'hpt':1};
    nxData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
    ri++; nxRows[r+2+ri] = {'hpt':1};
    /*[{s:{c:c,r:34}, e:{c:c+1,r:34}},
      {s:{c:c,r:37}, e:{c:c,r:40}},{s:{c:c+1,r:37}, e:{c:c+1,r:40}},{s:{c:c+2,r:37}, e:{c:c+2,r:40}},
      {s:{c:c+3,r:37}, e:{c:c+6,r:37}},{s:{c:c+7,r:37}, e:{c:c+11,r:37}},
      {s:{c:c,r:47}, e:{c:c+1,r:47}},{s:{c:c,r:51}, e:{c:c+1,r:51}}],  */
    nxData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
    nxMrge.push({s:{c:c,r:(r+2+ri)}, e:{c:c+1,r:(r+2+ri)}}); //r:26
    nxData[r+2+ri++] = [null,{t:"d",v:d1,s:fmBCC,z:'dd Mmmm yyyy'},null,null,
      {t:"n",v:(t2=getAnnexTotal(i,values,2),t2?t2.bal:0),s:fmB,z:fmZ},
      {t:"n",v:(t1?t1.addition:0),s:fmB,z:fmZ}, //t1=getAnnexTotal(i,values),
      {t:"n",v:(t1?t1.disposal:0),s:fmB,z:fmZ},
      {t:"n",v:(t1?t1.bal:0),s:fmB,z:fmZ},null,
      {t:"n",v:(t2?t2.depBal:0),s:fmB,z:fmZ},
      {t:"n",v:(t1?t1.depAmt:0),s:fmB,z:fmZ},
      {t:"n",v:(t1?t1.depAdj:0),s:fmB,z:fmZ},
      {t:"n",v:(t1?t1.depBal:0),s:fmB,z:fmZ},
      {t:"n",v:(t1?(t1.bal-t1.depBal):0),s:fmB,z:fmZ},
      {t:"n",v:(t2?(t2.bal-t2.depBal):0),s:fmB,z:fmZ},
    ]; nxRows[r+2+ri] = {'hpt':1};
    nxData[r+2+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
    ri++;
    nxData[r+2+ri++] = [null,"","","","","","Cost of sales",null,null,null,{t:"n",v:values.annexes[i].cos,z:fmZ}];
    nxData[r+2+ri++] = [null,"","","","","","Administrative expense",null,null,null,
      {t:"n",v:(t?(t.depAmt-values.annexes[i].cos):0),z:fmZ}];
    nxData[r+2+ri++] = [null,"","","","","","",null,null,null,{t:"n",v:(t?t.depAmt:0),z:fmZ,s:fsTotNT}];
    ri++;
  });
  //--------------------------------------------------------------------------------------------------------------------//
  ri = 0; //const sfp = getSfp();
  sfp.forEach((sec, i) => { //console.log("sec:", sec, ", i:", i);
    let gtot=0, gtot1=0, gtot2=0, stot=0, stot1=0, stot2=0, sgps = [];
    if(sec.link) { let arr = sec.link.split(",");
      arr.forEach(gp => { let si = -1;
        if(gp.length>1) si = parseInt(gp.substring(1));
        if(!isNaN(si) && si>=0) sgps.push(si);
      }); //console.log("sec.link:", sec.link, ", sgps:", sgps);
    }
    fpData[r+5+i+ri++] = [null,{v:sec.name, s:fmB14}]; fpRows[r+5+i+ri] = {'hpt':1};
    fpData[r+5+i+ri++] = [null,{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT}];
    sec.groups.forEach((grp, gi) => {
      let bal=0, tot=0, bal1=0, tot1=0, bal2=0, tot2=0;
      fpData[r+5+i+ri++] = [null,{v:grp.name, s:fmB13_7}];
      grp.lines.forEach((line, li) => { const kn = getKeyNote(keyNotes, (line.key? line.key: line.note));
        if(!(kn&& kn.hidden)) fpData[r+5+i+ri++] = [null,null,line.desc,null,null,{v:(kn&& kn.note? kn.note: ""),s:fmC},null, //line.note
          {t:"n",v:(bal=getSfpBal(line.link,values),tot+=bal,bal),z:fmZ},null,
          {t:"n",v:(bal1=getSfpBal(line.link,values,1),tot1+=bal1,bal1),z:fmZ},null,
          {t:"n",v:(bal2=getSfpBal(line.link,values,2),tot2+=bal2,bal2),z:fmZ},null,
        ];
      }); fpRows[r+5+i+ri] = {'hpt':1};
      fpData[r+5+i+ri++] = [null,null,{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}];
      fpData[r+5+i+ri++] = [null,null,{v:grp.totLine,s:fmI},null,null,null,null,
        {t:"n",v:(gtot+=tot,stot+=(sgps.includes(gi)?tot:0),tot),s:fmI,z:fmZ},null,
        {t:"n",v:(gtot1+=tot1,stot1+=(sgps.includes(gi)?tot1:0),tot1),s:fmI,z:fmZ},null,
        {t:"n",v:(gtot2+=tot2,stot2+=(sgps.includes(gi)?tot2:0),tot2),s:fmI,z:fmZ},null,
      ]; fpRows[r+5+i+ri] = {'hpt':1};
      fpData[r+5+i+ri++] = [null,null,{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}];
    });
    if(sec.subTot) {
      fpData[r+5+i+ri++] = [null,null,{v:sec.subTot,s:fmI},null,null,null,null,
        {t:"n",v:stot,s:fmI,z:fmZ},null,{t:"n",v:stot1,s:fmI,z:fmZ},null,{t:"n",v:stot2,s:fmI,z:fmZ},null,
      ]; fpRows[r+5+i+ri] = {'hpt':1};
      fpData[r+5+i+ri++] = [null,null,{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}];
    }
    fpData[r+5+i+ri++] = [null,{v:sec.totLine,s:fmBT14},null,null,null,null,null,
      {t:"n",v:gtot,s:fmB13,z:fmZ},null,{t:"n",v:gtot1,s:fmB13,z:fmZ},null,{t:"n",v:gtot2,s:fmB13,z:fmZ},null,
    ]; fpRows[r+5+i+ri] = {'hpt':1};
    fpData[r+5+i+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
  });
  //--------------------------------------------------------------------------------------------------------------------//
  ri = 0; bal=0; let tot=0, bal1=0, tot1=0; //const pnl = getPnl();
  plData[r+5+ri++] = [null,{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}];
  pnl.forEach((grp, i) => { //console.log("grp:", grp, ", i:", i);
    grp.lines.forEach((line, li) => { const kn = getKeyNote(keyNotes, (line.key? line.key: line.note));
      plData[r+5+i+ri++] = [null,null,{v:line.desc,s:(line.bold?fmB13:fmL)},null,null,{v:(kn&& kn.note? kn.note: ""),s:fmR},null, //line.note
        {t:"n",v:(bal= getSfpBal(line.link,values), tot += bal, bal), s:(line.bold?fmB13:fmR),z:fmZ},null,
        {t:"n",v:(bal1=getSfpBal(line.link,values,1),tot1+=bal1,bal1),s:(line.bold?fmB13:fmR),z:fmZ},null,
      ];
    }); plRows[r+5+i+ri] = {'hpt':1};
    plData[r+5+i+ri++] = [null,(grp.bold?{v:"",s:fmBB}:null),{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}];
    plData[r+5+i+ri++] = [null,null,{v:grp.totLine,s:(grp.bold?fmB13:fmI)},null,null,null,null,
      {t:"n", v:tot,s:(grp.bold?fmB13:fmI),z:fmZ},null, {t:"n",v:tot1,s:(grp.bold?fmB13:fmI),z:fmZ}, null,
    ]; plRows[r+5+i+ri] = {'hpt':1};
    plData[r+5+i+ri++] = [null,(grp.bold?{v:"",s:fmBTT}:null),{v:"",s:(grp.bold?fmBTT:fmBT)},{v:"",s:(grp.bold?fmBTT:fmBT)},{v:"",s:(grp.bold?fmBTT:fmBT)},{v:"",s:(grp.bold?fmBTT:fmBT)},{v:"",s:(grp.bold?fmBTT:fmBT)},{v:"",s:(grp.bold?fmBTT:fmBT)},{v:"",s:(grp.bold?fmBTT:fmBT)},{v:"",s:(grp.bold?fmBTT:fmBT)},{v:"",s:(grp.bold?fmBTT:fmBT)}];
  });
  plData[r+5+4+ri++] = [null,"Annexed notes form an integral parts of these Financial Statements."];
  //--------------------------------------------------------------------------------------------------------------------//
  const cf1 = getCf1(); ri = 0; let gtot=0, gtot1=0; cfRows[r+6+ri] = {'hpt':1};
  cfData[r+6+ri++] = [null,{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT}]; //,{v:"",s:fmBB},{v:"",s:fmBB},{v:"",s:fmBB}
  cf1.forEach((sec, i) => { //console.log("sec:", sec, ", i:", i);
    let bal=0, tot=0, bal1=0, tot1=0;
    if(sec.lineBf) ri++;
    sec.groups.forEach((grp, gi) => { //console.log("grp:", grp);
      cfData[r+6+ri++] = [null,{v:grp.seq,s:fmB13},{v:grp.name,s:fmB13}];
      grp.groups.forEach((sgrp,si) => { //console.log("sgrp:", sgrp);
        sgrp.lines.forEach((line, li) => { //console.log("line:", line);
          cfData[r+6+ri++] = [null,null,{v:line.desc,s:(line.bold?fmB13:fmL)},null,
          {t:"n",v:(bal= getSfpBal(line.link,values), gtot+= bal,tot+= bal,bal),s:(line.bold?fmB13:fmL),z:fmZ},null,
          {t:"n",v:(bal1=getSfpBal(line.link,values,1),gtot1+=bal1,tot1+=bal1,bal1),s:(line.bold?fmB13:fmL),z:fmZ}];
        });
        if(sgrp.totLine) {
          cfData[r+6+ri++] = [null,null,{v:sgrp.totLine,s:(sgrp.bold&&sgrp.italic?fmB13IT:fmL)},null,
          {t:"n",v:tot,s:(sgrp.bold&&sgrp.italic?fmB13IT:fmL),z:fmZ},null,
          {t:"n",v:tot1,s:(sgrp.bold&&sgrp.italic?fmB13IT:fmL),z:fmZ}];
        }
      });
      grp.lines.forEach((line, li) => { //console.log("line:", line);
        cfData[r+6+ri++] = [null,null,{v:line.desc,s:(line.bold?fmB13:fmL)},null,
        {t:"n",v:(bal= getSfpBal(line.link,values), gtot+= bal,tot+= bal,bal),s:(line.bold?fmB13:fmL),z:fmZ},null,
        {t:"n",v:(bal1=getSfpBal(line.link,values,1),gtot1+=bal1,tot1+=bal1,bal1),s:(line.bold?fmB13:fmL),z:fmZ}];
      });
      if(grp.totLine) {
        cfData[r+6+ri++] = [null,null,{v:grp.totLine,s:(grp.italic&&grp.underln?fm13ITB:fm13IT)},null,
        {t:"n",v:tot,s:(grp.italic&&grp.underln?fm13ITB:fm13IT),z:fmZ},null,
        {t:"n",v:tot1,s:(grp.italic&&grp.underln?fm13ITB:fm13IT),z:fmZ}];
      }
    });
    sec.lines.forEach((line, li) => {
      cfData[r+6+ri++] = [null,{v:line.desc,s:(line.bold?fmB13:fmL)},null,null,
      {t:"n",v:(bal= getSfpBal(line.link,values), gtot+= bal,bal),s:(line.bold?fmB13:fmL),z:fmZ},null,
      {t:"n",v:(bal1=getSfpBal(line.link,values,1),gtot1+=bal1,bal1),s:(line.bold?fmB13:fmL),z:fmZ}];
    }); cfRows[r+6+ri] = {'hpt':1};
    cfData[r+6+ri++] = [null,{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT},{v:"",s:fmBT}];
    cfData[r+6+ri++] = [null,{v:getCfLine(sec.totLine,gtot),s:(sec.bold&&sec.italic?fmB13I:fmB13I)},null,null,
      {t:"n",v:gtot,s:(sec.bold&&sec.italic?fmB13I:fmB13I),z:fmZ},null,
      {t:"n",v:gtot1,s:(sec.bold&&sec.italic?fmB13I:fmB13I),z:fmZ}]; cfRows[r+6+ri] = {'hpt':1};
    cfData[r+6+ri++] = [null,{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT},{v:"",s:fmBTT}];
  });
  //--------------------------------------------------------------------------------------------------------------------//
  //let idx = 0;
  if(keyNotes) { ri = 0; keyNotes.forEach((kNote, ki) => { //console.log("kNote:", kNote);
    if(!kNote.hidden&& dfNotes) { dfNotes.forEach((nt, ni) => { //
      //if(kNote.note===6&&ni===9) console.log("kNote:", kNote, ", ni:", ni, ", nt:", nt, ", ri:", ri);
      let tMax = false, formula = "";
      let ntName = nt.name; if(ntName.substring(0,2)==="&=") { ntName = ntName.substring(2);
        let result = ""; if(ntName[0]==="(") { const fi = ntName.indexOf(")");
          if(fi>=0) { formula = ntName.substring(0, fi+1); ntName = ntName.substring(fi+1);
            let frmNote = ""; const ni = formula.indexOf("{"); if(ni>=0) {
              const ne = formula.indexOf("}"); if(ne>=0) {
                frmNote = formula.substring(ni+1, ne); //console.log("frmNote:", frmNote);
                if(frmNote) { const fB=getNoteBal(frmNote,values.balances); //console.log("fB:", fB);
                  formula = formula.replace(`{${frmNote}}`,fB.bal.toString()); result = eval(formula);
          } } } } ntName = result + ntName;
        } //console.log("formula:", formula, ", result:", result);
      } let totLine = nt.totLine, totBold = (totLine!=="YN-"), totYN = (totLine=="YN");
      if(totLine==="YN"||totLine==="YN-"||totLine.substring(0,2)==="&=") totLine = "";
      let si = 1, ti = totLine.indexOf(")"); if(ti >= 0 && totLine.substring(0,5)==="&(YN)") {
        totYN = true; totBold = false; totLine = totLine.substring(ti+1);
      } else { ti = totLine.indexOf("&="); //console.log("ntName:", totLine, ", ti:", ti)
        if(ti >= 0) { formula = totLine.substring(ti); tMax = (formula.substring(2)==="Max");
        if(tMax) totLine = totLine.replace(formula,""); //console.log("formula:", formula, ", tMax:", tMax);
      } }
      const t=getNoteBal(nt.note,values.balances), t1=getNoteBal(nt.note,values.balance1);
      const m=getNoteMax(nt.note,values.balances), m1=getNoteMax(nt.note,values.balance1);
      const arr = nt.note.split("."), key = "N"+arr[0], kn = getKeyNote(keyNotes, key);
      let rest = ""; if(arr.length>1) rest = "."+arr[1];
      const fsTotL = {font:{bold:true}}; //medium
      const fsMidH = (nt.midHd1&&nt.midHd1.length<13?{alignment:{horizontal:"center"},font:{underline:true}}:
        {alignment:{horizontal:"center",wrapText:true},font:{italic:true}});
      const fsBTM = {border:{top:{style:"medium"}}};
      //if(kNote.note===6&&ni===9) console.log("kNote:", kNote, ", kn:", kn, (kn && !kn.hidden && kn.note===kNote.note));
      if(kn && !kn.hidden && kn.note===kNote.note) {
        //ntData[r+4+ri++] = [null, kn.note+rest, nt.note, nt.name]; //+ni
        //if(kNote.note===6&&ni===9 || (kNote.note===7&&(ni===4||ni===5))) console.log("kNote:", kNote, ", nt:", nt);

        //if(kNote.note===7&&(ni===4||ni===5)) console.log("note:", kNote.note, ", ni:", ni, ", ri:", ri, ", idx:", idx, ", ntData[idx]:", ntData[idx]);
        if(!(nt.note[nt.note.length-1]==="-" && nt.name==="N")) { //ri = ri + 1;
          //if(kNote.note===6&&ni===9) console.log("kNote:", kNote, ", ntName:", ntName, (r+4+ni+ri));
          ntData[r+4+ri++] = [null,(nt.note.substring(nt.note.length-1)==="-"?null:{v:(kn?kn.note+rest:nt.note),s:fmB12}), //+ni
            (nt.note.substring(nt.note.length-1)==="-"?null:{v:".",s:fmB13}),
            {v:ntName,s:fmB13},(nt.midHd1?{v:nt.midHd1,s:fsMidH}:null),(nt.midHd2?{v:nt.midHd2,s:fsMidH}:null)];
          //if(kNote.note===6&&ni===9) { idx = ntData.length-1;
          //console.log("kNote:", kNote, ", idx:", idx, ", ntData[idx]:", ntData[idx]); }
          //ri = ri + 1;
        }
        //if(kNote.note===7&&(ni===4||ni===5)) console.log("note:", kNote.note, ", ni:", ni, ", ri:", ri, ", idx:", idx, ", ntData[idx]:", ntData[idx]);

        nt.lines.forEach((line, li) => { if(li < 1) si = ri; //console.log("line:", line, ", ri:", ri);
          const bl = getBal(nt.note,li,values.balances), bl1 = getBal(nt.note,li,values.balance1);
          if(li<1 || line || bl.num || bl.bal || bl1.bal) { //ri = ri + 1;
            //if(kNote.note===7&&(ni===4||ni===5)) console.log("note:", kNote.note, ", ni:", ni, ", ri:", ri, ", idx:", idx, ", ntData[idx]:", ntData[idx]);
            ntData[r+4+ri++] = [null,"",null,line,(nt.midCol?{t:"n",v:bl.num,z:fmZ}:null), //+ni
            (nt.midCol==="R"?{v:getBal(nt.note,li,values.balances).num*100/(Math.abs(t.num)>0.001?t.num:1)+"%",s:fmR}:
            nt.midCol==="B"?{t:"n",v:(bl.bal-bl.num),z:fmZ}:null), //getBal(nt.note,li,values.balances)
            null,{t:"n",v:bl.bal,z:fmZ},null,{t:"n",v:bl1.bal,z:fmZ}]; //getBal(nt.note,li,values.balance1)
            //if(kNote.note===7&&(ni===4||ni===5)) console.log("note:", kNote.note, ", ni:", ni, ", ri:", ri, ", idx:", idx, ", ntData[idx]:", ntData[idx]);
          }
        });
        //if((kNote.note===6&&ni===9) || (kNote.note===7&&(ni===4||ni===5))) {
        //console.log("kNote:", kNote, ", nt:", nt);
        //console.log("note:", kNote.note, ", ni:", ni, ", ri:", ri, ", idx:", idx, ", ntData[idx]:", ntData[idx]);}
        if(!(nt.totLine=="N"||nt.totLine=="NN"||nt.totLine=="N-"||nt.totLine=="N--")){
          ntData[r+4+ri] = [null,"",null,{v:totLine,s:fsTotL}, //+ni
            (nt.midHd1?{t:"n",v:t.num,s:fsTotN,z:fmZ}:null),
            (nt.midCol==="R"?{v:(t.num>0.001?100:0)+"%",s:fsTotN}:
            (nt.midCol==="B"?{t:"n",v:(t.bal-t.num),s:fsTotN,z:fmZ}:null)),
            null,{t:"n",v:(tMax?m:t).bal,
            f:`${tMax?"MAX":"SUM"}(H${r+4+si}:H${r+4+ri})`,s:fsTotN,z:fmZ},null, {t:"n",v:(tMax?m1:t1).bal,
            f:`${tMax?"MAX":"SUM"}(J${r+4+si}:J${r+4+ri})`,s:fsTotN,z:fmZ}]; //+ni
        }
        if(!(totYN||nt.totLine=="YN-")) {
          if(!(nt.totLine=="NN"||nt.totLine=="N")) ri++;
          ntData[r+4+ri] = [null,"",null,{v:"",s:fsTotL}, //+ni
          (nt.midHd1 && !(nt.lines.length===0||nt.totLine=="N-"||nt.totLine=="N--")?{v:"",s:fsBTM}:null),
          (nt.midHd1?{v:"",s:fsBTM}:null),
          null,
          (nt.lines.length===0||nt.totLine=="N-"||nt.totLine=="N--"?null:{v:"",s:fsBTM}),null,
          (nt.lines.length===0||nt.totLine=="N-"||nt.totLine=="N--"?null:{v:"",s:fsBTM})];
          if(nt.totLine=="NN") { //console.log("nt.totLine:", nt.totLine, ", nt.name:", nt.name, ", ni:", ni, ", ri:", ri);
            //ri--; console.log("ri:", ri);
            ntRows[r+4+ri] = {'hpt':1}; //+ni //console.log("r+4+ni+ri:", (r+4+ni+ri));
        } } ri++;
        /*
        if(kNote.note===6&&ni===9) console.log("idx:", idx, ", ntData[idx]:", ntData[idx]);
        */
      }
    });}
  });}
  //console.log("idx:", idx, ", ntData[idx]:", ntData[idx]);
  //------------------------------------------------------------------------------------------------------------//
  const finExData = {
    "SFP":   {data: fpData, merge: [{s:{c:c,r:5}, e:{c:c+1,r:5}}, {s:{c:c+6,r:5}, e:{c:c+8,r:5}}], rows: fpRows,
              cols: [null,{wch:4.4},{wch:29.4},{wch:5},{wch:5},{wch:10},{wch:0.3},{wch:13.3},{wch:0.5},{wch:13.3},{wch:0.3},{wch:13.3}]},
    "PNL":   {data: plData, merge: [{s:{c:c,r:5}, e:{c:c+1,r:5}}, {s:{c:c+6,r:5}, e:{c:c+8,r:5}}], rows: plRows,
              cols: [null,{wch:2.4},{wch:33.4},{wch:5},{wch:5},{wch:8},{wch:0.3},{wch:13.3},{wch:0.5},{wch:13.3},{wch:0.3}]},
    "SCE":   {data: ecData, merge: [{s:{c:c,r:5}, e:{c:c+1,r:5}}, {s:{c:c+3,r:6}, e:{c:c+7,r:6}}], rows: ecRows,
              cols: [null,{wch:2.4},{wch:51.4},{wch:0.3},{wch:10.3},{wch:10.3},{wch:10.3},{wch:10.3},{wch:0.3},{wch:10.3}]},
    "SCF":   {data: cfData, merge: [{s:{c:c,r:5}, e:{c:c+1,r:5}}, {s:{c:c+3,r:5}, e:{c:c+5,r:5}}], rows: cfRows,
              cols: [null,{wch:2.4},{wch:52.4},{wch:0.3},{wch:13.3},{wch:0.3},{wch:13.3},{wch:0.3}]}, //,{wch:10.3},{wch:10.3}
    "Notes": {data: ntData, merge: [{s:{c:c+6,r:4}, e:{c:c+8,r:4}}], rows: ntRows,
              cols: [null,{wch:6.3},{wch:0.6},{wch:26.5},{wch:10.4},{wch:10},{wch:0.3},{wch:14.3},{wch:0.5},{wch:14.3}]},
    // "Annexure A": {data: aaData, merge: [{s:{c:c,r:5}, e:{c:c+1,r:5}},{s:{c:c,r:8}, e:{c:c,r:11}},{s:{c:c+1,r:8}, e:{c:c+1,r:11}},
    //           {s:{c:c+2,r:8}, e:{c:c+2,r:11}},{s:{c:c+3,r:8}, e:{c:c+6,r:8}},{s:{c:c+7,r:8}, e:{c:c+11,r:8}},
    //           {s:{c:c,r:22}, e:{c:c+1,r:22}},{s:{c:c,r:26}, e:{c:c+1,r:26}},
    //           {s:{c:c,r:34}, e:{c:c+1,r:34}},
    //           {s:{c:c,r:37}, e:{c:c,r:40}},{s:{c:c+1,r:37}, e:{c:c+1,r:40}},{s:{c:c+2,r:37}, e:{c:c+2,r:40}},
    //           {s:{c:c+3,r:37}, e:{c:c+6,r:37}},{s:{c:c+7,r:37}, e:{c:c+11,r:37}},
    //           {s:{c:c,r:47}, e:{c:c+1,r:47}},{s:{c:c,r:51}, e:{c:c+1,r:51}}], rows: aaRows,
    //           cols: [{wch:0.6},{wch:2.4},{wch:27},{wch:24},{wch:11.3},{wch:11.3},{wch:11.3},{wch:11.3},{wch:6.3},
    //           {wch:11.3},{wch:11.3},{wch:11.3},{wch:11.3},{wch:11.3},{wch:11.3}]},
    "Annexure A": {data: nxData, merge: nxMrge, rows: nxRows,
              cols: [{wch:0.6},{wch:2.4},{wch:27},{wch:24},{wch:11.3},{wch:11.3},{wch:11.3},{wch:11.3},{wch:6.3},
              {wch:11.3},{wch:11.3},{wch:11.3},{wch:11.3},{wch:11.3},{wch:11.3}]},
  }; //console.log("finExData:", finExData);
  return finExData;
};

const FinExcel = ({ excelSheets, fileName, disabled, children }) => { //excelData  //, cols = []
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx"

  const exportToExcel = async () => { //console.log("exportToExcel:: excelSheets:", excelSheets);
    // const ws = XLSX.utils.aoa_to_sheet(excelData);  //json
    const sheets = {}; //console.log("Object.entries(excelSheets):", Object.entries(excelSheets));
    for(const [key, data] of Object.entries(excelSheets)) { //console.log("key:", key, ", data:", data, (typeof data), Array.isArray(data));
      if((typeof data)==="object") {
        if(Array.isArray(data)) { //console.log("Array: key:", key, ", data:", data);
          sheets[key] = XLSX.utils.aoa_to_sheet(data);
        } else if(data.data) {
          sheets[key] = XLSX.utils.aoa_to_sheet(data.data);
          if(data.cols) sheets[key]['!cols'] = data.cols;
          if(data.rows) sheets[key]['!rows'] = data.rows;
          //sheets[key]['!rows'] = [null,null,null,{'hpx':1},{'hpt':1},{'hpx':1},{'hpt':80},{'hpx':80}]; //
          if(data.merge) sheets[key]['!merges'] = data.merge;
        }
        //sheets[key].sheet_view.showGridLines = false;
      }
    } //console.log("exportToExcel:: sheets", sheets, ", keys:", Object.keys(sheets));
    // if(cols && cols.length>0) ws['!cols'] = cols; //[{width:20},{width:20},{width:10}];
    // const wb = { Sheets: {"data": ws}, SheetNames: ["data"] };
    const wb = { Sheets: sheets, SheetNames: Object.keys(sheets) };
    const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Tooltip title="Excel Export">
      <Button variant="contained" onClick={e => exportToExcel()} color="primary" disabled={disabled}
        style={{ cursor: "pointer", fontSize: 14 }}>{children}{/* Fin Excel */}
      </Button>
    </Tooltip>
  );
};

export default FinExcel;
