import {ChangeEvent, SyntheticEvent} from "react";
import {FormGroup, FormLabel, TextField, Autocomplete} from "@mui/material";
import {Allocation, getResourceNames, getResourcePay} from "../../../Data/DocumentData";

const col1="1.7vw", col23="18.35vw", col4="5.8vw";
const col5="10vw", col6="5.1vw", col7="6vw", col8="6.05vw";

interface IProps {
  allocation: Allocation, disabled: boolean, efficiency: number,
  textProps: {onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void},
  autoProps: {onChange: (event: SyntheticEvent<Element, Event>, value: string | null) => void},
};

export default function AllocationRow({ allocation, disabled, textProps,
  autoProps, efficiency }: IProps) { let time, pay, minAmt;
  return (<>
    <FormGroup row>
      <FormLabel sx={{paddingTop: 1, paddingBottom: 1, textAlign: "center", width: col1,
        border: 1}}>{allocation? allocation.sl: ""}
      </FormLabel>
      <FormLabel sx={{paddingTop:(allocation.proc.length>38?0:1), paddingLeft:1, width:col23,
        lineHeight: 1.25, border: 1}}>{allocation.proc}
      </FormLabel>
      <TextField id={`time_${allocation.sl}`} name="time" size="small" sx={{width: col4}}
        variant="outlined" value={allocation.time} {...textProps} disabled={disabled}
      />
      <Autocomplete options={getResourceNames()} size="small" sx={{width: col5}}
        renderInput={(params) => (<TextField name="resource" {...params} />)}
        getOptionLabel={(option) => `${option}`} disabled={disabled}
        renderOption={(props, option) => (<li {...props}>{`${option}`}</li>)}
        value={allocation.resource || ""} disablePortal {...autoProps}
        isOptionEqualToValue={(option, value) => option === value || value === ""}
      />
      <FormLabel sx={{paddingTop: 1, paddingBottom: 1, textAlign: "center", width: col6,
        border: 1}}>{getResourcePay(allocation.resource)} /hr
      </FormLabel>
      <FormLabel sx={{paddingTop: 1, paddingRight: 1, textAlign: "right", width: col7, border: 1}}>
        {(time=parseFloat(allocation.time),!isNaN(time)?(time<0?"(":"")+"BDT "+(
          Math.ceil(Math.abs((getResourcePay(allocation.resource)*time/60)) /10) * 10
        ).toString()+(time<0?")":""):"#N/A")}
      </FormLabel>
      <FormLabel sx={{paddingTop: 1, paddingRight: 1, textAlign: "right", width: col8, border: 1}}>
        {(time=parseFloat(allocation.time),!isNaN(time)?(time<0?"(":"")+"BDT "+(Math.ceil(Math.abs((
          minAmt=parseFloat(allocation.minAmt), pay=getResourcePay(allocation.resource)
          *time/60, minAmt<1? pay*(1 + efficiency/100): Math.max(pay,minAmt)
        )) /10) * 10).toString()+(time<0?")":""):"#N/A")}
      </FormLabel>
    </FormGroup>
  </>);
};
