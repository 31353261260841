import { StyleSheet } from '@react-pdf/renderer';

// Create Styles
const styles = StyleSheet.create({
  sidebar: { width: "32vw", height: "88vh", float: "left", //45vw, 42vw, 39vw, 35vw
    backgroundColor: "rgba(0,100,200,0.3)" },
  sidemenu: { backgroundColor: "#fff", margin: "9.2vh 1vw",
    padding: "3.4vh 3vw", height: "76.5vh" },
  h1: { color: "var(--primary-color)", fontSize: "160%", fontWeight: "500" },
  ul: { marginTop: "15px" },
  page: {
    // flexDirection: "row", backgroundColor: "#f4f4f4", padding: "36px 36px 0", // A4
    // flexDirection: "row", backgroundColor: "#f4f4f4", padding: "36px 148px 0 36px", // B4
    flexDirection: "row", backgroundColor: "#f4f4f4", padding: "36px 79px 0 30px", // B4 - 124px 0 32px
  },
  section: {
    margin: "10px 0 10px 10px", padding: "10px 0 10px 10px", flexGrow: 1, fontSize: "11px",
    fontFamily: "Roboto" //Bottom: 10px, 10px
  },
  window: { height: "81.65vh", width: "98%" },  //87.45vh, 54%
  mail: {
    position: "absolute", top: "7.1px", left: "67%", padding: "3px 15px",  //38.8%
    borderRadius: "5px", borderWidth: "1px"  //calc(15vw+32%)
  },
  save: {
    position: "absolute", top: "7.1px", left: "25%", padding: "3px 15px",
    borderRadius: "5px", borderWidth: "1px"  //calc(15vw+10%)
  },
  login: {
    position: "absolute", top: "0px", left: "100%", padding: "3px 15px",
    borderRadius: "5px", borderWidth: "1px"
  },
  size6_5: { fontSize: "6.5px" },
  size6_8: { fontSize: "6.8px" },
  size6_9: { fontSize: "6.9px" },
  size6_94: { fontSize: "6.94px" },
  size6_96: { fontSize: "6.96px" },
  size6_965: { fontSize: "6.965px" },
  size6_97: { fontSize: "6.97px" },
  size6_98: { fontSize: "6.98px" },
  size7: { fontSize: "7px" },
  size7_02: { fontSize: "7.02px" },
  size7_025: { fontSize: "7.025px" },
  size7_03: { fontSize: "7.03px" },
  size7_04: { fontSize: "7.04px" },
  size7_1: { fontSize: "7.1px" },
  size7_12: { fontSize: "7.12px" },
  size7_14: { fontSize: "7.14px" },
  size7_16: { fontSize: "7.16px" },
  size7_2: { fontSize: "7.2px" },
  size7_5: { fontSize: "7.5px" },
  size8: { fontSize: "8px" },
  size9: { fontSize: "9px" },
  size9_4: { fontSize: "9.4px" },
  size9_45: { fontSize: "9.45px" },
  size9_48: { fontSize: "9.48px" },
  size9_485: { fontSize: "9.485px" },
  size9_49: { fontSize: "9.49px" },
  size9_5: { fontSize: "9.5px" },
  size9_6: { fontSize: "9.6px" },
  size10: { fontSize: "10px" },
  size12: { fontSize: "12px" },
  bold: { fontWeight: "700" },
  title: { textAlign: "center", fontWeight: "700" },
  underline: { textDecoration: "underline" },
  // indented: { margin: "0 72px 0 37px" }, // A4
  // indented: { margin: "0 74px 0 37px" }, // B4
  indented: { margin: "0 76px 0 38px" }, // B4
  indentLeft: { marginLeft: "38px" }, // B4
  halfIndented: { margin: "0 76px 0 19px" }, // B4
  halfIndentLeft: { marginLeft: "19px" }, // B4
  justify: {textJustify: "inter-word", textAlign: "justify"},
  bordered: { border: "1px solid #000" },
  row: { display: "flex" }, hidden: { display: "none" },
  relative: { position: "relative" },

  //text: { marginTop:"0", marginBottom:"0", paddingTop:"0", paddingBottom:"0", display: "inlineBlock" }, //width: "100%", flex: "1"   // width: "95%",
  text: { width: "100%", flex: "1" },

  left: { width: "50%", position: "absolute", left: "-38px"}, // B4
  halfLeft: {left: "-19px"}, // B4
  right: {flex: "1", textAlign: "right"},
  middle: { marginLeft: "55.7%", paddingLeft: "18px"
    , width: "38%", textAlign: "center", flex: "1"}, //
  borderTop: { borderTop: "1px solid #000" },
  borderLeft: { borderLeft: "1px solid #000" },
  borderRight: { borderRight: "1px solid #000" },
  borderBottom: { borderBottom: "1px solid #000" },
  borderBottomThick: { borderBottom: "2px solid #000" },
  marginTop: { marginTop: "10px" },
  red: { color: "red" },
});

export default styles;
