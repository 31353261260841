import { useState, useEffect } from "react";
import TopBar from "../../components/topbar/TopBar";
import "./HomePage.css";

function HomePage({ scrollTop, lang }) {
  const [promo, setPromo] = useState("one"); if(!lang) lang = "EN";
  const [bangla, setBangla] = useState(lang.toLowerCase()==="bn");
  // console.log("lang:", lang);
  // useEffect(()=>{ console.log('HomePage: i fire once'); },[]);
  useEffect(() => {
    const interval = setInterval(() => {
      setPromo(promo => { // console.log("promo:", promo);
        switch(promo) {
          case "one": return "two";
          case "two": return "three";
          case "three": return "four";
          case "four": return "five";
          case "five": return "six";
          default: return "one";
        }
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="homepage" id="home">
      <TopBar scrollTop={scrollTop} bangla={bangla} />
      <div className={`banner ${promo}`}>
        <figure>
          <img className="one" src="/img/audit/Audit_walpaper.jpg" alt="Audit walpaper" />
          <img className="two" src="/img/audit/Audit_12.jpg" alt="Audit 12" />
          <img className="three" src="/audit.jpg" alt="Audit" />
          <img className="four" src="/img/audit/Logo_3.jpg" alt="Logo 3" />
          <img className="five" src="/img/audit/Audit_10.webp" alt="Audit 10" />
          <img className="six" src="/manufacturing.webp" alt="Manufacturing" />
        </figure>
        {/* <div className="overlay"></div> */}
      </div>
      {/* <div className="promo-container">
        <div className={`promo ${promo}`}>
          <div className="left">
            <div className="promo1"><img src="/img/agro_1.jpg" alt="Agro 1" /></div>
            <div className="promo2"><img src="/img/agro_3.jpg" alt="Agro 3" /></div>
            <div className="promo3"><img src="/img/agro_5.jpg" alt="Agro 5" /></div>
          </div>
          <div className="right">
            <div className="promo1"><img src="/img/agro_2.jpg" alt="Agro 2" /></div>
            <div className="promo2"><img src="/img/agro_4.jpg" alt="Agro 4" /></div>
            <div className="promo3"><img src="/img/agro_6.jpg" alt="Agro 6" /></div>
          </div>
        </div>
      </div> */}

      <section className="biz" id="services">
        <h2>{bangla?"সব অডিটিং সল্যুশন এক প্ল্যাটফর্মে":"One Platform for Auditing Solutions"}</h2>
        <div className="service-blocks">
          <div className="service-block">
            <div><i className="fa-solid fa-book"></i></div>
            <h3>{bangla?"লেজার":"Ledger"}</h3>
            <br></br><br></br><br></br><br></br>
            <a href="#">{bangla?"বিস্তারিত জানুন":"Learn More"}</a>
          </div>
          <div className="service-block">
            <div><i className="fa-solid fa-sack-dollar"></i></div>
            <h3>{bangla?"অডিট":"Audit"}</h3>
            <br></br><br></br><br></br><br></br>
            <a href="#">{bangla?"বিস্তারিত জানুন":"Learn More"}</a>
          </div>
          <div className="service-block">
            <div><i className="fa-solid fa-computer"></i></div>
            <h3>{bangla?"সফটওয়্যার":"Software"}</h3>
            <br></br><br></br><br></br><br></br>
            <a href="#">{bangla?"বিস্তারিত জানুন":"Learn More"}</a>
          </div>
          <div className="service-block">
            <div><i className="fa-solid fa-hands-holding"></i></div>
            <h3>{bangla?"সাপোর্ট":"Support"}</h3>
            <br></br><br></br><br></br><br></br>
            <a href="#">{bangla?"বিস্তারিত জানুন":"Learn More"}</a>
          </div>
        </div>
        {/* <img src="/orko_mobile.jpg" alt="RS App Mobile" /> */}
        <h4>{bangla?"RS কম্যুনিকেশন্স লিমিটেড-এর সার্ভিস সম্পর্কে আরো জানুন":"Learn More about the Services of RS Communications Ltd"}</h4>
        <div className="biz-services">
          <div className="service">
            <i className="fa-solid fa-book"></i><h5>{bangla?"লেজার":"Ledger"}</h5>
          </div>
          <div className="service">
            <i className="fa-solid fa-sack-dollar"></i><h5>{bangla?"অডিট":"Audit"}</h5>
          </div>
          <div className="service">
            <i className="fa-solid fa-computer"></i><h5>{bangla?"সফটওয়্যার":"Software"}</h5>
          </div>
          <div className="service">
            <i className="fa-solid fa-hands-holding"></i><h5>{bangla?"সাপোর্ট":"Support"}</h5>
          </div>
          <div className="service">
            <i className="fa-solid fa-gears"></i><h5>{bangla?"সাপ্লাইয়ার সংযোগ":"Supplier Connect"}</h5>
          </div>
          <div className="service">
            <i className="fa-solid fa-bullhorn"></i><h5>{bangla?"সংবাদ আপডেট":"News & Updates"}</h5>
          </div>
        </div>
      </section>

      <section className="download" id="download">
        <img className="mobile" src="/RS_mobile.jpg" alt="RS Mobile" />
        <h2>{bangla?"RS অ্যাপ ডাউনলোড করুন":"Download RS App"}</h2>
        <p>{bangla?"RS অ্যাপ থেকে সহজেই একাউন্ট খুলুন":"Open your Account from the RS App in a few simple steps"}</p>
        <img className="social google" src="/Google-Play-Icon.png" alt="Google-Play-Icon" />
        <img className="social apple" src="/Download_on_the_App_Store_Badge.svg.png" alt="app-store-apple" />
        <img className="logo" src="RSComms1.png" alt="RSComms Logo" />
        <div className="img-text audit">Audit</div>
        <div className="img-text software">Software</div>
        <div className="img-text support">Support</div>
      </section>

      {/* <div className="divider-box" id="about"></div>
      <div className="about-head"><h1>About Us</h1></div>
      <div className="about">
        <p>
          Lalkella Solutions Limited is a Management Consulting firm registered in August 31,
          2022 with an objective to <strong><em>support CMSMEs</em></strong> for <em>Business Development</em>, Brand Promotion,
          Digital Marketing, New Product Launch, Human Resources <em>recruitment & Training</em>, Retail
          Distribution channel set up, Project management, Franchising, <em>Fund raising</em> etc.
        </p>
      </div>
      <div className="about-foot"></div> */}

      {/* <div className="divider-box" id="vision"></div>
      <div className="vision-head"><h1>Vision &</h1><br /><h1>Values</h1></div>
      <div className="vision">
        <p>
          Lalkella is digital platform that provides CMSME customers management
          guidance & financial solution from partner Banks & Financial Institutions
          thereby serving their financial and operational needs.
        </p>
        <div className="left">
          <h3><i className="fa-regular fa-eye"></i> Vision</h3>
          <p>The <em>most preferred organization for the development of progressive Micro
             Enterprises</em> to enhance wealth creation and social well-being of the nation.</p>
        </div>
        <div className="right">
          <h3><i className="fa-solid fa-scale-balanced"></i> Values</h3>
          <p>Our exceptional client service is deeply entwined in a <strong>
            <span>C</span><span>H</span><span>A</span><span>I</span><span>N</span> </strong>
             value system</p>
          <ul>
            <li><i className="fa-regular fa-handshake"></i> <strong><span>C</span>ollaboration</strong> - we work
             together to achieve collective and individual goals.</li>
            <li><i className="fa-solid fa-brain"></i> <strong><span>H</span>olistic perspective</strong> - we seek to
             continuously identify competitive advantage in all of our engagements through an outside-in
             and inside-out approach and convey a holistic perspective to our clients/ engagement partners
             to empower objective decision making based on rigorous data seeding & analysis</li>
            <li><i className="fa-solid fa-hand-point-right"></i> <strong><span>A</span>ccountability</strong> - We
             take responsibility for individual and collective actions</li>
            <li><i className="fa-brands fa-medium"></i> <strong><span>I</span>mpact</strong> - we are inspired
             to make a positive & lasting social impact!!!</li>
            <li><i className="fa-brands fa-pagelines"></i> <strong><span>N</span>urturing relationships</strong> - the way
             we treat each other in achieving our objectives is just as important as what we achieve</li>
          </ul>
        </div>
      </div>
      <div className="vision-foot"></div> */}

      {/* <div className="divider-box" id="services"></div>
      <div className="services-head"><h1><span>S</span>ervices</h1></div>
      <div className="services">
        <img src="/products.jpg" />
        <ul>
          <li><i className="fa-solid fa-person-chalkboard"></i> Expert opinion</li>
          <li><i className="fa-solid fa-magnifying-glass"></i> Market Research</li>
          <li><i className="fa-solid fa-plane-departure"></i>
            Product Launching</li>
          <li><i className="fa-solid fa-bullhorn"></i> Brand Promotion</li>
          <li><i className="fa-solid fa-users"></i> Recruitment</li>
          <li><i className="fa-solid fa-graduation-cap"></i> Training</li>
          <li><i className="fa-solid fa-computer"></i> IT Solutions</li>
          <li><i className="fa-solid fa-sack-dollar"></i> Fund Raising</li>
        </ul>
      </div>
      <div className="services-foot"></div> */}

    </div>
  );
}

export default HomePage;
