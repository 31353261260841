import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: false };

export const uniqNamyrSlice = createSlice({
  name: "uniqNamyr", initialState, reducers: {
    setUniqNamyr: (state, action) => {
      //console.log("setUniqNamyr:: state.value:", state.value, ", action.payload:", action.payload);
      state.value = action.payload;
      //console.log("setUniqNamyr:: state.value:", state.value);
    }
  }
});

export const { setUniqNamyr } = uniqNamyrSlice.actions;
export default uniqNamyrSlice.reducer;
