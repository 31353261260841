import React, { useState, useEffect } from 'react';
import './TopBar.css';
let hoveringDiv = false, hoveringNav = false, j = -1;

// React component returns JSX kind of markup
function TopBar({ scrollTop, bangla }) { // console.log("window.innerWidth: ", window.innerWidth);
  const [search, setSearch] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [showDropdown, setShowDropdown] = useState([]);
  const [hovering, setHovering] = useState([]);
  // const [j, setJ] = useState(-1); //console.log("TopBar: j =", j, ", showDropdown =", showDropdown);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth>990);
  window.onresize = () => setIsDesktop(window.innerWidth>990);
  const height = isDesktop?"62px":"72px";
  function searchHandler() { // console.log("searchHandler:", search);
    setSearch(prevSearch => !prevSearch);
  } // console.log('TopBar: scrollTop -', scrollTop);
  const menuClickHandler = () => setMenuActive(prevActive => !prevActive);
  const mouseEnterHandler = function(i) { // console.log("mouseEnterHandler: i =", i);
    if(i>0) hoveringNav = true;
    const newValues = []; newValues[i] = true; setHovering(newValues);
  }
  const mouseLeaveHandler = function(i) { // console.log("mouseLeaveHandler: i =", i);
    hoveringNav = false;
    setTimeout(() => { // console.log("hoveringDiv =", hoveringDiv);
      // if(!hoveringDiv) setHovering([]);
      if(!hoveringDiv && !hoveringNav) {
        setHovering(prevValues => { // console.log("i =", i, ", j =", j);
          const newValues = prevValues; //.map((v, indx) => (indx===i?v:false));
          newValues[i] = false; // console.log("i =", i, ", newValues[i] =", newValues[i]);
          return newValues;
        }); if(i>0) mouseEnterHandler(0);
      }
    }, 300);
  }
  const subMenuHandler = function(i, e) { // console.log("subMenuHandler: i =", i, ", j =", j);
    // console.log("subMenuHandler: i =", i, ", j =", j, ", showDropdown =", showDropdown);
    e.stopPropagation(); // console.log("e:", e);
    if (i>0) { j=i; setTimeout(() => {j=0}, 100); //setJ(i), setJ(0)
      setTimeout(() => { // console.log("i =", i); //setShowDropdown([]);
        setHovering([]); setShowDropdown(prevValues => { // console.log("i =", i, ", j =", j);
        // const newValues = prevValues.map(v => v);
        const newValues = prevValues.map((v, indx) => (indx===i?v:false));
        // console.log("newValues:", newValues);
        // console.log("newValues[i]:", newValues[i], ", !newValues[i]:", !newValues[i]);
        newValues[i] = !newValues[i]; //console.log("newValues:", newValues);
        return newValues;
      });}, 1);
    } else if (j===0) {
      setShowDropdown([]); j = -1; // console.log("Emptied showDropdown!"); setJ(-1)
    }
    return false;
  }
  const appClickHandler = () => { console.log("appClickHandler");
    window.location.href = "#download";
  }
  // useEffect(()=>{ console.log('i fire once'); },[]);
  useEffect(() => { // console.log("useEffect");
    window.addEventListener("keyup", e => { // console.log("e.key:", e.key);
      if(e.key === "Escape") { setShowDropdown([]); setHovering([]); }
    });
  }, [setShowDropdown, setHovering]);
  return (
    <>
      {/* <nav className="topbar-top">
        <ul className="topbar-topmenu">
          <li className="topbar-topitem topbar-sm-hide"><a href="#">downloads</a></li>
          <li className="topbar-topitem"><a href="#">contact us</a></li>
          <li className="topbar-topitem">
            <a href="#" className="highlight">locations</a>
          </li>
          <li className="topbar-topitem topbar-sm-hide">
            <a href="#">complaint cell <img src="/call-icon-ani.gif" alt="complaint-cell" /></a>
          </li>
        </ul>
      </nav> */}

      {/* <div className="topbar-logo">
        <div className="topbar-logo-img"><img src="/logo.png" alt="Logo" /></div>
        <div className="topbar-logo-srch">
          <span onClick={searchHandler}>search <i className="fa-solid fa-magnifying-glass"></i></span>
        </div>
      </div> */}

      <header className="topbar-nav" style={{height: (isDesktop?"auto":"auto")
        , position: (menuActive && !isDesktop?"relative":"sticky")}}
        onClick={subMenuHandler.bind(this,0)}>
        {/* <div className="topbar-srch" style={{height: (search?height:"0px"),
          zIndex: (search?15:9), padding: (search?"10px 0":"0px")}}>
          <form className="topbar-srch-frm" action="#" method="GET">
            <div className="topbar-srch1">
              <input className="topbar-srch1-inp" type="text" name="keyword" placeholder="Tell us what you are looking for" />
            </div>
            <div className="topbar-srch2">
              <input className="topbar-srch2-btn" type="submit" value="Search" />
              <span className="topbar-srch2-cls" onClick={searchHandler}>Close</span>
            </div>
          </form>
        </div> */}
        <nav className="topbar-nav1" >
          <div className="logo">
            <a href="#home">
              {/* <i className="fa-solid fa-house-chimney"></i> */}
              {/* {bangla?(<div style={{padding: "0 87px 0 0px", backgroundColor: "transparent",
                height: "60.4px"}}><img src="RSComms1.png" alt="RSComms Logo" /><p style={{color: "#fff",
                position: "absolute", top: "18px", right: "16px", fontSize: "130%",
                fontWeight: 400}}>লিমিটেড</p></div>)
              :<img src="RSComms1.png" alt="RSComms Logo" />} */}
              {(<div style={{padding: "0 87px 0 0px", backgroundColor: "transparent",
                height: "60.4px"}}><img src="RSComms1.png" alt="RSComms Logo" /><p style={{
                color: "#666", position: "absolute", // #00338d
                top: (bangla?"15px":"6px"), left: "265px", fontSize: (bangla?"150%":"200%"),
                fontWeight: 300, fontStretch: (bangla?"ultra-expanded":"ultra-condensed"),
                letterSpacing: (bangla?"1px":"0"),
                // fontFamily: ["Arial Narrow", "Arial", "sans-serif"]
              }}>{bangla?"লিমিটেড":"Limited"}</p></div>)}
            </a>
          </div>
          <ul style={{display: (isDesktop||menuActive?"inline-block":"none")}}>
            {/* <li><a href="#vision">{bangla?"দৃষ্টি এবং মূল্যবোধ":"Vision & Values"}</a></li> */}
            {/* <li><a href="#services">{bangla?"সেবা সমূহ":"Services"}</a></li> */}
            <li className={`${showDropdown[1]?"showDropdown":""}`}>
              <a href="#" onClick={subMenuHandler.bind(this,1)} onMouseEnter={mouseEnterHandler.bind(this,1)}
              onMouseLeave={mouseLeaveHandler.bind(this,1)}>{bangla?"ISA অডিট সফটওয়্যার":"ISA audit software"}</a>
              {/* <span onClick={subMenuHandler.bind(this, 1)}>
                <i className="fa-solid fa-chevron-down"></i>
              </span> */}
              {/* <ul className="topbar-nav1-dropdown">
                <li><a href="#">{bangla?"কোটেশন এবং চিঠি [ISA210]":"Quotation & Letters [ISA210]"}</a></li>
                <li><a href="#">{bangla?"রিস্ক অ্যাসেসমেন্ট [ISA330/315] এবং প্ল্যানিং [ISA300]":"Risk assessment [ISA330/315] & Planning [ISA300]"}</a></li>
                <li><a href="#">{bangla?
                  "ফিনান্সিয়ালস ফরম্যাটিং [IFRS] এবং ডকুমেন্টেশন [ISA230/500/ 501/505/510/520/530/560]":"Financials formatting [IFRS] & Documentation [ISA230/500/ 501/505/510/520/530/560]"
                }</a></li>
                <li><a href="#">{bangla?"ক্লায়েন্ট লিস্ট, কস্ট এবং PnL [ICAB রিটার্ন/ FRC]":"Client list, Costs & PnL [ICAB return/ FRC]"}</a></li>
                <li><a href="#">{bangla?"ISA, অ্যাক্ট, FRC এবং রেগুলেটরি কমপ্লায়েন্স":"ISA, Act, FRC & regulatory compliances"}</a></li>
                <li><a href="#">{bangla?"ম্যানেজমেন্ট লেটার":"Management letter"}</a></li>
              </ul> */}
              <div className="dropdown-box" style={{display:`${showDropdown[1]||hovering[1]?"block":"none"}`}}
               onMouseEnter={()=>{hoveringDiv=true;}} onMouseLeave={()=>{hoveringDiv=false;setTimeout(()=>{if(!hoveringNav)mouseEnterHandler(0)},300)}}>
                <div className="container">
                  <nav>
                    <ul className="menu">
                      <li><a href="/letters">{bangla?"কোটেশন এবং চিঠি [ISA210]":"Quotation & Letters [ISA210]"}</a></li>
                      <li><a href="#">{bangla?"রিস্ক অ্যাসেসমেন্ট [ISA330/315] এবং প্ল্যানিং [ISA300]":"Risk assessment [ISA330/315] & Planning [ISA300]"}</a></li>
                      <li><a href="#">{bangla?
                        "ফিনান্সিয়ালস ফরম্যাটিং [IFRS] এবং ডকুমেন্টেশন [ISA230/500/ 501/505/510/520/530/560]":"Financials formatting [IFRS] & Documentation [ISA230/500/ 501/505/510/520/530/560]"
                      }</a></li>
                      <li><a href="#">{bangla?"ক্লায়েন্ট লিস্ট, কস্ট এবং PnL [ICAB রিটার্ন/ FRC]":"Client list, Costs & PnL [ICAB return/ FRC]"}</a></li>
                      <li><a href="#">{bangla?"ISA, অ্যাক্ট, FRC এবং রেগুলেটরি কমপ্লায়েন্স":"ISA, Act, FRC & regulatory compliances"}</a></li>
                      <li><a href="#">{bangla?"ম্যানেজমেন্ট লেটার":"Management letter"}</a></li>
                    </ul>
                  </nav>
                  <div>
                    <a href="#" className="card">
                      <div className="img-container">
                        <img className="top" src="/img/kpmg/glasses.png" />
                        <div className="gradient-blurred"></div>
                        <img className="below" src="/img/kpmg/glasses.png" />
                      </div>
                      <div className="card-body">
                        <div className="card-info">
                          <h4>Value of Audit Trust through transparency</h4>
                          <p>
                            Auditors, organizations and investors all have important roles to play in
                            working to maintain the flow of capital that underpins the capital...
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div>
                  <a href="#" className="card">
                      <div className="img-container">
                        <img className="top" src="/img/audit/Audit_2.jpg" />
                        <div className="gradient-blurred"></div>
                        <img className="below" src="/img/audit/Audit_2.jpg" />
                      </div>
                      <div className="card-body">
                        <div className="card-info">
                          <h4>High accuracy level of Audit through using software</h4>
                          <p>
                            We leverage the power of technology and software for auditing.
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li className={`${showDropdown[2]?"showDropdown":""}`}>
              <a href="#" onClick={subMenuHandler.bind(this,2)} onMouseEnter={mouseEnterHandler.bind(this,2)}
              onMouseLeave={mouseLeaveHandler.bind(this,2)}>{bangla?"ইনকাম ট্যাক্স/ ভ্যাট সফটওয়্যার":"Income tax/ VAT software"}</a>
              {/* <span onClick={subMenuHandler.bind(this, 2)}>
                <i className="fa-solid fa-chevron-down"></i>
              </span> */}
              {/* <ul className="topbar-nav1-dropdown">
                <li><a href="#">{bangla?"কর্পোরেট ইনকাম ট্যাক্স":"Corporate Income tax"}</a></li>
                <li><a href="#">{bangla?"স্বতন্ত্র রিটার্ন":"Individual return"}</a></li>
                <li><a href="#">{bangla?"ভ্যাট রিটার্ন":"VAT return"}</a></li>
                <li><a href="#">{bangla?"ভ্যাট চালান":"VAT challan"}</a></li>
              </ul> */}
              <div className="dropdown-box" style={{display:`${showDropdown[2]||hovering[2]?"block":"none"}`}}
               onMouseEnter={()=>{hoveringDiv=true}} onMouseLeave={()=>{hoveringDiv=false;setTimeout(()=>{if(!hoveringNav)mouseEnterHandler(0)},300)}}>
                <div className="container">
                  <nav>
                    <ul className="menu">
                      <li><a href="#">{bangla?"কর্পোরেট ইনকাম ট্যাক্স":"Corporate Income tax"}</a></li>
                      <li><a href="#">{bangla?"স্বতন্ত্র রিটার্ন":"Individual return"}</a></li>
                      <li><a href="#">{bangla?"ভ্যাট রিটার্ন":"VAT return"}</a></li>
                      <li><a href="#">{bangla?"ভ্যাট চালান":"VAT challan"}</a></li>
                    </ul>
                  </nav>
                  <div>Flex 2</div>
                  <div>Flex 3</div>
                </div>
              </div>
            </li>
            <li className={`${showDropdown[3]?"showDropdown":""}`}>
              <a href="#" onClick={subMenuHandler.bind(this,3)} onMouseEnter={mouseEnterHandler.bind(this,3)}
              onMouseLeave={mouseLeaveHandler.bind(this,3)}>{bangla?"IFRS ফিনান্সিয়াল স্টেটমেন্ট":"IFRS Financial Statements"}</a>
              {/* <span onClick={subMenuHandler.bind(this, 3)}>
                <i className="fa-solid fa-chevron-down"></i>
              </span> */}
              {/* <ul className="topbar-nav1-dropdown">
                <li><a href="#">{bangla?"ভাউচার":"Vouchers"}</a></li>
                <li><a href="#">{bangla?"লেজার":"Ledger"}</a></li>
                <li><a href="#">{bangla?"ট্রায়াল ব্যালেন্স":"Trial balance"}</a></li>
                <li><a href="#">{bangla?"IFRS ফিনান্সিয়াল স্টেটমেন্ট":"IFRS financial statements"}</a></li>
              </ul> */}
              <div className="dropdown-box" style={{display:`${showDropdown[3]||hovering[3]?"block":"none"}`}}
               onMouseEnter={()=>{hoveringDiv=true}} onMouseLeave={()=>{hoveringDiv=false;setTimeout(()=>{if(!hoveringNav)mouseEnterHandler(0)},300)}}>
                <div className="container">
                  <nav>
                    <ul className="menu">
                      <li><a href="#">{bangla?"ভাউচার":"Vouchers"}</a></li>
                      <li><a href="#">{bangla?"লেজার":"Ledger"}</a></li>
                      <li><a href="#">{bangla?"ট্রায়াল ব্যালেন্স":"Trial balance"}</a></li>
                      <li><a href="/financials">{bangla?"IFRS ফিনান্সিয়াল স্টেটমেন্ট":"IFRS financial statements"}</a></li>
                    </ul>
                  </nav>
                  <div>Flex 2</div>
                  <div>Flex 3</div>
                </div>
              </div>
            </li>
            {/* <li><a href="#about">{bangla?"পরিচিতি":"About"}</a></li> */}
            {/* <li><a href="#blog">{bangla?"ব্লগ":"Blog"}</a></li> */}
            {/* <li className="lang">
              <a href="/en" style={{fontWeight: (bangla?"":"bolder")}}>Eng</a> |
              <a href="/" style={{fontWeight: (bangla?"bolder":"")}}>বাংলা</a>
            </li> */}
            {/* <li className="btn">
              <div></div><button onClick={appClickHandler}>
                {(bangla?"RS অ্যাপ":"RS App")}
              </button><div></div>
            </li> */}
            {/* <li className={`imp menu ${showDropdown[2]?"showDropdown":""}`}>
              <a href="#"><img src="logo1.png" /><div className="screen"></div></a>
              <span onClick={subMenuHandler.bind(this, 2)}>
                <i className="fa-solid fa-chevron-down"></i>
              </span>
              <ul className="topbar-nav1-dropdown">
                <li><a href="#">Partners</a></li>
                <li><a href="#">Agents</a></li>
                <li><a href="#">Promotions</a></li>
              </ul>
            </li> */}
            {/* <li className={`imp logo${scrollTop<98?"":" stk"}`}></li> */}
          </ul>
          <div className="right">
            {/* <div className="btn">
              <div></div><button onClick={appClickHandler}>
                {(bangla?"RS অ্যাপ":"RS App")}
              </button><div></div>
            </div> */}
            <div className="lang">
              <a href="/" style={{fontWeight: (bangla?"":"bolder")}}>Eng</a> |
              <a href="/bn" style={{fontWeight: (bangla?"bolder":"")}}>বাংলা</a>
            </div>
          </div>
          <span className={`topbar-nav1-mobile${menuActive?" active":""}`} onClick={menuClickHandler}>
            Menu <i className="fa-solid fa-chevron-down"></i>
          </span>
        </nav>
        <div className={"topbar-nav2 "+(scrollTop<98?"bc":"st")+"kimg"} style={{display: "none",
          flex: (scrollTop<98?6.5:10.5), height: (scrollTop<98?"51px":"75px")}}></div>
        <div className="topbar-nav3"
          style={{display: "none", marginTop: (scrollTop<98?0:"12px")}}></div>
        <div className="topbar-nav4" style={{height:
          (isDesktop?"60.4px":!(menuActive&&scrollTop>97)?"46.5px":"34.5px")}}></div>
      </header>

      {/* <br />
      <div className="topbar-tut">
        <div className="topbar-left">
          <i className="topbar-social-icon fa-brands fa-instagram"></i>
          <i className="topbar-social-icon fa-brands fa-facebook"></i>
          <i className="topbar-social-icon fa-brands fa-linkedin"></i>
          <i className="topbar-social-icon fa-brands fa-twitter"></i>
        </div>
        <div className="topbar-center">
          <ul className="topbar-list">
            <li className="topbar-list-item dropdown">
              <a className="dropdown-btn">HOME</a>
              <ul className="dropdown-content">
                <li><a>Link 1</a></li>
                <li><a>Link 2</a></li>
                <li><a>Link 3</a></li>
              </ul>
            </li>
            <li className="topbar-list-item dropdown"><a className="dropdown-btn">ABOUT</a></li>
            <li className="topbar-list-item">CONTACT</li>
            <li className="topbar-list-item">CREATE LISTING</li>
            <li className="topbar-list-item">LOGOUT</li>
          </ul>
        </div>
        <div className="topbar-right">
          <img className="topbar-profile-pic" src="https://images.pexels.com/photos/2182970/pexels-photo-2182970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
          <i className="topbar-search fa-solid fa-magnifying-glass"></i>
        </div>
      </div> */}
    </>
  );
}

export default TopBar;
