// import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from "./pages/homepage/HomePage";
import FinancialsPage from "./pages/financialspage/FinancialsPage";
import LettersPage from "./pages/letterspage/LettersPage";
import LettersPageMUI from "./pages/letterspage-mui/LettersPage";
import MapsPage from "./pages/mapspage/MapsPage";

function App({ scrollTop }) {
  // useEffect(()=>{ console.log('App: i fire once'); },[]);
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage scrollTop={scrollTop} lang="en" />} />
        <Route path='/bn' element={<HomePage scrollTop={scrollTop} lang="bn" />} />
        <Route path='/financials' element={<FinancialsPage scrollTop={scrollTop} lang="en" />} />
        <Route path='/letters' element={<LettersPage scrollTop={scrollTop} lang="en" />} />
        <Route path='/areas' element={<MapsPage />} />
        <Route path='/letters-mui' element={<LettersPageMUI />} />
        <Route path='/letters-mui/:content' element={<LettersPageMUI />} />
      </Routes>
    </Router>
  );
}

export default App;
